import {EntityService} from 'src/service/EntityService';

export class OrganizationTaxService extends EntityService {

  static entity = '/iam-tax/organization';

  static getTaxList(fromDate: any, thruDate: any): Promise<any> {
    return this.axios.get(`${this.entity}/categories?fromDate=${fromDate}&thruDate=${thruDate}`).then(res => res.data);
  }
  static getTaxDetail(productCategoryId:string, fromDate: any, thruDate: any): Promise<any> {
    return this.axios.get(`${this.entity}/categories/${productCategoryId}?fromDate=${fromDate}&thruDate=${thruDate}`, ).then(res => res.data);
  }


}
