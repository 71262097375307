import {useEffect, useState} from 'react';

import {useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {ProductsService as Service} from './ProductsService';

const periodUoms = [
  {value: 'TF_mon', label: 'Tháng'},
  {value: 'TF_yr', label: 'Năm'}
];

export const Products = () => {

  const header = 'Gói dịch vụ';
  const dataKey = 'productId';

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    Service.getCategories().then(data => {
      setCategories(data.listData.map(item => {
        return {value: item.productCategoryId, label: item.categoryName}
      }));
    });
  }, []);

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    dataKey,
    columns: [
      {field: 'pseudoId', header: 'Mã gói', width: 150, matchMode: 'contains'},
      {field: 'productName', header: 'Tên gói', minWidth: 180, matchMode: 'contains'},
      {field: 'productCategory', header: 'Loại gói', width: 150, matchMode: 'contains'},
      {field: 'amountValue', header: 'Số lượng', width: 150, matchMode: 'contains'},
      {
        field: 'periodNum', header: 'Thời hạn sử dụng', width: 170, dataType: 'custom', customCell(rowData: any): any {
          return `${rowData.periodNum} ${rowData.periodUom}`
        }
      },
      {field: 'price', header: 'Giá', width: 150, matchMode: 'numeric' as any, dataType: 'number'},
      {
        field: 'promoQuantity', header: 'Khuyến mại', width: 150, dataType: 'custom', customCell(rowData: any): any {
          let value = [];
          if (rowData.promoQuantity) {
            value.push(`${rowData.promoQuantity} số`);
          }
          if (rowData.promoPeriodNum) {
            value.push(`${rowData.promoPeriodNum} tháng`);
          }
          return value.join(', ');
        }
      }
    ],
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    }
  });

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '60rem',
    fields: [
      {field: 'pseudoId', header: 'Mã gói', required: true, InputTextProps: {maxLength: 15}, className: 'md:col-4'},
      {field: 'productName', header: 'Tên gói', required: true, InputTextProps: {maxLength: 500}, className: 'md:col-8'},
      {field: 'productCategoryId', header: 'Loại gói', required: true, type: 'Dropdown', DropdownProps: {options: categories}, className: 'md:col-4'},
      {field: 'amountFixed', header: 'Số lượng', required: true, type: 'InputNumber', InputNumberProps: {min: 1}, className: 'md:col-4'},
      {field: 'price', header: 'Giá', type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-4'},
      {field: 'periodNum', header: 'Thời hạn sử dụng', required: true, type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-3'},
      {field: 'periodUomId', header: 'Đơn vị', required: true, type: 'Dropdown', DropdownProps: {options: periodUoms}, className: 'md:col-3'},
      {field: 'promoQuantity', header: 'Số lượng KM', type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-3'},
      {field: 'promoPeriodNum', header: 'Thời gian KM (tháng)', type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-3'}
    ],
    createItem: item => {
      return Service.create(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doUpdate = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.get(selectedItem[dataKey]).then(data => {
        update(data);
      });
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate: () => create(),
    hasSelectedItem: selectedItem,
    doUpdate
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12">
        {renderDataTable()}
      </div>

      {renderDialogCrup()}
    </div>
  );
}