import {ConfirmDialogService, ToastService, useDataTable, useDataTableBasic, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';
import {Fieldset} from 'primereact/fieldset';
import {CashBookService, CashBookService as Service} from './CashBookService';
import {Button} from 'primereact/button';
import {useEffect, useState} from 'react';
import {Histories} from './histories';

import {InputNumber} from 'primereact/inputnumber';


export const BankAccounts = () => {

  const header = 'Tài khoản ngân hàng';
  const dataKey = 'paymentMethodId';
  const updateTooltip = 'Cập nhật';
  const deleteTooltip = 'Xóa';
  const [display, setDisplay] = useState(null);
  const [items, setItems] = useState([]);

  useEffect(() => {
    CashBookService.cashAccount().then(data => {
      setItems([data])
    })
  }, []);

  const reload = () =>{
    CashBookService.cashAccount().then(data => {
      setItems([data])
    })
  }

  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        {/*<span>Ngân hàng</span>*/}
        <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"
                size="small" onClick={() => create()}/>
      </div>
    </div>
  );

  const {render: renderDataTable, reloadLazyData, selectedItems} = useDataTable({
    tableHeader: tabHeader,
    stateKey: 'mainTable',
    dataKey,
    columns: [
      // {field: 'paymentMethodTypeEnum', header: 'Loại quỹ', minWidth: 200, matchMode: 'contains'},
      {field: 'bankName', header: 'Tên ngân hàng', minWidth: 250, matchMode: 'contains'},
      {field: 'accountNumber', header: 'Số tài khoản', minWidth: 200, matchMode: 'contains'},
      {field: 'titleOnAccount', header: 'Chủ tài khoản', minWidth: 150, matchMode: 'contains'},
      {field: 'beginningBalance', header: 'Số dư ban đầu', minWidth: 150, matchMode: 'contains', dataType: 'number'},
      {field: 'availableBalance', header: 'Số dư hiện tại', minWidth: 150, matchMode: 'contains', dataType: 'number'},
      {field: 'balanceDate', header: 'Ngày cập nhật', minWidth: 150, matchMode: 'contains', dataType: 'date', align: 'right'},
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 120,
    getList: lazyLoadEvent => {
      return Service.bankAccount(lazyLoadEvent);
    },
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-pencil', title: updateTooltip, className: 'p-button-warning', command: () => doRowUpdate(item)},
            {icon: 'pi pi-history', title: 'Lịch sử giao dịch', className: 'p-button-secondary', command: () => setDisplay(item)},
            {icon: 'pi pi-trash', title: deleteTooltip, className: 'p-button-danger', command: () => doDelete(item)},
          ]
        })
      }
    }
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '40rem',
    fields: [
      {field: 'bankName', header: 'Tên ngân hàng', required: true, className: 'md:col-12'},
      {field: 'accountNumber', header: 'Số tài khoản', required: true, className: 'md:col-12'},
      {field: 'titleOnAccount', header: 'Chủ tài khoản', className: 'md:col-12'},
      {field: 'beginningBalance', header: 'Số dư ban đầu', className: 'md:col-12', type: 'InputNumber', InputNumberProps: {min: 0}},
    ],
    createItem: item => {
      return Service.createBank(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });

  const dialogCash = useDialogCrup({
    header: 'Quỹ tiền mặt',
    dataKey,
    width: '40rem',
    fields: [
      {field: 'beginningBalance', header: 'Số dư ban đầu', className: 'md:col-12', type: 'InputNumber', InputNumberProps: {min: 0}},
    ],
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData: reload
  });

  const {render: renderCash} = useDataTableBasic({
    tableHeader: '',
    dataKey,
    indexColumnWidth: 45,
    columns: [
      {field: 'availableBalance', header: 'Số dư hiện tại', minWidth: 150},
      {field: 'beginningBalance', header: 'Số dư ban đầu', minWidth: 150},
      {field: 'transAmount', header: 'Tổng tiền giao dịch', minWidth: 150},
    ],
    items
  })

  const doRowUpdate = (data) => {
    update(data)
  }

  const doDelete = (data) => {
    if (data && data[dataKey]) {
      ConfirmDialogService.confirm('Xác Nhận', 'Bạn chắc chắn muốn xóa sổ quỹ này', () => {
        Service.delete(data[dataKey]).then(() => {
          ToastService.success();
          reloadLazyData();
        })
      })
    }
  }

  return (
    <div className="grid">
      <div className="col-12 pb-0">
        <Fieldset legend="Tiền mặt">
          <div className="card flex flex-wrap gap-2 p-fluid">
            <div className="flex-auto">
              <label htmlFor="availableBalance" className="font-bold block mb-2">Số dư hiện tại</label>
              <InputNumber inputId="availableBalance" value={items[0]?.availableBalance} disabled={true}/>
            </div>
            <div className="flex-auto">
              <label htmlFor="beginningBalance" className="font-bold block mb-2">Số dư ban đầu</label>
              <InputNumber inputId="beginningBalance" value={items[0]?.beginningBalance} disabled={true}/>
            </div>
            <div className="flex-auto">
              <label htmlFor="transAmount" className="font-bold block mb-2">Tổng tiền giao dịch</label>
              <InputNumber inputId="transAmount" value={items[0]?.transAmount} disabled={true}/>
            </div>
            <div className="">
              <Button aria-label="Chỉnh sửa" rounded outlined  icon="pi pi-pencil" severity="warning" className="mt-5" tooltip="Chỉnh sửa"
                      onClick={() => dialogCash.update(items[0])}/>
            </div>
            <div className="">
              <Button aria-label="Lịch sử" rounded outlined  icon="pi pi-history" severity="secondary" className="mt-5" tooltip="Lịch sử"
                      onClick={() => setDisplay(items[0])}/>
            </div>
          </div>
        </Fieldset>
      </div>
      <div className="col-12 pb-0">
        <Fieldset legend="Tài khoản ngân hàng" style={{minWidth: 0}}>
          {renderDataTable()}
        </Fieldset>
      </div>
      {renderDialogCrup()}
      {dialogCash.render()}
      <Histories display={display} setDisplay={setDisplay}/>
    </div>
  );
}