import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDataTableBasic, useDialogCrup, useForm, useToolbar} from '@iamsoftware/react-hooks';
import React, {Fragment, useEffect, useState} from 'react';
import {Button} from 'primereact/button';
import {InvoiceServices as Service} from './InvoiceServices';
import {CommonService} from '../../../service/CommonService';
import {BussinessJobService} from '../../danh-muc/business-jobs/BussinessJobService';
import { AutoComplete } from "primereact/autocomplete";
import {Fieldset} from 'primereact/fieldset';
import {InputNumber} from 'primereact/inputnumber';
import {PurchaseOrderService} from '../don-hang/OrdersService';

interface Invoices {
  placedDate: string;
  displayId: string;
  vendorPartyName: string;
  grandTotal: number;
  orderId: string;
}

export const PurchaseInvoices = () =>{
  const header = 'Hóa đơn';
  const dataKey = 'invoiceId';

  const [disabled, setDisabled] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null)
  const [invoiceFormTypes, setInvoiceFormTypes] = useState(null)
  const [issueStatusIds, setIssueStatusIds] = useState(null)
  const [paymentMethodEnumIds, setPaymentMethodEnumIds] = useState(null)
  const [items, setItems] = useState(null)
  const [treeCategories, setTreeCategories] = useState(null)
  const [itemTaxTypeEnumIds, setItemTaxTypeEnumIds] = useState(null)
  const [grandTotal, setGrandTotal] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalExemptAmount, setTotalExemptAmount] = useState(0);
  const [totalTaxAmount, setTotalTaxAmount] = useState(0);

  useEffect(() => {
    Service.orderAddbled().then(({items})=>{
      setInvoices(items.map(item =>{
        return {...item, value: item.orderId}
      }))
    })
    CommonService.getInvoiceFormTypes().then(({listData})=>{
      setInvoiceFormTypes(listData.map(item =>{
        return {value: item.enumId, label: `${item.enumCode} - ${item.description}`}
      }))
    })
    CommonService.getInvoiceIssueStatus().then(({listData})=>{
      setIssueStatusIds(listData.map(item  =>{
        return {value: item.statusId, label:item.description}
      }))
    });
    CommonService.getInvoicePaymentMethod().then(({listData})=>{
      setPaymentMethodEnumIds(listData.map(item =>{
        return {value: item.enumId, label: item.description}
      }))
    });
    CommonService.getTaxRate().then(({listData})=>{
      setItemTaxTypeEnumIds(listData.map(item =>{
        return {...item,value: item.enumId, label: item.description}
      }))
    })
    BussinessJobService.getTreeList().then(({data})=>{
      setTreeCategories(data)
    })
  }, []);

  const invoiceTemplate = (item: Invoices) => {
    return (
      <div className="flex">
        <div style={{margin:'5px', width:'6rem'}}>{FormatDisplay.date(item.placedDate,'YYYY-MM-DD')}</div>
        <div style={{margin:'5px' , width:'8rem', overflow:'hidden', textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{item.displayId}</div>
        <div style={{margin:'5px', width:'22rem', overflow:'hidden', textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{item.vendorPartyName}</div>
        <div style={{margin:'5px'}}>{FormatDisplay.number(item.grandTotal)}</div>
      </div>
    );
  };

  const headerTable = (
    <div>
      <div className="flex justify-content-between align-items-center">
        <span>{header}</span>
        <Button icon="pi pi-plus" size="small" severity="success" className="mr-4 mb-1 h-2rem" onClick={() => doCreate()} label="Thêm"></Button>
      </div>
    </div>
  )

  const searchInvoices = () => {
    Service.orderAddbled().then(({items})=>{
      setInvoices(items.map(item =>{
        return {...item, value: item.orderId}
      }))
    })
  }

  //Set orderId và set Dirty = true
  const changeOrderId = (data) =>{
    setSelectedItem(data)
    setDirty(true)
  }

  const onEditorChange = async (rowData, column, newValue) => {
    setItems(prevOrderItems => prevOrderItems.map(prevOrderItem => {
      if (prevOrderItem._id === rowData._id) {
        prevOrderItem.itemTaxAmount = 0;
        if (column.field === 'itemTaxTypeEnumId'){
          var taxNum = 0
          itemTaxTypeEnumIds.map(item =>{
            if (newValue === item.value && Number(item.enumCode)){
              taxNum = Number(item.enumCode)
            }
          })
          prevOrderItem.itemTaxAmount = rowData.itemTotal * taxNum /100
        }
        return {
          ...prevOrderItem,
          [column.field]: newValue
        }
      }
      return prevOrderItem;
    }))
  }

  //tinh lai tong tien tax item
  useEffect(() => {
    if (items?.length >0){
      var totalAmoun = 0
      items.map(item =>{
        if (item?.itemTaxAmount){
          totalAmoun += item?.itemTaxAmount
        }
      })
      setTotalTaxAmount(totalAmoun);
      setGrandTotal(totalAmount + totalAmoun - totalExemptAmount)
    }
  }, [items]);

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    tableHeader: headerTable,
    stateKey:'mainTable',
    dataKey,
    columns:[
      {field:'invoiceDate',header:'Ngày hóa đơn' ,matchMode:'contains', minWidth: 150, dataType:'date'},
      {field:'invoiceForm',header: 'Mẫu số' ,matchMode:'contains', minWidth: 150, dataType:'custom', customCell(rowData: any): any {
          return <span>{rowData.invoiceForm}-{rowData.invoiceFormTypeEnum}</span>
        }},
      {field:'invoiceSerial',header: 'Ký hiệu' ,matchMode:'contains', minWidth: 150},
      {field:'invoiceNo',header: 'Số hóa đơn' ,matchMode:'contains', minWidth: 150},
      {field:'vendor',header: 'Nhà cung cấp' ,matchMode:'contains', minWidth: 250, dataType:'custom', customCell(rowData: any): any {
          return <span>[{rowData.fromPseudoId}] - {rowData.fromPartyName}</span>
        }},
      {field:'invoiceTotal',header: 'Giá trị HĐ' ,matchMode:'contains', minWidth: 150, dataType:'number'},
      {field:'issueStatus',header: 'TT phát hành' ,matchMode:'contains', minWidth: 150},
      {field:'externalId',header: 'Mã CQT' ,matchMode:'contains', minWidth: 250},
    ],
    indexColumnWidth:45,
    getList(lazyLoadEvent: any): Promise<any> {
      return Service.getList(lazyLoadEvent)
    },
    actionColumnWidth:90,
    initActions(items: Array<any>) {
      if (items){
        items.forEach(item =>{
          item.actions = [
            // {icon: 'pi pi-eye', title: 'Xem', className: 'p-button-info', command: () => doUpdate(item.invoiceId, true)},
            {icon: 'pi pi-pencil', title: 'Chỉnh sửa', className: 'p-button-warning', command: () => doUpdate(item.invoiceId, false)}
          ]
        })
      }
    }
  })


  const commonInfoForm = useForm({
    fields:[
      {field:'orderId', header:'Đơn hàng', type:'custom', className:'md:col-3',
        body: <div>
          <label>Đơn hàng <span style={{color:'red'}}>*</span></label>
          <AutoComplete className="mt-2" field="displayId" value={selectedItem} suggestions={invoices} disabled={disabled} forceSelection={true}
                        completeMethod={searchInvoices} onChange={(e) => changeOrderId(e.value)} itemTemplate={invoiceTemplate} dropdown/>
        </div>},
      {
        field:'vendorPseudoId', header:'Mã nhà cung cấp', className:'md:col-3', disabled: true
      },
      {
        field:'vendorPartyName', header:'Tên Nhà cung cấp', className:'md:col-6', disabled: true
      },
      {
        field:'fromPartyTaxId', header:'Mã số thuế NCC',className:'md:col-3',required: true,
      },
      {
        field:'address1', header:'Địa chỉ', className:'md:col-9', disabled: true
      },
      {
        field:'paymentMethodEnumId', header:'Hình thức thanh toán', className:'md:col-3', type:'Dropdown', DropdownProps:{options: paymentMethodEnumIds}
      },
      {
        field:'targetAccountNumber', header:'Số tài khoản', className:'md:col-4', disabledDependency(item: any): boolean {
          return item.paymentMethodEnumId !== 'PiWireTransfer'
        }
      },
      {
        field:'targetBankName', header:'Tên ngân hàng', className:'md:col-5', disabledDependency(item: any): boolean {
          return item.paymentMethodEnumId !== 'PiWireTransfer'
        }
      },
      {
        field:'description', header:'Diễn giải', className:'md:col-12'
      },
      {
        field:'orderComments', header:'Ghi chú', className:'md:col-12'
      }
    ],
    readOnly: disabled
  })

  const certInfoForm = useForm({
    fields:[
      {field:'invoiceFormTypeEnumId', header:'Mẫu số',required: true, className:'md:col-12', type:'Dropdown', DropdownProps:{options: invoiceFormTypes}},
      {field:'invoiceSerial', header:'Ký hiệu',required: true, className:'md:col-6'},
      {field:'invoiceNo', header:'Số hóa đơn',required: true, className:'md:col-6'},
      {field:'invoiceDate', header:'Ngày hóa đơn',required: true, className:'md:col-12', InputTextProps:{type:'date'}},
      {field:'issueStatusId', header:'Trạng thái phát hành',required: true, className:'md:col-12', type:'Dropdown', DropdownProps:{options: issueStatusIds}},
      {field:'externalId', header:'Mã CQT', className:'md:col-12'}
    ],
    readOnly: disabled
  })

  const {invoiceFormTypeEnumId} = certInfoForm.getRawValue()

  const {render: renderDataTableBasic, setSelectedItems} = useDataTableBasic({
    tableHeader:'Danh sách sản phẩm',
    dataKey: '_id',
    indexColumnWidth:45,
    columns:[
      {field: 'productName', header: 'Sản phẩm', width: 300},
      {field: 'quantityUom', header: 'ĐVT', width: 75},
      {field: 'quantity', header: 'SL', width: 75, dataType: 'number'},
      {field: 'unitListPrice', header: 'Đơn giá', width: 100, dataType: 'number'},
      {field: 'itemTotal', header: 'Thành tiền', width: 100, dataType: 'number'},
      {field: 'sourcePercentage', header: 'Tỷ lệ CK', width: 75, dataType: 'number'},
      {field: 'exemptAmount', header: 'Số tiền CK', width: 100, dataType: 'number'},
      {field: 'itemTaxTypeEnumId', header: '% thuế', width: 100, hidden: invoiceFormTypeEnumId !== 'IftTax',dataType: 'editor', editorConfig:{
        onEditorChange,disabled: (disabled || invoiceFormTypeEnumId !== 'IftTax'),
          field:{type:'Dropdown',DropdownProps:{options:itemTaxTypeEnumIds}}
        }},
      {field: 'itemTaxAmount', header: 'Tiền thuế', width: 120, hidden: invoiceFormTypeEnumId !== 'IftTax',dataType: 'editor', editorConfig:{
        onEditorChange, disabled: (disabled || invoiceFormTypeEnumId !== 'IftTax'),
          field: {type: 'InputNumber', InputNumberProps:{min: 0}}
        }},
    ],
    items
  })

  const {render: renderDialogCrup, create, view, update} = useDialogCrup({
    header: header,
    dataKey,
    width: '120rem',
    fields:[
      {
        type:'custom', className:'md:col-9',
        body: <Fieldset legend="Thông tin chung">
          {commonInfoForm.render()}
        </Fieldset>
      },
      {
        type:'custom', className:'md:col-3 fieldset-width-invoice',
        body: <Fieldset legend="Chứng từ">
          {certInfoForm.render()}
        </Fieldset>
      },
      {
        type: 'custom', className: 'md:col-12',
        body: <div>
          <div style={{maxHeight: 'calc(100vh - 15.5rem)'}}>
            {renderDataTableBasic()}
          </div>
          <div className="flex flex-row-reverse flex-wrap mt-3">
            <div className="ml-4">
              <label htmlFor="total" className="font-bold block mb-2">Tổng tiền thanh toán</label>
              <InputNumber inputId="total" value={grandTotal} disabled/>
            </div>
            <div className="ml-4">
              <label htmlFor="total-exempt" className="font-bold block mb-2">Tổng tiền thuế</label>
              <InputNumber inputId="total-exempt" value={totalTaxAmount} disabled/>
            </div>
            <div className="ml-4">
              <label htmlFor="total-exempt" className="font-bold block mb-2">Tổng tiền chiết khấu</label>
              <InputNumber inputId="total-exempt" value={totalExemptAmount} disabled/>
            </div>
            <div>
              <label htmlFor="total-amount" className="font-bold block mb-2">Tổng tiền hàng</label>
              <InputNumber inputId="total-amount" value={totalAmount} disabled/>
            </div>
          </div>
        </div>
      }
    ],
    createItem(item: any): Promise<any> {
      if (!selectedItem?.orderId || !inject()?.fromPartyTaxId){
        if (!selectedItem?.orderId){
          ToastService.error('Đơn hàng không được rỗng')
        }
        if (!inject()?.fromPartyTaxId){
          ToastService.error('Mã số thuế nhà cung cấp không được rỗng')
        }
        return Promise.reject();
      }else {
        return Service.create(inject())
      }
    },
    updateItem(id: string, item: any): Promise<any> {
      if (!selectedItem?.orderId || !inject()?.fromPartyTaxId){
        if (!selectedItem?.orderId){
          ToastService.error('Đơn hàng không được rỗng')
        }
        if (!inject()?.fromPartyTaxId){
          ToastService.error('Mã số thuế nhà cung cấp không được rỗng')
        }
        return Promise.reject();
      }else {
        return Service.update(id, inject())
      }
    },
    reloadLazyData,
    useSaveSplitButton: false
  })


  //Fill dữ liệu khi thay đổi đơn hàng
  useEffect(() => {
    if (selectedItem?.orderId && dirty) {
      setItems(null)
      const {paymentMethodEnumId} = commonInfoForm.getRawValue()
      const {invoiceDate} = certInfoForm.getRawValue()
      PurchaseOrderService.get(selectedItem.orderId).then(data => {
        setItems(data.items.map(it => {
          return {...it, itemTotal: it.quantity * it.unitListPrice, _id: `${it.productId}_${it.orderItemSeqId}_${Math.random()}`}
        }))
        data.paymentMethodEnumId = paymentMethodEnumId;
        data.invoiceDate = invoiceDate;
        commonInfoForm.setValue(data);
        certInfoForm.setValue(data);
      })
    }
  }, [selectedItem]);

  //tinh tong tien invoice
  useEffect(() => {
    if (items && dirty) {
      setTotalAmount(0);
      setTotalExemptAmount(0);
      setGrandTotal(0)
      let totalCounter = 0;
      let exemptCounter = 0;
      let grandTotalCounter = 0;
      items.forEach(item => {
        if (item.productId) {
          totalCounter += item.itemTotal
          if (item.exemptAmount) {
            exemptCounter += item.exemptAmount
          }
        }
      });
      grandTotalCounter = totalCounter - exemptCounter
      setTotalAmount(totalCounter);
      setTotalExemptAmount(exemptCounter);
      setGrandTotal(grandTotalCounter);
    }
  }, [JSON.stringify(items)]);

  const commonData = commonInfoForm.getRawValue()
  const certData = certInfoForm.getRawValue()

  const inject = () =>{
    const item = Object.assign(
      commonData,
      certData
    )
    item.items = items
    if (invoiceFormTypeEnumId !== 'IftTax'){
      item.items.map(it =>{
        delete it.itemTaxTypeEnumId
        delete it.itemTaxAmount
        return it
      })
    }
    item.invoiceDate = new Date(item.invoiceDate).getTime()
    item.orderId = selectedItem.orderId
    return item
  }

  const doCreate = () => {
    setGrandTotal(0);
    setTotalExemptAmount(0);
    setTotalAmount(0);
    setTotalTaxAmount(0)
    setSelectedItems([])
    setDisabled(false)
    setDirty(false)
    setItems(null)
    setSelectedItem(null)
    commonInfoForm.setValue({paymentMethodEnumId: 'PiWireTransfer'})
    certInfoForm.setValue({invoiceDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD'), issueStatusId: issueStatusIds[0]?.value})
    create()
  }

  const doUpdate = (invoiceId, doView: boolean) => {
    setSelectedItems([])
    setDirty(false)
    if (invoiceId){
      Service.get(invoiceId).then(data =>{
        data.invoiceDate = FormatDisplay.date(new Date(data.invoiceDate),'YYYY-MM-DD');
        data.vendorPartyName = data.fromPartyName;
        data.vendorPseudoId = data.fromPseudoId;
        setItems(data.items.map(item=>{
          item._id = `${item.productId}_${item.invoiceItemSeqId}_${Math.random()}`
          item.sourcePercentage = item.adjustmentPercent;
          item.exemptAmount = item.adjustmentAmount;
          item.unitListPrice = item.listPrice;
          item.productName = item.description;
          item.itemTaxTypeEnumId = item.taxTypeEnumId;
          item.itemTaxAmount = item.taxAmount
          return item
        }))
        setTotalTaxAmount(data.invoiceTaxTotal)
        setGrandTotal(data.invoiceTotal);
        setTotalExemptAmount(data.invoiceDiscountTotal);
        setTotalAmount(data.invoiceSubTotal);
        setSelectedItem({orderId: data.orderId, displayId: data.orderDisplayId})
        certInfoForm.setValue(data);
        commonInfoForm.setValue(data)
        if (doView === true){
          setDisabled(true)
          view(data)
        }else {
          setDisabled(false)
          update(data)
        }
      })
    }
  }

  return (
    <div className="grid">
      {/*<div className="col-12">*/}
      {/*  {renderToolbar()}*/}
      {/*</div>*/}
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12.5rem)'}}>
        {renderDataTable()}
      </div>
      {renderDialogCrup()}
    </div>
  )
}