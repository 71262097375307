import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Checkbox} from "primereact/checkbox";
import {InputText} from "primereact/inputtext";
import {Dropdown} from 'primereact/dropdown';
import {TreeSelect} from 'primereact/treeselect';
import {Calendar} from 'primereact/calendar';
import {Column} from 'primereact/column';
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';
import {TaxDeclareService as Service} from './TaxDeclareService'
import {CashBookService} from '../../danh-muc/tai-khoan-ngan-hang/CashBookService';
import {CommonService} from '../../../service/CommonService';
import {ConfirmDialogService, FormatDisplay, ToastService, useDataTableBasic, useForm} from '@iamsoftware/react-hooks';
import {TabView, TabPanel} from 'primereact/tabview';
import {AddendumHDKD} from './phu-luc/01-2-BK-HDKD';
import {AddendumQH15} from './phu-luc/110-2023-QH15';

export const DeclareDetail = ({display, setDisplay, doUpdate, reloadLazyData}) => {
  const [declarationTypeEnumItems, setDeclarationTypeEnumItems] = useState([])
  const [timePeriodTypeItems, setTimePeriodTypeItems] = useState([])
  const [dataList, setDataList] = useState(null)
  const [bankAccounts, setBankAccounts] = useState([])
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [category, setCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [stateGeo, setStateGeo] = useState([])
  const [countyGeo, setCountyGeo] = useState([])
  const [wardGeo, setWardGeo] = useState([])
  const [stateGeo2, setStateGeo2] = useState([])
  const [countyGeo2, setCountyGeo2] = useState([])
  const [wardGeo2, setWardGeo2] = useState([])
  const [residenceStateGeoId, setResidenceStateGeoId] = useState(null)
  const [residenceCountyGeoId, setResidenceCountyGeoId] = useState(null)
  const [residenceWardGeoId, setResidenceWardGeoId] = useState(null)
  const [businessStateGeoId, setBusinessStateGeoId] = useState(null);
  const [businessCountyGeoId, setBusinessCountyGeoId] = useState(null)
  const [businessWardGeoId, setBusinessWardGeoId] = useState(null)
  const [vatPitItems, setVatPitItems] = useState([]);
  const [displayRForm, setDisplayRForm] = useState(null)
  const [displayBForm, setDisplayBForm] = useState(null)
  const [isRent, setIsRent] = useState(false)
  const [isBusinessInMarket, setIsBusinessInMarket] = useState(false)
  const [isEditBusinessAddress, setIsEditBusinessAddress] = useState(false)
  const [isEditCareerCat, setIsEditCareerCat] = useState(false)
  const [taxAgencyContractDate, setTaxAgencyContractDate] = useState(null)
  const [signDate, setSignDate] = useState(null)
  const [documentDate, setDocumentDate] = useState(null)
  const [taxPayerName, setTaxPayerName] = useState(null)
  const [taxOrganizationName, setTaxOrganizationName] = useState(null)
  const [telecomNumber, setTelecomNumber] = useState(null)
  const [faxNumber, setFaxNumber] = useState(null)
  const [emailAddress, setEmailAddress] = useState(null)
  const [businessArea, setBusinessArea] = useState(null)
  const [employeeNumber, setEmployeeNumber] = useState(null)
  const [fromHour, setFromHour] = useState(null)
  const [fromMinute, setFromMinute] = useState(null)
  const [toHour, setToHour] = useState(null)
  const [toMinute, setToMinute] = useState(null)
  const [taxCode, setTaxCode] = useState(null)
  const [taxAgencyName, setTaxAgencyName] = useState(null)
  const [taxAgencyTaxCode, setTaxAgencyTaxCode] = useState(null)
  const [taxAgencyContract, setTaxAgencyContract] = useState(null)
  const [taxAgencyEmployeeName, setTaxAgencyEmployeeName] = useState(null)
  const [certificationNo, setCertificationNo] = useState(null)
  const [signName, setSignName] = useState(null)
  //phu luc
  const [addendumTaxDeduction, setAddendumTaxDeduction] = useState('N')
  const [activeIndex, setActiveIndex] = useState(0);
  const [footerValue, setFooterValue] = useState({
    revenueVatAmount: 0,
    vatAmount: 0,
    revenuePitAmount: 0,
    pitAmount: 0
  });

  const {renderHDKD, setDeclarationExpense, setDeclarationInvent, itemExpense, itemInvent,declarationSeqDelList}
    = AddendumHDKD({display},signDate, signName,taxAgencyEmployeeName,setSignDate, setSignName, setTaxAgencyEmployeeName, certificationNo, setCertificationNo, doUpdate)
  const {renderTaxDeduction, setItemDeduction, itemDeduction, deductionSeqDelList} = AddendumQH15({display}, doUpdate)
  const recursiveLabel = (data) => {
    data.map(item => {
      item.label = item.data.categoryName
      if (item?.children) {
        recursiveLabel(item?.children)
      }
    })
  }

  const reload = () =>{
    Service.getTaxDetail(display.declarationId).then(res => {
      setDeclarationTypeEnumItems(res.declarationTypeEnumItems)
      setTimePeriodTypeItems(res.timePeriodTypeItems)
      setDataList(res)
      setSelectedBankAccount(res.paymentMethodId)
      setSelectedCategory(res.productCategoryId)
      setBusinessStateGeoId(res.businessStateGeoId)
      setBusinessCountyGeoId(res.businessCountyGeoId)
      setBusinessWardGeoId(res.businessWardGeoId)
      setResidenceStateGeoId(res.residenceStateGeoId)
      setResidenceCountyGeoId(res.residenceCountyGeoId)
      setResidenceWardGeoId(res.residenceWardGeoId)
      setVatPitItems(res.vatPitItems)
      setTaxAgencyContractDate(new Date(res.taxAgencyContractDate))
      setDocumentDate(new Date(res.documentDate))
      setSignDate(new Date(res.signDate))
      residenceForm.setValue(res)
      businessForm.setValue(res)
      setTaxPayerName(res.taxPayerName)
      setTaxOrganizationName(res.taxOrganizationName)
      setTelecomNumber(res.telecomNumber)
      setFaxNumber(res.faxNumber)
      setEmailAddress(res.emailAddress)
      setBusinessArea(res.businessArea)
      setEmployeeNumber(res.employeeNumber)
      setFromHour(res.fromHour)
      setFromMinute(res.fromMinute)
      setToHour(res.toHour)
      setToMinute(res.toMinute)
      setTaxCode(res.taxCode)
      setTaxAgencyName(res.taxAgencyName)
      setTaxAgencyTaxCode(res.taxAgencyTaxCode)
      setTaxAgencyContract(res.taxAgencyContract)
      setTaxAgencyEmployeeName(res.taxAgencyEmployeeName)
      setCertificationNo(res.certificationNo)
      setSignName(res.signName)
      if (res.isRent === 'Y') {
        setIsRent(true)
      } else setIsRent(false)
      if (res.isBusinessInMarket === "Y") {
        setIsBusinessInMarket(true)
      } else setIsBusinessInMarket(false)
      if (res.isEditBusinessAddress === "Y") {
        setIsEditBusinessAddress(true)
      } else setIsEditBusinessAddress(false)
      if (res.isEditCareerCat === "Y") {
        setIsEditCareerCat(true)
      } else setIsEditCareerCat(false)
      //set hien phu luc
      setAddendumTaxDeduction(res?.addendumTaxDeduction)
      //set item cho pluc 01-2/BK-HDKD
      setDeclarationInvent(res.taxDeclarationInventItems.map(item => {
        return {...item, _id: `${item.declarationId}_${item.declarationSeqId}`}
      }))
      setDeclarationExpense(res.taxDeclarationExpenseItems.map(item => {
        return {...item, _id: `${item.declarationId}_${item.declarationSeqId}`}
      }))
      //set item cho pluc 110/2023/QH15
      setItemDeduction(res.taxDeclarationDeductionItems.map(item =>{
        return {...item, _id: `${item.declarationId}_${item.declarationSeqId}`}
      }))
    })
  }

  useEffect(() => {
    if (display?.declarationId) {
      setActiveIndex(0)
      reload()
      CashBookService.bankAccount('').then(data => {
        setBankAccounts(data.listData.map(item => {
          return {value: item.paymentMethodId, label: `${item.accountNumber} ${item.bankName}`}
        }))
      })

      CommonService.careerSystemTree().then(data => {
        setCategory(data.data)
        recursiveLabel(data.data)
      })

      CommonService.geosProvinces().then(data => {
        setStateGeo(data.listData.map(item => {
          return {value: item.toGeoId, label: item.geoName}
        }))
        setStateGeo2(data.listData.map(item => {
          return {value: item.toGeoId, label: item.geoName}
        }))
      })
    }else {
      setItemDeduction([]);
    }
  }, [display])
  useEffect(() => {
    if (vatPitItems && footerValue.revenueVatAmount === 0 && footerValue.vatAmount === 0 && footerValue.pitAmount === 0) {
      vatPitItems?.map(item => {
        footerValue.revenueVatAmount += item.revenueVatAmount
        footerValue.vatAmount += item.vatAmount
        footerValue.pitAmount += item.pitAmount
        footerValue.revenuePitAmount += item.revenuePitAmount
      })
    }
  }, [vatPitItems])

  const {render: renderVatPitItems} = useDataTableBasic({
    indexColumnWidth: 40,
    columns: [
      {field: 'categoryName', header: 'Nhóm ngành nghề', minWidth: 200},
      {
        header: 'Mã chỉ tiêu', minWidth: 100, dataType: 'custom',
        customCell(rowData: any): any {
          return <span>[{rowData.sequenceNum}]</span>
        }
      },
      {field: 'revenueVatAmount', header: 'Doanh thu', minWidth: 100, dataType: 'number'},
      {field: 'vatAmount', header: 'Số thuế', minWidth: 100, dataType: 'number'},
      {field: 'revenuePitAmount', header: 'Doanh thu', minWidth: 100, dataType: 'number'},
      {field: 'pitAmount', header: 'Số thuế', minWidth: 100, dataType: 'number'},
    ],
    items: vatPitItems,
    originalProps: {
      footerColumnGroup: <ColumnGroup>
        <Row>
          <Column colSpan={3} footerStyle={{textAlign: 'right'}} footer='Tổng cộng:'></Column>
          <Column footer={FormatDisplay.number(footerValue.revenueVatAmount)} footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
          <Column footer={FormatDisplay.number(footerValue.vatAmount)} footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
          <Column footer={FormatDisplay.number(footerValue.revenuePitAmount)} footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
          <Column footer={FormatDisplay.number(footerValue.pitAmount)} footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
        </Row>
      </ColumnGroup>,
      headerColumnGroup: <ColumnGroup>
        <Row>
          <Column header="STT" rowSpan={2}/>
          <Column header="Nhóm ngành nghề" rowSpan={2}/>
          <Column header="Mã chỉ tiêu" rowSpan={2}/>
          <Column header="Thuế GTGT" colSpan={2} headerClassName="iam-table-header-content"/>
          <Column header="Thuế TNCN" colSpan={2} headerClassName="iam-table-header-content"/>
        </Row>
        <Row>
          <Column header="Doanh thu" field="revenueVatAmount" headerClassName="iam-table-header-content"/>
          <Column header="Số thuế" field="vatAmount" headerClassName="iam-table-header-content"/>
          <Column header="Doanh thu" field="revenuePitAmount" headerClassName="iam-table-header-content"/>
          <Column header="Số thuế" field="pitAmount" headerClassName="iam-table-header-content"/>
        </Row>
      </ColumnGroup>
    }
  });

  const {render: renderVatSpecialItems} = useDataTableBasic({
    indexColumnWidth: 40,
    columns: [
      {field: 'categoryName', header: 'Hàng hóa, dịch vụ chịu thuế TTĐB', minWidth: 200},
      {
        header: 'Mã chỉ tiêu', minWidth: 100, dataType: 'custom',
        customCell(rowData: any): any {
          return <span>[{rowData.sequenceNum}]</span>
        }
      },
      {field: 'revenueAmount', header: 'Đơn vị tính', minWidth: 100},
      {field: 'vatAmount', header: 'Doanh thu thuế TTĐB', minWidth: 100, dataType: 'number'},
      {field: 'vatAmunt', header: 'Thuế suất', minWidth: 100, dataType: 'number'},
      {field: 'pitAmount', header: 'Số thuế', minWidth: 100, dataType: 'number'},
    ],
    items: [],
    originalProps: {
      footerColumnGroup: <ColumnGroup>
        <Row>
          <Column colSpan={4} footerStyle={{textAlign: 'right'}} footer='Tổng cộng:'></Column>
          <Column footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
          <Column footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
          <Column footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
        </Row>
      </ColumnGroup>
    }
  });

  const {render: renderVatNaturalItems} = useDataTableBasic({
    indexColumnWidth: 40,
    columns: [
      {field: 'categoryName', header: 'Chỉ tiêu', minWidth: 200},
      {
        header: 'Mã chỉ tiêu', minWidth: 100, dataType: 'custom',
        customCell(rowData: any): any {
          return <span>[{rowData.sequenceNum}]</span>
        }
      },
      {field: 'revenueAmount', header: 'Đơn vị tính', minWidth: 100},
      {field: 'vatAmount', header: 'Sản lượng/Số lượng', minWidth: 100, dataType: 'number'},
      {field: 'vatAmount', header: 'Giá tính thuế tài nguyên/Mức thuế hoặc phí BVMT', minWidth: 100, dataType: 'number'},
      {field: 'pitAmount', header: 'Thuế suất', minWidth: 100, dataType: 'number'},
      {field: 'pitAmount', header: 'Số thuế phải nộp', minWidth: 100, dataType: 'number'},
    ],
    items: [],
    originalProps: {
      footerColumnGroup: <ColumnGroup>
        <Row>
          <Column colSpan={5} footerStyle={{textAlign: 'right'}} footer='Tổng cộng:'></Column>
          <Column footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
          <Column footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
          <Column footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
        </Row>
      </ColumnGroup>
    }
  });


  const residenceForm = useForm({
    fields: [
      {field: 'residenceAddress1', header: 'Địa chỉ', className: 'md:col-12'},
      {field: 'residenceStateGeoId', header: 'Tỉnh/Thành phố', className: 'md:col-12', type: 'Dropdown', DropdownProps: {options: stateGeo2}},
      {field: 'residenceCountyGeoId', header: 'Quận/Huyện', className: 'md:col-12', type: 'Dropdown', DropdownProps: {options: countyGeo2}},
      {field: 'residenceWardGeoId', header: 'Xã/Phường', className: 'md:col-12', type: 'Dropdown', DropdownProps: {options: wardGeo2}},
      {field: 'residenceAddress2', header: 'Số nhà, đường phố', className: 'md:col-12'},
    ],
  });
  const businessForm = useForm({
    fields: [
      {field: 'businessAddress1', header: 'Địa chỉ', className: 'md:col-12'},
      {field: 'businessStateGeoId', header: 'Tỉnh/Thành phố', className: 'md:col-12', type: 'Dropdown', DropdownProps: {options: stateGeo}},
      {field: 'businessCountyGeoId', header: 'Quận/Huyện', className: 'md:col-12', type: 'Dropdown', DropdownProps: {options: countyGeo}},
      {field: 'businessWardGeoId', header: 'Xã/Phường', className: 'md:col-12', type: 'Dropdown', DropdownProps: {options: wardGeo}},
      {field: 'businessAddress2', header: 'Số nhà, đường phố', className: 'md:col-12'},
    ],
  });
  const rValue = residenceForm.getValue();
  const bValue = businessForm.getValue();
  useEffect(() => {
    if (bValue?.businessStateGeoId) {
      CommonService.geosCounty(bValue.businessStateGeoId).then(data => {
        setCountyGeo(data.listData.map(item => {
          return {value: item.toGeoId, label: item.geoName}
        }))
      })
    }
  }, [bValue?.businessStateGeoId])
  useEffect(() => {
    if (bValue?.businessCountyGeoId) {
      CommonService.geoWard(bValue.businessCountyGeoId).then(data => {
        setWardGeo(data.listData.map(item => {
          return {value: item.toGeoId, label: item.geoName}
        }))
      })
    }
  }, [bValue?.businessCountyGeoId])
  useEffect(() => {
    if (rValue?.residenceStateGeoId) {
      CommonService.geosCounty(rValue.residenceStateGeoId).then(data => {
        setCountyGeo2(data.listData.map(item => {
          return {value: item.toGeoId, label: item.geoName}
        }))
      })
    }
  }, [rValue?.residenceStateGeoId])
  useEffect(() => {
    if (rValue.residenceCountyGeoId) {
      CommonService.geoWard(rValue.residenceCountyGeoId).then(data => {
        setWardGeo2(data.listData.map(item => {
          return {value: item.toGeoId, label: item.geoName}
        }))
      })
    }
  }, [rValue.residenceCountyGeoId])

  const onSubmitResidenceForm = () => {
    if (rValue.residenceStateGeoId) {
      setResidenceStateGeoId(rValue.residenceStateGeoId)
    }
    if (rValue.residenceCountyGeoId) {
      setResidenceCountyGeoId(rValue.residenceCountyGeoId)
    }
    if (rValue.residenceWardGeoId) {
      setResidenceWardGeoId(rValue.residenceWardGeoId)
    }
    if (rValue.residenceAddress1) {
      dataList.residenceAddress1 = rValue.residenceAddress1
    }
    if (rValue.residenceAddress2) {
      dataList.residenceAddress2 = rValue.residenceAddress2
    }
    setDisplayRForm(null)
  }

  const onSubmitBusinessForm = () => {
    if (bValue.businessStateGeoId) {
      setBusinessStateGeoId(bValue.businessStateGeoId)
    }
    if (bValue.businessCountyGeoId) {
      setBusinessCountyGeoId(bValue.businessCountyGeoId)
    }
    if (bValue.businessWardGeoId) {
      setBusinessWardGeoId(bValue.businessWardGeoId)
    }
    if (bValue.businessAddress1) {
      dataList.businessAddress1 = bValue.businessAddress1
    }
    if (bValue.businessAddress2) {
      dataList.businessAddress2 = bValue.businessAddress2
    }
    setDisplayBForm(null)
  }
  const updateResidenceForm = () => {
    setDisplayRForm(Date.now())
  }
  const updateBusinessForm = () => {
    setDisplayBForm(Date.now())
  }
  const onSubmit = () => {
    const data = {
      residenceAddress1: dataList.residenceAddress1,
      postalResidenceContactMechId: dataList.postalResidenceContactMechId,
      residenceStateGeoId: residenceStateGeoId,
      residenceCountyGeoId: residenceCountyGeoId,
      residenceWardGeoId: residenceWardGeoId,
      residenceAddress2: dataList.residenceAddress2,
      taxPayerName: taxPayerName,
      businessAddress1: dataList.businessAddress1,
      postalBusinessContactMechId: dataList.postalBusinessContactMechId,
      businessStateGeoId: businessStateGeoId,
      businessCountyGeoId: businessCountyGeoId,
      businessWardGeoId: businessWardGeoId,
      businessAddress2: dataList.businessAddress2,
      taxOrganizationName: taxOrganizationName,
      telecomNumber: telecomNumber,
      faxNumber: faxNumber,
      emailAddress: emailAddress,
      businessArea: businessArea,
      employeeNumber: employeeNumber,
      fromHour: fromHour,
      fromMinute: fromMinute,
      toHour: toHour,
      toMinute: toMinute,
      productCategoryId: selectedCategory,
      paymentMethodId: selectedBankAccount,
      taxCode: taxCode,
      taxAgencyName: taxAgencyName,
      taxAgencyTaxCode: taxAgencyTaxCode,
      taxAgencyContract: taxAgencyContract,
      taxAgencyEmployeeName: taxAgencyEmployeeName,
      certificationNo: certificationNo,
      signName: signName,
      taxAgencyContractDate: Date.parse(taxAgencyContractDate),
      signDate: Date.parse(signDate),
      isRent: "N",
      isEditBusinessAddress: "N",
      isBusinessInMarket: "N",
      isEditCareerCat: "N",
      addendumTaxDeduction: addendumTaxDeduction,
      itemInvent: null,
      itemExpense: null,
      declarationSeqDelList: [],
      deductionSeqDelList: [],
      itemDeduction: null
    }
    if (isRent === true) {
      data.isRent = "Y"
    }
    if (isEditBusinessAddress === true) {
      data.isEditBusinessAddress = "Y"
    }
    if (isBusinessInMarket === true) {
      data.isBusinessInMarket = "Y"
    }
    if (isEditCareerCat === true) {
      data.isEditCareerCat = "Y"
    }
    if (itemInvent?.length>0){
      data.itemInvent = itemInvent.filter((item)=> item.productId)
    }
    data.itemExpense = itemExpense;
    data.itemDeduction = itemDeduction;
    data.declarationSeqDelList = declarationSeqDelList;
    data.deductionSeqDelList = deductionSeqDelList;
    Service.update(display.declarationId, data).then(() => {
      ToastService.success();
      reloadLazyData();
      setDisplay(null);
    })
  }

  //lay lai du lieu
  const rollBackData = (item) =>{
    if (item){
      ConfirmDialogService.confirm('Lấy lại dữ liệu', 'Bạn xác nhận muốn lấy lại dữ liệu?', ()=>{
        Service.recalculate(item).then(()=>{
          reload();
          ToastService.success();
        })
      })
    }
  }

  //ham close phu luc
  const onCloseTaxDeduction = () =>{
    ConfirmDialogService.confirm('Xóa phụ lục','Bạn xác nhận muốn xóa phụ lục này',()=>{
      setAddendumTaxDeduction('N');
      setActiveIndex(1);
    })
  }

  //them moi phu luc
  const addTaxDeduction = () =>{
    setAddendumTaxDeduction('Y');
    setActiveIndex(2);
  }

  //template header tabview phu luc
  const templateAddendumTaxDeduction  = (options) => {
    return (
      <div className="flex align-items-center" style={{cursor: 'pointer'}}>
        <a className="p-tabview-title p-tabview-nav-link" style={{fontWeight: 700, padding:'1.25rem 0.5rem 1.25rem 1.25rem'}} onClick={options.onClick}>PL 110/2023/QH15</a>
        <i className="pi pi-times" onClick={() => onCloseTaxDeduction()}></i>
      </div>
    )
  }
  //template header tabview them moi phu luc
  const addAddendumTaxDeduction = (options) => {
    return (
      <div className="flex align-items-center" style={{cursor: 'pointer'}}>
        <span id="addAddendum" className="p-tabview-title" onClick={() => addTaxDeduction()} style={{fontWeight: 700, padding:'1.25rem 0.5rem 1.25rem 1.25rem'}}><i className="pi pi-plus"></i> Phụ lục</span>
      </div>
    )
  }

  const header = (
    <div style={{textAlign: 'center'}}>
      <h4>Tờ khai thuế đối với hộ kinh doanh, cá nhân kinh doanh</h4>
    </div>
  )
  const footer = (
    <Fragment>
      <div className="flex align-items-center justify-content-between">
        <div>
          <Button label="Lấy lại dữ liệu" icon="pi pi-history" type="button" outlined severity="success" size="small" visible={doUpdate === true} onClick={() => rollBackData(display?.declarationId)}/>
        </div>
        <div className="flex flex-row-reverse flex-wrap">
          <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
          <Button label="Cập nhật" icon="pi pi-pencil" type="button" outlined severity="warning" size="small" visible={doUpdate === true} onClick={() => onSubmit()}/>
        </div>
      </div>
    </Fragment>
  );
  const rFooter = (
    <div className="flex flex-row-reverse flex-wrap">
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplayRForm(null)}/>
      <Button label="Cập nhật" icon="pi pi-pencil" type="button" outlined severity="warning" size="small" visible={doUpdate === true} onClick={() => onSubmitResidenceForm()}/>
    </div>
  );
  const bFooter = (
    <div className="flex flex-row-reverse flex-wrap">
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplayBForm(null)}/>
      <Button label="Cập nhật" icon="pi pi-pencil" type="button" outlined severity="warning" size="small" visible={doUpdate === true} onClick={() => onSubmitBusinessForm()}/>
    </div>
  );
  return (
    <Dialog header={header} footer={footer} visible={!!display} maximized={true} draggable={false} onHide={() => setDisplay(null)}>
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <TabPanel header="Tờ khai">
          <div className={'col-12'}>
            <div className="flex flex-column gap-3 col-12" style={{marginLeft: '20px'}}>
              {declarationTypeEnumItems.map((declarationItem, index) => {
                return (
                  <div key={index} className="flex align-items-center">
                    <Checkbox inputId={declarationItem.enumId} name="category" value={declarationItem} checked={declarationItem?.checked === "Y"}/>
                    <label htmlFor={declarationItem.enumId} className="ml-2">
                      {declarationItem.description}
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="flex justify-content-end flex-wrap gap-3 col-12">
              <div className="flex flex-column gap-3 col-1">
                [01] Kỳ tính thuế
              </div>
              <div className="flex flex-column gap-3 col-4">
                {timePeriodTypeItems.map((timePeriodTypeItem, index) => {
                  return (
                    <div key={index} className="flex align-items-center">
                      <span>[{timePeriodTypeItem.sequence}]&emsp;</span>
                      <Checkbox inputId={timePeriodTypeItem.timePeriodTypeId} name="category" value={timePeriodTypeItem} checked={timePeriodTypeItem?.checked === "Y"}/>
                      <label htmlFor={timePeriodTypeItem.timePeriodTypeId} className="ml-2">
                        {timePeriodTypeItem.description}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex justify-content-center align-items-center flex-wrap gap-3 col-12 ">
              [02] Lần đầu: [{dataList?.firstDeclaration === 'Y' && <b>X</b>}] <span>[03] Bổ sung lần thứ: {dataList?.additionalTime > 0 && <b>{dataList?.additionalTime}</b>}</span>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px'}} className={'col-3'}>[04] Người nộp thuế: </span>
              <InputText style={{marginRight: '100px'}} value={taxPayerName} onChange={e => setTaxPayerName(e.target.value)} disabled={doUpdate === false}/>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px'}} className={'col-3'}>[05] Tên cửa hàng/thương hiệu: </span>
              <InputText style={{marginRight: '100px'}} value={taxOrganizationName} onChange={e => setTaxOrganizationName(e.target.value)} disabled={doUpdate === false}/>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px'}} className={'col-3'}>[06] Tài khoản ngân hàng : </span>
              <Dropdown value={selectedBankAccount} className={'w-full'} style={{marginRight: '100px'}} onChange={(e) => setSelectedBankAccount(e.value)} options={bankAccounts} disabled={doUpdate === false}/>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px'}} className={'col-3'}>[07] Mã số thuế: </span>
              <InputText style={{marginRight: '100px'}} value={taxCode} onChange={e => setTaxCode(e.target.value)} disabled={doUpdate === false}/>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px'}} className={'col-3'}>[08] Ngành nghề kinh doanh : </span>
              <TreeSelect value={selectedCategory} className={'w-full'} style={{marginRight: '100px'}} onChange={(e) => setSelectedCategory(e.value)} options={category} disabled={doUpdate === false}/>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px'}} className={'col-3'}>[08a] Thay đổi thông tin </span>
              <Checkbox style={{marginTop: '5px'}} checked={isEditCareerCat} disabled={doUpdate === false} onChange={e => setIsEditCareerCat(e.checked)}></Checkbox>
            </div>
            <div className={'flex mb-2'} style={{marginLeft: '95px'}}>
              <div className={'flex col-6'}>
                <span style={{width: '300px'}} className={'col-3'}>[09] Diện tích kinh doanh:</span>
                <InputText style={{marginRight: '20px'}} type={'number'} min={0} value={businessArea} onChange={e => setBusinessArea(e.target.value)} disabled={doUpdate === false}/>
              </div>
              <div className={'col-6'}>
                <span style={{width: '300px'}} className={'col-3'}>[09a] Đi thuê </span>
                <Checkbox style={{marginTop: '5px'}} checked={isRent} disabled={doUpdate === false} onChange={e => setIsRent(e.checked)}></Checkbox>
              </div>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px'}} className={'col-3'}> [10] Số lượng lao động sử dụng thường xuyên </span>
              <InputText style={{marginRight: '100px', height: '40px'}} type={'number'} value={employeeNumber} onChange={e => setEmployeeNumber(e.target.value)} disabled={doUpdate === false}/>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px'}} className={'col-3'}> [11] Thời gian hoạt động trong ngày từ </span>
              <div className={'flex justify-content-center align-items-center'}>
                <InputText value={fromHour} onChange={e => setFromHour(e.target.value)} type={'number'} min={0} max={24} style={{width: '150px', marginRight: '10px'}} disabled={doUpdate === false}></InputText> giờ
                <InputText value={fromMinute} onChange={e => setFromMinute(e.target.value)} type={'number'} min={0} max={60} style={{width: '150px', marginRight: '10px', marginLeft: '10px'}} disabled={doUpdate === false}></InputText> phút &emsp; đến &emsp;
                <InputText value={toHour} onChange={e => setToHour(e.target.value)} type={'number'} min={0} max={24} style={{width: '150px', marginRight: '10px', marginLeft: '10px'}} disabled={doUpdate === false}></InputText> giờ
                <InputText value={toMinute} onChange={e => setToMinute(e.target.value)} type={'number'} min={0} max={60} style={{width: '150px', marginRight: '10px', marginLeft: '10px'}} disabled={doUpdate === false}></InputText> phút
              </div>
            </div>
            <div className={'flex mb-2'} style={{marginLeft: '95px'}}>
              <div className={'flex col-9'}>
                <span style={{width: '300px'}} className={'col-3'}>[12] Địa chỉ kinh doanh :</span>
                <InputText style={{width: '900px'}} value={dataList?.businessAddress1} disabled/>
                <Button size={'small'} style={{width: '45px'}} severity={'warning'} icon={'pi pi-pencil'} disabled={doUpdate === false} onClick={() => updateBusinessForm()}></Button>
              </div>
              <div className={'col-3'}>
                <span style={{width: '300px'}} className={'col-3'}>[12.a] Thay đổi thông tin </span>
                <Checkbox style={{marginTop: '5px'}} checked={isEditBusinessAddress} onChange={e => setIsEditBusinessAddress(e.checked)} disabled={doUpdate === false}></Checkbox>
              </div>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px'}} className={'col-3'}>[12.đ] Tỉnh/Thành phố : </span>
              <Dropdown value={businessStateGeoId} className={'w-full'} style={{marginRight: '100px'}} onChange={(e) => setBusinessStateGeoId(e.value)} options={stateGeo} disabled/>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px'}} className={'col-3'}>[12.d] Quận/Huyện/Thị xã/TP thuộc tỉnh : </span>
              <Dropdown value={businessCountyGeoId} className={'w-full'} style={{marginRight: '100px'}} onChange={(e) => setBusinessCountyGeoId(e.value)} options={countyGeo} disabled/>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px'}} className={'col-3'}>[12.c] Phường/Xã/Thị trấn :</span>
              <Dropdown value={businessWardGeoId} className={'w-full'} style={{marginRight: '100px'}} onChange={(e) => setResidenceWardGeoId(e.value)} options={wardGeo} disabled/>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px'}} className={'col-3'}>[12.b] Số nhà, đường phố/xóm/ấp/thôn :</span>
              <InputText style={{marginRight: '100px', height: '40px'}} value={dataList?.businessAddress2} disabled/>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px'}} className={'col-3'}>[12.e] Kinh doanh tại chợ biên giới :  </span>
              <Checkbox style={{marginTop: '5px'}} checked={isBusinessInMarket} onChange={e => setIsBusinessInMarket(e.checked)} disabled={doUpdate === false}></Checkbox>
            </div>
            <div className={'flex mb-2'} style={{marginLeft: '95px', marginRight: '195px'}}>
              <div className={'flex col-12'} style={{marginRight: '100px'}}>
                <span style={{width: '300px'}} className={'col-3'}>[13] Địa chỉ cư trú:</span>
                <InputText value={dataList?.residenceAddress1} disabled/>
                <Button size={'small'} style={{width: '45px'}} severity={'warning'} icon={'pi pi-pencil'} disabled={doUpdate === false} onClick={() => updateResidenceForm()}></Button>
              </div>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px'}} className={'col-3 align-items-center'}>[13.đ] Tỉnh/Thành phố : </span>
              <Dropdown value={residenceStateGeoId} className={'w-full'} style={{marginRight: '100px'}} onChange={(e) => setResidenceStateGeoId(e.value)} options={stateGeo2} disabled/>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px'}} className={'col-3 align-items-center'}>[13.d] Quận/Huyện/Thị xã/TP thuộc tỉnh : </span>
              <Dropdown value={residenceCountyGeoId} className={'w-full'} style={{marginRight: '100px'}} onChange={(e) => setResidenceCountyGeoId(e.value)} options={countyGeo2} disabled/>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px'}} className={'col-3 align-items-center'}>[13.c] Phường/Xã/Thị trấn :</span>
              <Dropdown value={residenceWardGeoId} className={'w-full'} style={{marginRight: '100px'}} onChange={(e) => setBusinessWardGeoId(e.value)} options={wardGeo2} disabled/>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px'}} className={'col-3 align-items-center'}>[13.b] Số nhà, đường phố/xóm/ấp/thôn :</span>
              <InputText style={{marginRight: '100px', height: '40px'}} value={dataList?.residenceAddress2} disabled/>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px', alignItems: 'center'}} className={'col-3'}> [14] Điện thoại:</span>
              <div className={'flex justify-content-center align-items-center'}>
                <InputText value={telecomNumber} onChange={e => setTelecomNumber(e.target.value)} style={{width: '250px', marginRight: '10px',}} disabled={doUpdate === false}></InputText>&emsp;&emsp;&emsp;&emsp;&emsp;
                [15] Fax:<InputText value={faxNumber} onChange={e => setFaxNumber(e.target.value)} style={{width: '250px', marginRight: '10px', marginLeft: '10px'}} disabled={doUpdate === false}></InputText>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                [16] Email:<InputText value={emailAddress} type={'email'} onChange={e => setEmailAddress(e.target.value)} style={{width: '250px', marginRight: '10px', marginLeft: '10px'}} disabled={doUpdate === false}></InputText>
              </div>
            </div>
            <div className={'flex mb-2'} style={{marginLeft: '94px', marginRight: '195px'}}>
              <div className={'flex col-8 align-items-center'}>
                <span style={{width: '300px'}} className={'col-3'}>[17] Văn bản uỷ quyền khai thuế (nếu có):</span>
                <InputText value={dataList?.authorizationDocuments} disabled/>
              </div>
              <div className={'flex col-4 align-items-center'}>
                <span style={{width: '100px'}} className={'col-3'}>Ngày: </span>
                <Calendar style={{width: '500px'}} value={new Date(documentDate)} onChange={e => setDocumentDate(e.value)} dateFormat="dd/mm/yy" disabled/>
              </div>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px'}} className={'col-3 align-items-center'}>[19] Tên đại lý thuế (nếu có):</span>
              <InputText style={{marginRight: '100px', height: '40px'}} value={taxAgencyName} onChange={e => setTaxAgencyName(e.target.value)} disabled={doUpdate === false}/>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px'}} className={'col-3 align-items-center'}>[20] Mã số thuế đại lý:</span>
              <InputText style={{marginRight: '100px', height: '40px'}} value={taxAgencyTaxCode} onChange={e => setTaxAgencyTaxCode(e.target.value)} disabled={doUpdate === false}/>
            </div>
            <div className={'flex mb-2'} style={{marginLeft: '95px', marginRight: '195px'}}>
              <div className={'flex col-8 align-items-center'}>
                <span style={{width: '300px'}} className={'col-3'}>[21] Hợp đồng đại lý thuế: Số:</span>
                <InputText value={taxAgencyContract} onChange={e => setTaxAgencyContract(e.target.value)} disabled={doUpdate === false}/>
              </div>
              <div className={'flex col-4 align-items-center'}>
                <span style={{width: '100px'}} className={'col-3'}>Ngày: </span>
                <Calendar style={{width: '500px'}} value={new Date(taxAgencyContractDate)} onChange={e => setTaxAgencyContractDate(e.value)} dateFormat="dd/mm/yy" disabled={doUpdate === false}/>
              </div>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px'}} className={'col-3 align-items-center'}>[22] Tên của tổ chức khai thay (nếu có):</span>
              <InputText style={{marginRight: '100px', height: '40px'}} value={dataList?.representOrgName} disabled/>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px'}} className={'col-3 align-items-center'}>[23] Mã số thuế:</span>
              <InputText style={{marginRight: '100px', height: '40px'}} value={dataList?.representOrgTaxCode} disabled/>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px'}} className={'col-3 align-items-center'}>[24] Địa chỉ: </span>
              <InputText style={{marginRight: '100px', height: '40px'}} value={dataList?.representOrgAddress} disabled/>
            </div>
            <div className={'flex mb-2'}>
              <span style={{width: '300px', marginLeft: '100px', alignItems: 'center'}} className={'col-3'}> [25] Điện thoại:</span>
              <div className={'flex justify-content-center align-items-center'}>
                <InputText value={dataList?.representOrgPhoneNumber} style={{width: '250px', marginRight: '10px',}} disabled></InputText>&emsp;&emsp;&emsp;&emsp;
                [26] Fax:<InputText value={dataList?.representOrgFaxNumber} style={{width: '250px', marginRight: '10px', marginLeft: '10px'}} disabled></InputText>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                [27] Email:<InputText value={dataList?.representOrgEmail} style={{width: '250px', marginRight: '10px', marginLeft: '10px'}} disabled></InputText>
              </div>
            </div>
            <div className={'mb-2 justify-content-center align-items-center'}>
              <h5 style={{marginLeft: '100px'}}>A. Kê khai thuế giá trị gia tăng (GTGT), thuế thu nhập cá nhân(TNCN)</h5>
              <div style={{marginLeft: '100px', marginRight: '100px'}}>
                {renderVatPitItems()}
              </div>
            </div>
            <div className={'mb-2 justify-content-center align-items-center'}>
              <h5 style={{marginLeft: '100px'}}>B. Kê khai thuế tiêu thụ đặc biệt (TTĐB)</h5>
              <div style={{marginLeft: '100px', marginRight: '100px'}}>
                {renderVatSpecialItems()}
              </div>
            </div>
            <div className={'mb-2 justify-content-center align-items-center'}>
              <h5 style={{marginLeft: '100px'}}>C. Kê khai thuế/phí bảo vệ môi trường hoặc thuế tài nguyên </h5>
              <div style={{marginLeft: '100px', marginRight: '100px'}}>
                {renderVatNaturalItems()}
              </div>
            </div>
            <div className='mt-5  '>
              <h5 style={{marginLeft: '100px'}}>Nhân viên đại lý thuế </h5>
              <div className={'flex mb-2'} style={{marginLeft: '195px'}}>
                <div className={'flex col-6 align-items-center'}>
                  <span style={{width: '300px'}} className={'col-3'}>Họ và tên:</span>
                  <InputText value={taxAgencyEmployeeName} onChange={(e) => setTaxAgencyEmployeeName(e.target.value)} disabled={doUpdate === false}/>
                </div>
                <div className={'flex col-6 align-items-center'}>
                  <span style={{width: '100px'}} className={'col-3'}>Người ký </span>
                  <InputText style={{width: '500px'}} value={signName} onChange={(e) => setSignName(e.target.value)} disabled={doUpdate === false}/>
                </div>
              </div>
              <div className={'flex mb-2'} style={{marginLeft: '195px'}}>
                <div className={'flex col-6 align-items-center'}>
                  <span style={{width: '300px'}} className={'col-3'}>Chứng chỉ hành nghề số:</span>
                  <InputText value={certificationNo} onChange={(e) => setCertificationNo(e.target.value)} disabled={doUpdate === false}/>
                </div>
                <div className={'flex col-6 align-items-center'}>
                  <span style={{width: '100px'}} className={'col-3'}>Ngày ký</span>
                  <Calendar style={{width: '500px'}} value={signDate} dateFormat="dd/mm/yy" onChange={e => setSignDate(e.value)} disabled={doUpdate === false}/>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="01-2/BK-HĐKD">
          {renderHDKD}
        </TabPanel>
        {addendumTaxDeduction === 'Y'&& <TabPanel header="PL 110/2023/QH15" headerTemplate={templateAddendumTaxDeduction}>
          {renderTaxDeduction}
        </TabPanel>}
        {addendumTaxDeduction === 'N' && <TabPanel headerTemplate = {addAddendumTaxDeduction}>

        </TabPanel>}
      </TabView>
      <Dialog header={'Cập nhật thông tin địa chỉ cư trú'} footer={rFooter} visible={!!displayRForm} style={{width: '800px'}} onHide={() => setDisplayRForm(null)}>
        {residenceForm.render()}
      </Dialog>
      <Dialog header={'Cập nhật thông tin địa chỉ kinh doanh'} footer={bFooter} visible={!!displayBForm} style={{width: '800px'}} onHide={() => setDisplayBForm(null)}>
        {businessForm.render()}
      </Dialog>
    </Dialog>
  );

}

