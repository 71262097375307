import {EntityService} from 'src/service/EntityService';

export class TaxDeclareService extends EntityService {

  static entity = '/iam-tax/tax-declaration';

  static getTaxList(): Promise<any> {
    return this.axios.get(`${this.entity}/list`).then(res => res.data);
  }
  static getTaxDetail(declarationId:string, ): Promise<any> {
    return this.axios.get(`${this.entity}/${declarationId}/detail`, ).then(res => res.data);
  }

  static getDeductionProdCategory(): Promise<any> {
    return this.axios.get(`${this.entity}/deduction-prod-category`, ).then(res => res.data);
  }

  static recalculate(id: string, data?: any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/recalculate`, data).then(res => res.data);
  }
}
