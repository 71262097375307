import {Route, Routes} from 'react-router-dom';

import PhieuChi from './phieu-chi';
import PhieuThu from './phieu-thu';
import {ExpenseInvoice} from './chi-phi';
import QuyTienMat from './quy-tien-mat';
import {TimePeriod} from './so-du-dau-ki';


export default function KeToan() {

  return (
    <Routes>
      <Route path="phieu-chi" element={<PhieuChi/>}/>
      <Route path="phieu-thu" element={<PhieuThu/>}/>
      <Route path="chi-phi" element={<ExpenseInvoice/>}/>
      <Route path="quy-tien-mat" element={<QuyTienMat/>}/>
      <Route path="so-du-dau-ki" element={<TimePeriod/>}/>
    </Routes>
  );
}
