import {EntityService} from '../../../../service/EntityService';

export class EmailMessagesService extends EntityService {

  static entity = 'iam-manager/email-messages';

  static send(emailMessageId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${emailMessageId}/send`).then(res => res.data);
  }

}
