import { useEffect, useState} from 'react';
import {Fieldset} from 'primereact/fieldset';

import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDialogCrup, useForm, useToolbar} from '@iamsoftware/react-hooks';
import {EmployeesService as Service} from './EmployeesService';
import {CommonService} from '../../../service/CommonService';
import {useCrupEmployee} from './CrupEmployee';

const statuses = [
  {value: 'N', label: 'Đang hoạt động'},
  {value: 'Y', label: 'Ngừng hoạt động'}
];


export const Employees = () => {
  const header = 'Nhân viên';
  const dataKey = 'partyId';
  const updateTooltip = 'Cập nhật';
  const lockTooltip = 'Khóa';
  const unlockTooltip = 'Mở khóa';
  const [readOnly, setReadOnly] = useState(false)

  const [disabled, setDisabled] = useState('N');
  const [requiredParams, setRequiredParams] = useState(null);
  const [displayCrupEmployee, setDisplayCrupEmployee] = useState(null)



  useEffect(() => {
    if (disabled) {
      setRequiredParams({disabled: {value: disabled, matchMode: 'equals'}});
    } else {
      setRequiredParams({});
    }
  }, [disabled]); // eslint-disable-line react-hooks/exhaustive-deps

  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <span>{header}</span>
        <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"
                size="small" onClick={() => doCreateEmployee()}/>
      </div>
    </div>
  );
  const {render: renderDataTable, reloadLazyData} = useDataTable({
    tableHeader: tabHeader,
    stateKey: 'mainTable',
    dataKey,
    columns: [
      {
        field: 'pseudoId', header: 'Mã nhân viên', width: 200, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <a onDoubleClick={() => Service.get(rowData.partyId).then(data => {
            doUpdateEmployee(rowData, true)
          })}>{rowData.pseudoId}</a>
        }
      },
      {field: 'partyName', header: 'Tên nhân viên', minWidth: 150, matchMode: 'contains'},
      {field: 'address1', header: 'Địa chỉ', minWidth: 300, matchMode: 'contains'},
      {field: 'jobTitle', header: 'Chức vụ', minWidth: 150, matchMode: 'contains'}
    ],
    indexColumnWidth: 45,
    actionColumnWidth:90,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    initActions(items: Array<any>) {
      if (items){
        items.forEach(item=>{
          item.actions = [
            {icon: 'pi pi-pencil', title: updateTooltip, className: 'p-button-warning', command: () => doUpdateEmployee(item, false)},
          ]
          if (item.disabled === 'N'){
            item.actions.push({icon: 'pi pi-lock', title: lockTooltip, className: 'p-button-danger', command: () => doDisable(item)},)
          }else {
            item.actions.push({icon: 'pi pi-unlock', title: unlockTooltip, className: 'p-button-success', command: () => doEnable(item)},)
          }
        })
      }
    },
    requiredParams
  })
  const {doCreateEmployee, doUpdateEmployee, renderEmployee} = useCrupEmployee({display:displayCrupEmployee, setDisplay: setDisplayCrupEmployee, reloadLazyData})

  const clearAndSwitch = (data) => {
    setDisabled(data);
  }

  const doDisable = (data) => {
    ConfirmDialogService.confirm('Khóa nhân viên', `Xác nhận khóa ${data.partyName} ?`, () => {
      Service.disable(data.partyId).then(() => {
        ToastService.success();
        reloadLazyData();
      });

    })
  }
  const doEnable = (data) => {
    ConfirmDialogService.confirm('Mở khóa nhân viên', `Xác nhận mở khóa ${data.partyName} ?`, () => {
      Service.enable(data.partyId).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    })
  }

  return (
    <div className="grid">
      <div className="col-12">
        <div className="col-12">
          <SelectButton value={disabled} options={statuses} onChange={e => clearAndSwitch(e.value)}/>
        </div>
        {/*<div className="col-12">*/}
        {/*  {renderToolbar()}*/}
        {/*</div>*/}
        <div className="col-12 pb-0">
          {renderDataTable()}
        </div>
      </div>
      {renderEmployee()}
    </div>
  );
}