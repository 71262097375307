import {FormatDisplay, ConfirmDialogService, ToastService, useDataTable, useDialogCrup, useForm, useToolbar} from '@iamsoftware/react-hooks';
import {IncomingPaymentsService as Service} from './incomingPaymentsService';

import {Fragment, useEffect, useState} from 'react';
import {Button} from 'primereact/button';
import {Fieldset} from 'primereact/fieldset';
import {Dropdown} from 'primereact/dropdown';
import {RadioButton} from 'primereact/radiobutton';
import {InputText} from 'primereact/inputtext';
import {CustomersService} from '../../ban-hang/khach-hang/CustomersService';
import {EmployeesService} from '../../quan-ly/nhan-vien/EmployeesService';
import {CommonService} from '../../../service/CommonService';
import {useCrupSO} from '../../ban-hang/don-hang/CrupSO';
import {SalesOrdersService} from '../../ban-hang/don-hang/ordersService';
import {BankAccountService} from '../../danh-muc/tai-khoan-ngan-hang/BankAccountService';
import {ObjectType} from './objectType';
import {SuppliersService} from '../../mua-hang/nha-cung-cap/SuppliersService';
import {useCrupPayment} from '../../ban-hang/thanh-toan/crupPayment';

export default function PhieuThu() {

  const header = 'Phiếu thu';
  const dataKey = 'paymentId';
  const updateTooltip = 'Cập nhật';
  const resendTooltip = 'Xóa';
  const [customers, setCustomers] = useState([]);
  const [readOnly, setReadOnly] = useState(false);
  const [enteredParties, setEnteredParties] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [customerBank, setCustomerBank] = useState([]);
  const [hkdBank, setHkdBank] = useState([]);
  const [objectTypes, setObjectTypes] = useState(null);
  const [roleCurrency, setRoleCurrency] = useState(null);
  const [expenseTypes, setExpenseTypes] = useState(null);
  const [paymentRefNum, setPaymentRefNum] = useState(null);
  const [displayObj, setDisplayObj] = useState(null);
  const [objBySearch, setObjBySearch] = useState([]);
  const [statusId, setStatusId] = useState('PmntDelivered');
  const [selectedPartyId, setSelectedPartyId] = useState(null);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    CommonService.getPaymentInstruments().then(data => {
      setPaymentTypes(data.listData.map(item => {
        return {value: item.enumId, label: item.description};
      }))
    })
    CommonService.otherIncomingPaymentTypes().then(data => {
      setExpenseTypes(data.listData.map(item => {
        return {value: item.enumId, label: item.description}
      }))
    })
  }, []);

  useEffect(() => {
    const _event: any = {
      filters: {}
    };
    BankAccountService.getList(JSON.stringify(_event)).then(data => {
      setHkdBank(data.listData.map(item => {
        return {value: item.paymentMethodId, label: `[${item.bankAccount}] - ${item.bankName}`};
      }))
    })
  }, []);


  const searchCustomer = event => {
    CustomersService.find(objectTypes, event.query).then(data => {
      setCustomers(data.listData.map(item => {
        delete item.disabled;
        let label = `[${item.pseudoId}] - ${item.partyName}`;
        return {...item, value: item.partyId, label: label};
      }));
    });
  }

  const searchEmployees = event => {
    EmployeesService.find(event.query).then(data => {
      setEnteredParties(data.listData.map(item => {
        delete item.disabled;
        let label = `[${item.pseudoId}] - ${item.partyName}`;
        return {...item, value: item.partyId, label: label};
      }));
    });
  }

  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <span>Phiếu thu</span>
        <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"
                size="small" onClick={() => doCreate()}/>
      </div>
    </div>
  );

  const {render: renderDataTable, selectedItems, setSelectedItems, reloadLazyData} = useDataTable({
    tableHeader: tabHeader,
    stateKey: 'mainTable',
    dataKey,
    columns: [
      {
        field: 'paymentRefNum', header: 'Mã chứng từ', width: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <a onDoubleClick={() => {
            doUpdate(rowData, true)
          }}>{rowData.paymentRefNum}</a>
        }
      },
      {field: 'effectiveDate', header: 'Ngày chứng từ', dataType: 'date', width: 140, matchMode: 'contains'},
      {field: 'paymentTypeEnum', header: 'Loại chứng từ', minWidth: 180, matchMode: 'contains'},
      {field: 'fromPartyName', header: 'Đối tượng', minWidth: 250, matchMode: 'contains'},
      {field: 'memo', header: 'Diễn giải', minWidth: 250, matchMode: 'contains'},
      {field: 'amount', header: 'Tổng tiền', dataType: 'number', minWidth: 120, matchMode: 'contains'},
      {field: 'status', header: 'Trạng thái', minWidth: 150, matchMode: 'contains'}
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 140,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-pencil', title: updateTooltip, className: 'p-button-warning', command: () => doUpdate(item, false)},
          ]
          if (item.statusId === 'PmntDelivered') {
            item.actions.push({icon: 'pi pi-times', title: 'Bỏ ghi sổ', className: 'p-button-danger', command: () => doUnApprove(item)})
          } else {
            item.actions.push({icon: 'pi pi-check', title: 'Ghi sổ', className: 'p-button-success', command: () => doApprove(item)})
          }
          item.actions.push({icon: 'pi pi-trash', title: resendTooltip, className: 'p-button-secondary', command: () => doDelete(item)})
          item.actionMenus = [
            {icon: 'pi pi-file-pdf', label: 'Xem pdf', className: 'p-button-help', command: () => pdf(item)}
          ]
        })
      }
    }
  });

  //Thay đổi đối tượng -> set dirty = true
  const doChangeParty = (value) => {
    setSelectedPartyId(value)
    setDirty(true)
  }

  const commonInfoForm = useForm({
    fields: [
      {
        field: 'paymentTypeEnumId', header: 'Loại chứng từ', className: 'md:col-4', type: 'Dropdown', DropdownProps: {options: expenseTypes}, required: true
      },
      {
        field: 'status', header: '', className: 'md:col-8', type: 'custom',
        body: <Fragment>
          <div className="flex flex-wrap gap-3 radio-config-mt">
            <div className="flex align-items-center">
              <RadioButton inputId="status1" name="status" value="PmntDelivered" disabled={readOnly} onChange={(e) => setStatusId(e.value)} checked={statusId === 'PmntDelivered'}/>
              <label htmlFor="status1" className="ml-2">Đã thanh toán</label>
            </div>
            <div className="flex align-items-center">
              <RadioButton inputId="status2" name="status" value="PmntAuthorized" disabled={readOnly} onChange={(e) => setStatusId(e.value)} checked={statusId === 'PmntAuthorized'}/>
              <label htmlFor="status2" className="ml-2">Chưa thanh toán</label>
            </div>
          </div>
        </Fragment>
      },
      {
        field: 'party', header: 'Mã đối tượng', required: true, className: 'md:col-4', type: 'custom',
        body: <Fragment>
          <label>Mã đối tượng <span style={{color: 'red'}}>*</span></label>
          <div className="p-inputgroup flex-1">
            <Dropdown value={selectedPartyId} onChange={(e) => doChangeParty(e.value)} options={customers} optionLabel="label" placeholder="Chọn đối tượng" filter={true} disabled={readOnly}></Dropdown>
            <Button onClick={() => setDisplayObj(true)} icon="pi pi-search" severity="info" aria-label="Search" disabled={readOnly}></Button>
          </div>
        </Fragment>
      },
      {
        field: 'partyName', header: 'Tên đối tượng', className: 'md:col-8'
      },
      {
        field: 'paymentInstrumentEnumId', header: 'Hình thức thanh toán', required: true, className: 'md:col-4', type: 'Dropdown', DropdownProps: {options: paymentTypes}
      },
      {
        field: 'amount', header: 'Số tiền', className: 'md:col-4', type: 'InputNumber', InputNumberProps: {min: 0}, displayDependency(item: any): boolean {
          return item?.paymentInstrumentEnumId === 'PiCash';
        }
      },
      {
        field: 'fromName', header: 'Người nộp', className: 'md:col-4', displayDependency(item: any): boolean {
          return item?.paymentInstrumentEnumId === 'PiCash';
        }
      },
      {
        field: 'paymentMethodId', header: 'Tài khoản chuyển', className: 'md:col-4', displayDependency(item: any): boolean {
          return ['PiCashOrWireTransfer', 'PiWireTransfer'].includes(item?.paymentInstrumentEnumId)
        }, type: 'Dropdown', DropdownProps: {options: customerBank}
      },
      {
        field: 'toPaymentMethodId', header: 'Tài khoản nhận', required: true, className: 'md:col-4', displayDependency(item: any): boolean {
          return ['PiCashOrWireTransfer', 'PiWireTransfer'].includes(item?.paymentInstrumentEnumId)
        }, type: 'Dropdown', DropdownProps: {options: hkdBank}
      },
      {
        field: 'amount', header: 'Số tiền', className: 'md:col-4', type: 'InputNumber', InputNumberProps: {min: 0}, displayDependency(item: any): boolean {
          return item?.paymentInstrumentEnumId !== 'PiCash';
        }
      },
      {
        field: 'address1', header: 'Địa chỉ', className: 'md:col-8', displayDependency(item: any): boolean {
          return item?.paymentInstrumentEnumId !== 'PiCash';
        }
      },
      {
        field: 'address1', header: 'Địa chỉ', className: 'md:col-12', displayDependency(item: any): boolean {
          return item?.paymentInstrumentEnumId == 'PiCash';
        }
      },
      {
        field: 'enteredByParty', header: 'Nhân viên thực hiện', className: 'md:col-4', type: 'AutoComplete',
        AutoCompleteProps: {suggestions: enteredParties, completeMethod: searchEmployees, field: 'label', dropdown: true, forceSelection: true},
        disabled: 'View'
      },
      {field: 'comments', header: 'Kèm theo', className: 'md:col-8'},
      {field: 'memo', header: 'Ghi chú diễn giải', className: 'md:col-12'},
    ],
    readOnly
  })

  const certInfoForm = useForm({
    fields: [
      {
        field: 'paymentRefNum', header: 'Số chứng từ', required: true, className: 'md:col-12', type: 'custom',
        body: <Fragment>
          <label>Số chứng từ <span style={{color: 'red'}}>*</span></label>
          <div className="p-inputgroup flex-1">
            <InputText id="paymentRefNum" value={paymentRefNum} onChange={e => setPaymentRefNum(e.target.value)}/>
            <Button onClick={() => doRefreshRefNum()} icon="pi pi-refresh" severity="info" aria-label="Reload" disabled={readOnly}></Button>
          </div>
        </Fragment>
      },
      {field: 'effectiveDate', header: 'Ngày chứng từ', InputTextProps: {type: 'date'}, className: 'md:col-12'},
    ]
  })

  const {render: renderDialogCrup, create, update, view, formMethod, display} = useDialogCrup({
    header,
    dataKey,
    width: '120rem',
    fields: [
      {
        type: 'custom', className: 'md:col-8', body: <Fieldset legend="Thông tin chung">
          {commonInfoForm.render()}
        </Fieldset>
      },
      {
        type: 'custom', className: 'md:col-4', body: <Fieldset legend="Chứng từ">
          {certInfoForm.render()}
        </Fieldset>
      },
    ],
    createItem: async item => {
      if (!selectedPartyId || !paymentRefNum){
        if (!selectedPartyId){
          ToastService.error('Mã đối tượng không được rỗng')
        }
        if (!paymentRefNum){
          ToastService.error('Số chứng từ không được rỗng')
        }
        return Promise.reject();
      }else {
        return Service.create(await inject());
      }
    },
    updateItem: async (id, item) => {
      if (!selectedPartyId || !paymentRefNum){
        if (!selectedPartyId){
          ToastService.error('Mã đối tượng không được rỗng')
        }
        if (!paymentRefNum){
          ToastService.error('Số chứng từ không được rỗng')
        }
        return Promise.reject();
      }else {
        return Service.update(id, await inject());
      }
    },
    reloadLazyData
  });
  const {renderCrupSO, doUpdateSO} = useCrupSO({reloadLazyData},);
  const {renderPayment, doCreatePayment, doUpdatePayment} = useCrupPayment({reloadLazyData});

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  useEffect(() => {
    CommonService.getParties().then(data => {
      setCustomers(data.listData.map(item => {
        return {value: item.partyId, label: `[${item.pseudoId}] - ${item.partyName}`}
      }))
    })
  }, [display]);

  useEffect(() => {
    if (objBySearch?.length) {
      setSelectedPartyId(objBySearch[0].value);
      setObjBySearch([])
    }
  }, [displayObj]);

  const {paymentInstrumentEnumId} = commonInfoForm.getRawValue();

  //Thay doi ma chung tu khi doi hinh thuc thanh toan
  useEffect(() => {
    if (paymentInstrumentEnumId && commonInfoForm.dirty['paymentInstrumentEnumId']) {
      SalesOrdersService.genPaymentPseudoId(paymentInstrumentEnumId).then(data => {
        setPaymentRefNum(data.code)
      })
    }
  }, [paymentInstrumentEnumId]);

  const {partyId} = commonInfoForm.getRawValue()

  //thay doi thong tin khi thay doi doi tuong
  useEffect(() => {
    if (selectedPartyId && dirty) {
      const _value = commonInfoForm.getRawValue();
      SuppliersService.get(selectedPartyId).then(data => {
        _value.address1 = data?.address1;
        _value.memo = `Thu từ ${data?.partyName}`;
        _value.partyName = data?.partyName;
        if (data?.paymentMethodId) {
          setCustomerBank([{value: data.paymentMethodId, label: `[${data.accountNumber}] - ${data.bankName}`}])
        } else {
          setCustomerBank([]);
        }
        commonInfoForm.setValue(_value);
      })
    }
  }, [selectedPartyId]); // eslint-disable-line react-hooks/exhaustive-deps

  const inject = () => {
    const item = commonInfoForm.getRawValue();
    item.fromPartyId = selectedPartyId;
    item.enteredByPartyId = item.enteredByParty?.value;
    if (item.fromPartyId) {
      SuppliersService.get(item.fromPartyId).then(data => {
        item.postalContactMechId = data.postalContactMechId;
      })
    }
    item.paymentRefNum = paymentRefNum;
    item.paymentStatusId = statusId
    item.effectiveDate = Date.parse(certInfoForm.getRawValue().effectiveDate);
    return item;
  }

  const doCreate = () => {
    setSelectedPartyId(null)
    setStatusId('PmntDelivered')
    setDirty(false)
    setCustomerBank([]);
    setObjBySearch([]);
    setObjectTypes([]);
    commonInfoForm.reset();
    certInfoForm.reset();
    SalesOrdersService.genPaymentPseudoId('PiCash').then(data => {
      setPaymentRefNum(data.code);
      certInfoForm.setValue({effectiveDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD')})
      commonInfoForm.setValue({paymentInstrumentEnumId: 'PiCash', paymentTypeEnumId: expenseTypes[0]?.value});
    });
    setReadOnly(false);
    setSelectedItems([]);
    create();
  }

  const doUpdate = (data, doView?: boolean) => {
    setDirty(false)
    setObjBySearch([]);
    if (data.paymentTypeEnumId === 'PtOtherReceipt') {
      Service.get(data.paymentId).then(async data => {
        if (data.orderId) {
          doSwitch(data.orderId, false);
        } else {
          if (data.fromPartyId) {
            setCustomers([{value: data.fromPartyId, label: `[${data.fromPseudoId}] - ${data.fromPartyName}`}])
            setSelectedPartyId(data.fromPartyId);
            const item = await SuppliersService.get(data.fromPartyId)
            data.roleTypeId = item.roleTypeId;
            setRoleCurrency(item.roleTypeId);
            if (item?.paymentMethodId) {
              setCustomerBank([{value: item.paymentMethodId, label: `[${item.accountNumber}] - ${item.bankName}`}])
            } else {
              setCustomerBank([]);
            }
          }
          setStatusId(data.statusId)
          data.partyName = data.fromPartyName
          if (data.effectiveDate) {
            data.effectiveDate = FormatDisplay.date(new Date(data.effectiveDate), 'YYYY-MM-DD');
          }
          if (data.enteredByPartyId) {
            const item = await EmployeesService.get(data.enteredByPartyId)
            data.enteredByParty = {value: item.enteredByPartyId, label: `[${item.pseudoId}] - ${item.partyName}`}
          }
          setPaymentRefNum(data.paymentRefNum)
          commonInfoForm.setValue(data);
          certInfoForm.setValue(data);
          if (doView === true) {
            setReadOnly(true)
            view(data)
          } else {
            setReadOnly(false);
            update(data)
          }
        }
      })
    } else {
      doUpdatePayment(data, doView)
    }
  }

  const pdf = (item) => {
    if (item) {
      window.open(`${Service.baseURL}/${Service.entity}/${item[dataKey]}/pdf`)
    }
  }

  const doDelete = (item) => {
    if (item) {
      ConfirmDialogService.confirm('Xóa phiếu thu', 'Xác nhận xóa phiếu thu này?', () => {
        Service.cancel(item[dataKey]).then(() => {
          ToastService.success();
          reloadLazyData();
        });
      });
    }
  }

  const doSwitch = (orderId, doView: boolean) => {
    SalesOrdersService.get(orderId).then(data => {
      doUpdateSO(orderId, data, doView, 'Payment');
    })
  }

  const doRefreshRefNum = () => {
    if (paymentInstrumentEnumId) {
      SalesOrdersService.genPaymentPseudoId(paymentInstrumentEnumId).then(data => {
        setPaymentRefNum(data.code)
      })
    }
  }

  const doApprove = (item) => {
    if (item) {
      ConfirmDialogService.confirm('Ghi sổ', 'Xác nhận ghi sổ phiếu thu này?', () => {
        Service.approve(item[dataKey]).then(() => {
          ToastService.success();
          reloadLazyData();
        });
      })
    }
  }
  const doUnApprove = (item) => {
    if (item) {
      ConfirmDialogService.confirm('Bỏ ghi sổ', 'Xác nhận bỏ ghi sổ phiếu thu này?', () => {
        Service.unApprove(item[dataKey]).then(() => {
          ToastService.success();
          reloadLazyData();
        });
      })
    }
  }

  return (
    <div className="grid">
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12.5rem)'}}>
        {renderDataTable()}
      </div>
      {renderDialogCrup()}
      {renderCrupSO()}
      {renderPayment()}
      <ObjectType method={formMethod} setObjectType={setObjBySearch} display={displayObj} setDisplay={setDisplayObj}/>
    </div>
  );
}
