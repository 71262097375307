import {EntityService} from 'src/service/EntityService';

export class IncomingPaymentsService extends EntityService {

  static entity = 'iam-accounting/incomingPayments';

  static approve(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/delivery`).then(res => res.data);
  }
  static unApprove(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/un-delivery`).then(res => res.data);
  }
  static cancel(id: string): Promise<any> {
    return this.axios.delete(`${this.entity}/${id}/cancel`).then(res => res.data);
  }
}