import {SalesOrdersService, SalesOrdersService as Service} from '../don-hang/ordersService';
import {Column} from 'primereact/column';
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';
import {Dialog} from 'primereact/dialog';
import {Splitter, SplitterPanel} from 'primereact/splitter';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {FormatDisplay, ToastService, useDataTableBasic, useForm} from '@iamsoftware/react-hooks';
import {CustomersService} from '../khach-hang/CustomersService';
import {Fragment, useEffect, useState} from 'react';
import {useCrupPaymentItems} from '../don-hang/PaymentItems';
import {CommonService} from '../../../service/CommonService';
import {BankAccountService} from '../../danh-muc/tai-khoan-ngan-hang/BankAccountService';
import {ObjectType} from '../../ke-toan/phieu-thu/objectType';
import {SuppliersService} from '../../mua-hang/nha-cung-cap/SuppliersService';

export const useCrupPayment = ({reloadLazyData}) => {
  const [paymentInstruments, setPaymentInstruments] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [display, setDisplay] = useState(null);
  const [items, setItems] = useState([])
  const [grandTotal, setGrandTotal] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [amountApplied, setAmountApplied] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [creating, setCreating] = useState(false)
  const [displayCreating, setDisplayCreating] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null)
  const [customers, setCustomers] = useState([]);
  const [selectedPartyId, setSelectedPartyId] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [dirty, setDirty] = useState(false);
  const [displayObj, setDisplayObj] = useState(null);
  const [objBySearch, setObjBySearch] = useState([]);

  useEffect(() => {
    CommonService.getPaymentInstruments().then(data => {
      setPaymentInstruments(data.listData.map(item => {
        return {value: item.enumId, label: item.description}
      }));
    });
    BankAccountService.getList('').then(data => {
      setBankAccounts(data.listData.map(item => {
        item.toPaymentMethodId = item.paymentMethodId
        return {value: item.toPaymentMethodId, label: `${item.bankName} - ${item.bankAccount}`}
      }));
    });
    CustomersService.getList('{"filters":{"disabled":{"value":"N","matchMode":"equals"}}}').then(data => {
      setCustomers(data.listData.map(item => {
        return {value: item.partyId, label: `[${item.pseudoId}] - ${item.partyName}`}
      }));
    });
    CommonService.getPaymentStatuses().then(data => {
      setPaymentStatus(data.listData.map(item => {
        return {value: item.statusId, label: item.description};
      }))
    })
    if (!display) {
      setSelectedPayment(null)
      setItems([])
      setSelectedItems([])
    } else if (!displayCreating) {
      setItems([])
      setSelectedItems([])
    }
  }, [])
  const {renderPaymentItems} = useCrupPaymentItems({display, disabled: true, items: items});

  const onEditorChange = (rowData, column, newValue) => {
    setItems(prevItems => prevItems.map(prevItem => {
      if (prevItem.orderId === rowData.orderId) {
        return {
          ...prevItem,
          [column.field]: newValue
        }
      }
      return prevItem;
    }));
  }
  const doChangeParty = (value) => {
    setSelectedPartyId(value)
    setDirty(true)
  }
  const commonInfoForm = useForm({
    fields: [
      {
        field: 'fromPartyId', header: 'Mã đối tượng', required: true, className: 'md:col-4', type: 'custom',
        body: <Fragment>
          <label>Mã đối tượng <span style={{color: 'red'}}>*</span></label>
          <div className="p-inputgroup flex-1">
            <Dropdown value={selectedPartyId} onChange={(e) => doChangeParty(e.value)} options={customers} optionLabel="label" placeholder="Chọn đối tượng" filter={true} disabled={creating === false}></Dropdown>
            <Button onClick={() => setDisplayObj(true)} icon="pi pi-search" severity="info" aria-label="Search" disabled={creating === false}></Button>
          </div>
        </Fragment>
      },
      {
        field: 'partyName', header: 'Tên đối tượng', className: 'md:col-8'
      },
      {field: 'address1', header: 'Địa chỉ', className: 'md:col-12', disabled: true},
      {field: 'paymentInstrumentEnumId', header: 'HTTT', type: 'Dropdown', DropdownProps: {options: paymentInstruments}, className: 'md:col-4'},
      {
        field: 'toPaymentMethodId', header: 'Tài khoản nhận', type: 'Dropdown', DropdownProps: {options: bankAccounts}, className: 'md:col-8', displayDependency(item: any): boolean {
          return paymentInstrumentEnumId === 'PiWireTransfer'
        }
      },
      {
        field: 'toName', header: 'Người nhận', className: 'md:col-8', displayDependency(item: any): boolean {
          return paymentInstrumentEnumId === 'PiCash'
        }
      },
      {field: 'memo', header: 'Lý do thu', className: 'md:col-12'},
    ],
    disabled
  });
  const infoForm = useForm({
    fields: [
      {field: 'paymentRefNum', header: 'Mã chứng từ', className: 'md:col-12'},
      {field: 'effectiveDate', header: 'Ngày chứng từ', InputTextProps: {type: 'date'}, className: 'md:col-12'},
      {field: 'statusId', header: 'Trạng thái TT', type: 'Dropdown', DropdownProps: {options: paymentStatus}, className: 'md:col-12'},
      {field: 'amount', header: 'Tổng tiền TT', type: 'InputNumber', className: 'md:col-12', disabled: true},
    ],
    disabled
  });
  const {render: renderNewPaymentItems, selectedItems, setSelectedItems} = useDataTableBasic({
    tableHeader: <div className="flex align-items-center justify-content-between ">
      <Fragment>
        <span>Chứng từ công nợ</span>
      </Fragment>
    </div>,
    dataKey: 'orderId',
    columns: [
      {field: 'orderId', hidden: true, width: 150},
      {field: 'placedDate', header: 'Ngày chứng từ', dataType: 'date', width: 150},
      {field: 'displayId', header: 'Số chứng từ', width: 150},
      {field: 'orderComments', header: 'Diễn giải', width: 400},
      {field: 'grandTotal', header: 'Số phải thu', dataType: 'number', width: 200},
      {field: 'remainingAmount', header: 'Số chưa thu', dataType: 'number', width: 200},
      {
        field: 'amountApplied', header: 'Số thu', width: 200, dataType: 'editor', editorConfig: {
          onEditorChange,
          field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }
      },
    ],
    items,
    selectionMode: 'checkbox',
    originalProps: {
      footerColumnGroup: <ColumnGroup>
        <Row>
          <Column colSpan={4} footerStyle={{textAlign: 'center'}} footer='Tổng cộng:'></Column>
          <Column footer={FormatDisplay.number(grandTotal)} footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
          <Column footer={FormatDisplay.number(remainingAmount)} footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
          <Column footer={FormatDisplay.number(amountApplied)} footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
        </Row>
      </ColumnGroup>
    }
  });

  const commonData = commonInfoForm.getRawValue();
  const infoData = infoForm.getRawValue();
  const {paymentInstrumentEnumId, fromPartyId} = commonInfoForm.getValue()
  useEffect(() => {
    if (paymentInstrumentEnumId && infoData && commonInfoForm.dirty['paymentInstrumentEnumId']) {
      Service.genPaymentPseudoId(paymentInstrumentEnumId).then(data => {
        infoData.paymentRefNum = data.code
        if (infoData.effectiveDate === '') {
          infoData.effectiveDate = FormatDisplay.date(new Date(), 'YYYY-MM-DD')
        }
        infoForm.setValue(infoData)
      })
    }
  }, [paymentInstrumentEnumId])

  useEffect(() => {
    if (selectedPartyId && dirty) {
      Service.uncompletedPayments(selectedPartyId, '').then(data => {
        setItems(data.items)
      })
      const _value = commonInfoForm.getRawValue();
      CustomersService.get(selectedPartyId).then(data => {
        _value.address1 = data?.address1;
        _value.memo = `Thu tiền từ khách hàng ${data?.partyName}`;
        _value.partyName = data?.partyName;
        _value.paymentInstrumentEnumId = "PiCash";
        commonInfoForm.setValue(_value);
      })
    }
  }, [selectedPartyId]); // eslint-disable-line react-hooks/exhaustive-deps

  //set value tong tien
  useEffect(() => {
    if (items.length) {
      let sum1 = 0;
      let sum2 = 0;
      let sum3 = 0;
      if (displayCreating) {
        items.map(item => {
          if (item?.checked === 'Y') {
            sum1 += item?.grandTotal;
            sum2 += item?.remainingAmount;
            sum3 += item?.amountApplied;
            selectedItems.push(item)
          }
        })
      } else {
        items.map(item => {
          sum1 += item?.grandTotal;
          sum2 += item?.remainingAmount;
          sum3 += item?.amountApplied;
          selectedItems.push(item)

        })
      }
      setGrandTotal(sum1)
      setRemainingAmount(sum2)
      setAmountApplied(sum3)
    }
  }, [items.length]);
  useEffect(() => {
    if (selectedItems.length) {
      let sum1 = 0;
      let sum2 = 0;
      let sum3 = 0;
      selectedItems.map(selectedItem => {
        sum1 += selectedItem?.grandTotal;
        sum2 += selectedItem?.remainingAmount;
        sum3 += selectedItem?.amountApplied;
      })
      setGrandTotal(sum1)
      setRemainingAmount(sum2)
      setAmountApplied(sum3)
    } else {
      setGrandTotal(0)
      setRemainingAmount(0)
      setAmountApplied(0)
    }
  }, [selectedItems]);


  useEffect(() => {
    if (displayCreating) {
      setDisabled(false)
    } else {
      setSelectedItems([])
      setDisabled(true)
    }
  }, [displayCreating])
  const onAddPayment = () => {
    Service.uncompletedPayments(fromPartyId, '').then(payments => {
      setItems(payments.items)
    });
    setDisplayCreating(Date.now());
  }
  useEffect(() => {
    if (objBySearch?.length) {
      setSelectedPartyId(objBySearch[0].value);
      setObjBySearch([])
    }
  }, [displayObj]);
  const reset = () => {
    setItems([])
    setSelectedItems([])
    commonInfoForm.reset();
    infoForm.reset();
  }


  const doCreate = async () => {
    reset();
    setDisabled(false);
    setCreating(true)
    setSelectedPartyId('')
    Service.genPaymentPseudoId('PiCash').then(data => {
      infoForm.setValue({
        paymentRefNum: data.code,
        effectiveDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD'),
        statusId: paymentStatus[0]?.value
      })
    })
    setDisplayCreating(Date.now());
  }


  const doUpdate = async (rowData: any, doView: boolean) => {
    reset()
    if (doView === true) {
      setDisabled(true)
    } else if (doView === false) {
      setDisabled(false)
    }
    if (rowData.effectiveDate) {
      rowData.effectiveDate = FormatDisplay.date(new Date(rowData.effectiveDate), 'YYYY-MM-DD')
    }
    setSelectedPayment(rowData.paymentId)
    setSelectedPartyId(rowData.fromPartyId);

    Service.paymentDetail(rowData.paymentId).then(data => {
      setItems(data.items)
    })
    CustomersService.get(rowData.fromPartyId).then(customer => {
      rowData.address1 = customer.address1
      rowData.partyName = customer.partyName
      commonInfoForm.setValue(rowData)
    })
    infoForm.setValue(rowData)
    setDisplay(Date.now())
    setCreating(false)
  }


  const onSubmit = () => {
    const data = Object.assign(
      commonData,
      infoData);
    data.fromPartyId = selectedPartyId;
    if (data.effectiveDate) {
      data.effectiveDate = Date.parse(data.effectiveDate)
    }
    if (!data.fromPartyId){
      ToastService.error('Mã đối tượng không được rỗng')
    }else {
      if (creating === false && disabled === false) {
        Service.updatePayment(selectedPayment, data).then(() => {
          setDisplay(null)
          ToastService.success()
          reloadLazyData();
        })
      } else {
        if (selectedItems.length > 0) {
          selectedItems.map(selectedItem => {
            selectedItem.paymentAmount = selectedItem.amountApplied
          })
          data.items = selectedItems
          Service.createPayment(data).then(() => {
            ToastService.success();
            setDisplayCreating(null);
            reloadLazyData();
          })
        } else {
          ToastService.error('Bạn chưa chọn chứng từ !')
        }
      }
    }
  }
  const header = () => {
    return (
      <div className="flex flex-wrap">
        {creating === false && <div className="md:col-12 justify-content-center flex-wrap flex">
					<div className={'flex mt-2 ml-4'}>
						<h4><b> Chi tiết phiếu thu</b></h4>
					</div>
				</div>}
      </div>
    )
  }
  const footer = (
    <div className="flex" style={{justifyContent: 'end'}}>
      {!disabled && <Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit}/>}
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
    </div>
  );
  const creatingFooter = (
    <div className="flex" style={{justifyContent: 'end'}}>
      {!disabled && <Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit}/>}
      {!disabled && creating === false && <Button label="Hủy" icon="pi pi-ban" severity={'danger'} size="small" onClick={() => setDisabled(true)}/>}
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplayCreating(null)}/>
    </div>
  );
  const render = () => {
    return (
      <Fragment>

        <Dialog header={header} footer={footer} visible={!!display} maximized={true} draggable={false} onHide={() => setDisplay(null)}>
          <div className="grid">
            <div className="col-12">
              <Splitter draggable={false}>
                <SplitterPanel size={75} minSize={10}>
                  <div style={{padding: '20px'}}>
                    {commonInfoForm.render()}
                  </div>
                </SplitterPanel>
                <SplitterPanel size={25}>
                  <div style={{padding: '20px'}}>
                    {infoForm.render()}
                  </div>
                </SplitterPanel>
              </Splitter>
              {renderPaymentItems()}
            </div>
          </div>
        </Dialog>
        <Dialog header={'Thu tiền khách hàng theo đơn hàng'} footer={creatingFooter} visible={!!displayCreating} maximized={true} draggable={false} onHide={() => setDisplayCreating(null)}>
          <div className="grid">
            <div className="col-12">
              <Splitter draggable={false}>
                <SplitterPanel size={75} minSize={10}>
                  <div style={{padding: '20px'}}>
                    {commonInfoForm.render()}
                  </div>
                </SplitterPanel>
                <SplitterPanel size={25}>
                  <div style={{padding: '20px'}}>
                    {infoForm.render()}
                  </div>
                </SplitterPanel>
              </Splitter>
              <div className="grid">
                {renderNewPaymentItems()}
              </div>
            </div>
          </div>
        </Dialog>
        <ObjectType method={Date.now()} setObjectType={setObjBySearch} display={displayObj} setDisplay={setDisplayObj}/>
      </Fragment>
    )
      ;
  }

  return {renderPayment: render, doCreatePayment: doCreate, doUpdatePayment: doUpdate};

}
