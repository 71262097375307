import {ConfirmDialogService, ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';
import {Fragment, useEffect, useState} from 'react';
import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {TreeSelect} from 'primereact/treeselect';
import {AutoComplete} from "primereact/autocomplete";

import {ProductService as Service} from './productService';
import {CommonService} from 'src/service/CommonService';
import {BussinessJobService} from '../../danh-muc/business-jobs/BussinessJobService';
import {UomsService} from '../../danh-muc/uom/UomsService';
import {UploadExcel} from './UploadExcel';

const statuses = [
  {value: 'N', label: 'Đang hoạt động'},
  {value: 'Y', label: 'Ngừng hoạt động'}
];

export const SanPham = () => {

  const header = 'Sản phẩm';
  const dataKey = 'productId';
  const updateTooltip = 'Cập nhật';
  const lockTooltip = 'Khóa';
  const unlockTooltip = 'Mở khóa';
  const deleteTooltip = 'Xóa';

  const [statusId, setStatusId] = useState(statuses[0].value);
  const [countries, setCountries] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [displayUploadExcel, setDisplayUploadExcel] = useState(null);
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const [nodes, setNodes] = useState(null);
  const [quantityUoms, setQuantityUoms] = useState([]);
  const [uom, setUom] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const [quickCreateData, setQuickCreateData] = useState(null);
  useEffect(() => {
    if (statusId) {
      reloadLazyData();
    }
  }, [statusId]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    CommonService.getCountries().then(data => {
      setCountries(data.listData.map(item => {
        return {value: item.geoId, label: item.geoName}
      }));
    })
    Service.getProductType().then(data => {
      setProductTypes(data.listData.map(item => {
        return {value: item.enumId, label: item.description}
      }));
    });

    BussinessJobService.getTreeList().then(data => {
      setLabel(data.data)
      setNodes(data.data);
    });
  }, []);
  const searchQuantityUoms = event => {
    UomsService.find(event.query).then(data => {
      setQuantityUoms(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.uomId, label: item.abbreviation};
      }));
    });
  }

  const setLabel = (data) => {
    data.map(item => {
      item.label = item.data.categoryName
      item.children.map(children => {
        children.label = children.data.categoryName
        return children
      })
      return item
    })
  }
  const itemTemplate = (item) => {
    return (
      <div className="flex align-items-center">
        <div>{item.label}</div>
      </div>
    );
  };

  const doDownloadExcel = () => {
    window.open(`${Service.baseURL}/${Service.entity}/excel`)
  }

  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <span>{header}</span>
        <div>
          <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-1 mb-1 w-7rem h-2rem"
                  size="small" onClick={() => doCreate()}/>
          <Button label="Upload Excel" icon="pi pi-upload" severity="secondary" className="mr-1 mb-1 w-10rem h-2rem"
                  size="small" onClick={() => setDisplayUploadExcel(Date.now())}/>
          <Button label="Download Excel" icon="pi pi-download" outlined severity="secondary" className="mr-1 mb-1 h-2rem"
                  size="small" onClick={doDownloadExcel}/>
        </div>
      </div>
    </div>
  );

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    tableHeader: tabHeader,
    dataKey,
    columns: [

      {
        field: 'pseudoId', header: 'Mã sản phẩm', width: 130, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <a onClick={() => Service.get(rowData.productId).then(data => {
            data.selectedNodeKey = data.productCategoryId;
            setSelectedNodeKey(data.productCategoryId);
            setUom(data.amountUom);
            setReadOnly(true);
            view(data);
          })}>{rowData.pseudoId}</a>
        }
      },
      {field: 'productName', header: 'Tên sản phẩm', width: 135, matchMode: 'contains'},
      {field: 'salePrice', header: 'Giá bán', dataType: 'number', width: 100, matchMode: 'numeric'},
      {field: 'purchasePrice', header: 'Giá mua', dataType: 'number', width: 100, matchMode: 'numeric'},
      {field: 'productClassEnum', header: 'Loại hàng hóa', width: 250, matchMode: 'contains'},
      {field: 'categoryName', header: 'Ngành nghề KD', width: 250, matchMode: 'contains'},
      {field: 'originGeo', header: 'Xuất xứ', width: 150, matchMode: 'contains'},
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 120,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent, statusId)
    },
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-pencil', title: updateTooltip, className: 'p-button-warning', command: () => doRowUpdate(item)},
          ]
          if (statusId === 'N') {
            item.actions.push({icon: 'pi pi-lock', title: lockTooltip, className: 'p-button-danger', command: () => doDisable(item)},)
          } else {
            item.actions.push({icon: 'pi pi-unlock', title: unlockTooltip, className: 'p-button-success', command: () => doEnable(item)},)
          }
          item.actions.push({icon: 'pi pi-trash', title: deleteTooltip, className: 'p-button-secondary', command: () => doRowDelete(item)},)
        })
      }
    }
  });

  const {render: renderDialogCrup, create, update, view, form} = useDialogCrup({
    header,
    dataKey,
    width: '80rem',
    fields: [
      {field: '_id', display: false},
      {field: 'pseudoId', header: 'Mã sản phẩm', required: true, className: 'md:col-4'},
      {field: 'productName', header: 'Tên sản phẩm', required: true, className: 'md:col-8'},

      {
        field: 'productClassEnumId', header: 'Loại sản phẩm', type: 'Dropdown', className: 'md:col-4', required: true,
        DropdownProps: {
          options: productTypes,
        },
      },
      {
        field: 'originGeoId', header: 'Xuất xứ', type: 'Dropdown', className: 'md:col-4',
        DropdownProps: {
          options: countries,
        },
      },
      {
        field: 'productCategory', header: 'Ngành nghề KD', required: true, className: 'md:col-4', type: 'custom',
        body: <div>
          <div>Ngành nghề KD <span style={{color: 'red'}}>*</span></div>
          <TreeSelect value={selectedNodeKey} onChange={(e) => setSelectedNodeKey(e.value)} options={nodes}
                      className="mt-2" disabled={readOnly}></TreeSelect>
        </div>
      },
      {field: 'salePrice', header: 'Giá bán', type: 'InputNumber', className: 'md:col-4'},
      {field: 'purchasePrice', header: 'Giá mua', type: 'InputNumber', className: 'md:col-4'},
      // {
      //   field: 'amountUomId', header: 'Đơn vị tính', type: 'Dropdown', className: 'md:col-3',
      //   DropdownProps: {
      //     options: uoms,
      //   }
      // }, {
      //   field: 'toPaymentMethod', type: 'custom', className: 'md:col-1',
      //   body:
      //     <div>
      //       <div className='flex justify-content-center mt-4 mr-6 '>
      //         <Button icon="pi pi-plus" severity="success" onClick={() => createUom()}/>
      //       </div>
      //     </div>
      //
      // },
      {
        field: 'toPaymentMethod', header: 'Đơn vị tính', type: 'custom', className: 'md:col-4',
        body:
          <div>
            <div>
              Đơn vị tính <span style={{color: 'red'}}>*</span>
            </div>
            <div className='flex mt-2'>
              <AutoComplete className="flex" field='label' style={{width: '40rem'}} value={uom} suggestions={quantityUoms} completeMethod={searchQuantityUoms} itemTemplate={itemTemplate} onChange={(e) => {
                setUom(e.value);
              }} dropdown disabled={readOnly}/>
              <Button icon="pi pi-plus" style={{width: '5rem'}} severity="success" onClick={() => createUom()} disabled={readOnly}/>
            </div>
          </div>

      },


    ],
    createItem: async item => {
      if (!uom){
        ToastService.error('Đơn vị tính không được rỗng')
        return Promise.reject()
      }else {
        return Service.create(await inject(item)).then(data =>{
          setQuickCreateData({...inject(item),productId: data.productId})
        });
      }
    },
    updateItem: async (id, item) => {
      if (!uom){
        ToastService.error('Đơn vị tính không được rỗng')
        return Promise.reject()
      }else {
        return Service.update(id, await inject(item));
      }
    },
    reloadLazyData
  });
  const {render: renderCreateUom, create: createUom} = useDialogCrup({
    header: "đơn vị sản phẩm",
    dataKey,
    width: '40rem',
    fields: [
      {field: 'abbreviation', header: 'Tên đơn vị sản phẩm', required: true, className: 'md:col-12'},
    ],
    createItem: item => {
      return UomsService.create(item);
    },
    reloadLazyData
  });

  const _value = form.getValue()

  const inject = (data) => {
    data.productCategoryId = selectedNodeKey;
    data.amountUomId = uom?.value;
    return data;
  }


  const doCreate = (id?: any) => {
    setQuickCreateData(null)
    setReadOnly(false);
    setUom(null);
    setSelectedNodeKey(null)
    form.reset()
    Service.genProductPseudoId().then(data => {
      _value._id = id
      _value.pseudoId = data.pseudoId
      _value.productName = ''
      _value.productClassEnumId = ''
      _value.originGeoId = ''
      _value.productCategory = ''
      _value.salePrice = ''
      _value.purchasePrice = ''
      _value.amountUomId = ''
      form.setValue(_value)
    })
    create()
  }
  const doRowUpdate = (data) => {
    form.reset()
    data.selectedNodeKey = data.productCategoryId;
    setSelectedNodeKey(data.productCategoryId);
    setUom(data.amountUom);
    setReadOnly(false);
    update(data);

  }

  const doDisable = (data) => {
    ConfirmDialogService.confirm('Xóa sản phẩm', `Xác nhận khóa ${data.productName} ?`, () => {
      Service.disable(data.productId).then(() => {
        ToastService.success();
        reloadLazyData();
      });

    })
  }
  const doEnable = (data) => {
    ConfirmDialogService.confirm('Xóa sản phẩm', `Xác nhận mở khóa ${data.productName} ?`, () => {
      Service.enable(data.productId).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    })
  }
  const doRowDelete = (data) => {
    ConfirmDialogService.confirm('Xác Nhận', `Bạn chắc chắn muốn xóa sản phẩm ${data.productName} ?`, () => {
      Service.delete(data.productId).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    })
  }

  const {renderToolbar} = useToolbar({
    doCreate,
  });

  const renderProduct = (
    <div className="grid">
      <div className="col-12">
        <SelectButton value={statusId} options={statuses} onChange={e => setStatusId(e.value)}/>
      </div>
      {/*<div className="col-12">*/}
      {/*  {renderToolbar()}*/}
      {/*</div>*/}
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12.5rem)'}}>
        {renderDataTable()}
      </div>
      {renderDialogCrup()}
      {renderCreateUom()}
      <UploadExcel display={displayUploadExcel} setDisplay={setDisplayUploadExcel} reloadLazyData={reloadLazyData}/>
    </div>
  )

  return {renderProduct, createProduct: doCreate, renderCrupProduct: renderDialogCrup, renderCrupUom: renderCreateUom, quickCreateData, setQuickCreateData}
}