import {useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {IncomingInvoiceService as Service} from './IncomingInvoiceService';

export const IncomingInvoices = () => {

  const dataKey = 'invoiceId';


  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    dataKey,
    columns: [
      {width: 120, field: 'invoiceForm', header: 'Mẫu số', matchMode: 'contains',},
      {width: 120, field: 'invoiceSerial', header: 'Ký hiệu', matchMode: 'contains',},
      {width: 120, field: 'invoiceNo', header: 'Số HĐ', matchMode: 'contains',},
      {width: 120, field: 'invoiceDate', header: 'Ngày lập', matchMode: 'contains', dataType: 'date'},
      {width: 120, field: 'issuedDate', header: 'Ngày phát hành', matchMode: 'contains', dataType: 'date'},
      {width: 120, field: 'classTypeEnum', header: 'Loại hóa đơn', matchMode: 'contains',},
      {width: 120, field: 'processStatus', header: 'TT đồng bộ', matchMode: 'contains',},
      {width: 120, field: 'status', header: 'TT liên kết', matchMode: 'contains',},
      {width: 120, field: 'toPartyName', header: 'Đơn vị mua hàng', matchMode: 'contains',},
      {width: 120, field: 'toName', header: 'Người mua hàng', matchMode: 'contains',},
      {width: 200, field: 'toAddress', header: 'Địa chỉ người mua', matchMode: 'contains',},
      {width: 120, field: 'paymentType', header: 'HTTT', matchMode: 'contains',},
      {width: 120, field: 'subTotal', header: 'Thành tiền chưa thuế', matchMode: 'numeric', dataType: 'number'},
      {width: 120, field: 'discountTotal', header: 'Chiết khấu', matchMode: 'numeric', dataType: 'number'},
      {width: 120, field: 'taxTotal', header: 'Tiền thuế', matchMode: 'numeric', dataType: 'number'},
      {width: 120, field: 'invoiceTotal', header: 'Tổng tiền', matchMode: 'numeric', dataType: 'number'},
      {width: 120, field: 'currencyUom', header: 'Tiền tệ', matchMode: 'contains',},
      {width: 120, field: 'taxAuthCode', header: 'Mã CQT', matchMode: 'contains',},
      {width: 200, field: 'comments', header: 'Ghi chú', matchMode: 'contains',},
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 90,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
  });


  return (
    <div className="grid">
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12.5rem)'}}>
        {renderDataTable()}
      </div>
    </div>
  );
}