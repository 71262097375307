import {Route, Routes} from 'react-router-dom';
import {InventorySummary} from './tong-hop-ton-kho';

export default function BaoCaoQuanTri() {

  return (
    <Routes>
      <Route path="tong-hop-ton-kho" element={<InventorySummary/>}/>
    </Routes>
  );
}
