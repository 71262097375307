import {Fragment} from 'react';

import {Button} from 'primereact/button';
import {confirmPopup} from 'primereact/confirmpopup';
import {ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {CustomersService as Service} from './CustomersService';

export const List = () => {

  const dataKey = 'partyId';

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    dataKey,
    columns: [
      {field: 'pseudoId', header: 'Mã khách hàng', width: 150, matchMode: 'contains'},
      {field: 'partyName', header: 'Tên khách hàng', minWidth: 150, matchMode: 'contains'},
      {field: 'stateProvinceGeo', header: 'Tỉnh/thành phố', width: 150, matchMode: 'contains'},
      {field: 'purchasedQuantity', header: 'Số hđ đã mua', width: 140, matchMode: 'contains'},
      {field: 'usedQuantity', header: 'Đã sử dụng', width: 140, matchMode: 'contains'},
      {field: 'purchasedTimePeriod', header: 'Thời hạn', width: 140, matchMode: 'contains'},
      {field: 'effectiveDate', header: 'Bắt đầu', width: 165, dataType: 'date-time'},
      {field: 'expireDate', header: 'Hết hạn', width: 165, dataType: 'date-time'},
      {field: 'status', header: 'Trạng thái', width: 140, matchMode: 'contains'},
      {field: 'agentPartyName', header: 'Đại lý', width: 150, matchMode: 'contains'}
    ],
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    }
  });

  const {render: renderDialogCrup, update: changeAgent} = useDialogCrup({
    header: '!Đổi đại lý',
    dataKey,
    width: '40rem',
    fields: [
      {field: 'agentPartyId', header: 'Đại lý', type: 'Calendar', className: 'md:col-12'}
    ],
    updateItem: (id, item) => {
      return Service.changeAgent(id, item.effectiveDate);
    },
    reloadLazyData
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doRenew = () => {
    if (selectedItem && selectedItem[dataKey]) {
      window.location.hash = `/manager/customer/requests?renew=${selectedItems[dataKey]}`;
    }
  }

  const doApprove = event => {
    if (selectedItem && selectedItem[dataKey]) {
      confirmPopup({
        target: event.currentTarget,
        message: 'Xác nhận Duyệt khách hàng này?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          Service.approve(selectedItem[dataKey]).then(() => {
            ToastService.success();
            reloadLazyData();
          })
        }
      });
    }
  }
  const doReject = event => {
    if (selectedItem && selectedItem[dataKey]) {
      confirmPopup({
        target: event.currentTarget,
        message: 'Xác nhận Từ chối khách hàng này?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          Service.reject(selectedItem[dataKey]).then(() => {
            ToastService.success();
            reloadLazyData();
          })
        }
      });
    }
  }

  const doChangeAgent = () => {
    if (selectedItem && selectedItem[dataKey]) {
      // Service.unpaid(selectedItem[dataKey]).then(() => {
      //   ToastService.success();
      //   reloadLazyData();
      // });
      changeAgent(selectedItem);
    }
  }

  const {renderToolbar} = useToolbar({
    leftButtons: <Fragment>
      <Button label="Gia hạn" icon="pi pi-calendar-plus" className="p-button-info mr-2" onClick={doRenew} disabled={!(selectedItem && selectedItem.customerStatusId === 'CustApproved')}/>
      <Button label="Duyệt" icon="pi pi-check" className="p-button-success mr-2" onClick={doApprove} disabled={!selectedItem || selectedItem?.customerStatusId === 'CustApproved'}/>
      <Button label="Từ chối" icon="pi pi-times" className="p-button-secondary mr-2" onClick={doReject} disabled={!(selectedItem && selectedItem.customerStatusId === 'CustCreated')}/>
      <Button label="Đổi đại lý" icon="pi pi-sitemap" className="p-button-warning" onClick={doChangeAgent} disabled={!selectedItem}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12">
        {renderDataTable()}
      </div>

      {renderDialogCrup()}
    </div>
  );
}