import {Route, Routes} from 'react-router-dom';
import {S1HKD} from './S1-HKD'
import {S2HKD} from './S2-HKD'
import {S3HKD} from './S3-HKD';
import {S4HKD} from './S4-HKD';
import {S5HKD} from './S5-HKD';
import {S6HKD} from './S6-HKD';
import {S7HKD} from './S7-HKD';

export default function BaoCao({me}) {

  return (
    <Routes>
      <Route path="s1" element={<S1HKD me={me}/>}/>
      <Route path="s2" element={<S2HKD me={me}/>}/>
      <Route path="s3" element={<S3HKD me={me}/>}/>
      <Route path="s4" element={<S4HKD me={me}/>}/>
      <Route path="s5" element={<S5HKD me={me}/>}/>
      <Route path="s6" element={<S6HKD me={me}/>}/>
      <Route path="s7" element={<S7HKD me={me}/>}/>
    </Routes>
  );
}
