import {EntityService} from 'src/service/EntityService';

export class OtherPaymentService extends EntityService {
  static entity = "iam-accounting/otherPayments"

  static genPseudoId(): Promise<any> {
    return this.axios.get(`${this.entity}/gen-pseudo-id`).then(res => res.data);
  }

  static complete(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/complete`).then(res => res.data);
  }

  static unComplete(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/un-complete`).then(res => res.data);
  }

  static cancel(id: string): Promise<any> {
    return this.axios.delete(`${this.entity}/${id}/cancel`).then(res => res.data);
  }
}