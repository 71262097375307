import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDialogCrup, useForm, useToolbar} from '@iamsoftware/react-hooks';
import {Fragment, useEffect, useState} from 'react';
import {Button} from 'primereact/button';
import {Fieldset} from 'primereact/fieldset';
import {CommonService} from '../../../service/CommonService';
import {OtherPaymentService as Service} from './OtherPaymentService';
import {EmployeesService} from '../../quan-ly/nhan-vien/EmployeesService';
import {BankAccountService} from '../../danh-muc/tai-khoan-ngan-hang/BankAccountService';

export default function QuyTienMat() {
  const header = 'Nghiệp vụ';
  const dataKey = 'paymentId';
  const updateTooltip = 'Cập nhật';
  const deleteTooltip = 'Xóa';
  const [otherPaymentTypes, setOtherPaymentTypes] = useState([]);
  const [enteredParties, setEnteredParties] = useState([]);
  const [hkdBank, setHkdBank] = useState([]);
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    CommonService.otherPayment().then(data => {
      setOtherPaymentTypes(data.listData.map(item => {
        return {value: item.enumId, label: item.description}
      }))
    })
  }, []);

  useEffect(() => {
    const _event: any = {
      filters: {}
    };
    BankAccountService.getList(JSON.stringify(_event)).then(data => {
      setHkdBank(data.listData.map(item => {
        return {value: item.paymentMethodId, label: `[${item.bankAccount}] - ${item.bankName}`};
      }))
    })
  }, []);

  const searchEmployees = event => {
    EmployeesService.find(event.query).then(data => {
      setEnteredParties(data.listData.map(item => {
        delete item.disabled;
        let label = `[${item.pseudoId}] - ${item.partyName}`;
        return {...item, value: item.partyId, label: label};
      }));
    });
  }

  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <span>Nghiệp vụ khác</span>
        <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"
                size="small" onClick={() => doCreate()}/>
      </div>
    </div>
  );

  const {render: renderDataTable, selectedItems, setSelectedItems, reloadLazyData} = useDataTable({
    tableHeader: tabHeader,
    stateKey: 'mainTable',
    dataKey,
    columns: [
      {
        field: 'paymentRefNum', header: 'Mã chứng từ', minWidth: 120, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <a onDoubleClick={() => {
            Service.get(rowData.paymentId).then(async data => {
              if (data.effectiveDate) {
                data.effectiveDate = FormatDisplay.date(new Date(data.effectiveDate), 'YYYY-MM-DD');
              }
              if (data.enteredByPartyId) {
                const item = await EmployeesService.get(data.enteredByPartyId)
                data.enteredByParty = {value: item.enteredByPartyId, label: `[${item.pseudoId}] - ${item.partyName}`}
              }
              commonInfoForm.setValue(data);
              certInfoForm.setValue(data);
              setSelectedItems(rowData)
              setReadOnly(true);
              view(data);
            })
          }}>{rowData.paymentRefNum}</a>
        }
      },
      {field: 'effectiveDate', header: 'Ngày chứng từ', dataType: 'date', width: 140, matchMode: 'contains'},
      {field: 'paymentTypeEnum', header: 'Loại nghiệp vụ', minWidth: 140, matchMode: 'contains'},
      {field: 'memo', header: 'Diễn giải', minWidth: 200, matchMode: 'contains'},
      {field: 'amount', header: 'Số tiền', dataType: 'number', width: 140, matchMode: 'contains'},
      {field: 'status', header: 'Trạng thái', minWidth: 100, matchMode: 'contains'},
    ],
    indexColumnWidth: 45,
    actionColumnWidth:120,
    getList(lazyLoadEvent: any): Promise<any> {
      return Service.getList(lazyLoadEvent)
    },
    initActions(items: Array<any>) {
      if (items){
        items.forEach(item =>{
          item.actions = [
            {icon: 'pi pi-pencil', title: updateTooltip, className: 'p-button-warning', command: () => doUpdate(item.paymentId)},
          ]
          if (item.statusId === 'PmntFinished'){
            item.actions.push({icon: 'pi pi-times', title: 'Bỏ ghi sổ', className: 'p-button-danger', command: () => doUnComplete(item.paymentId)},)
          }else {
            item.actions.push({icon: 'pi pi-check', title: 'Ghi sổ', className: 'p-button-success', command: () => doComplete(item.paymentId)},)
          }
          item.actions.push({icon: 'pi pi-trash', title: deleteTooltip, className: 'p-button-secondary', command: () => doDelete(item.paymentId)})
        })
      }
    }
  })

  const commonInfoForm = useForm({
    fields: [
      {field: 'paymentTypeEnumId', required: true, header: 'Loại nghiệp vụ', type: 'Dropdown', DropdownProps: {options: otherPaymentTypes}, className: 'md:col-4'},
      {
        field: 'enteredByParty', header: 'Nhân viên thực hiện', className: 'md:col-4', type: 'AutoComplete',required: true,
        AutoCompleteProps: {suggestions: enteredParties, completeMethod: searchEmployees, field: 'label', dropdown: true, forceSelection: true},
        disabled: 'View'
      },
      {field: 'amount', header: 'Số tiền', type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-4', required: true},
      {field: 'memo', header: 'Diễn giải', className: 'md:col-12'},
      {
        field: 'paymentMethodId', header: 'Tài khoản chuyển', required: true, className: 'md:col-6', displayDependency(item: any): boolean {
          return ['PtInternalTransfer'].includes(item?.paymentTypeEnumId)
        }, type: 'Dropdown', DropdownProps: {options: hkdBank}
      },
      {
        field: 'paymentMethodId', header: 'Tài khoản rút', required: true, className: 'md:col-6', displayDependency(item: any): boolean {
          return ['PtWithdraw'].includes(item?.paymentTypeEnumId)
        }, type: 'Dropdown', DropdownProps: {options: hkdBank}
      },
      {
        field: 'paymentMethodId', header: 'Tài khoản nộp', required: true, className: 'md:col-6', displayDependency(item: any): boolean {
          return ['PtDeposit'].includes(item?.paymentTypeEnumId)
        }, type: 'Dropdown', DropdownProps: {options: hkdBank}
      },
      {
        field: 'toPaymentMethodId', header: 'Tài khoản nhận', required: true, className: 'md:col-6', displayDependency(item: any): boolean {
          return ['PtInternalTransfer'].includes(item?.paymentTypeEnumId)
        }, type: 'Dropdown', DropdownProps: {options: hkdBank}
      },
    ],
    readOnly
  })

  const certInfoForm = useForm({
    fields: [
      {field: 'paymentRefNum', header: 'Số chứng từ', required: true, className: 'md:col-12'},
      {field: 'effectiveDate', header: 'Ngày chứng từ', InputTextProps: {type: 'date'}, className: 'md:col-12'},
    ],
    readOnly
  })

  const {render: renderDialogCrup, create, update, view} = useDialogCrup({
    header,
    dataKey,
    width: '120rem',
    fields: [
      {
        type: 'custom', className: 'md:col-8', body: <Fieldset legend="Thông tin chung">
          {commonInfoForm.render()}
        </Fieldset>
      },
      {
        type: 'custom', className: 'md:col-4', body: <Fieldset legend="Chứng từ">
          {certInfoForm.render()}
        </Fieldset>
      },
    ],
    createItem: async item => {
      if (inject().paymentMethodId === inject().toPaymentMethodId && inject().paymentTypeEnumId === 'PtInternalTransfer'){
        ToastService.error('Ngân hàng nhận với ngân hàng chuyển không được trùng nhau')
        return Promise.reject()
      }else {
        return Service.create(await inject());
      }

    },
    updateItem: async (id, item) => {
      if (inject().paymentMethodId === inject().toPaymentMethodId && inject().paymentTypeEnumId === 'PtInternalTransfer'){
        ToastService.error('Ngân hàng nhận với ngân hàng chuyển không được trùng nhau')
        return Promise.reject()
      }else {
        return Service.update(id, await inject());
      }
    },
    reloadLazyData
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const inject = () => {
    const item = commonInfoForm.getValue();
    item.enteredByPartyId = item.enteredByParty?.value;
    item.paymentRefNum = certInfoForm.getValue().paymentRefNum;
    item.effectiveDate = certInfoForm.getValue().effectiveDate;
    return item;
  }

  const doCreate = () => {
    commonInfoForm.reset();
    certInfoForm.reset();
    Service.genPseudoId().then(data => {
      certInfoForm.setValue({paymentRefNum: data.code})
    })
    setReadOnly(false);
    setSelectedItems([]);
    create()
  }

  const doUpdate = (data) => {
    if (data) {
      Service.get(data).then(async data => {
        if (data.effectiveDate) {
          data.effectiveDate = FormatDisplay.date(new Date(data.effectiveDate), 'YYYY-MM-DD');
        }
        if (data.enteredByPartyId) {
          const item = await EmployeesService.get(data.enteredByPartyId)
          data.enteredByParty = {value: item.enteredByPartyId, label: `[${item.pseudoId}] - ${item.partyName}`}
        }
        commonInfoForm.setValue(data);
        certInfoForm.setValue(data);
        setReadOnly(false);
        update(data);
      })
    }
  }

  const doComplete = (paymentId) => {
    if (paymentId){
      ConfirmDialogService.confirm('Xác nhận ghi sổ','Bạn có muốn ghi sổ nghiệp vụ này?', ()=>{
        Service.complete(paymentId).then(()=>{
          ToastService.success();
          reloadLazyData();
        })
      })
    }
  }

  const doUnComplete = (paymentId) => {
    if (paymentId){
      ConfirmDialogService.confirm('Xác nhận bỏ ghi sổ','Bạn có muốn bỏ ghi sổ nghiệp vụ này?', ()=>{
        Service.unComplete(paymentId).then(()=>{
          ToastService.success();
          reloadLazyData();
        })
      })
    }
  }

  const doDelete = (paymentId) => {
    if (paymentId){
      ConfirmDialogService.confirm('Xác nhận xóa nghiệp vụ','Bạn có muốn xóa nghiệp vụ này?', ()=>{
        Service.cancel(paymentId).then(()=>{
          ToastService.success();
          reloadLazyData();
        })
      })
    }
  }

  // const {renderToolbar} = useToolbar({
  //   doCreate,
  //   hasSelectedItem: selectedItem,
  // })

  return (
    <div className="grid">
      {/*<div className="col-12">*/}
      {/*  {renderToolbar()}*/}
      {/*</div>*/}
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12.5rem)'}}>
        {renderDataTable()}
      </div>
      {renderDialogCrup()}
    </div>
  )
}