import {Fragment, useEffect, useState} from 'react';

import {SelectButton} from 'primereact/selectbutton';
import {Button} from 'primereact/button';

import {ConfirmDialogService, ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {FacilitiesService as Service} from './FacilitiesService';

const statuses = [
  {value: 'FacActive', label: 'Đang hoạt động'},
  {value: 'FacInactive', label: 'Ngừng hoạt động'}
];
const updateTooltip = 'Cập nhật';
const lockTooltip = 'Khóa';
const unlockTooltip = 'Mở khóa';

export const Facilities = () => {

  const header = 'Kho hàng';
  const dataKey = 'facilityId';

  const [statusId, setStatusId] = useState('FacActive');
  const [requiredParams, setRequiredParams] = useState(null);
  const [headerResult, setHeaderResult] = useState(null);

  useEffect(() => {
    setHeaderResult(tabHeader)
  }, []);

  useEffect(() => {
    if (statusId) {
      setRequiredParams({statusId: {value: statusId, matchMode: 'equals'}});
    } else {
      setRequiredParams({});
    }
  }, [statusId]); // eslint-disable-line react-hooks/exhaustive-deps
  const {render: renderDataTable, reloadLazyData} = useDataTable({
    tableHeader: headerResult,
    stateKey: 'mainTable',
    dataKey,
    columns: [
      {
        field: 'pseudoId', header: 'Mã kho', width: 200, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <a onClick={() => Service.get(rowData.facilityId).then(data => {
            view(data)
          })}>{rowData.pseudoId}</a>
        }
      },
      {field: 'facilityName', header: 'Tên kho', minWidth: 250, matchMode: 'contains'},
      {field: 'address1', header: 'Địa chỉ', minWidth: 300, matchMode: 'contains'}
    ],
    indexColumnWidth: 45,
    actionColumnWidth:90,
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [{icon: 'pi pi-pencil', title: 'Cập nhật', className: 'p-button-warning', command: () => doRowUpdate(item)}];
          if (item.statusId === 'FacActive') {
            item.actions.push({icon: 'pi pi-lock', title: 'Khóa', className: 'p-button-danger', command: () => doDisable(item)},)
          }
          if (item.statusId === 'FacInactive') {
            item.actions.push({icon: 'pi pi-unlock', title: 'Mở khóa', className: 'p-button-success', command: () => doEnable(item)},)
          }
        })}
    },
    getList: async lazyLoadEvent => {
      await clearAndSwitch
      return Service.getList(lazyLoadEvent)
    },
    requiredParams
  });

  const {render: renderDialogCrup, create, update,view} = useDialogCrup({
    header,
    dataKey,
    width: '40rem',
    fields: [
      {field: 'pseudoId', header: 'Mã kho', required: true, className: 'md:col-12'},
      {field: 'facilityName', header: 'Tên kho', required: true, className: 'md:col-12'},
      {field: 'address1', header: 'Địa chỉ', className: 'md:col-12'}
    ],
    createItem: item => {
      return Service.create(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });

  const doCreate = () => {
    create();
  }
  const clearAndSwitch = (data) => {
    setStatusId(data);
  }

  const doRowUpdate = (data) => {
    update(data)
  }

  const doDisable = (data) => {
    ConfirmDialogService.confirm('Xóa sản phẩm', `Xác nhận khóa ${data.facilityName}?`, () => {
      Service.disable(data.facilityId).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    })
  }

  const doEnable = (data) => {
    ConfirmDialogService.confirm('Xóa sản phẩm', `Xác nhận mở khóa ${data.facilityName}?`, () => {
      Service.enable(data.facilityId).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    })
  }

  const {renderToolbar} = useToolbar({
    doCreate,
  });
  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>Kho hàng</span>
          <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"
                  size="small" onClick={doCreate}/>
        </Fragment>
      </div>
    </div>
  );
  return (
    <div className="grid">
      <div className="col-12">
        <SelectButton value={statusId} options={statuses} onChange={e => clearAndSwitch(e.value)}/>
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12.5rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogCrup()}
    </div>
  );
}