import {EntityService} from '../../../../service/EntityService';

export class ProductsService extends EntityService {

  static entity = 'iam-manager/services';

  static getCategories(): Promise<any> {
    return this.axios.get(`${this.entity}/categories`).then(res => res.data);
  }

}
