import {Route, Routes} from 'react-router-dom';

import {Products} from './products';

export default function Category() {

  return (
    <Routes>
      <Route path="products" element={<Products/>}/>
    </Routes>
  );
}
