import {ConfirmDialogService, ToastService, useDataTable} from '@iamsoftware/react-hooks';
import {OutgoingPaymentsService as Service} from './OutgoingPaymentsService';
import {Button} from 'primereact/button';
import {useCrupOutGoingPayment} from './CrupOutGoingPayment';

export default function PhieuChi() {
  const dataKey = 'paymentId';
  const updateTooltip = 'Cập nhật';
  const resendTooltip = 'Xóa';
  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <span>Phiếu chi</span>
        <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 h-2rem"
                size="small" onClick={() => doCreateOutgoingPayment()}/>
      </div>
    </div>
  );


  const {render: renderDataTable, reloadLazyData} = useDataTable({
    tableHeader: tabHeader,
    stateKey: 'mainTable',
    dataKey,
    columns: [
      {
        field: 'paymentRefNum', header: 'Mã chứng từ', width: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <a onClick={() => {
            doUpdateOutgoingPayment(rowData, false)
          }}>{rowData.paymentRefNum}</a>
        }
      },
      {field: 'effectiveDate', header: 'Ngày chứng từ', dataType: 'date', width: 140, matchMode: 'contains'},
      {field: 'paymentTypeEnum', header: 'Loại chứng từ', minWidth: 180, matchMode: 'contains'},
      {field: 'toPartyName', header: 'Đối tượng', minWidth: 250, matchMode: 'contains'},
      {field: 'memo', header: 'Diễn giải', minWidth: 250, matchMode: 'contains'},
      {field: 'amount', header: 'Tổng tiền', dataType: 'number', minWidth: 120, matchMode: 'contains'},
      {field: 'status', header: 'Trạng thái', minWidth: 150, matchMode: 'contains'}
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 140,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-pencil', title: updateTooltip, className: 'p-button-warning', command: () => doUpdateOutgoingPayment(item, false)},
          ]
          if (item.statusId === 'PmntDelivered') {
            item.actions.push({icon: 'pi pi-times', title: 'Bỏ ghi sổ', className: 'p-button-danger', command: () => doUnApprove(item)})
          } else {
            item.actions.push({icon: 'pi pi-check', title: 'Ghi sổ', className: 'p-button-success', command: () => doApprove(item)})
          }
          item.actions.push({icon: 'pi pi-trash', title: resendTooltip, className: 'p-button-secondary', command: () => doDelete(item)})
          item.actionMenus = [
            {icon: 'pi pi-file-pdf', label: 'Xem pdf', className: 'p-button-help', command: () => pdf(item)}
          ]
        })
      }
    }
  });
  const {renderPayments, doCreateOutgoingPayment, doUpdateOutgoingPayment}=useCrupOutGoingPayment({reloadLazyData})

  const pdf = (item) => {
    if (item) {
      window.open(`${Service.baseURL}/${Service.entity}/${item[dataKey]}/pdf`)
    }
  }

  const doDelete = (item) => {
    if (item) {
      ConfirmDialogService.confirm('Xóa phiếu thu', 'Xác nhận xóa phiếu thu này?', () => {
        Service.cancel(item[dataKey]).then(() => {
          ToastService.success();
          reloadLazyData();
        });
      });
    }
  }

  const doApprove = (item) => {
    if (item) {
      ConfirmDialogService.confirm('Ghi sổ', 'Xác nhận ghi sổ phiếu thu này?', () => {
        Service.approve(item[dataKey]).then(() => {
          ToastService.success();
          reloadLazyData();
        });
      })
    }
  }
  const doUnApprove = (item) => {
    if (item) {
      ConfirmDialogService.confirm('Bỏ ghi sổ', 'Xác nhận bỏ ghi sổ phiếu thu này?', () => {
        Service.unApprove(item[dataKey]).then(() => {
          ToastService.success();
          reloadLazyData();
        });
      })
    }
  }


  return (
    <div className="grid">
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12.5rem)'}}>
        {renderDataTable()}
      </div>
      {renderPayments()}
    </div>
  );
}
