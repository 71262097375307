import {Service} from 'src/service/Service';

export class ReportService extends Service {

  static entity = 'iam-manager/report';

  static getCustomerSummary(lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/customer-summary?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static getCustomerDetail(lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/customer-detail?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static getAgents(lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/agents?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

}
