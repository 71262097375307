import {useEffect, useState} from 'react';
import {TabView, TabPanel} from 'primereact/tabview';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import {InputText} from "primereact/inputtext";
import {RadioButton} from "primereact/radiobutton";

import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDataTableBasic, useDialogCrup} from '@iamsoftware/react-hooks';
import {TimePeriodService as Service} from './TimePeriodService';
import {useCrupTimePeriod} from './crupTimePeriod';
import {FacilitiesService} from '../../kho-van/kho-hang/FacilitiesService';
import {LiabilityServices} from '../../ban-hang/cong-no/LiabilityServices';

export const TimePeriod = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [reload, setReload] = useState(false);
  const [periodOptions, setPeriodOptions] = useState([]);
  const [periodYear, setPeriodYear] = useState(null);
  const [paymentMethodTypeEnumId, setPaymentMethodTypeEnumId] = useState('PmtBankAccount');
  const [facilities, setFacilities] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState('');

  useEffect(() => {
    Service.getBeginTimePeriod().then(data => {
        setPeriodYear({value: data.timePeriodId, label: data.periodNum})
      }
    )
    FacilitiesService.getList('').then(data => {
      setFacilities(data.listData.map((item, index) => {
        if (index === 0) {
          setSelectedFacility(item.facilityId)
        }
        return {value: item.facilityId, label: item.facilityName}
      }));
    });
  }, []);
  useEffect(() => {
    reloadLazyData2()
    reloadLazyData3();
    reloadLiabilitiesSupplier();
    reloadLiabilitiesCustomer();
  }, [selectedPeriod]);
  useEffect(() => {
    reloadLazyData()
  }, [selectedPeriod, selectedFacility]);
  useEffect(() => {
    reloadLazyData4()
  }, [selectedPeriod, paymentMethodTypeEnumId]);

  useEffect(() => {
    if (reload === true) {
      Service.getBeginTimePeriod().then(data => {
          setPeriodYear({value: data.timePeriodId, label: data.periodNum})
          setReload(false)
        }
      )
    }
  }, [reload]);
  useEffect(() => {
    Service.getTimePeriod().then(data => {
      setPeriodOptions(data.listData.map((item, index) => {
        if (index === 0) {
          setSelectedPeriod(item.timePeriodId)
        }
        return {value: item.timePeriodId, label: item.periodNum, index: index}
      }))
    })
  }, [periodYear])

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    columns: [
      {field: 'pseudoId', header: 'Mã hàng hóa', minWidth: 150, matchMode: 'contains'},
      {field: 'productName', header: 'Tên hàng hóa', minWidth: 220, matchMode: 'contains'},
      {
        field: 'facility', header: 'Kho', minWidth: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          if (rowData.facilityName && rowData.facilityPseudoId) {
            return `${rowData.facilityName} [${rowData.facilityPseudoId}]`
          } else return ''
        }
      },
      {field: 'amountUom', header: 'ĐVT', minWidth: 100, matchMode: 'contains'},
      {field: 'beginningQuantityTotal', header: 'Số lượng', dataType: 'number', minWidth: 150, matchMode: 'contains'},
      {field: 'beginningUnitAmount', header: 'Đơn giá', dataType: 'number', minWidth: 150, matchMode: 'contains'},
      {field: 'beginningAmountTotal', header: 'Thành tiền', dataType: 'number', minWidth: 150, matchMode: 'contains'},
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      if (selectedPeriod) {
        return Service.getTimePeriodList(selectedPeriod, lazyLoadEvent, selectedFacility);
      } else {
        return Promise.resolve({});
      }
    }
  });
  const {render: renderDataTable2, reloadLazyData: reloadLazyData2} = useDataTable({
    stateKey: 'mainTable',
    columns: [
      {field: 'description', header: 'Các khoản nộp theo lương', minWidth: 150, matchMode: 'contains'},
      {field: 'beginningBalance', header: 'Số tiền', minWidth: 150, dataType: 'number', matchMode: 'contains'},
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      if (selectedPeriod) {
        return Service.getEmployeeExpensesList(selectedPeriod, lazyLoadEvent);
      } else {
        return Promise.resolve({});
      }
    }
  });
  const {render: renderDataTable3, reloadLazyData: reloadLazyData3} = useDataTable({
    stateKey: 'mainTable',
    columns: [
      {field: 'description', header: 'Loại thuế', minWidth: 150, matchMode: 'contains'},
      {field: 'beginningBalance', header: 'Số tiền đầu kì', minWidth: 150, dataType: 'number', matchMode: 'contains'},
      {field: 'endingBalance', header: 'Số tiền cuối kì', minWidth: 150, dataType: 'number', matchMode: 'contains'},
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      if (selectedPeriod) {
        return Service.organizationTaxes(lazyLoadEvent);
      } else {
        return Promise.resolve({});
      }
    }
  });
  const {render: renderDataTable4, reloadLazyData: reloadLazyData4} = useDataTable({
    stateKey: 'mainTable',
    columns: [
      {field: 'bankName', header: 'Tên ngân hàng', minWidth: 150, matchMode: 'contains', hidden: paymentMethodTypeEnumId === 'PmtCash'},
      {field: 'accountNumber', header: 'Số tài khoản', minWidth: 150, matchMode: 'contains', hidden: paymentMethodTypeEnumId === 'PmtCash'},
      {field: 'titleOnAccount', header: 'Chủ tài khoản', minWidth: 150, matchMode: 'contains', hidden: paymentMethodTypeEnumId === 'PmtCash'},
      {
        field: 'paymentMethodTypeEnumId', header: 'Loại', minWidth: 150, hidden: paymentMethodTypeEnumId === 'PmtBankAccount', dataType: 'custom', customCell(rowData: any): any {
          return <span> Quỹ tiền mặt </span>
        }
      },
      {field: 'beginningBalance', header: 'Số tiền', minWidth: 150, dataType: 'number', matchMode: 'numeric'},
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      if (selectedPeriod) {
        return Service.paymentMethod(paymentMethodTypeEnumId, lazyLoadEvent, selectedPeriod);
      } else {
        return Promise.resolve({});
      }
    }
  });

  //render cong no NCC
  const dataKey = '_id';
  const {render: renderLiabilitiesSupplier, reloadLazyData: reloadLiabilitiesSupplier} = useDataTable({
    tableHeader: `Công nợ nhà cung cấp`,
    dataKey,
    stateKey: 'mainTable',
    indexColumnWidth: 45,
    columns: [
      {
        field: 'toPseudoId', header: 'Mã nhà cung cấp', width: 200, matchMode:'contains'
      },
      {
        field: 'toPartyName', header: 'Tên nhà cung cấp', minWidth: 250, matchMode: 'contains',},
      // {field:'periodName', header: 'Năm', minWidth: 100, matchMode:'contains'},
      {field:'beginningBalance', header: 'Nợ đầu kỳ', width:200,matchMode:'equals', className:'td-config-number'},
    ],
    getList: (lazyLoadEvent: any) => {
      let params = {timePeriodId: selectedPeriod}
      if (selectedPeriod){
        return LiabilityServices.getListPurchase(lazyLoadEvent,params).then((data) =>{
          data.listData.forEach(item =>{
            //thêm id ,format số -a -> (a)
            item._id = `${item.organizationPartyId}_${item.toPartyId}_${item.timePeriodId}`;
            if (item.endingBalance < 0){
              item.endingBalance = `(${FormatDisplay.number(item.endingBalance*(-1))})`
            }else {
              item.endingBalance = `${FormatDisplay.number(item.endingBalance)}`
            }
            if (item.beginningBalance < 0){
              item.beginningBalance = `(${FormatDisplay.number(item.beginningBalance*(-1))})`
            }else {
              item.beginningBalance = `${FormatDisplay.number(item.beginningBalance)}`
            }
          })
          return data
        })
      }else {
        return new Promise<any>(resolve => resolve({}));
      }
    },
  })

  //render cong no KH
  const {render: renderLiabilitiesCustomer, reloadLazyData: reloadLiabilitiesCustomer} = useDataTable({
    tableHeader: `Công nợ khách hàng`,
    dataKey,
    stateKey: 'mainTable1',
    indexColumnWidth: 45,
    columns: [
      {field: 'toPseudoId', header:'Mã khách hàng', width: 200, matchMode:'contains'},
      {field: 'toPartyName', header:'Tên khách hàng', minWidth: 250, matchMode:'contains'},
      // {field:'periodName', header: 'Năm', minWidth: 100, matchMode:'contains'},
      {field:'beginningBalance', header: 'Nợ đầu kỳ', width:200,matchMode:'equals', className:'td-config-number'},
    ],
    getList: (lazyLoadEvent: any) => {
      let params = {timePeriodId: selectedPeriod}
      if (selectedPeriod){
        return LiabilityServices.getListSale(lazyLoadEvent,params).then((data) =>{
          data.listData.forEach(item =>{
            //thêm id ,format số -a -> (a)
            item._id = `${item.organizationPartyId}_${item.toPartyId}_${item.timePeriodId}`;
            if (item.endingBalance < 0){
              item.endingBalance = `(${FormatDisplay.number(item.endingBalance*(-1))})`
            }else {
              item.endingBalance = `${FormatDisplay.number(item.endingBalance)}`
            }
            if (item.beginningBalance < 0){
              item.beginningBalance = `(${FormatDisplay.number(item.beginningBalance*(-1))})`
            }else {
              item.beginningBalance = `${FormatDisplay.number(item.beginningBalance)}`
            }
          })
          return data
        })
      }else {
        return new Promise<any>(resolve => resolve({}));
      }
    },
  })

  const {renderCrupTimePeriod, doUpdateTimePeriod} = useCrupTimePeriod({
    reloadLazyData, reloadLazyData2,
    reloadLazyData3, reloadLazyData4,
    periodYear, activeIndex, paymentMethodTypeEnumId,
    selectedFacility, facilities, reloadLiabilitiesSupplier, reloadLiabilitiesCustomer
  })

  const {render: renderUpdatePeriod, create: updatePeriod} = useDialogCrup({
    header: '!Cập nhật năm bắt đầu sử dụng',
    width: '40rem',
    fields: [
      {field: 'periodNum', header: 'Năm bắt đầu sử dụng', required: true, className: 'md:col-12'}
    ],
    createItem: item => {
      return Service.updateBeginTimePeriod(item).then(data => {
        setReload(true)
      });
    }
  });
  const reCalculate = () => {
    ConfirmDialogService.confirm('Tính toán lại', 'Xác nhận tính toán lại dữ liệu báo cáo?', () => {
      Service.reCalculate(selectedPeriod).then(() => {
        ToastService.success();
        reloadLazyData();
        reloadLazyData2();
        reloadLazyData3();
        reloadLazyData4();
      })
    })
  }

  return (
    <div className="grid">
      <div className="col-12 pb-0">
        <h5>Số dư đầu kì</h5>
        <div className='grid'>
          <div className='xl:col-3'>
            <div className='p-2 border-1 surface-border flex align-items-center justify-content-between hover:surface-100 cursor-pointer border-round'>
              <div className='flex align-items-center'>
                <span><b>Năm bắt đầu sử dụng:</b></span>
              </div>
              <InputText value={periodYear?.label} disabled={true} style={{width: '65px', height: '35px'}}/>
              <Button severity="warning" icon="pi pi-pencil" tooltip={'Cập nhật năm bắt đầu sử dụng'} size="small" rounded outlined onClick={updatePeriod}></Button>
            </div>
          </div>
          <div className='xl:col-3'>
            <div className='p-2 border-1 surface-border flex align-items-center justify-content-between hover:surface-100 cursor-pointer border-round'>
              <div className='flex align-items-center' style={{width: '120px'}}>
                <span><b>Năm làm việc: </b></span>
              </div>
              <Dropdown className="ml-3" style={{width: '180px', height: '35px'}} value={selectedPeriod} onChange={(e) => setSelectedPeriod(e.value)} options={periodOptions}/>
            </div>
          </div>
          <div className='xl:col-3 flex'>
            <div className='p-2 border-1 surface-border flex align-items-center justify-content-between hover:surface-100 cursor-pointer border-round'>
              <Button outlined severity="warning" tooltip={'Chỉnh sửa số dư đầu kì'} icon="pi pi-pencil" size="small" rounded disabled={periodOptions[periodOptions?.length - 1]?.value !== selectedPeriod || periodYear?.value !== selectedPeriod} onClick={() => doUpdateTimePeriod(selectedPeriod, activeIndex)}/>
              <Button className="ml-2" outlined severity="danger" tooltip={'Tính toán lại'} icon="pi pi-replay" size="small" rounded onClick={() => reCalculate()}/>
            </div>
          </div>
        </div>
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <TabPanel header='Tồn kho VTHH'>
            <div className="grid">
              <div className="col-12 pb-0 iam-table" style={{maxHeight: 'calc(100vh - 20rem)'}}>
                <b className="ml-2 mt-2">Kho hàng :</b> <Dropdown className="mb-3 mr-2   ml-2 w-full md:w-14rem" value={selectedFacility} onChange={(e) => setSelectedFacility(e.value)} options={facilities}/>
                {renderDataTable()}
              </div>
            </div>
          </TabPanel>
          <TabPanel header='Tiền lương NLĐ'>
            <div className="grid">
              {renderDataTable2()}
            </div>
          </TabPanel>
          <TabPanel header='Tiền thuế nộp NSNN'>
            <div className="grid">
              {renderDataTable3()}
            </div>
          </TabPanel>
          <TabPanel header='Tiền mặt, tiền gửi NH'>
            <div className="grid">
              <div className={'mb-2 flex'}>
                <div className="flex align-items-center">
                  <RadioButton inputId="input1" value="1" onChange={() => setPaymentMethodTypeEnumId('PmtBankAccount')} checked={paymentMethodTypeEnumId === 'PmtBankAccount'}/>
                  <label htmlFor="input1" className="ml-2">Tiền gửi NH</label>
                </div>
                <div className=" ml-2 flex align-items-center">
                  <RadioButton inputId="input2" value="2" onChange={() => setPaymentMethodTypeEnumId('PmtCash')} checked={paymentMethodTypeEnumId === 'PmtCash'}/>
                  <label htmlFor="input2" className="ml-2">Tiền mặt</label>
                </div>
              </div>
              {renderDataTable4()}

            </div>
          </TabPanel>
          <TabPanel header="Công nợ NCC">
            <div className="grid">
              <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 22.5rem)'}}>
                {renderLiabilitiesSupplier()}
              </div>
              </div>
          </TabPanel>
          <TabPanel header="Công nợ KH">
            <div className="grid">
              <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 22.5rem)'}}>
                {renderLiabilitiesCustomer()}
              </div>
            </div>
          </TabPanel>
        </TabView>
        {renderCrupTimePeriod()}
        {renderUpdatePeriod()}
      </div>
    </div>
  );
}