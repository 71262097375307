import {Fragment, useEffect, useState} from 'react';

import {InputNumber} from 'primereact/inputnumber';
import { AutoComplete } from 'primereact/autocomplete';
import {Button} from 'primereact/button';

import {ConfirmDialogService, InputTemplateProps, useDataTableBasic, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';
import {UomsService} from '../../danh-muc/uom/UomsService';
import {ProductService} from '../../ban-hang/san-pham/productService';
import {SanPham} from '../../ban-hang/san-pham';

export const useCrupItems = ({displayDia, disabled}) => {
  const dataKey = 'dataKey';
  const updateTooltip = 'Cập nhật';
  const resendTooltip = 'Xóa';
  const [items, setItems] = useState([]);
  const [products, setProducts] = useState([]);
  const [quantityUoms, setQuantityUoms] = useState([]);
  const [deleteShipmentItemSourceIds,setDeleteShipmentItemSourceIds] =useState([])
  const [grandTotal, setGrandTotal] =useState(0)

  const {createProduct,renderCrupProduct, renderCrupUom, quickCreateData, setQuickCreateData} = SanPham()

  useEffect(() => {
    if (displayDia) {
      setSelectedItems(null);
      ProductService.getList('','N').then(data => {
        setProducts(data.listData.map(item => {
          delete item.disabled;
          return {...item, value: item.productId, label: `[${item.pseudoId}] - ${item.productName}`};
        }));
      });
      UomsService.getList('').then(data => {
        setQuantityUoms(data.listData.map(item => {
          delete item.disabled;
          return {...item, value: item.uomId, label: item.abbreviation};
        }));
      });
      //tao id cho items
      items.map(item =>{
        item._id = `${Date.now()}_${item.shipmentItemSourceId}_${Math.random()}`;
        item.itemTotal = item.quantity * item.amount || '';
        if (disabled === false){
          item.actions = [
            {icon: 'pi pi-trash', className: 'p-button-danger', title: 'Xoá', command: () => doDelete(item)}
          ]
        }
        return item
      })
    }
  }, [displayDia]);

  // useEffect(() => {
  //   if (displayDia && items?.length) {
  //     items.forEach((item, index) => {
  //       if (!item[dataKey]) {
  //         item[dataKey] = `${Date.now()}_${Math.random()}_${index}`;
  //       }
  //       if (!item.productNameWithPseudoId) {
  //         item.productNameWithPseudoId = `[${item.pseudoId}] - ${item.productName}`;
  //       }
  //       item.actions = [
  //         {icon: 'pi pi-pencil', title: updateTooltip, className: 'p-button-warning', command: () => doUpdate(item)},
  //         {icon: 'pi pi-trash', title: resendTooltip, className: 'p-button-danger', command: () => doDelete(item)},
  //       ]
  //     });
  //   }
  // }, [displayDia, items?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchProducts = event => {
    const _event = {
      filters: {
        productName: {value: event.query, matchMode: 'contains'}
      }
    };
    ProductService.getList(JSON.stringify(_event),'N').then(data => {
      setProducts(data.listData.map(item => {
        delete item.disabled;
        let label = `[${item.pseudoId}] - ${item.productName}`;
        return {...item, value: item.productId, label: label};
      }));
    });
  }

  const searchQuantityUoms = event => {
    const _event: any = {
      filters: {
        disabled: {value: 'N', matchMode: 'equals'},
        description: {value: event.query, matchMode: 'contains'}
      }
    };
    UomsService.getList(JSON.stringify(_event)).then(data => {
      setQuantityUoms(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.uomId, label: item.abbreviation};
      }));
    });
  }

  const onEditorChange = async (rowData, column, newValue) => {
    let prodData: any
    setItems(prevOrderItems => prevOrderItems.map(prevOrderItem => {
      if (prevOrderItem._id === rowData._id) {
        // console.log(prodData)
        if (column.field === 'product') {
          prodData = products.find(it => it.value === newValue?.value)
          if (prodData){
            prevOrderItem.productId = prodData?.productId;
            prevOrderItem.productName = prodData?.productName;
            prevOrderItem.quantityUomId = prodData?.amountUomId;
            prevOrderItem.quantityUom = quantityUoms.find(uom=> uom.value === prodData?.amountUomId)?.label
            prevOrderItem.amount = prodData?.salePrice || 0;
            prevOrderItem.quantity = 1;
            prevOrderItem.itemTotal = prevOrderItem.amount * prevOrderItem.quantity;
          }
        }
        if (column.field === 'quantity'){
          prevOrderItem.itemTotal = newValue * rowData.amount;
        }
        if (column.field === 'amount'){
          prevOrderItem.itemTotal = newValue * rowData.quantity;
        }
        return {
          ...prevOrderItem,
          [column.field]: newValue
        }
      }
      return prevOrderItem;
    }))
  }

  const addBlankItem = () => {
    setItems(prevOrderItems => {
      const _id = `${Date.now()}_${Math.random()}`;
      prevOrderItems.push({
        _id,
        actions: [
          {icon: 'pi pi-trash', className: 'p-button-danger', title: 'Xoá mã hàng', command: () => doDelete(_id)}
        ]
      });
      return [...prevOrderItems];
    });
  }

  useEffect(() => {
    if (items?.length) {
      if (!items.find(it => !it.product) && disabled === false) {
        addBlankItem();
      }
    } else {
      addBlankItem();
    }
  }, [items]);

  const refreshData = (data) => {
    if (data?.length > 0) {
      let total = 0;
      data.forEach(item => {
        if (item.amount){
          total += item.itemTotal;
        }
        item.actions = []
        if (disabled === false) {
          item.actions.push({icon: 'pi pi-trash', title: 'Xóa', className: 'p-button-danger', command: () => doDelete(item)})
        }
      })
      setGrandTotal(total);
    }
  }
  //Set lai data cho item
  useEffect(() => {
    refreshData(items)
  }, [items, items?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDialogCrup, create, update, form} = useDialogCrup({
    header: '!Sản phẩm',
    dataKey,
    width: '50rem',
    fields: [
      {
        field: 'product', header: 'Tên sản phẩm', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: products, completeMethod: searchProducts, field: 'label', dropdown: true, forceSelection: true},
        disabled: 'Update', className: 'md:col-12'
      },
      {field: 'amount', header: 'Đơn giá', required: true, type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-4'},
      {field: 'quantity', header: 'Số lượng', required: true, type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-4'},
      {
        field: 'qtyUom', header: 'Đơn vị tính', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: quantityUoms, completeMethod: searchQuantityUoms, field: 'label', dropdown: true, forceSelection: true},
        className: 'md:col-4'
      },
    ],
    createItem: item => {
      setItems(prevItems => {
        prevItems.push({
          dataKey: `${Date.now()}_${Math.random()}`,
          productId: item.product.value,
          pseudoId: item.product.pseudoId,
          productName: item.product.productName,
          amount: item.amount,
          quantity: item.quantity,
          quantityUomId: item.qtyUom?.value,
          quantityUom: item.qtyUom?.label,
          itemTotal: item.amount > 0 && item.quantity > 0 ? item.amount * item.quantity : 0,
        });
        return prevItems;
      });
      return Promise.resolve(item);
    },
    updateItem: (id, item) => {
      setItems(prevItems => {
        for (const prevItem of prevItems) {
          if (prevItem[dataKey] === id) {
            prevItem.amount = item.amount;
            prevItem.quantity = item.quantity;
            prevItem.quantityUomId = item.qtyUom?.value;
            prevItem.quantityUom = item.qtyUom?.label;
            prevItem.itemTotal = item.amount > 0 && item.quantity > 0 ? item.amount * item.quantity : 0;
            break;
          }
        }
        return prevItems;
      });
      return Promise.resolve(item);
    },
    hideToastMessage: true
  });

  // const {product} = form.getValue();

  // useEffect(() => {
  //   if (product?.value && form.dirty['product']) {
  //     const _value = form.getRawValue();
  //     _value.amount = product.purchasePrice;
  //     _value.qtyUom = {value: product.amountUomId, label: product.amountUom};
  //     form.setValue(_value);
  //   }
  // }, [product?.value]); // eslint-disable-line react-hooks/exhaustive-deps

  const tabHeader = (
      <div>
        <div className="flex align-items-center justify-content-between ">
          <span>{items.filter(it => it.productId)?.length} Sản phẩm</span>
          {/*{!disabled &&<Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"*/}
          {/*                     size="small" onClick={() => doCreate()}/>}*/}
        </div>
      </div>
  );

  const {render, selectedItems, setSelectedItems} = useDataTableBasic({
    tableHeader: tabHeader,
    dataKey:'_id',
    indexColumnWidth: 45,
    columns: [
      {
        field: 'product', header: 'Sản phẩm', width: 400, dataType: 'editor', className: 'editor-config', editorConfig: {
          onEditorChange, readOnly: disabled,
          field: {type: 'InputCustom', inputTemplate(inputTemplateProps: InputTemplateProps): any {
              const {props, fieldProps} = inputTemplateProps;
              return (
                <div className="p-inputgroup flex-1">
                  <AutoComplete {...fieldProps} suggestions={products} field="label" completeMethod={searchProducts} onChange={e => props.onInputChange(props.field, e.value)} dropdown></AutoComplete>
                  <Button icon="pi pi-plus" title="Thêm sản phẩm" className="p-button-success" onClick={()=> createProduct(props.item._id)}></Button>
                </div>
              )
            }}
        }
      },
      {field: 'quantityUomId', hidden: true},
      {field: 'quantityUom', header: 'Đơn vị tính', width: 150, dataType:'editor', className: 'editor-config', editorConfig:{
          onEditorChange,readOnly: true,
        }},
      {field: 'quantity', header: 'Số lượng', width: 150, dataType: 'editor', className: 'editor-config', editorConfig:{
          onEditorChange,readOnly: disabled,
          field: {type: 'InputNumber', InputNumberProps: {min:0}}
        }},
      {field: 'amount', header: 'Đơn giá', width: 200, dataType: 'editor', className: 'editor-config', editorConfig:{
          onEditorChange, readOnly: disabled,
          field: {type: 'InputNumber', InputNumberProps:{min:0}}
        }},
      {field: 'itemTotal', header: 'Thành tiền', width: 200, dataType: 'editor', className: 'editor-config',editorConfig:{
          onEditorChange,readOnly: disabled,
          field: {type: 'InputNumber',InputNumberProps: {min:0}}
        }},
    ],
    actionColumnWidth:90,
    items
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doCreate = () => {
    create();
  }

  //Fill sản phẩm vừa tạo nhanh vào đơn hàng
  useEffect(() => {
    if (quickCreateData?._id && quickCreateData?.productId){
      let objIndex = items.findIndex(obj => obj._id === quickCreateData._id);
      quickCreateData.product = {value: quickCreateData.productId, label: `[${quickCreateData.pseudoId}] - ${quickCreateData.productName}`};
      quickCreateData.quantityUomId = quickCreateData?.amountUomId;
      quickCreateData.quantity = 1;
      quickCreateData.unitListPrice = quickCreateData?.salePrice || 0;
      quickCreateData.itemTotal = quickCreateData.unitListPrice;
      items[objIndex] = {...quickCreateData,...items[objIndex]};
      addBlankItem();
    }
  }, [quickCreateData]);

  const doDelete = (data) => {
    if (data && data['_id']) {
      ConfirmDialogService.confirm('Xóa sản phẩm', `Xác nhận xóa sản phẩm [${data.productName}] ra khỏi danh sách?`, () => {
        setItems(prevItems => {
          if (data.shipmentItemSourceId){
            deleteShipmentItemSourceIds.push(data.shipmentItemSourceId)
          }
          return prevItems.filter(prevItem => prevItem['_id'] !== data['_id']);
        });
      });
    };
    setSelectedItems([]);
  }


  const renderProducts = () => {
    return (
      <div className="grid">
        {/*{!disabled && <div className="col-12 pb-1">*/}
        {/*  {renderToolbar()}*/}
        {/*		</div>}*/}
        <div className="col-12 py-0" style={{minHeight: '0rem', maxHeight: 'calc(100vh - 35.4rem)'}}>
          {render()}
        </div>
        <div className="flex col-12 flex-row-reverse mt-3">
          <div className="ml-4">
            <label htmlFor="total" className="font-bold block mb-2">Tổng tiền</label>
            <InputNumber inputId="total" value={grandTotal} disabled/>
          </div>
        </div>
        {renderCrupProduct()}
        {renderCrupUom()}
        {renderDialogCrup()}
      </div>
    );
  }

  return {renderProducts, items, setItems, deleteShipmentItemSourceIds, setDeleteShipmentItemSourceIds};
}

