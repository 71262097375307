import {createContext, useEffect, useState} from 'react';

import {AuthService} from 'src/service/AuthService';
import {AppStorage, UserStorage} from 'src/service/UserStorage';

export interface AppContextProps {
  setAuthContext(authContext: boolean): void

  me: any
  household: any

  setHousehold(household: any): void
}

export const AppContext = createContext({} as AppContextProps);

export const AppProvider = ({children}) => {

  const [authContext, setAuthContext] = useState(false);
  const [me, setMe] = useState(null);
  const [household, setHousehold] = useState(null);

  useEffect(() => {
    if (authContext) {
      AuthService.getMe().then(data => {
        setMe(data);
        AppStorage.set('me', JSON.stringify(data));
        UserStorage.id = data.partyId;
      }).catch(() => {
      });
    }
  }, [authContext]); // eslint-disable-line react-hooks/exhaustive-deps

  const value: AppContextProps = {
    setAuthContext,
    me,
    household,
    setHousehold
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
