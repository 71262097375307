import {useDataTable, useDialogCrup} from '@iamsoftware/react-hooks';
import {Column} from 'primereact/column';
import {TreeTable} from 'primereact/treetable';
import {BussinessJobService as Service} from './BussinessJobService';
import {Fragment, useEffect, useState} from 'react';

export const BusinessJobs = () => {
  const [nodes, setNodes] = useState([]);
  // const [parents, setParents] = useState([]);

  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  useEffect(() => {
    load();
  }, []);

  const load = () => {
    Service.getTreeList().then(data => {
      setNodes(data.data
      );
    });
  }
  return (
    <div className="card">
      <div className="col-12 py-0">
        <h6> Ngành nghề kinh doanh</h6>
        <TreeTable value={nodes} selectionMode="single" selectionKeys={selectedNodeKey} onSelectionChange={e => setSelectedNodeKey(e.value)}
                   scrollable>
          <Column field="pseudoId" header="Mã ngành nghề tính thuế" expander></Column>
          <Column field="categoryName" header="Tên ngành nghề tính thuế" className="w-30rem"></Column>
          <Column field="vatRate" header="Thuế suất GTGT" className="text-center"></Column>
          <Column field="personalIncomeTaxRate" header="Thuế suất TNCN" className="text-center"></Column>
        </TreeTable>

      </div>
    </div>
  );
}