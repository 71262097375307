import {EntityService} from '../../service/EntityService';

export class DashboardService extends EntityService {
  static entity = '/iam-hkd/dashboard';

  static getDashboarhAmount(fromDate: any, thruDate: any): Promise<any> {
    return this.axios.get(`${this.entity}/issue-receipt-total?fromDate=${fromDate}&thruDate=${thruDate}`).then(res => res.data);
  }

  static getDashboarhCash(): Promise<any> {
    return this.axios.get(`${this.entity}/cash-available`).then(res => res.data);
  }

  static getDashboarhBank(): Promise<any> {
    return this.axios.get(`${this.entity}/bank-available`).then(res => res.data);
  }

  static getReceiptPeriod(lastNMonth: any): Promise<any> {
    return this.axios.get(`${this.entity}/receipt-period?lastNMonth=${lastNMonth}`).then(res => res.data);
  }

  static getIssuePeriod(lastNMonth: any): Promise<any> {
    return this.axios.get(`${this.entity}/issue-period?lastNMonth=${lastNMonth}`).then(res => res.data);
  }

  static getProfitPeriod(lastNMonth: any): Promise<any> {
    return this.axios.get(`${this.entity}/profit-period?lastNMonth=${lastNMonth}`).then(res => res.data);
  }
}