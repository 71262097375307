import {EntityService} from 'src/service/EntityService';

export class SalesOrdersService extends EntityService {
  static entity = 'iam-sale/orders';

  static items(id: string): Promise<any> {
    return this.axios.get(`${this.entity}/${id}/items`).then(res => res.data);
  }

  static genPseudoId(): Promise<any> {
    return this.axios.get(`${this.entity}/gen-pseudo-id`).then(res => res.data);
  }

  static genShipmentPseudoId(): Promise<any> {
    return this.axios.get(`iam-logistics/outgoing-shipment/gen-pseudo-id`).then(res => res.data);
  }

  static genPaymentPseudoId(paymentInstrumentEnumId?:string): Promise<any> {
    return this.axios.get(`iam-accounting/incomingPayments/gen-pseudo-id?paymentInstrumentEnumId=${paymentInstrumentEnumId}`).then(res => res.data);
  }

  static complete(id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/complete`, data).then(res => res.data);
  }

  static uncomplete(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/uncomplete`).then(res => res.data);
  }

  static cancel(id: string): Promise<any> {
    return this.axios.delete(`${this.entity}/${id}/cancel`).then(res => res.data);
  }

  static getInvoice(id: string): Promise<any> {
    return this.axios.get(`${this.entity}/${id}/invoices`).then(res => res.data);
  }
  static getPayment(id: string): Promise<any> {
    return this.axios.get(`${this.entity}/${id}/payment-headers`).then(res => res.data);
  }
  static getPaymentList(lazyLoadEvent: any): Promise<any> {
    return this.axios.get(`iam-sale/payments/list?type=incoming&lazyLoadEvent=${lazyLoadEvent}`).then(res => res.data);
  }
  static updatePayment(paymentId :string, data: any): Promise<any> {
    return this.axios.put(`iam-sale/payments/${paymentId}/update`, data).then(res => res.data);
  }
  static paymentDetail(paymentId: string): Promise<any> {
    return this.axios.get(`iam-sale/payments/${paymentId}/detail`).then(res => res.data);
  }
  static createPayment(data: any): Promise<any> {
    return this.axios.post(`iam-sale/payments/create`, data).then(res => res.data);
  }

  static updateInvoice(id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/invoices`, data).then(res => res.data);
  }
  static uploadExcel(data: any): Promise<any> {
    return this.axios.post(`iam-hkd/products/process-import-order-items`, data).then(res => res.data);
  }

  static genChannels(): Promise<any> {
    return this.axios.get(`iam-common/sale-channels`).then(res => res.data);
  }
  static uncompletedPayments(customerPartyId:string,orderId?:string): Promise<any> {
    return this.axios.get(`/iam-sale/orders/uncompleted-payments?orderId=${orderId}&customerPartyId=${customerPartyId}`).then(res => res.data);
  }

  static createGdt(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/create-from-invoice-gdt`, data).then(res => res.data);
  }

}