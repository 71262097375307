import {Service} from './Service';

export class CommonService extends Service {

  static entity: string = 'iam-common';

  static getPartyTypes(): Promise<any> {
    return this.axios.get(`${this.entity}/party-types`).then(res => res.data);
  }
  static getTaxRate(): Promise<any> {
    return this.axios.get(`${this.entity}/tax-rate`).then(res => res.data);
  }

  static getPartyClass(): Promise<any> {
    return this.axios.get(`${this.entity}/party-class`).then(res => res.data);
  }

  static getAgreementTypes(): Promise<any> {
    return this.axios.get(`${this.entity}/employee-agr`).then(res => res.data);
  }

  static getPartyRoles(): Promise<any> {
    return this.axios.get(`${this.entity}/party-roles`).then(res => res.data);
  }

  static getByTaxCode(partyTaxId: string, includeRepresentative: string): Promise<any> {
    return this.axios.get(`${this.entity}/info/by-tax-code`, {params: {partyTaxId, includeRepresentative}}).then(res => res.data);
  }

  static getProvinces(): Promise<any> {
    return this.axios.get(`${this.entity}/geos/provinces`).then(res => res.data);
  }

  static getCounties(stateProvinceGeoId: string): Promise<any> {
    return this.axios.get(`${this.entity}/geos/${stateProvinceGeoId}/assocs?toGeoTypeEnumId=GEOT_COUNTY`).then(res => res.data);
  }

  static getCountries(): Promise<any> {
    return this.axios.get(`${this.entity}/geos/countries`).then(res => res.data);
  }

  static getPaymentInstruments(): Promise<any> {
    return this.axios.get(`${this.entity}/payment-instrument-type`).then(res => res.data);
  }
  static getOutgoingPaymentInstruments(): Promise<any> {
    return this.axios.get(`${this.entity}/outgoing-payment-instrument-type`).then(res => res.data);
  }
  static getInvoicePaymentMethod(): Promise<any> {
    return this.axios.get(`${this.entity}/invoice-payment-method`).then(res => res.data);
  }

  static getPaymentStatuses(): Promise<any> {
    return this.axios.get(`${this.entity}/payment-status`).then(res => res.data);
  }

  static getInvoiceFormTypes(): Promise<any> {
    return this.axios.get(`${this.entity}/invoice-form-types`).then(res => res.data);
  }

  static getInvoiceFormTypesSales(): Promise<any> {
    return this.axios.get(`${this.entity}/invoice-form-types?orderType=Sales`).then(res => res.data);
  }

  static glAccountTypes(): Promise<any> {
    return this.axios.get(`${this.entity}/glAccount-class-type`).then(res => res.data);
  }

  static otherPayment(): Promise<any> {
    return this.axios.get(`${this.entity}/other-payment`).then(res => res.data);
  }

  static timePeriodType(): Promise<any> {
    return this.axios.get(`${this.entity}/time-period-type-report`).then(res => res.data);
  }

  static getAssetIssuePriceMethod(): Promise<any> {
    return this.axios.get(`${this.entity}/asset-issue-price-method`).then(res => res.data);
  }

  static getPayrollRateType(): Promise<any> {
    return this.axios.get(`${this.entity}/payroll-rate-type-enum`).then(res => res.data);
  }

  static getParties(): Promise<any> {
    return this.axios.get(`${this.entity}/parties`).then(res => res.data);
  }

  static getPaymentType(): Promise<any> {
    return this.axios.get(`${this.entity}/payroll-payment-type`).then(res => res.data);
  }

  static getTaxPaymentType(): Promise<any> {
    return this.axios.get(`${this.entity}/tax-payment-type`).then(res => res.data);
  }

  static getTaxDeclarationType(): Promise<any> {
    return this.axios.get(`${this.entity}/tax-declaration-type`).then(res => res.data);
  }

  static getTaxTimePeriodType(): Promise<any> {
    return this.axios.get(`${this.entity}/tax-time-period-type`).then(res => res.data);
  }

  static getDepartmentTaxAuthority(): Promise<any> {
    return this.axios.get(`${this.entity}/tax-authority-city`).then(res => res.data);
  }
  static getDistrictDeptTaxAuthority(taxAuthorityId: string): Promise<any> {
    return this.axios.get(`${this.entity}/tax-authority-district?taxAuthorityId=${taxAuthorityId}`).then(res => res.data);
  }
  static careerSystemTree(): Promise<any> {
    return this.axios.get(`/iam-hkd/categories/career-system-tree`).then(res => res.data);
  }
  static geosProvinces(): Promise<any> {
    return this.axios.get(`${this.entity}/geos/provinces`).then(res => res.data);
  }
  static otherOutgoingPaymentTypes(): Promise<any> {
    return this.axios.get(`${this.entity}/other-outgoing-payment-types`).then(res => res.data);
  }
  static otherIncomingPaymentTypes(): Promise<any> {
    return this.axios.get(`${this.entity}/other-incoming-payment-types`).then(res => res.data);
  }
  static geosCounty(geoId:string): Promise<any> {
    return this.axios.get(`${this.entity}/geos/${geoId}/assocs?toGeoTypeEnumId=GEOT_COUNTY`).then(res => res.data);
  }
  static geoWard(toGeoId:string): Promise<any> {
    return this.axios.get(`${this.entity}/geos/${toGeoId}/assocs?toGeoTypeEnumId=GEOT_WARD`).then(res => res.data);
  }

  static getPurchaseInvoiceStatus(): Promise<any> {
    return this.axios.get(`${this.entity}/purchase-invoice-status`).then(res => res.data);
  }
  static getIssueStatuses(): Promise<any> {
    return this.axios.get(`${this.entity}/invoice-issue-status`).then(res => res.data);
  }
  static getDiscountType(): Promise<any> {
    return this.axios.get(`${this.entity}/discount-type`).then(res => res.data);
  }
  static getTaxRates(): Promise<any> {
    return this.axios.get(`${this.entity}/tax-rate`).then(res => res.data);
  }

  static getInvoiceIssueStatus(): Promise<any> {
    return this.axios.get(`${this.entity}/invoice-issue-status`).then(res => res.data);
  }

  static getInvoiceProvider(): Promise<any> {
    return this.axios.get(`${this.entity}/invoice-provider`).then(res => res.data);
  }
  static getInvoiceTemplateParty(providerPartyId: any): Promise<any> {
    return this.axios.get(`${this.entity}/invoice-template-party?providerPartyId=${providerPartyId}`).then(res => res.data);
  }

  static Genders = [
    {value: 'M', label: 'Nam'},
    {value: 'F', label: 'Nữ'}
  ];

  static partyTypeEnumIds = [
    {value: 'PtidCccd', label: 'CCCD'},
    {value: 'PtidCmnd', label: 'CMND'}
  ];

}
