import {Route, Routes} from 'react-router-dom';
import {EmployeePayments} from './bang-luong';
import ThamSo from './tham-so';
import PhieuChi from './phieu-chi';
export default function Salary() {

  return (
    <Routes>
      <Route path="bang-luong" element={<EmployeePayments/>}/>
      <Route path="tham-so" element={<ThamSo/>}/>
      <Route path="phieu-chi" element={<PhieuChi/>}/>
    </Routes>
  );
}