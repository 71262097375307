import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputNumber} from 'primereact/inputnumber';

import {ConfirmDialogService, FormatDisplay, ToastService, useForm} from '@iamsoftware/react-hooks';
import {Fieldset} from 'primereact/fieldset';
import {useCrupPOItems} from './CrupPOItems';
import {CustomersService} from '../../ban-hang/khach-hang/CustomersService';
import {CommonService} from '../../../service/CommonService';
import {Service} from '../../../service/Service';
import {PurchaseOrderService} from './OrdersService'

export const useCrupInvoice = ({reloadLazyData}) => {
  const [display, setDisplay] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [invoiceFormTypes, setInvoiceFormTypes] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalExemptAmount, setTotalExemptAmount] = useState(0);
  const [invoiceStatusId, setInvoiceStatusId] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [issueStatuses, setIssueStatuses] = useState([])
  const [invoicePaymentMethods, setInvoicePaymentMethods] = useState([]);

  useEffect(() => {
    if (display) {
      CustomersService.getList('{"filters":{"disabled":{"value":"N","matchMode":"equals"}}}').then(data => {
        setCustomers(data.listData.map(item => {
          return {value: item.partyId, label: item.partyName}
        }));
      });
      CommonService.getInvoiceFormTypes().then(data => {
        setInvoiceFormTypes(data.listData.map(item => {
          return {value: item.enumId, label: `${item.enumCode} - ${item.description}`}
        }));
      });
      CommonService.getInvoicePaymentMethod().then(data => {
        setInvoicePaymentMethods(data.listData.map(item => {
          return {value: item.enumId, label: item.description}
        }));
      });
      CommonService.getIssueStatuses().then(data => {
        setIssueStatuses(data.listData.map(item => {
          return {value: item.statusId, label: item.description}
        }));
      });
    }
  }, [display])
  const searchCustomer = event => {
    CustomersService.find('Buyer', event.query).then(data => {
      setCustomers(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.partyId, label: item.partyName};
      }));
    });
  }

  const {renderProducts, items, setItems, deleteOrderItemSeqIds} = useCrupPOItems({display, disabled: true, displayTax: false});

  const commonInfoForm = useForm({
    fields: [

      {
        field: 'toParty', header: 'Khách hàng', required: true, className: 'md:col-6', type: 'AutoComplete',
        AutoCompleteProps: {suggestions: customers, completeMethod: searchCustomer, field: 'label', dropdown: true, forceSelection: true}, disabled: true
      },
      {field: 'address1', header: 'Địa chỉ', className: 'md:col-6', disabled: true},
      {field: 'fromPartyTaxId', header: 'Mã số thuế', className: 'md:col-4', disabled: false},
      {field: 'sourceAccountNumber', header: 'Số tài khoản ', className: 'md:col-4'},
      {field: 'sourceBankName', header: 'Tên NH', className: 'md:col-4'},
      {field: 'orderComments', header: 'Diễn giải', className: 'md:col-12'},
      {field: 'invoiceDescription', header: 'Ghi chú', className: 'md:col-12'},

    ],

  });

  const infoForm = useForm({
    fields: [
      {
        field: 'invoiceFormTypeEnumId', header: 'Mẫu hóa đơn', className: 'md:col-12', type: 'Dropdown', DropdownProps: {
          options: invoiceFormTypes
        }, required: true
      },
      {field: 'invoiceSerial', header: 'Kí hiệu hóa đơn', className: 'md:col-6', required: true},
      {field: 'invoiceNo', header: 'Số hóa đơn', className: 'md:col-6', required: true},
      {field: 'invoiceDate', header: 'Ngày hóa đơn', className: 'md:col-6', InputTextProps: {type: 'date'}, required: true},
      {
        field: 'paymentMethodEnumId', header: 'Hình thức thanh toán', className: 'md:col-6', type: 'Dropdown', DropdownProps: {
          options: invoicePaymentMethods
        }
      },
      {
        field: 'issueStatusId', header: 'TT phát hành', className: 'md:col-12', type: 'Dropdown', DropdownProps: {
          options: issueStatuses
        }
      },
      {
        field: 'externalId', header: 'Mã CQT', className: 'md:col-12',
      }
    ],

  });

  const form1 = useForm({
    fields: [
      {
        type: 'custom', className: 'md:col-9',
        body: <Fieldset legend="Thông tin chung">
          {commonInfoForm.render()}
        </Fieldset>
      },
      {
        type: 'custom', className: 'md:col-3',
        body: <Fieldset legend="Chứng từ">
          {infoForm.render()}
        </Fieldset>
      },
    ],

  });


  const commonData = commonInfoForm.getValue();
  const infoData = infoForm.getValue();

  useEffect(() => {
    setTotalAmount(0);
    setTotalExemptAmount(0);
    setGrandTotal(0)
    let totalCounter = 0;
    let exemptCounter = 0;
    let grandTotalCounter = 0;
    items.forEach(item => {
      if (item.itemTotal) {
        totalCounter += item.itemTotal

        if (item.exemptAmount) {
          exemptCounter += item.exemptAmount
          grandTotalCounter = totalCounter - exemptCounter
        } else {
          grandTotalCounter = totalCounter
        }
      }
    });
    setTotalAmount(totalCounter);
    setTotalExemptAmount(exemptCounter);
    setGrandTotal(grandTotalCounter);
  }, [JSON.stringify(items)]);

  const reset = () => {
    commonInfoForm.reset();
    infoForm.reset();
  }

  const doUpdate = async (orderId: string, data: any) => {
    setOrderId(orderId)
    reset()
    setTotalAmount(0);
    setTotalExemptAmount(0);

    if (data.invoiceDate) {
      data.invoiceDate = FormatDisplay.date(new Date(data.invoiceDate), 'YYYY-MM-DD');
    }
    if (data.customerPartyId) {
      data.toParty = {value: data.customerPartyId, label: data.customerPartyName}
    }
    if (data.invoiceStatusId) {
      setInvoiceStatusId(data.invoiceStatusId)
    }
    infoForm.setValue(data);
    commonInfoForm.setValue(data);
    data.items = data.items.map(item =>{
      item.product = {value: item.productId, label: `[${item.pseudoId}] - ${item.productName}`}
      return item
    })
    setItems(data.items);
    setDisplay(Date.now());
    setDisabled(true)
  }
  const onSubmit = (cancelInvoice: string) => {
    if (infoForm.valid() && commonInfoForm.valid()) {
      if (cancelInvoice.length === 0) {
        const data = Object.assign(infoData);
        data.description = commonData.invoiceDescription
        PurchaseOrderService.updateInvoice(orderId, data).then(() => {
          ToastService.success();
          reloadLazyData();
          setDisplay(null)
        })
      } else {
        ConfirmDialogService.confirm('Xóa hóa đơn', 'Xác nhận xóa hóa đơn của đơn hàng này?', () => {
          PurchaseOrderService.updateInvoice(orderId, {'cancelInvoice': cancelInvoice}).then(() => {
            ToastService.success();
            reloadLazyData();
            setDisplay(null)
          })
        })
      }

    }
  }
  const footer = (
    <div className="flex flex-row-reverse flex-wrap">
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
      {invoiceStatusId === 'InvoiceInProcess' && <Button label="Tạo hóa đơn" icon="pi pi-file-edit" size="small" onClick={() => onSubmit('')}/>}
      {invoiceStatusId === 'InvoiceFinalized' && <Button label="Hủy hóa đơn" icon="pi pi-trash" size="small" severity="danger" onClick={() => onSubmit('Y')}/>}
      <Button label="Lưu" icon="pi pi-check" size="small" severity="success" onClick={() => onSubmit('')}/>
    </div>
  );
  const renderItems = (
    <div className="col-12">
      {renderProducts()}
      <div className="flex flex-row-reverse flex-wrap mt-3">
        <div className="ml-4">
          <label htmlFor="total" className="font-bold block mb-2">Tổng tiền thanh toán</label>
          <InputNumber inputId="total" value={grandTotal} disabled/>
        </div>
        <div className="ml-4">
          <label htmlFor="total-exempt" className="font-bold block mb-2">Tổng tiền chiết khấu</label>
          <InputNumber inputId="total-exempt" value={totalExemptAmount} disabled/>
        </div>
        <div>
          <label htmlFor="total-amount" className="font-bold block mb-2">Tổng tiền hàng</label>
          <InputNumber inputId="total-amount" value={totalAmount} disabled/>
        </div>
      </div>

    </div>);
  const header = () => {
    return (
      <div className=" flex jjustify-content-between flex-wrap">
        <div className="mr-8">
          <h4> Hóa đơn</h4>
        </div>
      </div>
    )

  }

  const render = () => {
    return (
      <Fragment>
        <Dialog header={header} footer={footer} visible={!!display} maximized={true} draggable={false} onHide={() => setDisplay(null)}>
          <div className="grid">
            <div className="col-12">
              {form1.render()}
            </div>
            {renderItems}
          </div>
        </Dialog>
      </Fragment>
    );
  }

  return {renderCrupInvoice: render, doUpdateInvoice: doUpdate};
}
