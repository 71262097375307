import {FormatDisplay, ToastService, useDataTable, useDataTableBasic, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';
import {SalesOrdersService as Service} from './ordersService';
import {Fragment, useEffect, useState} from 'react';
import {Button} from 'primereact/button';
import {ConfirmDialogService} from '@iamsoftware/react-hooks';
import {useCrupSO} from './CrupSO';
import {useCrupInvoice} from './CrupInvoice';
import {useCrupComplete} from './crupComplete';
import {Dropdown} from 'primereact/dropdown';
import {useCrupPayment} from './Payment';
import {Dialog} from 'primereact/dialog';
import {PurchaseOrderService} from '../../mua-hang/don-hang/OrdersService';
import {SaleInvoices} from "../hoa-don";


export default function DonHang() {

  const dataKey = 'orderId';
  const [headerResult, setHeaderResult] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState('null');
  const [channels, setChannels] = useState(null);
  const [displayGdt, setDisplayGdt] = useState(false);
  const [invoiceGdtItems, setInvoiceGdtItems] = useState(null);
  const [invoiceGdtTotal, setInvoiceGdtTotal] = useState(0);

  useEffect(() => {
    setHeaderResult(tabHeader)
    Service.genChannels().then(({listData}) => {
      let _channels = listData.map(item => {
        return {value: item.enumId, label: item.description};
      })
      _channels.unshift({value: 'null', label: 'Tất cả'})
      setChannels(_channels)
    })
  }, []);

  const {render: renderDataTable, reloadLazyData, refreshLazyData} = useDataTable({
    tableHeader: headerResult,
    stateKey: 'mainTable',
    dataKey,
    columns: [
      {
        header: '#', sortable: false, frozen: true, width: 40, dataType: 'custom', customCell(rowData: any): any {
          return <div style={{textAlign: 'center'}}>{rowData?.index}</div>
        }
      },
      {
        field: 'displayId', header: 'Số chứng từ', frozen: true, minWidth: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <a onClick={() => doRowView(rowData)}>
            {rowData.displayId}
          </a>
        }
      },
      {field: 'placedDate', header: 'Ngày chứng từ', frozen: true, dataType: 'date', width: 140},
      {field: 'completedDate', header: 'Ngày ghi sổ', dataType: 'date', width: 140},
      {field: 'invoiceNo', header: 'Số hóa đơn', dataType: 'number', width: 125, matchMode: 'numeric'},
      {field: 'customerPartyName', header: 'Khách hàng', width: 300, matchMode: 'contains'},
      {field: 'subTotal', header: 'Tổng tiền hàng', dataType: 'number', width: 150, matchMode: 'numeric'},
      {field: 'discountTotal', header: 'Chiết khấu', dataType: 'number', width: 150, matchMode: 'numeric'},
      {field: 'grandTotal', header: 'Tổng tiền TT', dataType: 'number', width: 150, matchMode: 'numeric'},
      {
        field: '0', header: 'TT Thanh toán', width: 200, dataType: 'custom', customCell(rowData: any): any {
          let payment;
          if (rowData.paymentStatus === 'Đã thanh toán') {
            payment = <p>
              <i className="true-icon pi pi-check-circle mr-1"></i>
              <span>{rowData.paymentStatus}</span>
            </p>
          } else if (rowData.paymentStatus ==="Chưa thanh toán") {
            payment = <p>
              <i className="false-icon pi pi-times-circle mr-1"></i>
              <span>{rowData.paymentStatus}</span>
            </p>
          } else payment = <p>
            <i className="warning-icon pi pi-check-square mr-1"></i>
            <span>{rowData.paymentStatus}</span>
          </p>
          return <div>
            {payment}
          </div>
        }
      },
      {
        field: '1', header: 'TT Hóa đơn', width: 150, dataType: 'custom', customCell(rowData: any): any {
          let payment;
          if (rowData.invoiceStatus === 'Đã thanh toán') {
            payment = <p>
              <i className="true-icon pi pi-check-circle mr-1"></i>
              <span>{rowData.invoiceStatus}</span>
            </p>
          } else {
            payment = <p>
              {/*<i className="false-icon pi pi-times-circle mr-1"></i>*/}
              <span>{rowData.invoiceStatus}</span>
            </p>
          }
          return <div>
            {payment}
          </div>
        }
      },
      {
        field: 'channels', header: 'Kênh bán hàng', width: 200, dataType: 'custom', customCell(rowData: any): any {
          let rowName = `${rowData.storeName || ''}`
          if (rowData.salesChannelEnum) {
            rowName += `[${rowData.salesChannelEnum}]`
          }
          return rowName
        }
      },
      {
        field: 'status', header: 'Trạng thái ', width: 200, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          let _className;
          switch (rowData.statusId) {
            case 'OrderApproved':
              _className = 'alert alert-danger';
              break;
            case 'OrderCompleted':
              _className = 'alert alert-success';
              break;
          }
          return <div className={_className}><b>{rowData.status}</b></div>
        }
      }
    ],
    // indexColumnWidth: 45,
    actionColumnWidth: 120,
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = []
          if (item.statusId !== 'OrderCompleted') {
            item.actions.push({icon: 'pi pi-pencil', title: 'Chỉnh sửa', className: 'p-button-warning', command: () => doRowView(item)},)
            item.actions.push({icon: 'pi pi-check', title: 'Ghi sổ', className: 'p-button-success', command: () => doComplete(item)},)
            item.actions.push({icon: 'pi pi-trash', title: 'Xóa', className: 'p-button-danger', command: () => doDelete(item.orderId)},)

          }
          if (item.statusId === 'OrderCompleted') {
            item.actions.push({icon: 'pi pi-credit-card', title: 'Phiếu thu', className: 'p-button-secondary', command: () => doViewPayment(item)},)
            item.actions.push({icon: 'pi pi-file-edit', title: 'Hóa đơn', className: 'p-button-info', command: () => doRowUpdateInvoice(item.orderId)},)
            item.actions.push({icon: 'pi pi-times', title: 'Bỏ ghi sổ', className: 'p-button-danger', command: () => doUncomplete(item.orderId)},)
          }

        })
      }
    },
    getList: lazyLoadEvent => {
      let _params: any
      if (selectedChannel !== 'null') {
        _params = {salesChannelEnumId: selectedChannel}
      }
      return Service.getList(lazyLoadEvent, _params).then(data => {
        data.listData.map((item, index) => {
          item.index = index + 1
        })
        return data
      });
    }
  });

  //dong pop up chon hoa don gdt
  const doClosePopUp = () =>{
    setSelectedInvoiceGdt([]);
    setDisplayGdt(false)
  }

  const {doUpdateSaleInvoice, renderSaleInvoice} = SaleInvoices(reloadLazyData)

  const {renderCrupSO, doCreateSO, doUpdateSO, doCreateGdt} = useCrupSO({reloadLazyData}, doClosePopUp);
  const {renderPayment, doCreatePayment, doUpdatePayment} = useCrupPayment({reloadLazyData});
  const {renderCrupInvoice, doUpdateInvoice} = useCrupInvoice({reloadLazyData});
  const {renderComplete, doComplete} = useCrupComplete({reloadLazyData})


  const doUncomplete = (input) => {
    Service.uncomplete(input).then(() => {
        ToastService.success();
        reloadLazyData();
        refreshLazyData();
      }
    )
  }

  const doRowUpdateInvoice = (input) => {
    Service.getInvoice(input).then(data => {
      doUpdateSaleInvoice(data.invoiceId, false, true)
    })
  }


  const doDelete = (input) => {
    ConfirmDialogService.confirm('Xác Nhận', 'Bạn chắc chắn muốn xóa đơn này', () => {
      Service.cancel(input).then(() => {
        ToastService.success();
        reloadLazyData();
      })
    })
  }


  const doViewPayment = (input) => {
    Service.getPayment(input.orderId).then(data => {
      if (data.items.length){
        doUpdatePayment(input, data)
      }else {
        doCreatePayment(input, data)
      }
    })
  }
  const doRowView = (input) => {
    Service.get(input.orderId).then(data => {
      if (input.statusId === "OrderApproved") {
        doUpdateSO(input.orderId, data, false)
      } else {
        doUpdateSO(input.orderId, data, true)
      }
    })
  }

  const {render: renderInvoiceGdtTable, selectedItems: selectedInvoiceGdt,setSelectedItems: setSelectedInvoiceGdt} = useDataTableBasic({
    tableHeader:`${invoiceGdtTotal} hóa đơn chưa tạo đơn hàng`,
    dataKey:'invoiceId',
    selectionMode: 'radiobutton',
    columns:[
      {field:'invoiceDate',header:'Ngày hóa đơn' ,matchMode:'contains', minWidth: 150, dataType:'date'},
      {field:'invoiceForm',header: 'Mẫu số' ,matchMode:'contains', minWidth: 150, dataType:'custom', customCell(rowData: any): any {
          return <span>{rowData.invoiceForm}-{rowData.invoiceType}</span>
        }},
      {field:'invoiceSerial',header: 'Ký hiệu' ,matchMode:'contains', minWidth: 150},
      {field:'invoiceNo',header: 'Số hóa đơn' ,matchMode:'contains', minWidth: 150},
      {field:'customer',header: 'Khách hàng' ,matchMode:'contains', minWidth: 250, dataType:'custom', customCell(rowData: any): any {
          if (rowData.toPartyName){
            return <span>[{rowData.toPartyTaxId}] - {rowData.toPartyName}</span>
          }else {
            return <span>{rowData.toName}</span>
          }
        }},
      {field:'invoiceTotal',header: 'Giá trị HĐ' ,matchMode:'contains', minWidth: 150, dataType:'number'},
      {field:'taxAuthCode',header: 'Mã CQT' ,matchMode:'contains', minWidth: 250},
    ],
    items: invoiceGdtItems,
    originalProps:{
      paginator: true,
      rows: 5,
      rowsPerPageOptions: [5, 10, 20, 50, 100],
    }
  })

  const selectedItemGdt: any = (selectedInvoiceGdt && selectedInvoiceGdt['invoiceId']) ? selectedInvoiceGdt : null;

  const popUpInvoiceGdt = ()=>{
    PurchaseOrderService.getInvoicesGdt('sale').then(data=>{
      setInvoiceGdtItems(data.listData)
      setInvoiceGdtTotal(data.totalRecords)
    })
    setDisplayGdt(true)
  }

  const onSubmitGdtInvoice = (invoiceId) =>{
    if (invoiceId){
      PurchaseOrderService.getInvoicesGdtInfo(invoiceId).then(data =>{
        doCreateGdt(data)
      })
    }
  }

  //Tim kiem theo kenh
  useEffect(() => {
    if (selectedChannel) {
      reloadLazyData()
    }
  }, [selectedChannel]);

  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>Đơn hàng bán</span>
          <div>
            <Button label="Thêm từ hóa đơn TCT" icon="pi pi-plus" outlined severity="secondary" className="mr-2 mb-1 h-2rem"
                    size="small" onClick={popUpInvoiceGdt}/>
            <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"
                    size="small" onClick={doCreateSO}/>
          </div>
        </Fragment>
      </div>
    </div>
  );

  const footer = (
    <div className="flex align-items-center justify-content-between ">
      <span></span>
      <div>
        <Button label="Xác nhận" icon="pi pi-check" outlined severity="success" className="mr-2 mb-1 h-2rem"
                size="small" onClick={() => onSubmitGdtInvoice(selectedItemGdt?.invoiceId)}/>
        <Button label="Hủy" outlined icon="pi pi-times" severity="secondary" className="mr-4 mb-1 h-2rem"
                size="small" onClick={() => doClosePopUp()}/>
      </div>
    </div>
  )

  return (
    <div className="grid">
      <div className="flex align-items-center">
        <label className="mb-2 ml-2">Kênh bán hàng </label>
        <Dropdown style={{width: '18vw'}} filter className="iam-dropdown ml-2 mb-2" value={selectedChannel} onChange={(e) => setSelectedChannel(e.value)} options={channels} optionLabel="label"
                  placeholder="Chọn kênh bán hàng"/>
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12.5rem)'}}>
        {renderDataTable()}
      </div>
      <Dialog header="Chọn hóa đơn" footer={footer} visible={!!displayGdt} style={{width:'80vw', minHeight:'calc(100vh - 30.5em)'}} draggable={false} onHide={() => setDisplayGdt(null)}>
        <div className="grid">
          <div className="col-12 mt-2" style={{maxHeight: 'calc(100vh - 22.5em)'}}>
            {renderInvoiceGdtTable()}
          </div>
        </div>
      </Dialog>
      {renderCrupSO()}
      {renderCrupInvoice()}
      {renderComplete()}
      {renderPayment()}
      {renderSaleInvoice(true)}
    </div>
  );
}