import {EntityService} from '../../../service/EntityService';

export class S1ReportService extends EntityService {
  static entity = 'iam-report/s1-hkd';

  static find(term: string): Promise<any> {
    return this.axios.get(`${this.entity}/find`, {params: {term}}).then(res => res.data);
  }
  static reCalculate(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/recalculate`).then(res => res.data);
  }

}