import {Fragment, useEffect, useState} from 'react';
import {ConfirmDialogService, useDataTableBasic, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';


export const useCrupReadOnlyPOItems = ({display, disabled, displayTax}) => {

  const dataKey = 'dataKey';

  const [items, setItems] = useState([]);
  const [headerResult, setHeaderResult] = useState(null);
  const [total, setTotal] = useState('');


  useEffect(() => {
    if (display) {
      setSelectedItems(null);
      items.map(it => {
        it._id = `${Date.now()}_${it.orderItemSeqId}_${Math.random()}`
        return it
      })
    }
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps

  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>{total}</span>
        </Fragment>
      </div>
    </div>
  );

  const {render, selectedItems, setSelectedItems} = useDataTableBasic({
    tableHeader: tabHeader,
    dataKey,
    indexColumnWidth: 40,
    columns: [
      {field: 'productName', header: 'Sản phẩm', width: 150, matchMode: 'contains'},
      {field: 'quantityUom', header: 'ĐVT', width: 50, matchMode: 'contains'},
      {field: 'quantity', header: 'SL', width: 75, matchMode: 'equals', dataType: 'number'},
      {field: 'unitListPrice', header: 'Đơn giá', width: 100, matchMode: 'equals', dataType: 'number'},
      {field: 'itemTotal', header: 'Thành tiền', width: 100, matchMode: 'equals', dataType: 'number'},
      {field: 'sourcePercentage', header: 'Tỷ lệ CK', width: 75, matchMode: 'equals', dataType: 'number'},
      {field: 'exemptAmount', header: 'Số tiền CK', width: 100, matchMode: 'equals', dataType: 'number'},
    ],
    items,
  });


  const refreshData = (data) => {
    if (data?.length > 0) {
      setTotal(`${data.length} Sản phẩm`)
    }
  }
  useEffect(() => {
    refreshData(items)
  }, [items, items?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderProducts = () => {
    return (
      <div className="grid">
        <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 15.5rem)'}}>
          {render()}
        </div>
      </div>
    );
  }
  return {renderProducts2: renderProducts, items2: items, setItems2: setItems};
}