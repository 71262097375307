import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {FormatDisplay, ToastService, useDataTableBasic, useForm} from '@iamsoftware/react-hooks';
import {EmployeePaymentService as Service} from './EmployeePaymentService'

export const NewEmployeePayment = ({display, setDisplay, reloadLazyData}) => {
  const [fieldItems, setFieldItems] = useState(null)
  const [fieldItemsDisplay, setFieldItemsDisplay] = useState(null)
  const [resultList, setResultList] = useState(null)
  const [resultDisplay, setResultDisplay] = useState(null)
  const [title, setTitle] = useState('')
  const [updating, setUpdating] = useState(false)
  const [adjustmentTypes, setAdjustmentTypes] = useState(null)
  useEffect(() => {
    if (display) {
      if (display?.doUpdate) {
        form.reset();
        setTitle('Cập nhật');
        setUpdating(true);
        if (display.fromDate) {
          display.fromDate = FormatDisplay.date(new Date(display.fromDate), 'YYYY-MM-DD');
        }
        if (display.thruDate) {
          display.thruDate = FormatDisplay.date(new Date(display.thruDate), 'YYYY-MM-DD');
        }
        form.setValue(display)
      } else {
        setTitle('Tạo mới');
        setUpdating(false);
        form.reset();
      }
      Service.adjustmentTypes().then(data => {
        setAdjustmentTypes(data.listData.map(item => {
          return {value: item.payrollAdjustmentId, label: item.description}
        }));
      })
    }

  }, [display])
  const form = useForm({
    fields: [
      {field: 'displayId', header: 'Mã bảng lương', className: 'md:col-12', required: true},
      {field: 'paySalaryName', header: 'Tên bảng lương', className: 'md:col-12', required: true},
      {field: 'periodNum', header: 'Tháng', className: 'md:col-6', type: 'Dropdown', DropdownProps: {options: Months}},
      {field: 'yearInt', header: 'Năm', className: 'md:col-6', type: 'Dropdown', DropdownProps: {options: Years}},
      {field: 'fromDate', header: 'Từ ngày', InputTextProps: {type: 'date'}, className: 'md:col-6', required: true},
      {field: 'thruDate', header: 'Đến ngày', InputTextProps: {type: 'date'}, className: 'md:col-6', required: true},
      {field: 'contentFile', header: 'File Upload', type: 'file', InputFileProps: {accept: '.xlsx'}, className: 'md:col-6', required: true},
      {field: 'rowStartData', header: 'Dòng bắt đầu dữ liệu', InputTextProps: {type: 'number'}, className: 'md:col-6', required: true},
    ],
  });
  const _value = form.getValue()

  const onEditorChange = (rowData, column, newValue) => {
    setFieldItems(prevItems => prevItems.map(prevItem => {
      if (prevItem.excelColId === rowData.excelColId) {
        return {
          ...prevItem,
          [column.field]: newValue
        }
      }
      return prevItem;
    }));
  }
  const {render: renderDataTable} = useDataTableBasic({
    indexColumnWidth: 40,
    columns: [
      {
        field: 'excelColName', header: 'Cột trên tệp dữ liệu', width: 150, matchMode: 'contains'
      },
      {
        field: 'dbFieldId', header: 'Cột trên phần mềm', width: 150, matchMode: 'contains', dataType: 'editor', editorConfig: {
          onEditorChange, readOnly: false,
          field: {
            type: 'Dropdown', DropdownProps: {options: adjustmentTypes, style: {width: '250px'}},
          }
        }
      },

    ],
    items: fieldItems,
  });
  const {render: renderResultTable} = useDataTableBasic({
    indexColumnWidth: 40,
    columns: [
      {field: 'rowNum', header: 'Dòng dữ liệu', minWidth: 150, matchMode: 'contains'},
      {
        field: 'success', header: 'Kết quả', minWidth: 150, dataType: 'custom', customCell(rowData: any): any {
          if (rowData.success === 'Y') return "Thành công"
          else return "Thất bại"
        }
      },
      {field: 'error', header: 'Mô tả lỗi', minWidth: 150, matchMode: 'contains'},
    ],
    items: resultList,
  });

  const onSubmit = () => {
    const input = _value
    if (input.contentFile.length) {
      input.contentFile = input.contentFile[0]
    }
    if (form.valid()) {
      Service.mappingColumns(Service.toFormData(input)).then(data => {
        if (data.fieldItems.length === 0) {
          ToastService.error('Dòng bắt đầu dữ liệu không đúng')
        } else {
          delete input.contentFile
          setFieldItems(data.fieldItems)
          setFieldItemsDisplay(Date.now())
        }
      })
    }
  }


  const onSave = async () => {
    const input = _value;
    if (input.contentFile.length) {
      input.contentFile = input.contentFile[0]
    }
    input.fieldItems = JSON.stringify(fieldItems);
    if (updating === true) {
      Service.update(display?.organizationPayId, Service.toFormData(input)).then(data => {
        setResultList(data.resultList);
        setFieldItemsDisplay(null)
        setDisplay(null)
        setResultDisplay(Date.now())
        reloadLazyData();
      })
    } else if (updating === false) {
      Service.create(Service.toFormData(input)).then(data => {
        setResultList(data.resultList);
        setFieldItemsDisplay(null)
        setDisplay(null)
        setResultDisplay(Date.now())
        reloadLazyData();
      })
    }
  }

  useEffect(() => {
    _value.paySalaryName = `Bảng lương tháng ${_value.periodNum} năm ${_value.yearInt}`
    if (_value.yearInt && _value.periodNum) {
      _value.fromDate = FormatDisplay.date(new Date(_value.yearInt, _value.periodNum - 1, 1), 'YYYY-MM-DD');
      _value.thruDate = FormatDisplay.date(new Date(_value.yearInt, _value.periodNum, 0), 'YYYY-MM-DD');
    }
    form.setValue(_value)
  }, [_value?.yearInt, _value?.periodNum])

  const footer = (
    <div className="flex flex-row-reverse flex-wrap">
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
      <Button label="Lưu" icon="pi pi-check" type="button" outlined severity="success" size="small" onClick={() => onSubmit()}/>
    </div>
  );
  const fieldItemsFooter = (
    <div className="flex flex-row-reverse flex-wrap">
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setFieldItemsDisplay(null)}/>
      <Button label="Lưu" icon="pi pi-check" type="button" outlined severity="success" size="small" onClick={() => onSave()}/>
    </div>
  );
  const resultFooter = (
    <div className="flex flex-row-reverse flex-wrap">
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setResultDisplay(null)}/>
    </div>
  );
  return (
    <Fragment>
      <Dialog header={`${title} bảng lương`} footer={footer} visible={!!display} onHide={() => setDisplay(null)} style={{width: '600px'}}>
        <div className="grid">
          <div className="col-12">
            {form.render()}
          </div>
        </div>
      </Dialog>
      <Dialog header={'Mapping dữ liệu'} footer={fieldItemsFooter} visible={!!fieldItemsDisplay} onHide={() => setFieldItemsDisplay(null)} style={{width: '700px'}}>
        <div className="grid">
          <div className="col-12">
            {renderDataTable()}
          </div>
        </div>
      </Dialog>
      <Dialog header={`Kết quả ${title} bảng lương`} footer={resultFooter} visible={!!resultDisplay} onHide={() => setResultDisplay(null)} style={{width: '700px'}}>
        <div className="grid">
          <div className="col-12">
            {renderResultTable()}
          </div>
        </div>
      </Dialog>
    </Fragment>
  );

}
const year = new Date().getFullYear();
const Years = [
  {value: year, label: year},
  {value: year - 1, label: year - 1},
  {value: year - 2, label: year - 2},
  {value: year - 3, label: year - 3},
];
const Months = [
  {value: 1, label: 1},
  {value: 2, label: 2},
  {value: 3, label: 3},
  {value: 4, label: 4},
  {value: 5, label: 5},
  {value: 6, label: 6},
  {value: 7, label: 7},
  {value: 8, label: 8},
  {value: 9, label: 9},
  {value: 10, label: 10},
  {value: 11, label: 11},
  {value: 12, label: 12}
];
