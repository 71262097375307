import {Fragment, useEffect, useRef, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {ProductService as Service} from './productService';

import {ConfirmDialogService, ToastService, useDataTableBasic} from '@iamsoftware/react-hooks';

export const UploadExcel = ({display, setDisplay, reloadLazyData}) => {

  const inputSignedFile = useRef(null);
  const [description, setDescription] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadResult, setUploadResult] = useState([])
  const [displayResult, setDisplayResult] = useState(null)

  const onUploadSignedFile = event => {
    if (event.target?.files?.length === 1) {
      setFile(event.target.files[0]);
    } else {
      ToastService.error('Chưa chọn file Excel')
    }
  }

  const downloadExcel = () => {
    window.open(`${Service.baseURL}/${Service.entity}/download-excel`);
  }

  const {render: renderDataTableItem} = useDataTableBasic({
    tableHeader: `Chi tiết kết quả  `,
    dataKey: 'rowNum',
    columns: [
      {field: 'rowNum', header: 'Dòng dữ liệu', width: 100, dataType: 'number'},
      {
        field: 'success', header: 'Kết quả', width: 100, dataType: 'custom', customCell(rowData: any): any {
          let access;
          if (rowData.success === 'Y') {
            access = <p>
              <i className="true-icon pi pi-check-circle mr-1"></i>
              <span>Thành công</span>
            </p>
          } else {
            access = <p>
              <i className="false-icon pi pi-times-circle mr-1"></i>
              <span>Thất bại</span>
            </p>
          }
          return <div>
            {access}
          </div>

        }
      },
      {field: 'error', header: 'Mô tả lỗi', width: 350}
    ],
    items: uploadResult
  });

  const onSubmit = () => {
    if (file) {
      Service.uploadExcel(Service.toFormData({contentFile: file})).then(data => {
        setUploadResult(data.resultList)
        setDisplay(false)
        reloadLazyData()
        setDisplayResult(true);
      });
      inputSignedFile.current.value = '';
    }
  }

  const close = () => {
    setDisplay(false)
    setDisplayResult(false)
  }

  const footer = (
    <div className="flex justify-content-between">
      <div>
      </div>
      <div>
        <Button label="Tải file excel mẫu" icon="pi pi-download" className="p-button-outlined p-button-help p-button-sm" type="button" onClick={downloadExcel}/>
        <Button label="Tải lên" icon="pi pi-upload" className="p-button-outlined p-button-success p-button-sm" type="button" onClick={onSubmit}/>
        <Button label="Đóng" icon="pi pi-times" className="p-button-outlined p-button-secondary p-button-sm" type="button" onClick={() => setDisplay(false)}/>
      </div>
    </div>
  );
  const resultFooter = (
    <div className="flex justify-content-between">
      <div>
      </div>
      <div>
        <Button label="Đóng" icon="pi pi-times" className="p-button-outlined p-button-secondary p-button-sm" type="button" onClick={() => setDisplayResult(null)}/>
      </div>
    </div>
  );

  return (
    <Fragment>
      <Dialog header={'Upload file Excel'} draggable={false} footer={footer} visible={!!display} style={{width: '40rem', height: '20rem',}} position="top" onHide={() => setDisplay(false)}>
        <div className="flex col-12">
          <input className="mt-5 md:col-12" ref={inputSignedFile} type="file" accept='.xlsx' onChange={onUploadSignedFile}/>
        </div>
      </Dialog>
      <Dialog header={'Kết quả upload Excel'} footer={resultFooter} visible={!!displayResult} style={{width: '80rem',}} position="top" onHide={() => close()}>
        {renderDataTableItem()}
      </Dialog>
    </Fragment>
  );
}