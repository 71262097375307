import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {FormatDisplay} from '@iamsoftware/react-hooks';
import {S3ReportService as Service} from './S3ReportSevice'
import {useCrupOutGoingPayment} from '../../ke-toan/phieu-chi/CrupOutGoingPayment';

export const S3Detail = ({display, setDisplay, update, reCalculate, reload}) => {
  const [headerDate, setHeaderDate] = useState('')
  const [data, setData] = useState(null)
  const [reportCategoryTaxIds, setReportCategoryTaxIds] = useState([])
  useEffect(() => {
    load()
  }, [display])
  useEffect(() => {
    if (reload === true) {
      load()
    }
  }, [reload])
  const load = () => {
    if (display?.reportId) {
      Service.get(display.reportId).then(data => {
        if (data.timePeriodTypeId === "ReportYear") {
          setHeaderDate(`${data?.timePeriodType} ${data?.yearInt}`)
        } else {
          setHeaderDate(`${data?.timePeriodType} ${data?.periodNum} năm ${data?.yearInt}`)
        }
        setData(data)
        setReportCategoryTaxIds(data.dynamicColumns.map(dynamicData => {
          return dynamicData.enumId
        }))
      })
    } else {
      setReportCategoryTaxIds([])
    }
  }
  const header = (
    <div style={{textAlign: 'center'}}>
      <span>Sổ chi phí sản xuất, kinh doanh.<br/> {headerDate}</span>
    </div>
  )
  const footer = (
    <div className="flex flex-row-reverse flex-wrap">
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
      <Button label="Tính toán lại dữ liệu" icon="pi pi-refresh" type="button" outlined severity="danger" size="small" onClick={() => reCalculate(display, true)}/>
      <Button label="Cập nhật" icon="pi pi-pencil" type="button" outlined severity="warning" size="small" onClick={() => update(display, true)}/>
    </div>
  );
  return (
    <Dialog className="dialog-report" header={header} footer={footer} visible={!!display} maximized={true} draggable={false} onHide={() => setDisplay(null)}>
      <Screen rowData={data} display={display} reportCategoryTaxIds={reportCategoryTaxIds}/>
    </Dialog>
  );

}
const Screen = ({rowData, display, reportCategoryTaxIds}) => {
  const {renderPayments, doUpdateOutgoingPayment} = useCrupOutGoingPayment({reloadLazyData: null})
  const displayPayment = (input) => {
    input.paymentTypeEnumId = 'PtOtherIssue'
    doUpdateOutgoingPayment(input, true)

  }
  useEffect(() => {
    if (rowData?.dynamicColumns) {
      rowData.dynamicColumns.map(data => {
        reportCategoryTaxIds.push(data.enumId)
      })
    }
  }, [display])
  return (
    <div className="grid">
      <div className="col-12" style={{textAlign: 'center'}}>
        <span style={{textAlign: 'center'}} className="mb-2"> <i style={{textAlign: 'center'}}> Từ ngày {FormatDisplay.date(new Date(rowData?.fromDate), 'DD-MM-YYYY')} đến ngày {FormatDisplay.date(new Date(rowData?.thruDate), 'DD-MM-YYYY')}</i></span>
      </div>
      <div className="p-datatable p-component p-datatable-striped p-datatable-gridlines p-datatable-sm">
        <div className="p-datatable-wrapper" style={{height: 'calc(100vh - 15.5rem)'}}>
          <table className="p-datatable-table">
            <thead className="p-datatable-thead" style={{position: 'sticky', top: 0, zIndex: 1}}>
            <tr>
              <th rowSpan={2} style={{width: 100, textAlign: 'center'}}>Ngày tháng ghi sổ</th>
              <th colSpan={2} style={{width: 150, textAlign: 'center'}}>Chứng từ</th>
              <th rowSpan={2} style={{width: 300}}>Diễn giải</th>
              <th colSpan={rowData?.dynamicColumns.length + 1} style={{textAlign: 'center', width: rowData?.dynamicColumns.length * 120}}>Tập hợp chi phí theo các yếu tố sản suất, kinh doanh</th>
            </tr>
            <tr>
              <th style={{width: 100}}>Số hiệu</th>
              <th style={{width: 130}}>Ngày tháng</th>
              <th style={{width: 130}}>Tổng số tiền</th>
              {rowData?.dynamicColumns.map((item) => {
                return (
                  <th key={item.enumId} style={{textAlign: 'center', width: 120}}> {item.description}</th>
                )
              })
              }
            </tr>
            </thead>
            <tbody className="p-datatable-tbody">
            <tr style={{background: "#F8F9FA"}}>
              <td style={{textAlign: 'center'}}><b>A</b></td>
              <td style={{textAlign: 'center'}}><b>B</b></td>
              <td style={{textAlign: 'center'}}><b>C</b></td>
              <td style={{textAlign: 'center'}}><b>D</b></td>
              <td style={{textAlign: 'center'}}><b>1</b></td>
              {reportCategoryTaxIds.map((data, index) => {
                return (<td style={{textAlign: 'center'}}><b>{index + 2}</b></td>)
              })
              }
            </tr>
            {rowData?.data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{FormatDisplay.date(new Date(item.completedDate), 'DD/MM/YYYY')}</td>
                  <td><a className={' cursor-pointer'} onClick={() => displayPayment(item)}>{item.displayId}</a></td>
                  <td>{FormatDisplay.date(new Date(item.effectiveDate), 'DD/MM/YYYY')}</td>
                  <td>{item.description}</td>
                  <td style={{textAlign: 'right'}}>{FormatDisplay.number(item.amount)}</td>
                  {reportCategoryTaxIds.map((reportCateId, index) => {
                    if (reportCateId === reportCategoryTaxIds[index]) {
                      if (item[reportCategoryTaxIds[index]]) {
                        return (<td style={{textAlign: 'right'}}>{FormatDisplay.number(item[reportCategoryTaxIds[index]])}</td>)
                      } else return (<td style={{textAlign: 'right'}}>{FormatDisplay.number(0)}</td>)
                    }
                  })}
                </tr>
              )
            })
            }
            <tr style={{background: "#F8F9FA"}}>
              <td colSpan={4} style={{textAlign: 'center'}}><b>Tổng cộng</b></td>
              <td style={{textAlign: 'right'}}><b>{FormatDisplay.number(rowData?.summaryData.totalAmount)}</b></td>
              {reportCategoryTaxIds.map((reportCateId, index) => {
                if (reportCateId === reportCategoryTaxIds[index]) {
                  if (rowData?.summaryData[reportCategoryTaxIds[index]]) {
                    return (<td style={{textAlign: 'right'}}><b>{FormatDisplay.number(rowData?.summaryData[reportCategoryTaxIds[index]])}</b></td>)
                  } else return (<td style={{textAlign: 'right'}}><b>{FormatDisplay.number(0)}</b></td>)
                }
              })}
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      {renderPayments()}
    </div>
  )
    ;

}
