import {EntityService} from 'src/service/EntityService';

export class PurchaseOrderService extends EntityService {
  static entity = 'iam-purchase/orders';

  static items(id: string): Promise<any> {
    return this.axios.get(`${this.entity}/${id}/items`).then(res => res.data);
  }

  static genPseudoId(): Promise<any> {
    return this.axios.get(`${this.entity}/gen-pseudo-id`).then(res => res.data);
  }

  static genShipmentPseudoId(): Promise<any> {
    return this.axios.get(`iam-logistics/incoming-shipment/gen-pseudo-id`).then(res => res.data);
  }

  static genPaymentPseudoId(paymentInstrumentEnumId: string): Promise<any> {
    return this.axios.get(`/iam-accounting/outgoingPayments/gen-pseudo-id?paymentInstrumentEnumId=${paymentInstrumentEnumId}`).then(res => res.data);
  }

  static complete(id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/complete`, data).then(res => res.data);
  }

  static uncomplete(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/uncomplete`).then(res => res.data);
  }

  static cancel(id: string): Promise<any> {
    return this.axios.delete(`${this.entity}/${id}/cancel`).then(res => res.data);
  }

  static getInvoice(id: string): Promise<any> {
    return this.axios.get(`${this.entity}/${id}/invoices`).then(res => res.data);
  }

  static getPayment(id: string): Promise<any> {
    return this.axios.get(`${this.entity}/${id}/payment-headers`).then(res => res.data);
  }

  static updatePayment(paymentId: string, data: any): Promise<any> {
    return this.axios.put(`iam-purchase/payments/${paymentId}/update`, data).then(res => res.data);
  }

  static paymentDetail(paymentId: string): Promise<any> {
    return this.axios.get(`iam-purchase/payments/${paymentId}/detail`).then(res => res.data);
  }

  static createPayment(data: any): Promise<any> {
    return this.axios.post(`iam-purchase/payments/create`, data).then(res => res.data);
  }

  static uncompletedPayments(orderId: string, vendorPartyId: string): Promise<any> {
    return this.axios.get(`/iam-purchase/orders/uncompleted-payments?orderId=${orderId}&vendorPartyId=${vendorPartyId}`).then(res => res.data);
  }

  static updateInvoice(id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/invoices`, data).then(res => res.data);
  }

  //sale invoice gdt
  static getInvoicesGdt(type: string): Promise<any> {
    return this.axios.get(`/iam-integrate/gdt/unlinked-invoices?type=${type}`).then(res => res.data);
  }

  static getInvoicesGdtInfo(invoiceId: string): Promise<any> {
    return this.axios.get(`/iam-integrate/gdt/invoices/${invoiceId}/mapping-info`).then(res => res.data);
  }

  static createGdt(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/create-from-invoice-gdt`, data).then(res => res.data);
  }
  static getPaymentList(lazyLoadEvent: any): Promise<any> {
    return this.axios.get(`iam-sale/payments/list?type=outgoing &lazyLoadEvent=${lazyLoadEvent}`).then(res => res.data);
  }
}
