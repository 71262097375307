import {EntityService} from 'src/service/EntityService';

export class TaxPaymentService extends EntityService {

  static entity = '/iam-tax/payments';

  static getTaxList(fromDate: string, thruDate: string): Promise<any> {
    return this.axios.get(`${this.entity}?fromDate=${fromDate}&thruDate=${thruDate}`).then(res => res.data);
  }
  static getTaxDetail(productCategoryId:string, fromDate: string, thruDate: string): Promise<any> {
    return this.axios.get(`${this.entity}/categories/${productCategoryId}?fromDate=${fromDate}&thruDate=${thruDate}`, ).then(res => res.data);
  }
  static delivery(paymentId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${paymentId}/delivery`).then(res => res.data);
  }
  static unDelivery(paymentId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${paymentId}/un-delivery`).then(res => res.data);
  }
  static deletePayment(paymentId: string): Promise<any> {
    return this.axios.delete(`${this.entity}/${paymentId}/cancel`).then(res => res.data);
  }

}
