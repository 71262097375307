import {Fragment, useState} from 'react';

import {Button} from 'primereact/button';
import {useDataTable, useForm} from '@iamsoftware/react-hooks';

import {ReportService as Service} from '../ReportService';

export const CustomerSummary = () => {

  const [currentLazyLoadEvent, setCurrentLazyLoadEvent] = useState(null);

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    indexColumnWidth: 45,
    columns: [
      {field: 'agreementDate', header: 'Ngày HĐ', width: 120, dataType: 'date'},
      {field: 'agreementNo', header: 'Số hợp đồng', minWidth: 180, matchMode: 'contains'},
      {field: 'partyName', header: 'Khách hàng', width: 170, matchMode: 'contains'},
      {field: 'partyTaxId', header: 'Mã số thuế', width: 145, matchMode: 'contains'},
      {field: 'address1', header: 'Địa chỉ', minWidth: 150, matchMode: 'contains'},
      {field: 'pseudoId', header: 'Mã gói dịch vụ', width: 170, matchMode: 'contains'},
      {field: 'itemDescription', header: 'SL đăng ký', width: 170, matchMode: 'contains'},
      {field: 'status', header: 'Trạng thái', width: 150, matchMode: 'contains'}
    ],
    getList: lazyLoadEvent => {
      setCurrentLazyLoadEvent(lazyLoadEvent);
      return new Promise<any>(resolve => {
        resolve(getFilters());
      }).then(params => Service.getCustomerSummary(lazyLoadEvent, params));
    }
  });

  const getFilters = () => {
    const params: any = {};
    const {agreementDate} = getValue();
    if (agreementDate?.length === 2) {
      params.fromDate = new Date(agreementDate[0]).getTime();
      params.thruDate = new Date(agreementDate[1]).getTime();
    }
    return params;
  }

  const exportExcel = () => {
    const params = getFilters();
    params.lazyLoadEvent = currentLazyLoadEvent;

    window.open(`${Service.baseURL}/${Service.entity}/customer-summary/excel?${new URLSearchParams(params).toString()}`);
  }

  const {render: renderForm, getValue} = useForm({
    fields: [
      {field: 'agreementDate', header: 'Ngày HĐ', type: 'Period', className: 'md:col-3'},
      {
        field: 'search', type: 'custom',
        body: <Fragment>
          <Button icon="pi pi-search" label="Tìm kiếm" className="p-button-info p-button-sm mr-2" onClick={reloadLazyData}/>
          <Button icon="pi pi-file-excel" label="Xuất Excel" className="p-button-success p-button-sm" onClick={exportExcel}/>
        </Fragment>,
        className: 'md:col-6 un-fluid pt-5'
      }
    ]
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderForm()}
      </div>
      <div className="col-12">
        {renderDataTable()}
      </div>
    </div>
  );

}