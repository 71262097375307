import {EntityService} from '../../../../service/EntityService';

export class PaymentsService extends EntityService {

  static entity = 'iam-manager/payments';

  static paid(orderId: string, effectiveDate: any): Promise<any> {
    return this.axios.put(`${this.entity}/${orderId}/paid`, {effectiveDate}).then(res => res.data);
  }

  static unpaid(orderId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${orderId}/unpaid`).then(res => res.data);
  }

}
