import {Route, Routes} from 'react-router-dom';
import {Request} from './yeu-cau-dong-bo';
import {IncomingInvoices} from './hoa-don-mua-vao';
import {OutgoingInvoices} from './hoa-don-ban-ra';

export default function CQT() {

  return (
    <Routes>
      <Route path='yeu-cau-dong-bo' element={<Request/>}/>
      <Route path='hoa-don-mua-vao' element={<IncomingInvoices/>}/>
      <Route path='hoa-don-ban-ra' element={<OutgoingInvoices/>}/>
    </Routes>
  );
}
