import {Route, Routes} from 'react-router-dom';

import {List} from './list';
import {Payments} from './payments';
import {Requests} from './requests';

export default function Customer({me}) {

  return (
    <Routes>
      <Route path="list" element={<List/>}/>
      <Route path="payments" element={<Payments/>}/>
      <Route path="requests" element={<Requests me={me}/>}/>
      <Route path="requests/create" element={<Requests me={me} beCreate={true}/>}/>
    </Routes>
  );
}
