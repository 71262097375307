import {Fragment, useEffect, useState} from 'react';
import {Button} from 'primereact/button';
import {Calendar} from 'primereact/calendar';
import moment from 'moment/moment';
import {FormatDisplay, useDataTable, useForm} from '@iamsoftware/react-hooks';
import {OrganizationTaxService as Service} from './OrganizationTaxService';
import {OrganizationDetail} from './OrganizationDetail'

export const HoKinhDoanh = () => {
  const header = 'Thuế HKD, CNKD';
  const [headerResult, setHeaderResult] = useState(null);
  const [data, setData] = useState([])
  const [total, setTotal] = useState(null)
  const [displayDetail, setDisplayDetail] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [thruDate, setThruDate] = useState(null)


  useEffect(() => {
    setHeaderResult(tabHeader)
    Service.getTaxList('', '').then(data => {
      setData(data.listData)
      setTotal(data.summaryData)
    })
  }, []);
  const doClear = () => {
    setFromDate(null)
    setThruDate(null)
  }
  const getData = () => {
    setData([])
    if (fromDate && thruDate) {
      Service.getTaxList(
        (new Date(new Date((fromDate)).toLocaleDateString()).setHours(0, 0, 0, 0)),
        (new Date(new Date((thruDate)).toLocaleDateString()).setHours(23, 59, 59, 999))).then(data => {
        setData(data.listData)
        setTotal(data.summaryData)
      })
    } else {
      if (fromDate && !thruDate) {
        Service.getTaxList((new Date(new Date((fromDate)).toLocaleDateString()).setHours(0, 0, 0, 0)), '').then(data => {
          setData(data.listData)
          setTotal(data.summaryData)
        })
      } else if (!fromDate && thruDate) {
        Service.getTaxList('', (new Date(new Date((thruDate)).toLocaleDateString()).setHours(23, 59, 59, 999))).then(data => {
          setData(data.listData)
          setTotal(data.summaryData)
        })
      } else {
        Service.getTaxList('', '').then(data => {
          setData(data.listData)
          setTotal(data.summaryData)
        })
      }
    }

  }
  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <span>{header}</span>
        {/*<Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"*/}
        {/*        size="small" onClick={doCreate}/>*/}
      </div>
    </div>
  );
  return (
    <div className="card">
      <div className="flex flex-row flex-wrap">
        {/*{searchForm.render()}*/}
        <div className="col-6 flex flex-wrap gap-3 p-fluid">
          <div className="flex-auto">
            <label htmlFor="calendar-1" className="font-bold block mb-2">
              Từ ngày
            </label>
            <Calendar id="calendar-1" value={fromDate} onChange={(e) => setFromDate(e.value)} dateFormat="dd/mm/yy"/>
          </div>
          <div className="flex-auto">
            <label htmlFor="calendar-2" className="font-bold block mb-2">
              Đến ngày
            </label>
            <Calendar id="calendar-2" value={thruDate} onChange={(e) => setThruDate(e.value)} dateFormat="dd/mm/yy"/>
          </div>
        </div>
        <Button style={{marginTop: '35px'}} icon="pi pi-search" rounded outlined aria-label="Search" tooltip={'Tìm kiếm'} onClick={() => getData()}/>

        <Button icon="pi pi-times" size="small" rounded outlined severity="secondary" style={{marginLeft: '10px', marginTop: '35px'}} onClick={doClear}/>
      </div>
      <div className="p-datatable p-component p-datatable-striped p-datatable-gridlines p-datatable-sm">
        <div className="p-datatable-header">
          <div className="table-header">Thuế giá trị gia tăng (GTGT), Thuế thu nhập cá nhân (TNCN)</div>
        </div>
        <div className="p-datatable-wrapper">
          <table className="p-datatable-table">
            <thead className="p-datatable-thead">
            <tr>
              <th rowSpan={2} style={{width: 50, textAlign: 'center'}}>STT</th>
              <th rowSpan={2} style={{width: 200, textAlign: 'center'}}>Nhóm ngành nghề</th>
              <th rowSpan={2} style={{width: 100, textAlign: 'center'}}>Doanh thu</th>
              <th colSpan={2} style={{width: 100, textAlign: 'center'}}>Thuế GTGT</th>
              <th colSpan={2} style={{width: 100, textAlign: 'center'}}>Thuế TNCN</th>
              <th rowSpan={2} style={{width: 50, textAlign: 'center'}}></th>
            </tr>
            <tr>
              <th style={{width: 100}}>Thuế suất</th>
              <th style={{width: 130}}>Số tiền</th>
              <th style={{width: 100}}>Thuế suất</th>
              <th style={{width: 130}}>Số tiền</th>
            </tr>
            </thead>
            <tbody className="p-datatable-tbody">
            {data?.map(dataItem => {
              return (<Fragment>
                  {dataItem?.items.map((item, index) => {
                    if (index === 0) {
                      return (<tr>
                        <td rowSpan={dataItem?.items.length} style={{textAlign: 'center'}}>{dataItem.sequenceNum}</td>
                        <td rowSpan={dataItem?.items.length} style={{textAlign: 'center'}}>{dataItem.description}</td>
                        <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.revenueAmount)} </td>
                        <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.vatRate)}%</td>
                        <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.vatAmount)}</td>
                        <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.pitRate)}%</td>
                        <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.pitAmount)}</td>
                        <td rowSpan={dataItem?.items.length} className={'table-actions'} role='cell' data-pc-section="root" data-pc-name="column">
                          <button className="p-button p-component p-button-info p-button-icon-only p-button-outlined p-button-rounded p-button-sm" title="Xem chi tiết" data-pc-name="button" data-pc-section="root" onClick={() => setDisplayDetail(dataItem)}><span className="p-button-icon p-c pi pi-eye" data-pc-section="icon"></span><span className="p-button-label p-c">&nbsp;</span></button>
                        </td>
                        {/*<td rowSpan={dataItem?.items.length} style={{textAlign: 'center'}}><i className="pi pi-eye" onClick={() => setDisplayDetail(dataItem)}></i></td>*/}
                      </tr>)
                    } else return (
                      <tr>
                        <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.revenueAmount)} </td>
                        <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.vatRate)}%</td>
                        <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.vatAmount)}</td>
                        <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.pitRate)}%</td>
                        <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.pitAmount)}</td>
                      </tr>
                    )
                  })}
                  {data.length === 0 && <tr>
										<td colSpan={8}>Không có dữ liệu</td>
									</tr>}
                </Fragment>
              )
            })}
            <tr style={{background: "#F8F9FA"}}>
              <td colSpan={2} style={{textAlign: 'right'}}><b>Tổng cộng</b></td>
              <td colSpan={1} style={{textAlign: 'right'}}><b>{FormatDisplay.number(total?.revenueAmount)}</b></td>
              <td colSpan={1} style={{textAlign: 'center'}}><b></b></td>
              <td colSpan={1} style={{textAlign: 'right'}}><b>{FormatDisplay.number(total?.vatAmount)}</b></td>
              <td colSpan={1} style={{textAlign: 'center'}}><b></b></td>
              <td colSpan={1} style={{textAlign: 'right'}}><b>{FormatDisplay.number(total?.pitAmount)}</b></td>
              <td colSpan={1} style={{textAlign: 'center'}}><b></b></td>

            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <OrganizationDetail display={displayDetail} setDisplay={setDisplayDetail} fromDate={fromDate} thruDate={thruDate}></OrganizationDetail>
    </div>
  );
}