import {Fragment, useEffect, useRef, useState} from 'react';

import { Dialog } from 'primereact/dialog';
import {Button} from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDataTableBasic, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';
import {ShopeeConnectionServices as Service} from './ShopeeConnectionServices';

export default function ShopeeConnection({code,shopId}) {

  const header = 'Danh sách sàn Shopee đã kết nối'
  const dataKey = 'productStoreId';

  const headerTable = (
    <div className="flex justify-content-between align-items-center">
      <span>{header}</span>
      <Button icon="pi pi-plus" size="small" severity="success" className="mr-4 mb-1 h-2rem" onClick={() => doCreate()} label="Thêm"></Button>
    </div>
  )

  const {render: renderDataTable,reloadLazyData} = useDataTable({
    tableHeader: headerTable,
    dataKey,
    stateKey:'mainTable',
    indexColumnWidth:45,
    columns: [
      {field:'externalCode', header:'Mã gian hàng', matchMode:'contains', minWidth:150},
      {field:'storeName', header:'Tên gian hàng', matchMode:'contains', minWidth:250},
      {field:'ownerName', header:'Chủ sở hữu', matchMode:'contains', minWidth:200},
      {field:'defaultLocation', header:'Địa điểm', matchMode:'contains', minWidth:200},
      {field:'defaultEmailAddress', header:'Email', matchMode:'contains', minWidth:200},
      {field:'tokenValidDate', header:'Ngày kết nối', matchMode:'contains', minWidth:175, dataType:'date-time'},
      {field:'refreshTokenExpire', header:'Ngày hết hạn', matchMode:'contains', minWidth:175, dataType:'date-time'},
      {field:'status', header:'Trạng thái', matchMode:'contains', minWidth:150},
    ],
    getList(lazyLoadEvent: any): Promise<any> {
      return Service.getList(lazyLoadEvent)
    },
    actionColumnWidth:90,
    initActions(items: Array<any>) {
      if (items){
        items.forEach(item =>{
          item.actions = [
          ]
          if (item.statusId === 'EecConnected'){
            item.actions.push({icon: 'pi icons8-disconnected', title: 'Ngắt kết nối', className: 'p-button-secondary', command: () => doDisconnect(item)},)
          }
        })
      }
    }
  })

  const doDisconnect = (item) => {
    if (item.productStoreId){
      ConfirmDialogService.confirm('Ngắt kết nối', 'Bạn xác nhận muốn ngắt kết nối?', ()=>{
        Service.disconnect(item.productStoreId).then(()=>{
          ToastService.success();
          reloadLazyData()
        })
      })
    }
  }


  const doCreate = () =>{
    ConfirmDialogService.confirm('Tạo mới kết nối sàn','Bạn xác nhận muốn thêm kết nối mới',()=>{
      Service.getLink().then(data =>{
        window.open(`${data.link}`)
      })
    })
  }

  //Tạo kết nối với sàn lazada
  useEffect(() => {
    if (code && shopId){
      document.getElementById("overlay").style.display ="block";
      setTimeout(()=>{
        Service.createCon({"code": code, "shopId": shopId}).then(()=>{
          ToastService.success()
          reloadLazyData();
        }).finally(()=>{
          const origin = `${window.location.origin}${window.location.pathname}${window.location.hash}`
          document.getElementById("overlay").style.display ="none";
          window.history.replaceState(null,null,origin)
        })
      },3000)
    }
  }, [code,shopId]);

  return (
    <div className="grid">
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12.5rem)'}}>
        {renderDataTable()}
      </div>
      <div id="overlay">
        <div className="loader"></div>
      </div>
    </div>
  )
}