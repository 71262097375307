import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDialogCrup, useForm, useToolbar, validateTaxCode} from '@iamsoftware/react-hooks';
import {PayrollAdjustmentService as Service} from './PayrollAdjustmentService';
import {Fragment, useEffect, useState} from 'react';
import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {CommonService} from '../../../service/CommonService';
import {useCrupSequenceNum} from './crupSequenceNum';

const statuses = [
  {value: 'N', label: 'Đang hoạt động'},
  {value: 'Y', label: 'Đã khóa'}
];

export default function ThamSo() {

  const header = 'Tham số';
  const dataKey = 'payrollAdjustmentId';
  const [disabled, setDisabled] = useState('N');
  const [rateBasisEnumIds, setRateBasisEnumIds] = useState([]);
  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <span>{header}</span>
        <div><Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"
                     size="small" onClick={() => create()}/>
          <Button label="Cập nhật thứ tự" icon="pi pi-pencil" severity="warning" className="mr-4 mb-1 w-12rem h-2rem"
                  size="small" onClick={() => doUpdateSequence()}/></div>
      </div>
    </div>
  );
  useEffect(() => {
    CommonService.getPayrollRateType().then(data => {
      setRateBasisEnumIds(data.listData.map(item => {
          return {value: item.enumId, label: item.description}
        }
      ))
    })
  }, [])

  const {render: renderDataTable, reloadLazyData, refreshLazyData} = useDataTable({
    stateKey: 'mainTable',
    tableHeader: tabHeader,
    dataKey,
    columns: [
      {field: 'description', header: 'Tên tham số', width: 250, matchMode: 'contains'},
      {field: 'rateBasisEnum', header: 'Tính chất', width: 250, matchMode: 'contains'},
      {field: 'classTypeEnum', header: 'Loại tham số', width: 250, matchMode: 'contains'},
      {field: 'thruDate', header: 'Ngày khóa', width: 250, matchMode: 'contains', hidden: disabled === 'N'},
      {field: 'sequenceNum', header: 'Thứ tự hiển thị', width: 150, matchMode: 'contains'},
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 80,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent, disabled).then(data => {
          return data
        }
      );
    },
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = []
          if (item.classTypeEnumId !== "PccSystemConfig") {
            item.actions.push({icon: 'pi pi-pencil', title: 'Cập nhật', className: 'p-button-warning', command: () => update(item)},
            )
          }
          if (disabled === 'N') {
            item.actions.push({icon: 'pi pi-lock', title: 'Khóa', className: 'p-button-danger', command: () => doDisable(item)},)
          } else {
            item.actions.push({icon: 'pi pi-unlock', title: 'Mở khóa', className: 'p-button-success', command: () => doEnable(item)},)
          }
        })
      }
    },
  });
  const {renderSequenceData, doUpdateSequence} = useCrupSequenceNum({reloadLazyData,disabled})

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '40rem',
    fields: [
      {field: 'description', header: 'Tên tham số', className: 'md:col-12'},
      {field: 'rateBasisEnumId', header: 'Tính chất', required: true, type: 'Dropdown', DropdownProps: {options: rateBasisEnumIds, filter: true}, className: 'md:col-12'},
      {field: 'sequenceNum', header: 'Thứ tự', InputTextProps: {maxLength: 10, keyfilter: 'int'}, className: 'md:col-12', display: 'Create'},
    ],
    createItem: item => {
      return Service.create(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });

  const clearAndSwitch = (data) => {
    setDisabled(data);
    refreshLazyData();
  };

  const doDisable = (data) => {
    ConfirmDialogService.confirm('Khóa tham số', `Xác nhận khóa ${data.description} ?`, () => {
      Service.disable(data[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });

    })
  }
  const doEnable = (data) => {
    ConfirmDialogService.confirm('Mở khóa tham số', `Xác nhận mở khóa ${data.description} ?`, () => {
      Service.enable(data[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    })
  }
  return (
    <div className="grid">
      <div className="col-12 flex">
        <SelectButton value={disabled} options={statuses} onChange={e => clearAndSwitch(e.value)}/>
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 15rem)'}}>
        {renderDataTable()}
        {renderDialogCrup()}
        {renderSequenceData()}
      </div>
    </div>
  );
}
