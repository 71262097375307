import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';

import {FormatDisplay, ToastService, useForm} from '@iamsoftware/react-hooks';

import {SalesOrdersService as Service} from './ordersService'

export const useCrupComplete = ({reloadLazyData}) => {
  const [display, setDisplay] = useState(null);
  const [orderId, setOrderId] = useState(null);

  const infoForm = useForm({
    fields: [
      {field: 'completedDate', header: 'Ngày ghi sổ', className: 'md:col-12', InputTextProps: {type: 'date'}, required: true},
    ],

  });

  const infoData = infoForm.getValue();

  const doUpdate = async (data: any) => {
    setOrderId(data.orderId)
    infoData.completedDate = FormatDisplay.date(new Date(data.placedDate), 'YYYY-MM-DD')
    infoForm.setValue(infoData)
    setDisplay(Date.now());
  }
  const onSubmit = () => {
    if (infoForm.valid()) {
      const data = Object.assign(infoData);
      Service.complete(orderId, data).then(() => {
        ToastService.success();
        reloadLazyData();
        setDisplay(null)
      })

    }
  }

  const footer = (
    <div className="flex flex-row-reverse flex-wrap">
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
      {<Button label="Lưu" icon="pi pi-check" size="small" severity="success" onClick={() => onSubmit()}/>}
    </div>
  );

  const header = () => {
    return (
      <div className=" flex jjustify-content-between flex-wrap">
        <div className="mr-8">
          <h4> Ghi sổ</h4>
        </div>
      </div>
    )

  }

  const render = () => {
    return (
      <Fragment>
        <Dialog header={header} footer={footer} visible={!!display} onHide={() => setDisplay(null)} style={{width: "400px"}}>
          <div className="grid">
            <div className="col-12">
              {infoForm.render()}
            </div>
          </div>
        </Dialog>
      </Fragment>
    );
  }

  return {renderComplete: render, doComplete: doUpdate};
}
