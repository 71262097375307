import {Fragment} from 'react';

import {Button} from 'primereact/button';

import {ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {PaymentsService as Service} from './PaymentsService';

export const Payments = () => {

  const dataKey = 'orderId';

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    dataKey,
    columns: [
      {field: dataKey, header: 'Mã', width: 110, matchMode: 'contains'},
      {field: 'requestTypeEnum', header: 'Loại yêu cầu', width: 150, matchMode: 'contains'},
      {field: 'requestDate', header: 'Ngày yêu cầu', width: 165, dataType: 'date-time'},
      {field: 'customerName', header: 'Tên khách hàng', minWidth: 150, matchMode: 'contains'},
      {field: 'partTotal', header: 'Tổng tiền', width: 170, matchMode: 'numeric' as any, dataType: 'number'},
      {field: 'isPaid', header: 'Đã thanh toán', width: 150, matchMode: 'equals', filterType: 'boolean-char', dataType: 'boolean'},
      {field: 'paidDate', header: 'Ngày thanh toán', width: 165, dataType: 'date-time'},
      {field: 'vendorPartyName', header: 'Thuộc đại lý', width: 150, matchMode: 'contains'}
    ],
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    }
  });

  const {render: renderDialogCrup, update: paid} = useDialogCrup({
    header: '!Thanh toán',
    dataKey,
    width: '40rem',
    fields: [
      {field: 'effectiveDate', header: 'Ngày thanh toán', type: 'Calendar', className: 'md:col-12'}
    ],
    updateItem: (id, item) => {
      return Service.paid(id, item.effectiveDate);
    },
    reloadLazyData
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doPaid = () => {
    if (selectedItem && selectedItem[dataKey]) {
      selectedItem.effectiveDate = new Date();
      paid(selectedItem);
    }
  }

  const doUnpaid = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.unpaid(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }

  const {renderToolbar} = useToolbar({
    leftButtons: <Fragment>
      <Button label="Thanh toán" icon="pi pi-check" className="p-button-success mr-2" onClick={doPaid} disabled={!selectedItem || selectedItem?.isPaid === 'Y'}/>
      <Button label="Huỷ thanh toán" icon="pi pi-times" className="p-button-warning" onClick={doUnpaid} disabled={!selectedItem || selectedItem?.isPaid === 'N'}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12">
        {renderDataTable()}
      </div>

      {renderDialogCrup()}
    </div>
  );
}