import {EntityService} from '../../../service/EntityService';

export class AccountInfoService extends EntityService {

  static entity = '/iam-party/organization-info';

  static getInfo(): Promise<any> {
    return this.axios.get(`${this.entity}`).then(res => res.data);
  }

  static updateInfo(data): Promise<any> {
    return this.axios.put(`${this.entity}`, data).then(res => res.data);
  }
}