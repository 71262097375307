import {Fragment, useEffect, useState} from 'react';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {RadioButton} from 'primereact/radiobutton';
import {Fieldset} from 'primereact/fieldset';
import {InputText} from 'primereact/inputtext';

import {ConfirmDialogService, FormatDisplay, ToastService, useDataTableBasic, useDialogCrup, useForm, useToolbar} from '@iamsoftware/react-hooks';
import {OutgoingPaymentsService as Service} from './OutgoingPaymentsService';
import {CommonService} from '../../../service/CommonService';
import {SuppliersService} from '../../mua-hang/nha-cung-cap/SuppliersService';
import {EmployeesService} from '../../quan-ly/nhan-vien/EmployeesService';
import {PurchaseOrderService} from '../../mua-hang/don-hang/OrdersService';
import {useCrupPO} from '../../mua-hang/don-hang/CrupPO';
import {useCrupPayment} from '../../thue/phieu-chi/CrupPayment';
import {useCrupPurchaseOrderPayment} from '../../mua-hang/thanh-toan/crupPayment';
import {useCrupPayment as luongCrupPayment} from '../../luong/phieu-chi/CrupPayment';
import {ObjectType} from './objectType';
import {BankAccountService} from '../../danh-muc/tai-khoan-ngan-hang/BankAccountService';
import {useCrupItems} from './CrupItems';


export const useCrupOutGoingPayment = ({reloadLazyData}) => {
  const header = 'Phiếu chi';
  const dataKey = 'paymentId';

  const [displayDia, setDisplayDia] = useState(null);
  const [displayObj, setDisplayObj] = useState(null);
  const [selectedPartyId, setSelectedPartyId] = useState(null);
  const [dirty, setDirty] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [readOnly, setReadOnly] = useState(false);
  const [enteredParties, setEnteredParties] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [supplierBank, setSupplierBank] = useState([]);
  const [hkdBank, setHkdBank] = useState([]);
  const [objBySearch, setObjBySearch] = useState([]);
  const [paymentRefNum, setPaymentRefNum] = useState(null);
  const [expenseTypes, setExpenseTypes] = useState(null);
  const [statusId, setStatusId] = useState('PmntDelivered');
  const {renderProducts, items, setItems, deleteInvoiceItemSeqIds, setDeleteInvoiceItemSeqIds} = useCrupItems({displayDia, disabled: readOnly});

  useEffect(() => {
    Service.outgoingPaymentInstrumentType().then(data => {
      setPaymentTypes(data.listData.map(item => {
        return {value: item.enumId, label: item.description};
      }))
    });
    CommonService.otherOutgoingPaymentTypes().then(data => {
      setExpenseTypes(data.listData.map(item => {
        return {value: item.enumId, label: item.description}
      }))
    })
  }, []);

  useEffect(() => {
    const _event: any = {
      filters: {}
    };
    BankAccountService.getList(JSON.stringify(_event)).then(data => {
      setHkdBank(data.listData.map(item => {
        return {value: item.paymentMethodId, label: `[${item.bankAccount}] - ${item.bankName}`};
      }))
    })
  }, []);

  const searchEmployees = event => {
    EmployeesService.find(event.query).then(data => {
      setEnteredParties(data.listData.map(item => {
        delete item.disabled;
        let label = `[${item.pseudoId}] - ${item.partyName}`;
        return {...item, value: item.partyId, label: label};
      }));
    });
  }
  const commonInfoForm = useForm({
    fields: [
      {
        field: 'paymentTypeEnumId', header: 'Loại chứng từ', className: 'md:col-4', type: 'Dropdown', DropdownProps: {options: expenseTypes}, required: true
      },
      {
        field: 'status', header: '', className: 'md:col-8', type: 'custom',
        body: <Fragment>
          <div className="flex flex-wrap gap-3 radio-config-mt">
            <div className="flex align-items-center">
              <RadioButton inputId="status1" name="status" value="PmntDelivered" disabled={readOnly} onChange={(e) => setStatusId(e.value)} checked={statusId === 'PmntDelivered'}/>
              <label htmlFor="status1" className="ml-2">Đã thanh toán</label>
            </div>
            <div className="flex align-items-center">
              <RadioButton inputId="status2" name="status" value="PmntAuthorized" disabled={readOnly} onChange={(e) => setStatusId(e.value)} checked={statusId === 'PmntAuthorized'}/>
              <label htmlFor="status2" className="ml-2">Chưa thanh toán</label>
            </div>
          </div>
        </Fragment>
      },
      {
        field: 'party', header: 'Mã đối tượng', required: true, className: 'md:col-4', type: 'custom',
        body: <Fragment>
          <label>Mã đối tượng <span style={{color: 'red'}}>*</span></label>
          <div className="p-inputgroup flex-1">
            <Dropdown value={selectedPartyId} onChange={(e) => doChangeParty(e.value)} options={suppliers} optionLabel="label" placeholder="Chọn đối tượng" filter={true} disabled={readOnly}></Dropdown>
            <Button onClick={() => setDisplayObj(true)} icon="pi pi-search" severity="info" aria-label="Search" disabled={readOnly}></Button>
          </div>
        </Fragment>
      },
      {
        field: 'partyName', header: 'Tên đối tượng', className: 'md:col-8'
      },
      {
        field: 'paymentInstrumentEnumId', header: 'Hình thức thanh toán', required: true, className: 'md:col-4', type: 'Dropdown', DropdownProps: {options: paymentTypes}
      },
      {
        field: 'amount', header: 'Số tiền', className: 'md:col-4', type: 'InputNumber', disabled: true, InputNumberProps: {min: 0}, displayDependency(item: any): boolean {
          return item?.paymentInstrumentEnumId === 'PiCash';
        }
      },
      {
        field: 'toName', header: 'Người nhận', className: 'md:col-4', displayDependency(item: any): boolean {
          return item?.paymentInstrumentEnumId === 'PiCash';
        }
      },
      {
        field: 'paymentMethodId', header: 'Tài khoản chuyển', required: true, className: 'md:col-4', displayDependency(item: any): boolean {
          return ['PiOutPaymentOrder'].includes(item?.paymentInstrumentEnumId)
        }, type: 'Dropdown', DropdownProps: {options: hkdBank}
      },
      {
        field: 'toPaymentMethodId', header: 'Tài khoản nhận', className: 'md:col-4', displayDependency(item: any): boolean {
          return ['PiOutPaymentOrder'].includes(item?.paymentInstrumentEnumId)
        }, type: 'Dropdown', DropdownProps: {options: supplierBank}
      },
      {
        field: 'amount', header: 'Số tiền', className: 'md:col-4', disabled: true, type: 'InputNumber', InputNumberProps: {min: 0}, displayDependency(item: any): boolean {
          return item?.paymentInstrumentEnumId !== 'PiCash';
        }
      },
      {
        field: 'address1', header: 'Địa chỉ', className: 'md:col-8', displayDependency(item: any): boolean {
          return item?.paymentInstrumentEnumId !== 'PiCash';
        }
      },
      {
        field: 'address1', header: 'Địa chỉ', className: 'md:col-12', displayDependency(item: any): boolean {
          return item?.paymentInstrumentEnumId === 'PiCash';
        }
      },
      {
        field: 'enteredByParty', header: 'Nhân viên thực hiện', className: 'md:col-4', type: 'AutoComplete',
        AutoCompleteProps: {suggestions: enteredParties, completeMethod: searchEmployees, field: 'label', dropdown: true, forceSelection: true},
        disabled: 'View'
      },
      {field: 'comments', header: 'Kèm theo', className: 'md:col-8'},
      {field: 'memo', header: 'Ghi chú diễn giải', className: 'md:col-12'},
    ],
    readOnly: readOnly
  })

  const certInfoForm = useForm({
    fields: [
      {
        field: 'paymentRefNum', header: 'Số chứng từ', required: true, className: 'md:col-12', type: 'custom',
        body: <Fragment>
          <label>Số chứng từ <span style={{color: 'red'}}>*</span></label>
          <div className="p-inputgroup flex-1">
            <InputText id="paymentRefNum" value={paymentRefNum} onChange={e => setPaymentRefNum(e.target.value)}/>
            <Button onClick={() => doRefreshRefNum()} icon="pi pi-refresh" severity="info" aria-label="Reload" disabled={readOnly}></Button>
          </div>
        </Fragment>
      },
      {field: 'effectiveDate', header: 'Ngày chứng từ', InputTextProps: {type: 'date'}, className: 'md:col-12'},
    ],
    readOnly: readOnly
  })

  const {render: renderDialogCrup, create, update, view, display, formMethod} = useDialogCrup({
    header,
    dataKey,
    width: '120rem',
    fields: [
      {
        type: 'custom', className: 'md:col-8', body: <Fieldset legend="Thông tin chung">
          {commonInfoForm.render()}
        </Fieldset>
      },
      {
        type: 'custom', className: 'md:col-4', body: <Fieldset legend="Chứng từ">
          {certInfoForm.render()}
        </Fieldset>
      },
      {
        type: 'custom', className: 'md:col-12', body: <Fieldset legend="Hạch toán">
          {renderProducts()}
        </Fieldset>
      },
    ],
    createItem: async item => {
      if (!selectedPartyId || !paymentRefNum){
        if (!selectedPartyId){
          ToastService.error('Mã đối tượng không được rỗng')
        }
        if (!paymentRefNum){
          ToastService.error('Số chứng từ không được rỗng')
        }
        return Promise.reject();
      }else {
        return Service.create(await inject());
      }
    },
    updateItem: async (id, item) => {
      if (!selectedPartyId || !paymentRefNum){
        if (!selectedPartyId){
          ToastService.error('Mã đối tượng không được rỗng')
        }
        if (!paymentRefNum){
          ToastService.error('Số chứng từ không được rỗng')
        }
        return Promise.reject();
      }else {
        return Service.update(id, await inject());
      }
    },
    reloadLazyData
  });

  useEffect(() => {
    setDisplayDia(display)
  }, [display]);
  const {paymentInstrumentEnumId} = commonInfoForm.getRawValue();

  //Thay doi ma chung tu khi doi hinh thuc thanh toan
  useEffect(() => {
    if (paymentInstrumentEnumId && commonInfoForm.dirty['paymentInstrumentEnumId']) {
      Service.genPaymentPseudoId(paymentInstrumentEnumId).then(data => {
        setPaymentRefNum(data.code)
      })
    }
  }, [paymentInstrumentEnumId]);

  useEffect(() => {
    CommonService.getParties().then(data => {
      setSuppliers(data.listData.map(item => {
        return {value: item.partyId, label: `[${item.pseudoId}] - ${item.partyName}`}
      }))
    })
  }, [display]);
  //render ra thong tin khi thay doi doi tuong
  useEffect(() => {
    if (selectedPartyId && dirty) {
      const _value = commonInfoForm.getRawValue();
      SuppliersService.get(selectedPartyId).then(data => {
        _value.address1 = data?.address1;
        _value.memo = `Chi trả cho ${data?.partyName}`;
        _value.partyName = data?.partyName;
        if (data?.paymentMethodId) {
          setSupplierBank([{value: data.paymentMethodId, label: `[${data.accountNumber}] - ${data.bankName}`}])
        } else {
          setSupplierBank([]);
        }
        commonInfoForm.setValue(_value);
      })
    }
  }, [selectedPartyId]); // eslint-disable-line react-hooks/exhaustive-deps

  //tinh tong tien
  useEffect(() => {
    const _formValue = commonInfoForm.getRawValue()
    let amountTotal = 0
    if (items) {
      items.forEach(item => {
        if (item.amount) {
          amountTotal += item.amount
        }
      })
      _formValue.amount = amountTotal
      commonInfoForm.setValue(_formValue)
    }
  }, [items]);

  const inject = () => {
    const item = commonInfoForm.getValue();
    item.toPartyId = selectedPartyId;
    item.enteredByPartyId = item.enteredByParty?.value;
    // if (item.toPartyId) {
    //   CustomersService.get(item.toPartyId).then(data => {
    //     item.postalContactMechId = data.postalContactMechId;
    //   })
    // }
    if (deleteInvoiceItemSeqIds?.length) {
      item.deleteInvoiceItemSeqIds = deleteInvoiceItemSeqIds;
    }
    item.paymentRefNum = paymentRefNum;
    item.effectiveDate = certInfoForm.getValue().effectiveDate;
    item.paymentStatusId = statusId
    item.items = items;
    return item;
  }

  const doCreate = () => {
    setSelectedPartyId(null)
    setStatusId('PmntDelivered')
    setDirty(false)
    setSuppliers([]);
    setObjBySearch([]);
    commonInfoForm.reset();
    certInfoForm.reset();
    Service.genPaymentPseudoId('PiCash').then(data => {
      setPaymentRefNum(data.code);
      setTimeout(()=>{
        certInfoForm.setValue({effectiveDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD')});
        commonInfoForm.setValue({paymentInstrumentEnumId: 'PiCash', paymentTypeEnumId: expenseTypes[0]?.value, amount: 0});
      },100)
    })
    setItems([]);
    setReadOnly(false);
    create();
  }

  const doUpdate = (input, doView?: boolean) => {
    setDirty(false)
    setSuppliers([]);
    setObjBySearch([]);
    if (input.paymentTypeEnumId === 'PtOtherIssue') {
      Service.get(input.paymentId).then(async data => {
        if (data.orderId !== undefined) {
          doSwitch(data.orderId, false)
        } else if (data.paymentTypeEnumTypeId === 'TaxPaymentType') {
          doSwitchPaymentGDT(data, doView)
        } else if (data.paymentTypeEnumTypeId === 'PayrollPaymentType') {
          doSwitchPaymentLuong(data, doView)
        } else {
          if (data.toPartyId) {
            setSuppliers([{value: data.toPartyId, label: `[${data.toPseudoId}] - ${data.toPartyName}`}])
            setSelectedPartyId(data.toPartyId);
            const item = await SuppliersService.get(data.toPartyId);
            data.roleTypeId = item.roleTypeId;
            if (item?.paymentMethodId) {
              setSupplierBank([{value: item.paymentMethodId, label: `[${item.accountNumber}] - ${item.bankName}`}])
            } else {
              setSupplierBank([]);
            }
          }
          setStatusId(data.statusId)
          data.partyName = data.toPartyName
          if (data.effectiveDate) {
            data.effectiveDate = FormatDisplay.date(new Date(data.effectiveDate), 'YYYY-MM-DD');
          }
          if (data.enteredByPartyId) {
            const item = await EmployeesService.get(data.enteredByPartyId);
            data.enteredByParty = {value: data.enteredByPartyId, label: `[${item.pseudoId}] - ${item.partyName}`}
          }
          setPaymentRefNum(data.paymentRefNum)
          commonInfoForm.setValue(data);
          certInfoForm.setValue(data);
          setItems(data.items);
          if (doView === false) {
            setReadOnly(false);
            update(data);
          } else {
            setReadOnly(true)
            view(data)
          }
        }
      });
    } else {
      doUpdateOrderPayment(input, doView)
    }
    setDeleteInvoiceItemSeqIds([]);
  }

  //Thay đổi đối tượng -> set dirty = true
  const doChangeParty = (value) => {
    setSelectedPartyId(value)
    setDirty(true)
  }
  useEffect(() => {
    if (objBySearch?.length) {
      setSelectedPartyId(objBySearch[0].value);
      setObjBySearch([])
    }
  }, [displayObj]);
  const {renderCrupPO, doCreatePO, doUpdatePO} = useCrupPO({reloadLazyData},);

  const doSwitch = (orderId, doView: boolean) => {
    PurchaseOrderService.get(orderId).then(data => {
      doUpdatePO(orderId, data, doView, 'Payment');
    })
  }

  //Chuyen sang man hinh chi phi thue
  const {renderCrupPayment, doUpdatePayment} = useCrupPayment({reloadLazyData})
  const {renderPayment, doUpdatePayment: doUpdateOrderPayment} = useCrupPurchaseOrderPayment({reloadLazyData});

  const doSwitchPaymentGDT = (data, doView: boolean) => {
    doUpdatePayment(data, doView)
  }
  //Chuyen sang man hinh chi phi luong
  const {renderCrupPaymentLuong, doUpdatePaymentLuong} = luongCrupPayment({reloadLazyData})
  const doSwitchPaymentLuong = (data, doView: boolean) => {
    doUpdatePaymentLuong(data, doView)
  }

  //refresh lai ma chung tu
  const doRefreshRefNum = () => {
    if (paymentInstrumentEnumId) {
      Service.genPaymentPseudoId(paymentInstrumentEnumId).then(data => {
        setPaymentRefNum(data.code)
      })
    }
  }

  const renderPayments = () => {
    return (
      <div className="grid">
        <div className="col-12 py-0" style={{minHeight: '0', maxHeight: 'calc(100vh - 35.4rem)'}}>
          {renderDialogCrup()}
          {renderCrupPO()}
          {renderCrupPayment()}
          {renderCrupPaymentLuong()}
          {renderPayment()}
        </div>
        <ObjectType method={formMethod} setObjectType={setObjBySearch} display={displayObj} setDisplay={setDisplayObj}/>
      </div>
    );
  }

  return {renderPayments, doCreateOutgoingPayment: doCreate, doUpdateOutgoingPayment: doUpdate};
}

