import {useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';
import {Button} from 'primereact/button';

import {UomsService as Service} from './UomsService';
import {UploadExcel} from './UploadExcel';
import {useState} from 'react';

export const UOM = () => {

  const header = 'Đơn vị sản phẩm';
  const dataKey = 'uomId';
  const updateTooltip = 'Cập nhật';
  const [displayUploadExcel, setDisplayUploadExcel] = useState(null);
  const doDownloadExcel = () => {
    window.open(`${Service.baseURL}/${Service.entity}/export-excel`)
  }
  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <span>{header}</span>
        <div>
          <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-1 mb-1 w-7rem h-2rem"
                  size="small" onClick={() => create()}/>
          <Button label="Upload Excel" icon="pi pi-upload" severity="secondary" className="mr-1 mb-1 w-10rem h-2rem"
                  size="small" onClick={() => setDisplayUploadExcel(Date.now())}/>
          <Button label="Download Excel" icon="pi pi-download" outlined severity="secondary" className="mr-1 mb-1 h-2rem"
                  size="small" onClick={doDownloadExcel}/>
        </div>
      </div>
    </div>
  );

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    tableHeader: tabHeader,
    dataKey,
    columns: [
      {field: 'abbreviation', header: 'Đơn vị', minWidth: 400, matchMode: 'contains'}
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 90,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-pencil', title: updateTooltip, className: 'p-button-warning', command: () => doRowUpdate(item)},
          ]
        })
      }
    }
  });

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '40rem',
    fields: [
      {field: 'abbreviation', header: 'Đơn vị', required: true, className: 'md:col-12'},
    ],
    createItem: item => {
      return Service.create(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });


  const doRowUpdate = (data) => {
    update(data)
  }

  return (
    <div className="grid">
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12.5rem)'}}>
        {renderDataTable()}
      </div>
      {renderDialogCrup()}
      <UploadExcel display={displayUploadExcel} setDisplay={setDisplayUploadExcel} reloadLazyData={reloadLazyData}/>
    </div>
  );
}