import {EntityService} from 'src/service/EntityService';

export class ExpenseInvoicesService extends EntityService {

  static entity = 'iam-accounting/expense-invoices';

  static getList(lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }
  static getDetail(id: string, invoiceItemSeqId): Promise<any> {
    return this.axios.get(`${this.entity}/${id}/items/${invoiceItemSeqId}`).then(res => res.data);
  }
  static updateItem(id: string, invoiceItemSeqId, data): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/items/${invoiceItemSeqId}`, data).then(res => res.data);
  }
  static create(data:any): Promise<any> {
    return this.axios.post(`${this.entity}`,data).then(res => res.data);
  }

  static complete(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/complete`).then(res => res.data);
  }

  static unComplete(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/un-complete`).then(res => res.data);
  }

  static genPseudoId(): Promise<any> {
    return this.axios.get(`${this.entity}/gen-pseudo-id`).then(res => res.data);
  }

}