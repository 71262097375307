import {ConfirmDialogService, FormatDisplay, ToastService, useDataTableBasic, useForm} from '@iamsoftware/react-hooks';
import {useEffect, useState} from 'react';
import {Column} from 'primereact/column';
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';
import { Button } from 'primereact/button';
import {TaxDeclareService} from '../TaxDeclareService';

export const AddendumQH15 = ({display}, doUpdate?) =>{
  const [header, setHeader] = useState(null)
  const [items, setItems] = useState([])
  const [deductionSeqDelList, setDeductionSeqDelList] = useState([])
  const [productCategories, setProductCategories] = useState([])

  useEffect(() => {
    if (display?.declarationId){
      TaxDeclareService.getDeductionProdCategory().then(data =>{
        setProductCategories(data.items.map(item =>{
          return {...item, value: item.productCategoryId, label: item.categoryName};
        }))
      })
    }
  }, [display]);

  const onEditorChange = async (rowData, column, newValue) => {
    setItems(prevOrderItems => prevOrderItems.map(prevOrderItem => {
      if (prevOrderItem._id === rowData._id) {
        if (column.field === 'productCategoryId'){
          var categoryData = productCategories.find((it)=> it.productCategoryId === newValue);
          prevOrderItem.taxRate = categoryData.vatRate;
          prevOrderItem.exemptedTaxRate = 80*categoryData.vatRate/100;
        }
        if (column.field === 'revenueAmount') {
          prevOrderItem.exemptAmount = newValue * (prevOrderItem.taxRate*0.2)/100;
        }
        return {
          ...prevOrderItem,
          [column.field]: newValue
        }
      }
      return prevOrderItem;
    }))
  }

  //ham tao dong`
  const addBlankItem = () => {
    setItems(prevOrderItems => {
      const _id = `${Date.now()}_${Math.random()}`;
      prevOrderItems.push({
        _id,
      });
      return [...prevOrderItems];
    });
  }

  //them 1 dong neu list item rong
  useEffect(() => {
    if (items?.length === 0){
      addBlankItem()
    }
  }, [items]);

  const {render: renderDataTableBasic, selectedItems} = useDataTableBasic({
    tableHeader: header,
    dataKey: '_id',
    indexColumnWidth:45,
    columns:[
      {field:'productCategoryId',header:'', width:350, dataType:'editor',className:'editor-config', editorConfig:{
          onEditorChange, disabled: !doUpdate,
          field:{type: 'Dropdown', DropdownProps: {options: productCategories}}
        }},
      {field:'productName',header:'', width:250, dataType:'editor',className:'editor-config', editorConfig:{
        onEditorChange, disabled: !doUpdate
        }},
      {field:'revenueAmount',header:'', width:150, dataType:'editor',className:'editor-config', editorConfig:{
        onEditorChange, disabled: !doUpdate,
          field: {type: 'InputNumber', InputNumberProps:{min: 0}}
        }},
      {field:'taxRate',header:'', width:150, dataType:'number'},
      {field:'exemptedTaxRate',header:'', width:150, dataType:'number'},
      {field:'exemptAmount',header:'', width:150, dataType:'number'},
    ],
    items,
    originalProps:{
      headerColumnGroup: <ColumnGroup>
        <Row>
          <Column header="STT" headerClassName="iam-table-header-content" style={{width: '50px'}}/>
          <Column sortable header="Nhóm ngành nghề" field="productCategoryId" headerClassName="iam-table-header-content" style={{width: '350px'}}/>
          <Column sortable header="Tên hàng hóa, dịch vụ" field="productName" headerClassName="iam-table-header-content" style={{width: '250px'}}/>
          <Column sortable header="Giá trị hàng hóa, dịch vụ chưa có thuế GTGT/ Doanh thu hàng hóa, dịch vụ chịu thuế" field="revenueAmount"  headerClassName="iam-table-header-content"/>
          <Column sortable header="Thuế suất/ Tỷ lệ tính thuế GTGT theo quy định" field="taxRate" headerClassName="iam-table-header-content"/>
          <Column sortable header="Thuế suất/ Tỷ lệ tính thuế GTGT sau giảm" field="exemptedTaxRate"  headerClassName="iam-table-header-content"/>
          <Column sortable header="Thuế GTGT được giảm" field="exemptAmount" headerClassName="iam-table-header-content"/>
        </Row>
        <Row>
          <Column header="(1)" headerClassName="iam-table-header-content"/>
          <Column header="" headerClassName="iam-table-header-content"/>
          <Column header="(2)" headerClassName="iam-table-header-content"/>
          <Column header="(3)" headerClassName="iam-table-header-content"/>
          <Column header="(4)" headerClassName="iam-table-header-content"/>
          <Column header="(5)=(4)x80%" headerClassName="iam-table-header-content"/>
          <Column header="(6)=(3)x[(4)-(5)]" headerClassName="iam-table-header-content"/>
        </Row>
      </ColumnGroup>,
      paginator: true,
      rows: 5,
      rowsPerPageOptions: [5, 10, 20, 50, 100]
    },
  })

  const headerTable = (
    <div>
      <Button icon="pi pi-plus" rounded outlined severity="success" title="Thêm dòng" onClick={()=> addBlankItem()} disabled={!doUpdate}/>
      <Button className="ml-2" icon="pi pi-trash" rounded outlined severity="danger" title="Xóa dòng" onClick={()=> doDelete(selectedItems)} disabled={!doUpdate}/>
    </div>
  )

  const doDelete = (item) => {
    if (item?._id){
      ConfirmDialogService.confirm('Xóa sản phẩm, vật liệu','Bạn xác nhận muốn xóa sản phẩm này?',()=>{
        if (item?.declarationSeqId){
          deductionSeqDelList.push(item?.declarationSeqId)
        }
        setItems(prevItems => {
          return prevItems.filter(prevItem => prevItem._id !== item?._id);
        });
      })
    }
  }

  //set header for table
  useEffect(() => {
    setHeader(headerTable);
  }, [selectedItems,doUpdate]);

  const render = (
    <div>
      <div id="header">
        <h5 style={{textAlign: 'center'}} className="align-items-center mt-5">GIẢM THUẾ GIÁ TRỊ GIA TĂNG THEO NGHỊ QUYẾT SỐ 142/2024/QH15</h5>
      </div>
      <div id="body" className="mt-5">
        <div style={{float: 'right', marginBottom: '12px'}}>Đơn vị tiền: Đồng Việt Nam</div>
        <div className="mt-3">
          {renderDataTableBasic()}
        </div>
      </div>
    </div>
  )

  return {renderTaxDeduction: render, setItemDeduction: setItems, itemDeduction: items, deductionSeqDelList}
}