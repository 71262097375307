import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {FormatDisplay} from '@iamsoftware/react-hooks';
import {S5ReportService as Service} from './S5ReportSevice'

export const S5Detail = ({display, setDisplay, update, reCalculate, reload}) => {
  const [headerDate, setHeaderDate] = useState('')
  const [data, setData] = useState(null)
  const [reportCategoryTaxIds, setReportCategoryTaxIds] = useState([])
  useEffect(() => {
    load()
  }, [display])
  useEffect(() => {
    if (reload === true) {
      load()
    }
  }, [reload])
  const load = () => {
    if (display?.reportId) {
      Service.get(display.reportId).then(data => {
        if (data.timePeriodTypeId === "ReportYear") {
          setHeaderDate(`${data?.timePeriodType} ${data?.yearInt}`)
        } else {
          setHeaderDate(`${data?.timePeriodType} ${data?.periodNum} năm ${data?.yearInt}`)
        }
        setData(data)
      })
    } else {
      setReportCategoryTaxIds([])
    }
  }
  const header = (
    <div style={{textAlign: 'center'}}>
      <span>Sổ theo dõi thanh toán lương và khoản nộp theo lương người lao động.<br/> {headerDate}</span>
    </div>
  )
  const footer = (
    <div className="flex flex-row-reverse flex-wrap">
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
      <Button label="Tính toán lại dữ liệu" icon="pi pi-refresh" type="button" outlined severity="danger" size="small" onClick={() => reCalculate(display, true)}/>
      <Button label="Cập nhật" icon="pi pi-pencil" type="button" outlined severity="warning" size="small" onClick={() => update(display, true)}/>
    </div>
  );
  return (
    <Dialog className="dialog-report" header={header} footer={footer} visible={!!display} maximized={true} draggable={false} onHide={() => setDisplay(null)}>
      <Screen rowData={data} display={display}/>
    </Dialog>
  );

}
const Screen = ({rowData, display}) => {
  useEffect(() => {

  }, [display])
  return (
    <div className="grid">
      <div className="col-12" style={{textAlign: 'center'}}>
        <span style={{textAlign: 'center'}} className="mb-2"> <i style={{textAlign: 'center'}}> Từ ngày {FormatDisplay.date(new Date(rowData?.fromDate), 'DD-MM-YYYY')} đến ngày {FormatDisplay.date(new Date(rowData?.thruDate), 'DD-MM-YYYY')}</i></span>
      </div>
      <div className="p-datatable p-component p-datatable-striped p-datatable-gridlines p-datatable-sm">
        <div className="p-datatable-wrapper" style={{height:'calc(100vh - 15.5rem)'}}>
          <table className="p-datatable-table">
            <thead className="p-datatable-thead" style={{position: 'sticky', top: 0,zIndex:1}}>
            <tr>
              <th rowSpan={2} style={{width: 200, textAlign: 'center'}}>Ngày, tháng ghi sổ</th>
              <th colSpan={2} style={{width: 200, textAlign: 'center'}}>Chứng từ</th>
              <th rowSpan={2} style={{width: 150, textAlign: 'center'}}>Diễn giải</th>
              <th colSpan={3} style={{width: 120, textAlign: 'center'}}>Tiền lương và thu nhập của người lao động</th>
              <th colSpan={3} style={{textAlign: 'center'}}>BHXH</th>
              <th colSpan={3} style={{textAlign: 'center'}}>BHYT</th>
              <th colSpan={3} style={{textAlign: 'center'}}>BHTN</th>
            </tr>
            <tr>
              <th style={{width: 100}}>Số hiệu</th>
              <th style={{width: 100}}>Ngày tháng</th>
              {rowData?.dynamicColumns.map(col => {
                if (col.sequenceNum) {
                  return <Fragment>
                    <th style={{width: 100}}>Số phải trả</th>
                    <th style={{width: 100}}>Số đã trả</th>
                    <th style={{width: 100}}>Số còn phải trả</th>
                  </Fragment>
                }
              })}

            </tr>
            <tr>
              <th style={{width: 100, textAlign: 'center'}}>A</th>
              <th style={{width: 100, textAlign: 'center'}}>B</th>
              <th style={{width: 100, textAlign: 'center'}}>C</th>
              <th style={{width: 200, textAlign: 'center'}}>D</th>
              {rowData?.dynamicColumns.map(col => {
                if (col.sequenceNum > 1) {
                  let index = (col.sequenceNum - 1) * 3
                  return <Fragment>
                    <th style={{width: 130, textAlign: 'center'}}>{index + 1}</th>
                    <th style={{width: 130, textAlign: 'center'}}>{index + 2}</th>
                    <th style={{width: 130, textAlign: 'center'}}>{index + 3}</th>
                  </Fragment>
                } else return <Fragment>
                  <th style={{width: 130, textAlign: 'center'}}>{1}</th>
                  <th style={{width: 130, textAlign: 'center'}}>{2}</th>
                  <th style={{width: 130, textAlign: 'center'}}>{3}</th>
                </Fragment>
              })}

            </tr>
            </thead>
            <tbody className="p-datatable-tbody">
            {
              rowData?.data.map((item, index) => {
                if (item.isBold === "Y") {
                  return (
                    <tr key={index}>
                      <td><b>{item.displayId}</b></td>
                      <td><b>{''}</b></td>
                      <td><b>{''}</b></td>
                      <td><b>{item.description}</b></td>
                      {rowData?.dynamicColumns.map(dynamic => {
                        return (<Fragment>
                          <td style={{textAlign: 'right'}}><b>{FormatDisplay.number(item[dynamic.enumId]?.debitsAmount)}</b></td>
                          <td style={{textAlign: 'right'}}><b>{FormatDisplay.number(item[dynamic.enumId]?.creditsAmount)}</b></td>
                          <td style={{textAlign: 'right'}}><b>{FormatDisplay.number(item[dynamic.enumId]?.remainAmount)}</b></td>
                        </Fragment>)
                      })}
                    </tr>
                  )
                } else {
                  return (
                    <tr key={index}>
                      <td>{FormatDisplay.date(new Date(item.completedDate), 'DD/MM/YYYY')}</td>
                      <td>{item.displayId}</td>
                      <td>{FormatDisplay.date(new Date(item.effectiveDate), 'DD/MM/YYYY')}</td>
                      <td>{item.description}</td>
                      {rowData?.dynamicColumns.map(dynamic => {
                        return (<Fragment>
                          <td style={{textAlign: 'right'}}>{FormatDisplay.number(item[dynamic.enumId]?.debitsAmount)}</td>
                          <td style={{textAlign: 'right'}}>{FormatDisplay.number(item[dynamic.enumId]?.creditsAmount)}</td>
                          <td style={{textAlign: 'right'}}>{FormatDisplay.number(item[dynamic.enumId]?.remainAmount)}</td>
                        </Fragment>)
                      })}
                    </tr>
                  )
                }
              })
            }
            </tbody>
          </table>
        </div>
      </div>

    </div>
  )
    ;

}
