import {EntityService} from 'src/service/EntityService';

export class InventorySummaryService extends EntityService {

  static entity = 'iam-report/inventory-summary';

  static getSummary(data): Promise<any> {
    return this.axios.post(`${this.entity}`, data).then(res => res.data);
  }
}
