import {FormatDisplay, ToastService, useDataTable, useDataTableBasic, useDialogCrup, useForm, useToolbar} from '@iamsoftware/react-hooks';
import {Button} from 'primereact/button';
import {SalesOrdersService as Service} from './../don-hang/ordersService';
import {useCrupPayment} from './crupPayment';
export const SalePayment = () => {

  const dataKey = 'paymentId';
  const updateTooltip = 'Cập nhật';
  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <span>Thanh toán</span>
        <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"
                size="small" onClick={() => doCreatePayment()}/>
      </div>
    </div>
  );

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    tableHeader: tabHeader,
    dataKey,
    columns: [
      {field: 'effectiveDate', header: 'Ngày chứng từ', width: 150, dataType: 'date', matchMode: 'contains'},
      {field: 'paymentRefNum', header: 'Số chứng từ', width: 120, matchMode: 'contains'},
      {
        field: 'fromPartyName', header: 'Đối tượng', width: 400, dataType: 'custom', customCell(rowData: any): any {
          return (
            <div>[{rowData.fromPseudoId}] - {rowData.fromPartyName}</div>)
        }
      },
      {field: 'amount', header: 'Số tiền', dataType: 'number', width: 100, matchMode: 'contains'},
      {field: 'paymentInstrumentEnum', header: 'HTTT', width: 120, matchMode: 'contains'},
      {field: 'memo', header: 'Diễn giải', width: 300, matchMode: 'contains'},
      {field: 'status', header: 'Trạng thái', width: 150, matchMode: 'contains'},
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 90,
    getList: lazyLoadEvent => {
      return Service.getPaymentList(lazyLoadEvent);
    },
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-pencil', title: updateTooltip, className: 'p-button-warning', command: () => doUpdatePayment(item, false)},
          ]
        })
      }
    }
  });
  const {renderPayment, doCreatePayment, doUpdatePayment} = useCrupPayment({reloadLazyData});

  return (
    <div className="grid">
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12.5rem)'}}>
        {renderDataTable()}
        {renderPayment()}
      </div>
    </div>
  );
}