import {Route, Routes} from 'react-router-dom';

import LuongMua from './luong-mua';
import LuongMuaTheoNhaCungCap from './luong-mua-theo-nha-cung-cap';

export default function BaoCao() {

  return (
    <Routes>
      <Route path="luong-mua" element={<LuongMua/>}/>
      <Route path="luong-mua-theo-nha-cung-cap" element={<LuongMuaTheoNhaCungCap/>}/>
    </Routes>
  );
}
