import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {FormatDisplay, useDataTableBasic} from '@iamsoftware/react-hooks';
import {OrganizationTaxService as Service} from './OrganizationTaxService'

export const OrganizationDetail = ({display, setDisplay, fromDate, thruDate}) => {
  const [headerDate, setHeaderDate] = useState('')
  const [data, setData] = useState(null)

  useEffect(() => {
    load()
  }, [display])
  const load = () => {
    if (display?.productCategoryId) {
      if (fromDate && thruDate) {
        Service.getTaxDetail(
          display.productCategoryId,
          (new Date(new Date((fromDate)).toLocaleDateString()).setHours(0, 0, 0, 0)),
          (new Date(new Date((thruDate)).toLocaleDateString()).setHours(23, 59, 59, 999))
        ).then(res => {
          setData(res.listData)
        })
      } else if (fromDate && !thruDate) {
        Service.getTaxDetail(
          display.productCategoryId, (new Date(new Date((fromDate)).toLocaleDateString()).setHours(0, 0, 0, 0)), '').then(res => {
          setData(res.listData)
        })
      } else if (!fromDate && thruDate) {
        Service.getTaxDetail(
          display.productCategoryId, '', (new Date(new Date((thruDate)).toLocaleDateString()).setHours(23, 59, 59, 999))
        ).then(res => {
          setData(res.listData)
        })
      } else {
        Service.getTaxDetail(
          display.productCategoryId, '', '').then(res => {
          setData(res.listData)
        })
      }

    }
  }


  const header = (
    <div style={{textAlign: 'center'}}>
      <span>Chi tiết thuế GTGT, TNCN theo từng SP trong đơn hàng</span>
    </div>
  )
  const footer = (
    <div className="flex flex-row-reverse flex-wrap">
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
    </div>
  );
  return (
    <Dialog header={header} footer={footer} visible={!!display} maximized={true} draggable={false} onHide={() => setDisplay(null)}>
      <div className="p-datatable p-component p-datatable-striped p-datatable-gridlines p-datatable-sm">
        <div className="p-datatable-header">
          <div className="table-header">{data?.length} Sản phẩm</div>
        </div>
        <div className="p-datatable-wrapper">
          <table className="p-datatable-table">
            <thead className="p-datatable-thead">
            <tr>
              <th rowSpan={2} style={{width: 50, textAlign: 'center'}}>STT</th>
              <th rowSpan={2} style={{width: 100, textAlign: 'center'}}>Ngày chứng từ</th>
              <th rowSpan={2} style={{width: 100, textAlign: 'center'}}>Mã đơn hàng</th>
              <th rowSpan={2} style={{width: 300, textAlign: 'center'}}>Sản phẩm</th>
              <th rowSpan={2} style={{width: 100, textAlign: 'center'}}>Doanh thu</th>
              <th colSpan={2} style={{width: 100, textAlign: 'center'}}>Thuế GTGT</th>
              <th colSpan={2} style={{width: 100, textAlign: 'center'}}>Thuế TNCN</th>
              <th rowSpan={2} style={{width: 300, textAlign: 'center'}}>Ngành nghề KD</th>
              <th rowSpan={2} style={{width: 150, textAlign: 'center'}}>Nhóm ngành nghề</th>
            </tr>
            <tr>
              <th style={{width: 80}}>Thuế suất</th>
              <th style={{width: 150}}>Số tiền</th>
              <th style={{width: 80}}>Thuế suất</th>
              <th style={{width: 150}}>Số tiền</th>
            </tr>
            </thead>
            <tbody className="p-datatable-tbody">
            {data?.map((dataItem, index) => {
              return (<tr>
                <td style={{textAlign: 'center'}}>{index + 1}</td>
                <td style={{textAlign: 'center'}}>{FormatDisplay.date(dataItem?.invoiceDate)}</td>
                <td style={{textAlign: 'center'}}>{dataItem.orderDisplayId}</td>
                <td style={{textAlign: 'center'}}>{dataItem.itemDescription} [{dataItem.pseudoId}]</td>
                <td style={{textAlign: 'right'}}>{FormatDisplay.number(dataItem?.revenueAmount)} </td>
                <td style={{textAlign: 'right'}}>{FormatDisplay.number(dataItem?.vatRate)}%</td>
                <td style={{textAlign: 'right'}}>{FormatDisplay.number(dataItem?.vatAmount)}</td>
                <td style={{textAlign: 'right'}}>{FormatDisplay.number(dataItem?.personalIncomeTaxRate)}%</td>
                <td style={{textAlign: 'right'}}>{FormatDisplay.number(dataItem?.pitAmount)}</td>
                <td style={{textAlign: 'center'}}>{dataItem.categoryName}</td>
                <td style={{textAlign: 'center'}}>{dataItem.parentCategoryName}</td>

              </tr>)

            })}
            </tbody>
          </table>
        </div>
      </div>

    </Dialog>
  );

}

