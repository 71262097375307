import {FormatDisplay, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {InputText} from "primereact/inputtext";

import {InventorySummaryService as Service} from './inventorySummaryService';
import {Fragment, useEffect, useState} from 'react';
import {CommonService} from '../../../service/CommonService';

export const InventorySummary = () => {
  const [items, setItems] = useState([])
  const [timePeriodTypeId, setTimePeriodTypeId] = useState('ReportMonth')
  const [timePeriodTypes, setTimePeriodTypes] = useState(null)
  const [monthPeriodNum, setMonthPeriodNum] = useState(new Date().getMonth() + 1)
  const [quarterPeriodNum, setQuarterPeriodNum] = useState(1)
  const [yearPeriod, setYearPeriod] = useState(year)

  const [beginningQuantity, setBeginningQuantity] = useState(0)
  const [beginningAmountTotal, setBeginningAmountTotal] = useState(0)
  const [receivedQuantity, setReceivedQuantity] = useState(0)
  const [receivedAmount, setReceivedAmount] = useState(0)
  const [issuedQuantity, setIssuedQuantity] = useState(0)
  const [issuedAmount, setIssuedAmount] = useState(0)
  const [endingQuantity, setEndingQuantity] = useState(0)
  const [endingAmountTotal, setEndingAmountTotal] = useState(0)
  useEffect(() => {
    CommonService.timePeriodType().then(data => {
      setTimePeriodTypes(data.listData.map(item => {
        return {value: item.timePeriodTypeId, label: item.description};
      }))
    })
  }, [])
  useEffect(() => {
    if (timePeriodTypeId === 'ReportMonth') {
      getData(monthPeriodNum)
    } else if (timePeriodTypeId === 'ReportQuarter') {
      getData(quarterPeriodNum)
    } else if (timePeriodTypeId === 'ReportYear') {
      getData(null)
    }
  }, [timePeriodTypeId, monthPeriodNum, quarterPeriodNum, yearPeriod])
  const getData = (periodNum) => {
    Service.getSummary({
      timePeriodTypeId: timePeriodTypeId,
      periodNum: periodNum,
      yearPeriod: yearPeriod
    }).then(data => {
      setItems(data.items)
      let sum1 = 0
      let sum2 = 0
      let sum3 = 0
      let sum4 = 0
      let sum5 = 0
      let sum6 = 0
      let sum7 = 0
      let sum8 = 0
      data.items.map(item => {
        sum1 += item.beginningQuantity
        sum2 += item.beginningAmountTotal
        sum3 += item.receivedQuantity
        sum4 += item.receivedAmount
        sum5 += item.issuedQuantity
        sum6 += item.issuedAmount
        sum7 += item.endingQuantity
        sum8 += item.endingAmountTotal
      })
      setBeginningQuantity(sum1)
      setBeginningAmountTotal(sum2)
      setReceivedQuantity(sum3)
      setReceivedAmount(sum4)
      setIssuedQuantity(sum5)
      setIssuedAmount(sum6)
      setEndingQuantity(sum7)
      setEndingAmountTotal(sum8)
    })
  }
  return (
    <div className="grid">
      <div>
        <label><b> Kỳ báo cáo</b></label>
        <Dropdown style={{width: '6vw'}} filter className="iam-dropdown ml-2 mb-2" value={timePeriodTypeId} onChange={(e) => setTimePeriodTypeId(e.value)} options={timePeriodTypes} optionLabel="label"
                  placeholder="Chọn kỳ báo cáo"/>
      </div>
      {timePeriodTypeId === 'ReportMonth' && <div className={'ml-2'}>
				<label><b> Tháng</b></label>
				<Dropdown style={{width: '5vw'}} filter className="iam-dropdown ml-2 mb-2" value={monthPeriodNum} onChange={(e) => setMonthPeriodNum(e.value)} options={Months} optionLabel="label"/>
			</div>}
      {timePeriodTypeId === 'ReportQuarter' &&
				<div className={'ml-2'}>
					<label><b> Quý</b></label>
					<Dropdown style={{width: '5vw'}} filter className="iam-dropdown ml-2 mb-2" value={quarterPeriodNum} onChange={(e) => setQuarterPeriodNum(e.value)} options={Quarters} optionLabel="label"/>
				</div>}
      <div className={'ml-2'}>
        <label><b> Năm</b></label>
        <Dropdown style={{width: '10vw'}} filter className="iam-dropdown ml-2 mb-2" value={yearPeriod} onChange={(e) => setYearPeriod(e.value)} options={Years} optionLabel="label"/>
      </div>
      <div className="col-12" style={{textAlign: 'center'}}>
        <h5><b>Tổng hợp tồn kho</b></h5>
        <span style={{textAlign: 'center'}} className="mb-2"> <i style={{textAlign: 'center'}}><b>{ timePeriodTypeId ==="ReportMonth" && <span>Tháng {monthPeriodNum}, </span> } {timePeriodTypeId==="ReportQuarter" &&<span> Quý {quarterPeriodNum},</span>} năm {yearPeriod}</b> </i></span>
      </div>
      <div className="p-datatable p-component p-datatable-striped p-datatable-gridlines p-datatable-sm">
        <div className="p-datatable-wrapper" style={{height: 'calc(100vh - 15.5rem)'}}>
          <table className="p-datatable-table">
            <thead className="p-datatable-thead" style={{position: 'sticky', top: 0, zIndex: 1}}>
            <tr>
              <th colSpan={11} style={{textAlign: 'left'}}> {items?.length} hàng hóa</th>
            </tr>
            <tr>
              <th rowSpan={2} style={{width: 100, textAlign: 'center'}}>Mã hàng</th>
              <th rowSpan={2} style={{width: 300, textAlign: 'center'}}>Tên hàng</th>
              <th rowSpan={2} style={{width: 100, textAlign: 'center'}}>ĐVT</th>
              <th colSpan={2} style={{width: 150, textAlign: 'center'}}>Đầu kỳ</th>
              <th colSpan={2} style={{width: 150, textAlign: 'center'}}>Nhập kho</th>
              <th colSpan={2} style={{width: 150, textAlign: 'center'}}>Xuất kho</th>
              <th colSpan={2} style={{width: 150, textAlign: 'center'}}>Cuối kỳ</th>
            </tr>
            <tr>
              <th style={{width: 100}}>Số lượng</th>
              <th style={{width: 150}}>Giá trị</th>
              <th style={{width: 100}}>Số lượng</th>
              <th style={{width: 150}}>Giá trị</th>
              <th style={{width: 100}}>Số lượng</th>
              <th style={{width: 150}}>Giá trị</th>
              <th style={{width: 100}}>Số lượng</th>
              <th style={{width: 150}}>Giá trị</th>
            </tr>
            </thead>
            <tbody className="p-datatable-tbody">
            {items.map(item => {
              return (
                <tr>
                  <td>{item.pseudoId}</td>
                  <td>{item.productName}</td>
                  <td>{item.amountUom}</td>
                  <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.beginningQuantity)} </td>
                  <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.beginningAmountTotal)} </td>
                  <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.receivedQuantity)} </td>
                  <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.receivedAmount)} </td>
                  <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.issuedQuantity)} </td>
                  <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.issuedAmount)} </td>
                  <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.endingQuantity)} </td>
                  <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.endingAmountTotal)} </td>
                </tr>
              )
            })}

            </tbody>
            <tfoot className="p-datatable-tfoot" style={{position: 'sticky', bottom: 0}}>
            <tr style={{background: "#F8F9FA"}}>
              <td colSpan={3} style={{width: 500, textAlign: 'center'}}><b>Tổng cộng</b></td>
              <td style={{textAlign: 'right'}}>{FormatDisplay.number(beginningQuantity)} </td>
              <td style={{textAlign: 'right'}}>{FormatDisplay.number(beginningAmountTotal)} </td>
              <td style={{textAlign: 'right'}}>{FormatDisplay.number(receivedQuantity)} </td>
              <td style={{textAlign: 'right'}}>{FormatDisplay.number(receivedAmount)} </td>
              <td style={{textAlign: 'right'}}>{FormatDisplay.number(issuedQuantity)} </td>
              <td style={{textAlign: 'right'}}>{FormatDisplay.number(issuedAmount)} </td>
              <td style={{textAlign: 'right'}}>{FormatDisplay.number(endingQuantity)} </td>
              <td style={{textAlign: 'right'}}>{FormatDisplay.number(endingAmountTotal)} </td>

            </tr>
            </tfoot>
          </table>
        </div>
      </div>

    </div>

  );
}
const year = new Date().getFullYear();
const Years = [
  {value: year, label: year},
  {value: year - 1, label: year - 1},
  {value: year - 2, label: year - 2},
  {value: year - 3, label: year - 3},
];
const Quarters = [
  {value: 1, label: 1},
  {value: 2, label: 2},
  {value: 3, label: 3},
  {value: 4, label: 4}
];
const Months = [
  {value: 1, label: 1},
  {value: 2, label: 2},
  {value: 3, label: 3},
  {value: 4, label: 4},
  {value: 5, label: 5},
  {value: 6, label: 6},
  {value: 7, label: 7},
  {value: 8, label: 8},
  {value: 9, label: 9},
  {value: 10, label: 10},
  {value: 11, label: 11},
  {value: 12, label: 12}
];