import {Route, Routes} from 'react-router-dom';

import BaoCao from './bao-cao';
import {Facilities} from './kho-hang';
import KiemKho from './kiem-kho';
import NhapKho from './nhap-kho';
import XuatKho from './xuat-kho';

export default function KhoVan() {

  return (
    <Routes>
      <Route path="bao-cao/*" element={<BaoCao/>}/>
      <Route path="kho-hang" element={<Facilities/>}/>
      <Route path="kiem-kho" element={<KiemKho/>}/>
      <Route path="nhap-kho" element={<NhapKho/>}/>
      <Route path="xuat-kho" element={<XuatKho/>}/>
    </Routes>
  );
}
