import {Route, Routes} from 'react-router-dom';

import ChiTietTon from './chi-tiet-ton';
import TongHopTon from './tong-hop-ton';
import XuatNhapTon from './xuat-nhap-ton';

export default function BaoCao() {

  return (
    <Routes>
      <Route path="chi-tiet-ton" element={<ChiTietTon/>}/>
      <Route path="tong-hop-ton" element={<TongHopTon/>}/>
      <Route path="xuat-nhap-ton" element={<XuatNhapTon/>}/>
    </Routes>
  );
}
