import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Splitter, SplitterPanel} from 'primereact/splitter';
import {Column} from 'primereact/column';
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';
import {Dropdown} from 'primereact/dropdown';
import {InputText} from 'primereact/inputtext';
import {FormatDisplay, ToastService, useDataTableBasic, useForm} from '@iamsoftware/react-hooks';
import {CommonService} from '../../../service/CommonService';
import {SalesOrdersService as Service} from './ordersService';
import {BankAccountService} from '../../danh-muc/tai-khoan-ngan-hang/BankAccountService';
import {useCrupPaymentItems} from './PaymentItems';
import {CustomersService} from '../khach-hang/CustomersService';


export const useCrupPayment = ({reloadLazyData}) => {
  const [display, setDisplay] = useState(null);
  const [displayCreating, setDisplayCreating] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [displayId, setDisplayId] = useState('')
  const [orderPaymentStatus, setOrderPaymentStatus] = useState('')
  const [orderPayments, setOrderPayments] = useState([])
  const [selectedPayment, setSelectedPayment] = useState(null)
  const [paymentInstruments, setPaymentInstruments] = useState([]);
  const [items, setItems] = useState([])
  const [displayCreate, setDisplayCreate] = useState(false)
  const [creating, setCreating] = useState(false)
  const [customers, setCustomers] = useState([])
  const [orderData, setOrderData] = useState(null)
  const [headerResult, setHeaderResult] = useState(null);
  const [grandTotal, setGrandTotal] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [amountApplied, setAmountApplied] = useState(0);
  useEffect(() => {
    setHeaderResult(tabHeader)
  }, []);

  useEffect(() => {
    if (display || displayCreating && customers.length === 0) {
      CommonService.getPaymentInstruments().then(data => {
        setPaymentInstruments(data.listData.map(item => {
          return {value: item.enumId, label: item.description}
        }));
      });
      BankAccountService.getList('').then(data => {
        setBankAccounts(data.listData.map(item => {
          item.toPaymentMethodId = item.paymentMethodId
          return {value: item.toPaymentMethodId, label: `${item.bankName} - ${item.bankAccount}`}
        }));
      });
      CustomersService.getList('').then(data => {
        setCustomers(data.listData.map(item => {
          return {value: item.partyId, label: `[${item.pseudoId}]- ${item.partyName}`}
        }))
      })
    } else if (!display) {
      setOrderPayments([])
      setSelectedPayment(null)
      setItems([])
      setSelectedItems([])
    } else if (!displayCreating) {
      setItems([])
      setSelectedItems([])
    }
  }, [display, displayCreating])
  const {renderPaymentItems} = useCrupPaymentItems({display, disabled: true, items: items});

  const commonInfoForm = useForm({
    fields: [
      {field: 'fromPseudoId', header: 'Mã khách hàng', className: 'md:col-4', disabled: true},
      {field: 'fromPartyName', header: 'Tên khách hàng', className: 'md:col-8', disabled: true},
      {field: 'address1', header: 'Địa chỉ', className: 'md:col-12', disabled: true},
      {field: 'paymentInstrumentEnumId', header: 'HTTT', type: 'Dropdown', DropdownProps: {options: paymentInstruments}, className: 'md:col-4'},
      {
        field: 'toPaymentMethodId', header: 'Tài khoản nhận', type: 'Dropdown', DropdownProps: {options: bankAccounts}, className: 'md:col-8', displayDependency(item: any): boolean {
          return paymentInstrumentEnumId === 'PiWireTransfer'
        }, required: true
      },
      {
        field: 'toName', header: 'Người nhận', className: 'md:col-8', displayDependency(item: any): boolean {
          return paymentInstrumentEnumId === 'PiCash'
        }
      },
      {field: 'memo', header: 'Lý do thu', className: 'md:col-12'},
    ],
    disabled
  });
  const infoForm = useForm({
    fields: [
      {field: 'paymentRefNum', header: 'Mã chứng từ', className: 'md:col-12'},
      {field: 'effectiveDate', header: 'Ngày chứng từ', InputTextProps: {type: 'date'}, className: 'md:col-12'},
      {field: 'amount', header: 'Tổng tiền TT', type: 'InputNumber', className: 'md:col-12', disabled: true},
    ],
    disabled
  });
  const newInfoForm = useForm({
    fields: [
      {field: 'paymentRefNum', header: 'Mã chứng từ', className: 'md:col-12'},
      {field: 'effectiveDate', header: 'Ngày chứng từ', InputTextProps: {type: 'date'}, className: 'md:col-12'},
      {field: 'amount', header: 'Tổng tiền TT', type: 'InputNumber', className: 'md:col-12', disabled: true},
    ],
    disabled
  });
  const {paymentInstrumentEnumId} = commonInfoForm.getValue()
  const onEditorChange = (rowData, column, newValue) => {
    setItems(prevItems => prevItems.map(prevItem => {
      if (prevItem.orderId === rowData.orderId) {
        selectedItems.map(selectedItem => {
          if (selectedItem.orderId === rowData.orderId) {
            selectedItem.amountApplied = newValue
          }
        })
        return {
          ...prevItem,
          [column.field]: newValue
        }
      }
      return prevItem;
    }));
  }
  const commonData = commonInfoForm.getRawValue();
  const infoData = infoForm.getRawValue();
  const newInfoData = newInfoForm.getRawValue();
  const {render: renderNewPaymentItems, selectedItems, setSelectedItems} = useDataTableBasic({
    tableHeader: headerResult,
    dataKey: 'orderId',
    columns: [
      {field: 'orderId', hidden: true, width: 150},
      {field: 'placedDate', header: 'Ngày chứng từ', dataType: 'date', width: 150},
      {field: 'displayId', header: 'Số chứng từ', width: 150},
      {field: 'orderComments', header: 'Diễn giải', width: 400},
      {field: 'grandTotal', header: 'Số phải thu', dataType: 'number', width: 200},
      {field: 'remainingAmount', header: 'Số chưa thu', dataType: 'number', width: 200},
      {
        field: 'amountApplied', header: 'Số thu', width: 200, dataType: 'editor', editorConfig: {
          onEditorChange,
          field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }
      },
    ],
    items,
    selectionMode: 'checkbox',
    originalProps: {
      footerColumnGroup: <ColumnGroup>
        <Row>
          <Column colSpan={4} footerStyle={{textAlign: 'center'}} footer='Tổng cộng:'></Column>
          <Column footer={FormatDisplay.number(grandTotal)} footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
          <Column footer={FormatDisplay.number(remainingAmount)} footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
          <Column footer={FormatDisplay.number(amountApplied)} footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
        </Row>
      </ColumnGroup>
    }
  });

  useEffect(() => {
    if (paymentInstrumentEnumId && infoData && commonInfoForm.dirty['paymentInstrumentEnumId'] && creating === false) {
      Service.genPaymentPseudoId(paymentInstrumentEnumId).then(data => {
        infoData.paymentRefNum = data.code
        if (infoData.effectiveDate === '') {
          infoData.effectiveDate = FormatDisplay.date(new Date(), 'YYYY-MM-DD')
        }
        infoForm.setValue(infoData)
      })
    }
  }, [paymentInstrumentEnumId])
  useEffect(() => {
    if (paymentInstrumentEnumId && newInfoData && commonInfoForm.dirty['paymentInstrumentEnumId'] && creating === true) {
      Service.genPaymentPseudoId(paymentInstrumentEnumId).then(data => {
        newInfoData.paymentRefNum = data.code
        if (newInfoData.effectiveDate === '') {
          newInfoData.effectiveDate = FormatDisplay.date(new Date(), 'YYYY-MM-DD')
        }
        newInfoForm.setValue(newInfoData)
      })
    }
  }, [paymentInstrumentEnumId])
  const reset = () => {
    commonInfoForm.reset();
    infoForm.reset();
    newInfoForm.reset();
  }
  useEffect(() => {
    if (orderPayments.length) {
      setSelectedPayment(orderPayments[0]?.value)
    }
  }, [orderPayments])
  useEffect(() => {
    if (items.length) {
      let sum1 = 0;
      let sum2 = 0;
      let sum3 = 0;
      if (displayCreating) {
        items.map(item => {
          if (item?.checked === 'Y') {
            sum1 += item?.grandTotal;
            sum2 += item?.remainingAmount;
            sum3 += item?.amountApplied;
            selectedItems.push(item)
          }
        })
      } else {
        items.map(item => {
          sum1 += item?.grandTotal;
          sum2 += item?.remainingAmount;
          sum3 += item?.amountApplied;
          selectedItems.push(item)

        })
      }
      setGrandTotal(sum1)
      setRemainingAmount(sum2)
      setAmountApplied(sum3)
    }
  }, [items]);
  useEffect(() => {
    if (selectedItems.length) {
      let sum1 = 0;
      let sum2 = 0;
      let sum3 = 0;
      selectedItems.map(selectedItem => {
        sum1 += selectedItem?.grandTotal;
        sum2 += selectedItem?.remainingAmount;
        sum3 += selectedItem?.amountApplied;
      })
      setGrandTotal(sum1)
      setRemainingAmount(sum2)
      setAmountApplied(sum3)
    } else {
      setGrandTotal(0)
      setRemainingAmount(0)
      setAmountApplied(0)
    }
  }, [selectedItems]);


  useEffect(() => {
    const _value = infoForm.getRawValue()
    _value.amount = amountApplied
    infoForm.setValue(_value)

  }, [amountApplied]);
  useEffect(() => {
    const _value = newInfoForm.getRawValue()
    _value.amount = amountApplied
    newInfoForm.setValue(_value)

  }, [amountApplied]);

  useEffect(() => {
    if (displayCreating) {
      setSelectedItems([])
      setDisabled(false)
      setCreating(true)
    } else {
      setSelectedItems([])
      setDisabled(true)
      setCreating(false)
    }
  }, [displayCreating])
  useEffect(() => {
    if (selectedPayment) {
      Service.paymentDetail(selectedPayment).then(async data => {
        if (data.effectiveDate) {
          data.effectiveDate = FormatDisplay.date(new Date(data.effectiveDate), 'YYYY-MM-DD');
        }
        await CustomersService.get(orderData.customerPartyId).then(customer => {
          data.address1 = customer.address1
        })
        setItems(data.items)
        commonInfoForm.setValue(data)
        infoForm.setValue(data)
      })
    }
  }, [selectedPayment])
  const doCreate = async (rowData: any, data?: any) => {
    reset();
    setDisabled(false);
    setCreating(true)
    setOrderData(rowData)
    if (rowData?.customerPartyId) {
      await CustomersService.get(rowData.customerPartyId).then(customer => {
        commonInfoForm.setValue({
          fromPseudoId: customer.pseudoId,
          fromPartyName: customer.partyName,
          address1: customer.address1,
          memo: `Thu tiền từ khách hàng ${customer.partyName}`,
          paymentInstrumentEnumId: "PiCash"
        })
      })
    }
    Service.genPaymentPseudoId('PiCash').then(data => {
      newInfoForm.setValue({
        paymentRefNum: data.code,
        effectiveDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD')
      })
    })

    Service.uncompletedPayments(rowData.customerPartyId, rowData.orderId).then(payments => {
        setItems(payments.items)
      }
    )
    setDisplayCreating(Date.now());
  }
  const doUpdate = async (rowData: any, data: any) => {
    reset()
    setDisabled(true)
    setDisplayId(data.displayId)
    setOrderData(rowData)
    setOrderPayments(data.items.map(item => {
      item.effectiveDate = FormatDisplay.date(new Date(item.effectiveDate), 'YYYY-MM-DD');
      return {value: item.paymentId, label: `${item.paymentRefNum} - Ngày ${item.effectiveDate}`}
    }))
    if (rowData.paymentStatus !== 'Đã thanh toán') {
      setDisplayCreate(true)
    } else {
      setDisplayCreate(false)
    }
    setOrderPaymentStatus(data.orderPaymentStatus)
    setDisplay(Date.now())
    setCreating(false)
  }
  const onAddPayment = () => {
    Service.uncompletedPayments(orderData.customerPartyId, orderData.orderId).then(payments => {
      setItems(payments.items)
    });
    Service.genPaymentPseudoId(paymentInstrumentEnumId).then(data => {
      newInfoForm.setValue({
        paymentRefNum: data.code,
        effectiveDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD')
      })
    })
    setCreating(true)
    setDisplayCreating(Date.now());
  }
  const onSubmit = () => {

    if (creating === false && disabled === false) {
      const data = Object.assign(
        commonData,
        infoData);
      if (data.effectiveDate) {
        data.effectiveDate = Date.parse(data.effectiveDate)
      }
      Service.updatePayment(selectedPayment, data).then(() => {
        reloadLazyData();
        setDisplay(null)
        ToastService.success()
      })
    } else {
      if (selectedItems.length > 0) {

        const data = Object.assign(commonData, newInfoData);
        if (data.effectiveDate) {
          data.effectiveDate = Date.parse(data.effectiveDate)
        }
        selectedItems.map(selectedItem => {
          selectedItem.paymentAmount = selectedItem.amountApplied
        })
        data.fromPartyId = orderData.customerPartyId
        data.items = selectedItems
        Service.createPayment(data).then(() => {
          reloadLazyData();
          ToastService.success();
          setDisplayCreating(null);
          setDisplay(null)
        })
      } else {
        ToastService.error('Bạn chưa chọn chứng từ!')
      }
    }
  }
  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>Chứng từ công nợ</span>
        </Fragment>
      </div>
    </div>
  );

  const footer = (
    <div className="flex" style={{justifyContent: 'end'}}>
      {!disabled && <Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit}/>}
      {!disabled && creating === false && <Button label="Bỏ qua" icon="pi pi-ban" severity={'danger'} size="small" onClick={() => setDisabled(true)}/>}
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
    </div>
  );
  const creatingFooter = (
    <div className="flex" style={{justifyContent: 'end'}}>
      {!disabled && <Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit}/>}
      {!disabled && creating === false && <Button label="Hủy" icon="pi pi-ban" severity={'danger'} size="small" onClick={() => setDisabled(true)}/>}
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplayCreating(null)}/>
    </div>
  );
  const header = () => {
    return (
      <div className="flex flex-wrap">
        {creating === false && <div className="md:col-12 justify-content-begin flex-wrap flex">
					<div className={'flex mt-2'}>
						<h4><b> Đơn hàng </b></h4>
					</div>
					<div className={'ml-2'}>
						<InputText style={{width: '150px'}} value={displayId} onChange={e => setDisplayId(e.target.value)} disabled/>
					</div>
					<div className={'ml-2'}>
						<InputText value={orderPaymentStatus} onChange={e => setOrderPaymentStatus(e.target.value)} disabled/>
					</div>
					<div className={'flex mt-2 ml-4'}>
						<h4><b> Phiếu thu</b></h4>
					</div>
					<div className="ml-2">
						<Dropdown style={{width: '200px'}} placeholder={'Chọn phiếu thu'} className="ml-2 w-full md:w-14rem p-inputtext-sm" value={selectedPayment} onChange={(e) => setSelectedPayment(e.value)} options={orderPayments} optionLabel="label"></Dropdown>
					</div>
					<div className="ml-2 mt-1">
						<Button icon="pi pi-pencil" outlined severity={'warning'} onClick={() => setDisabled(false)} rounded/>
					</div>
					<div className="ml-2 mt-1">
            {displayCreate && <Button icon="pi pi-plus" outlined severity={'success'} onClick={() => onAddPayment()} rounded/>}
					</div>
				</div>}
      </div>
    )
  }

  const render = () => {
    return (
      <Fragment>
        <Dialog header={header} footer={footer} visible={!!display} maximized={true} draggable={false} onHide={() => setDisplay(null)}>
          <div className="grid">
            <div className="col-12">
              <Splitter draggable={false}>
                <SplitterPanel size={75} minSize={10}>
                  <div style={{padding: '20px'}}>
                    {commonInfoForm.render()}
                  </div>
                </SplitterPanel>
                {creating === false && <SplitterPanel size={25}>
									<div style={{padding: '20px'}}>
                    {infoForm.render()}
									</div>
								</SplitterPanel>}

              </Splitter>
              {renderPaymentItems()}
            </div>
          </div>
        </Dialog>
        <Dialog header={'Thu tiền khách hàng theo đơn hàng'} footer={creatingFooter} visible={!!displayCreating} maximized={true} draggable={false} onHide={() => setDisplayCreating(null)}>
          <div className="grid">
            <div className="col-12">
              <Splitter draggable={false}>
                <SplitterPanel size={75} minSize={10}>
                  <div style={{padding: '20px'}}>
                    {commonInfoForm.render()}
                  </div>
                </SplitterPanel>
                <SplitterPanel size={25}>
                  <div style={{padding: '20px'}}>
                    {newInfoForm.render()}
                  </div>
                </SplitterPanel>
              </Splitter>
              <div className="grid">
                {renderNewPaymentItems()}
              </div>
            </div>
          </div>
        </Dialog>

      </Fragment>
    );
  }
  return {renderPayment: render, doCreatePayment: doCreate, doUpdatePayment: doUpdate};
}
