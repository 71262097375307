import {EntityService} from '../../../service/EntityService';

export class LiabilityServices extends EntityService{
  static entity = '/iam-accounting/liabilities';

  static getListSale(lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/sale?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static getListPurchase(lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/purchase?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static recalculate(data: any): Promise<any> {
    return this.axios.put(`${this.entity}/recalculate`, data).then(res => res.data);
  }

  static timeSync(): Promise<any> {
    return this.axios.get(`${this.entity}/time-sync`).then(res => res.data);
  }

  static getDetail(data: any): Promise<any> {
    return this.axios.put(`${this.entity}/detail`, data).then(res => res.data);
  }

  static update(data: any): Promise<any> {
    return this.axios.put(`${this.entity}/update`, data).then(res => res.data);
  }
}