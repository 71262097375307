import {ConfirmDialogService, FormatDisplay, ToastService, useDataTableBasic, useForm} from '@iamsoftware/react-hooks';
import {useEffect, useState} from 'react';
import {Column} from 'primereact/column';
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';
import {InputText} from 'primereact/inputtext';
import {Calendar} from 'primereact/calendar';
import {ProductService} from '../../../ban-hang/san-pham/productService';
import {UomsService} from '../../../danh-muc/uom/UomsService';
import { Button } from 'primereact/button';

export const AddendumHDKD = ({display}, signDate?, signName?,taxAgencyEmployeeName?,setSignDate?, setSignName?, setTaxAgencyEmployeeName?, certificationNo?, setCertificationNo?, doUpdate?) => {

  const [items, setItems] = useState([])
  const [expenseItems, setExpenseItems] = useState([])
  const [prodList, setProdList] = useState([])
  const [prodUomList, setProdUomList] = useState([])
  const [declarationSeqDelList, setDeclarationSeqDelList] = useState([])
  const [header, setHeader] = useState(null)

  //Tong tien items
  const [beginningQuantityTotal, setBeginningQuantityTotal] = useState(0);
  const [beginningAmountTotal, setBeginningAmountTotal] = useState(0);
  const [receivedQuantityTotal, setReceivedQuantityTotal] = useState(0);
  const [receivedAmountTotal, setReceivedAmountTotal] = useState(0);
  const [issuedQuantityTotal, setIssuedQuantityTotal] = useState(0);
  const [issuedAmountTotal, setIssuedAmountTotal] = useState(0);
  const [endingQuantityTotal, setEndingQuantityTotal] = useState(0);
  const [endingAmountTotal, setEndingAmountTotal] = useState(0);

  //tong tien expense
  const [expenseTotal, setExpenseTotal] = useState(0);

  useEffect(() => {
    if (display?.declarationId) {
      ProductService.getList('', 'N').then(({listData}) => {
        setProdList(listData.map(item => {
          return {value: item.productId, label: item.productName, amountUomId: item.amountUomId}
        }))
      })
      UomsService.getList('').then(({listData}) => {
        setProdUomList(listData.map(item => {
          return {value: item.uomId, label: item.abbreviation}
        }))
      })
      setDeclarationSeqDelList([]);
    }
  }, [display]);

  const onEditorChange = async (rowData, column, newValue) => {
    setItems(prevOrderItems => prevOrderItems.map(prevOrderItem => {
      if (prevOrderItem._id === rowData._id) {
        if (column.field === 'productId') {
          prevOrderItem.amountUomId = prodList.find((it) => newValue === it.value)?.amountUomId;
          prevOrderItem.beginningQuantity = 0;
          prevOrderItem.beginningAmount = 0;
          prevOrderItem.receivedQuantity = 0;
          prevOrderItem.receivedAmount = 0;
          prevOrderItem.issuedQuantity = 0;
          prevOrderItem.issuedAmount = 0;
          prevOrderItem.endingQuantity = 0;
          prevOrderItem.endingAmount = 0;
        }
        return {
          ...prevOrderItem,
          [column.field]: newValue
        }
      }
      return prevOrderItem;
    }))
  }

  //edit so tien chi phi
  const onEditorChange1 = async (rowData, column, newValue) => {
    setExpenseItems(prevOrderItems => prevOrderItems.map(prevOrderItem => {
      if (prevOrderItem._id === rowData._id) {
        return {
          ...prevOrderItem,
          [column.field]: newValue
        }
      }
      return prevOrderItem;
    }))
  }

  //ham tao dong`
  const addBlankItem = () => {
    setItems(prevOrderItems => {
      const _id = `${Date.now()}_${Math.random()}`;
      prevOrderItems.push({
        _id,
        beginningQuantity: 0,
        beginningAmount: 0,
        receivedQuantity: 0,
        receivedAmount: 0,
        issuedQuantity: 0,
        issuedAmount: 0,
        endingQuantity: 0,
        endingAmount: 0,
      });
      return [...prevOrderItems];
    });
  }

  //tinh lai tong tien khi thay doi
  useEffect(() => {
    var beginQuantity = 0;
    var beginAmount = 0;
    var receivedQuantity = 0;
    var receivedAmount = 0;
    var issuedQuantity = 0;
    var issuedAmount = 0;
    var endingQuantity = 0;
    var endingAmount = 0;
    items.forEach(item => {
      beginQuantity += item.beginningQuantity;
      beginAmount += item.beginningAmount;
      receivedQuantity += item.receivedQuantity;
      receivedAmount += item.receivedAmount;
      issuedAmount += item.issuedAmount;
      issuedQuantity += item.issuedQuantity;
      endingQuantity += item.endingQuantity;
      endingAmount += item.endingAmount;
    })
    setBeginningQuantityTotal(beginQuantity)
    setBeginningAmountTotal(beginAmount)
    setReceivedAmountTotal(receivedAmount)
    setReceivedQuantityTotal(receivedQuantity)
    setIssuedAmountTotal(issuedAmount)
    setIssuedQuantityTotal(issuedQuantity)
    setEndingAmountTotal(endingQuantity)
    setEndingQuantityTotal(endingAmount)
  }, [items]);

  const {render: renderDataTableBasic, selectedItems} = useDataTableBasic({
    tableHeader: header,
    dataKey: '_id',
    columns: [
      {
        field: 'productId', header: '', width: 300, dataType: 'editor', className: 'editor-config', editorConfig: {
          onEditorChange, disabled: !doUpdate,
          field: {type: 'Dropdown', DropdownProps: {options: prodList}}
        }
      },
      {
        field: 'amountUomId', header: 'Đơn vị tính', width: 150, dataType: 'editor', className: 'editor-config', editorConfig: {
          onEditorChange,disabled: !doUpdate,
          field: {type: 'Dropdown', DropdownProps: {options: prodUomList}}
        }
      },
      {
        field: 'beginningQuantity', header: 'Số lượng đầu kỳ', width: 175, dataType: 'editor', className: 'editor-config', editorConfig: {
          onEditorChange,disabled: !doUpdate,
          field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }
      },
      {
        field: 'beginningAmount', header: 'Thành tiền đầu kỳ', width: 175, dataType: 'editor', className: 'editor-config', editorConfig: {
          onEditorChange,disabled: !doUpdate,
          field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }
      },
      {
        field: 'receivedQuantity', header: 'Số lượng nhập', width: 175, dataType: 'editor', className: 'editor-config', editorConfig: {
          onEditorChange,disabled: !doUpdate,
          field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }
      },
      {
        field: 'receivedAmount', header: 'Thành tiền nhập', width: 175, dataType: 'editor', className: 'editor-config', editorConfig: {
          onEditorChange,disabled: !doUpdate,
          field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }
      },
      {
        field: 'issuedQuantity', header: 'Số lượng xuất', width: 175, dataType: 'editor', className: 'editor-config', editorConfig: {
          onEditorChange,disabled: !doUpdate,
          field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }
      },
      {
        field: 'issuedAmount', header: 'Thành tiền xuất', width: 175, dataType: 'editor', className: 'editor-config', editorConfig: {
          onEditorChange,disabled: !doUpdate,
          field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }
      },
      {
        field: 'endingQuantity', header: 'Số lượng tồn', width: 175, dataType: 'editor', className: 'editor-config', editorConfig: {
          onEditorChange,disabled: !doUpdate,
          field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }
      },
      {
        field: 'endingAmount', header: 'Thành tiền tồn', width: 175, dataType: 'editor', className: 'editor-config', editorConfig: {
          onEditorChange,disabled: !doUpdate,
          field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }
      },
    ],
    items,
    originalProps: {
      footerColumnGroup: <ColumnGroup>
        <Row>
          <Column colSpan={2} footerStyle={{textAlign: 'right'}} footer='Tổng cộng:'></Column>
          <Column footer={FormatDisplay.number(beginningQuantityTotal)} footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
          <Column footer={FormatDisplay.number(beginningAmountTotal)} footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
          <Column footer={FormatDisplay.number(receivedQuantityTotal)} footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
          <Column footer={FormatDisplay.number(receivedAmountTotal)} footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
          <Column footer={FormatDisplay.number(issuedQuantityTotal)} footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
          <Column footer={FormatDisplay.number(issuedAmountTotal)} footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
          <Column footer={FormatDisplay.number(endingQuantityTotal)} footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
          <Column footer={FormatDisplay.number(endingAmountTotal)} footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
        </Row>
      </ColumnGroup>,
      headerColumnGroup: <ColumnGroup>
        <Row>
          <Column sortable header="Vật liệu, dụng cụ, sản phẩm, hàng hóa/ Nhóm hàng hóa" rowSpan={2} field="productId" headerClassName="iam-table-header-content" style={{width: '400px'}}/>
          <Column header="Đơn vị tính" rowSpan={2} headerClassName="iam-table-header-content" style={{width: '100px'}}/>
          <Column header="Số dư đầu kỳ" colSpan={2} headerClassName="iam-table-header-content"/>
          <Column header="Nhập trong kỳ" colSpan={2} headerClassName="iam-table-header-content"/>
          <Column header="Xuất trong kỳ" colSpan={2} headerClassName="iam-table-header-content"/>
          <Column header="Tồn cuối kỳ" colSpan={2} headerClassName="iam-table-header-content"/>
        </Row>
        <Row>
          <Column header="Số lượng" field="beginningQuantity" headerClassName="iam-table-header-content" style={{width: '150px'}}/>
          <Column header="Thành tiền" field="beginningAmount" headerClassName="iam-table-header-content" style={{width: '150px'}}/>
          <Column header="Số lượng" field="receivedQuantity" headerClassName="iam-table-header-content" style={{width: '150px'}}/>
          <Column header="Thành tiền" field="receivedAmount" headerClassName="iam-table-header-content" style={{width: '150px'}}/>
          <Column header="Số lượng" field="issuedQuantity" headerClassName="iam-table-header-content" style={{width: '150px'}}/>
          <Column header="Thành tiền" field="issuedAmount" headerClassName="iam-table-header-content" style={{width: '150px'}}/>
          <Column header="Số lượng" field="endingQuantity" headerClassName="iam-table-header-content" style={{width: '150px'}}/>
          <Column header="Thành tiền" field="endingAmount" headerClassName="iam-table-header-content" style={{width: '150px'}}/>
        </Row>
        <Row>
          <Column header="[6]" headerClassName="iam-table-header-content"/>
          <Column header="[7]" headerClassName="iam-table-header-content"/>
          <Column header="[8]" headerClassName="iam-table-header-content"/>
          <Column header="[9]" headerClassName="iam-table-header-content"/>
          <Column header="[10]" headerClassName="iam-table-header-content"/>
          <Column header="[11]" headerClassName="iam-table-header-content"/>
          <Column header="[12]" headerClassName="iam-table-header-content"/>
          <Column header="[13]" headerClassName="iam-table-header-content"/>
          <Column header="[14]" headerClassName="iam-table-header-content"/>
          <Column header="[15]" headerClassName="iam-table-header-content"/>
        </Row>
      </ColumnGroup>,
      paginator: true,
      rows: 5,
      rowsPerPageOptions: [5, 10, 20, 50, 100],
    },
  })

  const headerTable = (
    <div>
      <Button icon="pi pi-plus" rounded outlined severity="success" title="Thêm dòng" onClick={()=> addBlankItem()} disabled={!doUpdate}/>
      <Button className="ml-2" icon="pi pi-trash" rounded outlined severity="danger" title="Xóa dòng" onClick={()=> doDelete(selectedItems)} disabled={!doUpdate}/>
    </div>
  )

  //set header for table
  useEffect(() => {
    setHeader(headerTable);
  }, [selectedItems, doUpdate]);

  //Tinh tong tien expense
  useEffect(() => {
    var expenseTotal = 0;
    expenseItems.forEach(item => {
      expenseTotal += item.amount;
    })
    setExpenseTotal(expenseTotal);
  }, [expenseItems]);

  const {render: renderExpense} = useDataTableBasic({
    dataKey: '_id',
    columns: [
      {field: 'accountName', header: 'Chỉ tiêu', minWidth: 250},
      {field: 'sequenceNum', header: 'Mã chỉ tiêu', width: 200},
      {
        field: 'amount', header: 'Số tiền', width: 150, dataType: 'editor', editorConfig: {
          onEditorChange: onEditorChange1,disabled: !doUpdate,
          field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }
      },
    ],
    items: expenseItems,
    originalProps: {
      footerColumnGroup: <ColumnGroup>
        <Row>
          <Column footer='Tổng cộng:'></Column>
          <Column footer="[31]" footerStyle={{textAlign: 'center', fontSize: '14px'}}/>
          <Column footer={FormatDisplay.number(expenseTotal)} footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
        </Row>
      </ColumnGroup>,
      paginator: true,
      rows: 5,
      rowsPerPageOptions: [5, 10, 20, 50, 100],
    }
  })

  const doDelete = (item) => {
    if (item?._id){
      ConfirmDialogService.confirm('Xóa sản phẩm, vật liệu','Bạn xác nhận muốn xóa sản phẩm này?',()=>{
        if (item?.declarationSeqId){
          declarationSeqDelList.push(item?.declarationSeqId)
        }
        setItems(prevItems => {
          return prevItems.filter(prevItem => prevItem._id !== item?._id);
        });
      })
    }
  }

  const render = (
    <div>
      <div id="header">
        <h5 style={{textAlign: 'center'}} className="align-items-center mt-5">BẢNG KÊ HOẠT ĐỘNG KINH DOANH TRONG KỲ CỦA HỘ KINH DOANH, CÁ NHÂN KINH DOANH</h5>
        <div style={{textAlign: 'center'}} className="align-items-center mt-3">
          <i>(Kèm theo tờ khai 01/CNKD áp dụng đối với hộ kinh doanh, cá nhân kinh doanh nộp thuế theo phương pháp kê khai)</i>
        </div>
      </div>
      <div id="body" className="mt-5">
        <h5>I. VẬT LIỆU, DỤNG CỤ, SẢN PHẨM, HÀNG HÓA</h5>
        <div style={{float: 'right', marginBottom: '12px'}}>Đơn vị tiền: Đồng Việt Nam</div>
        <div className="mt-3">
          {renderDataTableBasic()}
        </div>
        <h5>II. CHI PHÍ QUẢN LÝ</h5>
        <div style={{float: 'right', marginBottom: '12px'}}>Đơn vị tiền: Đồng Việt Nam</div>
        <div className="mt-3">
          {renderExpense()}
        </div>
      </div>
      <div id="footer" className="mt-5">
        <h5>NHÂN VIÊN ĐẠI LÝ THUẾ</h5>
        <div className="flex align-items-center justify-content-between col-12">
          <div className="col-6">
            <div className="flex align-items-center">
              <label style={{width: '200px'}}>Họ và tên</label>
              <InputText style={{width: '250px'}} value={taxAgencyEmployeeName} onChange={(e) => setTaxAgencyEmployeeName(e.target.value)} disabled={!doUpdate}></InputText>
            </div>
            <div className="flex align-items-center mt-3">
              <label style={{width: '200px'}}>Chứng chỉ hành nghề số</label>
              <InputText style={{width: '250px'}} value={certificationNo} onChange={(e) => setCertificationNo(e.target.value)} disabled={!doUpdate}></InputText>
            </div>
          </div>
          <div className="col-6">
            <div className="flex align-items-center" style={{justifyContent: 'end'}}>
              <label style={{width: '100px'}}>Người ký</label>
              <InputText style={{width: '250px'}} value={signName} onChange={(e) => setSignName(e.target.value)} disabled={!doUpdate}></InputText>
            </div>
            <div className="flex align-items-center mt-3" style={{justifyContent: 'end'}}>
              <label style={{width: '200px'}}>Ngày ký</label>
              <Calendar style={{width: '150px'}} value={signDate} onChange={(e) => setSignDate(e.value)} dateFormat="dd/mm/yy" disabled={!doUpdate}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  return {renderHDKD: render, setDeclarationInvent: setItems,itemInvent: items, setDeclarationExpense: setExpenseItems, itemExpense: expenseItems, declarationSeqDelList}
}