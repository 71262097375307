import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import {Dialog} from 'primereact/dialog';
import {TabView, TabPanel} from 'primereact/tabview';

import {FormatDisplay, ToastService, useDataTable, useDataTableBasic, useDialogCrup, useForm, useToolbar} from '@iamsoftware/react-hooks';
import {S2ReportService as Service} from './S2ReportService'

export const S2Detail = ({display, setDisplay, update, reCalculate, reload}) => {
  const [headerDate, setHeaderDate] = useState('')
  const [data, setData] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0);
  const [fromDate, setFromDate] = useState(null)
  const [thruDate, setThruDate] = useState(null)

  useEffect(() => {
    load()
  }, [display])

  useEffect(() => {
    if (reload === true) {
      load()
    }
  }, [reload])

  const load = () => {
    if (display?.reportId) {
      setActiveIndex(0)
      Service.get(display?.reportId).then(data => {
        if (data.timePeriodTypeId === "ReportYear") {
          setHeaderDate(`${data?.timePeriodType} ${data?.yearInt}`)
        } else {
          setHeaderDate(`${data?.timePeriodType} ${data?.periodNum} năm ${data?.yearInt}`)
        }
        setFromDate(FormatDisplay.date(new Date(data?.fromDate), 'DD-MM-YYYY'))
        setThruDate(FormatDisplay.date(new Date(data?.thruDate), 'DD-MM-YYYY'))
        setData(data.items)
      })
    }
  }

  const header = (
    <div style={{textAlign: 'center'}}>
      <span>Sổ chi tiết vật liệu, dụng cụ, sản phẩm, hàng hóa<br/>{headerDate} </span>
    </div>
  )

  const footer = (
    <div className="flex flex-row-reverse flex-wrap">
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
      <Button label="Tính toán lại dữ liệu" icon="pi pi-refresh" type="button" outlined severity="danger" size="small" onClick={() => reCalculate(display, true)}/>
      <Button label="Cập nhật" icon="pi pi-pencil" type="button" outlined severity="warning" size="small" onClick={() => update(display, true)}/>
    </div>
  );
  return (
    <Dialog className="dialog-report" header={header} footer={footer} visible={!!display} maximized={true} draggable={false} onHide={() => setDisplay(null)}>
      <div className="mb-2" style={{textAlign: 'center'}}><i style={{textAlign: 'center'}}> Từ ngày {fromDate} đến ngày {thruDate}</i></div>
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="p-tabview-h-full">
        {data?.map((tab) => {
          return (
            <TabPanel key={tab?.facilityId} header={`${tab.facilityPseudoId} ${tab.facilityName}`}>
              <Screen rowData={tab.productData}/>
            </TabPanel>
          )
        })}
      </TabView>
    </Dialog>
  );

}
const Screen = ({rowData}) => {
  return (
    <Fragment>
      <div className="p-datatable p-component p-datatable-striped p-datatable-gridlines p-datatable-sm">
        <div className="p-datatable-wrapper" style={{height:'calc(100vh - 15.5rem)'}}>
          <table className="p-datatable-table">
            <thead className="p-datatable-thead" style={{position: 'sticky', top: 0,zIndex:1}}>
            <tr>
              <th colSpan={2} style={{width: 200, textAlign: 'center'}}>Chứng từ</th>
              <th rowSpan={2} style={{width: 150, textAlign: 'center'}}>Diễn giải</th>
              <th rowSpan={2} style={{width: 100, textAlign: 'center'}}>ĐVT</th>
              <th rowSpan={2} style={{width: 120, textAlign: 'center'}}>Đơn giá</th>
              <th colSpan={2} style={{textAlign: 'center'}}>Nhập</th>
              <th colSpan={2} style={{textAlign: 'center'}}>Xuất</th>
              <th colSpan={2} style={{textAlign: 'center'}}>Tồn</th>
            </tr>
            <tr>
              <th style={{width: 100}}>Số chứng từ</th>
              <th style={{width: 100}}>Ngày tháng</th>
              <th style={{width: 130}}>Số lượng</th>
              <th style={{width: 120}}>Thành tiền</th>
              <th style={{width: 130}}>Số lượng</th>
              <th style={{width: 120}}>Thành tiền</th>
              <th style={{width: 130}}>Số lượng</th>
              <th style={{width: 120}}>Thành tiền</th>
            </tr>
            <tr>
              <th style={{width: 100, textAlign: 'center'}}>A</th>
              <th style={{width: 100, textAlign: 'center'}}>B</th>
              <th style={{width: 130, textAlign: 'center'}}>C</th>
              <th style={{width: 120, textAlign: 'center'}}>D</th>
              <th style={{width: 130, textAlign: 'center'}}>1</th>
              <th style={{width: 120, textAlign: 'center'}}>2</th>
              <th style={{width: 130, textAlign: 'center'}}>3</th>
              <th style={{width: 120, textAlign: 'center'}}>4</th>
              <th style={{width: 130, textAlign: 'center'}}>5</th>
              <th style={{width: 120, textAlign: 'center'}}>6</th>
              <th style={{width: 130, textAlign: 'center'}}>7</th>
            </tr>
            </thead>
            <tbody className="p-datatable-tbody">
            {
              rowData.map((item, index) => {
                if (item.reportItemId === "beginningAmount") {
                  return (
                    <Fragment>
                      <tr>
                        <td colSpan={11} style={{background: '#D6EEEE'}}><b>Mã - Tên vật tư: {item.pseudoId} - {item.productName}</b></td>
                      </tr>
                      <tr key={index}>
                        <td><b>{item.displayId}</b></td>
                        <td><b>{''}</b></td>
                        <td><b>{item.description}</b></td>
                        <td><b>{item.quantityUom}</b></td>
                        <td style={{textAlign: 'right'}}><b>{FormatDisplay.number(item.unitAmount)}</b></td>
                        <td style={{textAlign: 'right'}}><b>{FormatDisplay.number(item.receiptQuantity)}</b></td>
                        <td style={{textAlign: 'right'}}><b>{FormatDisplay.number(item.receiptAmountTotal)}</b></td>
                        <td style={{textAlign: 'right'}}><b>{FormatDisplay.number(item.issuanceQuantity)}</b></td>
                        <td style={{textAlign: 'right'}}><b>{FormatDisplay.number(item.issuanceAmountTotal)}</b></td>
                        <td style={{textAlign: 'right'}}><b>{FormatDisplay.number(item.qoh)}</b></td>
                        <td style={{textAlign: 'right'}}><b>{FormatDisplay.number(item.amountTotal)}</b></td>
                      </tr>
                    </Fragment>
                  )
                } else if (item.isBold === "Y") {
                  return (
                    <tr key={index}>
                      <td><b>{item.displayId}</b></td>
                      <td><b>{''}</b></td>
                      <td><b>{item.description}</b></td>
                      <td><b>{item.quantityUom}</b></td>
                      <td style={{textAlign: 'right'}}><b>{FormatDisplay.number(item.unitAmount)}</b></td>
                      <td style={{textAlign: 'right'}}><b>{FormatDisplay.number(item.receiptQuantity)}</b></td>
                      <td style={{textAlign: 'right'}}><b>{FormatDisplay.number(item.receiptAmountTotal)}</b></td>
                      <td style={{textAlign: 'right'}}><b>{FormatDisplay.number(item.issuanceQuantity)}</b></td>
                      <td style={{textAlign: 'right'}}><b>{FormatDisplay.number(item.issuanceAmountTotal)}</b></td>
                      <td style={{textAlign: 'right'}}><b>{FormatDisplay.number(item.qoh)}</b></td>
                      <td style={{textAlign: 'right'}}><b>{FormatDisplay.number(item.amountTotal)}</b></td>
                    </tr>
                  )
                } else {
                  return (
                    <tr key={index}>
                      <td>{item.displayId}</td>
                      <td>{FormatDisplay.date(new Date(item.effectiveDate), 'DD/MM/YYYY')}</td>
                      <td>{item.description}</td>
                      <td>{item.quantityUom}</td>
                      <td style={{textAlign: 'right'}}>{FormatDisplay.number(item.unitAmount)}</td>
                      <td style={{textAlign: 'right'}}>{FormatDisplay.number(item.receiptQuantity)}</td>
                      <td style={{textAlign: 'right'}}>{FormatDisplay.number(item.receiptAmountTotal)}</td>
                      <td style={{textAlign: 'right'}}>{FormatDisplay.number(item.issuanceQuantity)}</td>
                      <td style={{textAlign: 'right'}}>{FormatDisplay.number(item.issuanceAmountTotal)}</td>
                      <td style={{textAlign: 'right'}}>{FormatDisplay.number(item.qoh)}</td>
                      <td style={{textAlign: 'right'}}>{FormatDisplay.number(item.amountTotal)}</td>
                    </tr>
                  )
                }
              })
            }
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );

}
