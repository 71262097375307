import {EntityService} from '../../../../service/EntityService';

export class RequestsService extends EntityService {

  static entity = 'iam-manager/requests';

  static new(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/new-agreement`, data).then(res => res.data);
  }

  static renew(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/renew`, data).then(res => res.data);
  }

  static approve(requestId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${requestId}/approve`).then(res => res.data);
  }

  static reject(requestId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${requestId}/reject`).then(res => res.data);
  }

}
