import {createRoot} from 'react-dom/client';
import {HashRouter, Route, Routes} from 'react-router-dom';

import 'primereact/resources/themes/lara-light-blue/theme.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import {ConfirmPopup} from 'primereact/confirmpopup';
import {PrimeReactProvider} from 'primereact/api';
import {AppConfirmDialog, AppToast, DefaultConf, ScrollToTop} from '@iamsoftware/react-hooks';

import './assets/layout/layout.scss';
import '@iamsoftware/react-hooks/assets/style.scss';
import './App.scss';

import {LayoutProvider} from 'src/layout/context/layoutcontext';
import {AppProvider} from 'src/layout/context/appcontext';

import {AppLoading} from './AppLoading';

import App from './app';
import Login from './pages/login';

document.documentElement.style.fontSize = '14px';

DefaultConf.DataTable.ItemsField = 'listData';

const root = createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HashRouter>
    <ScrollToTop>
      <PrimeReactProvider>
        <LayoutProvider>
          <AppProvider>
            <ConfirmPopup/>
            <Routes>
              <Route path="login" element={<Login/>}/>
              <Route path="*" element={<App/>}/>
            </Routes>
            <AppLoading/>
            <AppToast/>
            <AppConfirmDialog/>
          </AppProvider>
        </LayoutProvider>
      </PrimeReactProvider>
    </ScrollToTop>
  </HashRouter>
);