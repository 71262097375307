import {Fragment, useEffect, useRef, useState} from 'react';

import { Dialog } from 'primereact/dialog';
import {Button} from 'primereact/button';
import {LazadaConnectionServices as Service} from './LazadaConnectionServices';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDataTableBasic, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

export default function LazadaConnection({code}) {
  const header = 'Danh sách sàn Lazada đã kết nối'
  const dataKey = 'productStoreId';

  const [displayHistories, setDisplayHistories] = useState(false)
  const [displayDetail, setDisplayDetail] = useState(false)
  const [historiesData, setHistoriesData] = useState(null)
  const [detailHis, setDetailHis] = useState(null)


  const headerTable = (
    <div className="flex justify-content-between align-items-center">
      <span>{header}</span>
      <Button icon="pi pi-plus" size="small" severity="success" className="mr-4 mb-1 h-2rem" onClick={() => doCreate()} label="Thêm"></Button>
    </div>
  )

  const {render: renderDataTable,reloadLazyData} = useDataTable({
    tableHeader: headerTable,
    dataKey,
    stateKey:'mainTable',
    indexColumnWidth:45,
    columns: [
      {field:'externalCode', header:'Mã gian hàng', matchMode:'contains', minWidth:150},
      {field:'storeName', header:'Tên gian hàng', matchMode:'contains', minWidth:250},
      {field:'ownerName', header:'Chủ sở hữu', matchMode:'contains', minWidth:200},
      {field:'defaultLocation', header:'Địa điểm', matchMode:'contains', minWidth:200},
      {field:'defaultEmailAddress', header:'Email', matchMode:'contains', minWidth:200},
      {field:'tokenValidDate', header:'Ngày kết nối', matchMode:'contains', minWidth:175, dataType:'date-time'},
      {field:'refreshTokenExpire', header:'Ngày hết hạn', matchMode:'contains', minWidth:175, dataType:'date-time'},
      {field:'status', header:'Trạng thái', matchMode:'contains', minWidth:150},
    ],
    getList(lazyLoadEvent: any): Promise<any> {
      return Service.getList(lazyLoadEvent)
    },
    actionColumnWidth:90,
    initActions(items: Array<any>) {
      if (items){
        items.forEach(item =>{
          item.actions = [
            {icon: 'pi pi-history', title: 'Lịch sử đồng bộ', className: 'p-button-warning', command: () => getHistories(item)},
          ]
          if (item.statusId === 'EecConnected'){
            item.actions.push({icon: 'pi icons8-disconnected', title: 'Ngắt kết nối', className: 'p-button-secondary', command: () => doDisconnect(item)},)
          }
        })
      }
    }
  })

  const {render: renderDataTableBasic, selectedItems} = useDataTableBasic({
    tableHeader:'',
    dataKey:'messageId',
    columns:[
      {field:'messageId', header:'Mã yêu cầu', minWidth: 200},
      {field:'messageDate', header:'Thời gian', width: 200, dataType: 'date-time'},
      {field:'systemMessageType', header:'Loại', minWidth: 200},
      {field:'status', header:'Trạng thái', minWidth: 150},
    ],
    items: historiesData,
    actionColumnWidth:45,
  })

  const {render: renderDataTableBasic1} = useDataTableBasic({
    tableHeader:'',
    dataKey:'messageId',
    columns:[
      {field:'messageId', header:'Mã yêu cầu', minWidth: 200},
      {field:'messageDate', header:'Thời gian', width: 200, dataType: 'date-time'},
      {field:'status', header:'Trạng thái', minWidth: 150},
      {field:'sentText', header:'requestData', minWidth: 200},
      {field:'messageText', header:'responseData', minWidth: 200},
    ],
    items: detailHis,
  })

  const doCreate = () =>{
    ConfirmDialogService.confirm('Tạo mới kết nối sàn','Bạn xác nhận muốn thêm kết nối mới',()=>{
      Service.getLink().then(data =>{
        window.open(`${data.link}`)
      })
    })
  }

  const doDisconnect = (item) => {
    if (item.productStoreId){
      ConfirmDialogService.confirm('Ngắt kết nối', 'Bạn xác nhận muốn ngắt kết nối?', ()=>{
        Service.disconnect(item.productStoreId).then(()=>{
          ToastService.success();
          reloadLazyData()
        })
      })
    }
  }

  const getHistories = (item) => {
    Service.getHistories(item.productStoreId).then(({listData}) =>{
      setHistoriesData(listData.map(item =>{
        item.actions = [
          {icon:'pi pi-eye', title: 'Xem chi tiết', className: 'p-button-info', command: (e) => getDetailHis(item,e)}
        ]
        return item
      }));
      setDisplayHistories(true);
    })
  }

  const getDetailHis = (item,e) =>{
    Service.getDetailHis(item.systemMessageId).then(data =>{
      data.messageDate = FormatDisplay.dateTime(data.messageDate)
      setDetailHis(data)
      setDisplayDetail(true)
    })
  }

  //Tạo kết nối với sàn lazada
  useEffect(() => {
    if (code){
      document.getElementById("overlay").style.display ="block";
      setTimeout(()=>{
        Service.createCon({"code": code}).then(()=>{
          ToastService.success()
          reloadLazyData();
        }).finally(()=>{
          const origin = `${window.location.origin}${window.location.pathname}${window.location.hash}`
          document.getElementById("overlay").style.display ="none";
          window.history.replaceState(null,null,origin)
        })
      },3000)
    }
  }, [code]);


  return (
    <div className="grid">
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12.5rem)'}}>
        {renderDataTable()}
      </div>
      <div id="overlay">
        <div className="loader"></div>
      </div>
      <Dialog header="Lịch sử đồng bộ" visible={!!displayHistories} style={{width:'60%', height:'60%'}} draggable={false} onHide={() => setDisplayHistories(false)}>
        <div className="mt-3">
          {renderDataTableBasic()}
        </div>
      </Dialog>
      <Dialog header="Chi tiết yêu cầu" visible={!!displayDetail} style={{width: '60%', height: '60%'}} draggable={false} onHide={() => setDisplayDetail(false)}>
        <div className="mt-3">
          <div className="flex col-12">
            <div className="flex flex-column gap-2 col-4">
              <label htmlFor="messageId">Mã yêu cầu</label>
              <InputText id="messageId" value={detailHis?.messageId}/>
            </div>
            <div className="flex flex-column gap-2 col-4">
              <label htmlFor="messageDate">Thời gian </label>
              <InputText id="messageDate" value={detailHis?.messageDate}/>
            </div>
            <div className="flex flex-column gap-2 col-4">
              <label htmlFor="status">Trạng thái</label>
              <InputText id="status" value={detailHis?.status}/>
            </div>
          </div>
          <div className="flex col-12">
            <div className="flex flex-column gap-2 col-12">
              <label htmlFor="sentText">requestData</label>
              <InputText id="sentText" value={detailHis?.sentText}/>
            </div>
          </div>
          <div className="flex col-12">
            <div className="flex flex-column gap-2 col-12">
              <label htmlFor="messageText">responseData</label>
              <InputTextarea id="messageText" style={{height:'100px'}} value={detailHis?.messageText}/>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
