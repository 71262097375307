import {useEffect, useState} from 'react';
import {Row} from 'primereact/row';
import {Column} from 'primereact/column';
import {ColumnGroup} from 'primereact/columngroup';
import {useDataTable} from '@iamsoftware/react-hooks';
import {EmployeePaymentService as Service} from './EmployeePaymentService';
import {Button} from 'primereact/button';
import {NewEmployeePayment} from './NewEmployeePayment';
import {useCrupEmployeePayment} from './CrupEmployeePayment'

export const EmployeePayments = () => {
  const header = 'Bảng lương';
  const dataKey = 'displayId';
  const [headerResult, setHeaderResult] = useState(null);
  const [displayCreate, setDisplayCreate] = useState(null)

  useEffect(() => {
    setHeaderResult(tabHeader)
  }, []);

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    tableHeader: headerResult,
    originalProps: {
      headerColumnGroup: <ColumnGroup>
        <Row style={{textAlign: 'center', fontSize: '14px'}}>
          <Column colSpan={1} rowSpan={2} header={'STT'} style={{minWidth: '45px'}}/>
          <Column colSpan={1} rowSpan={2} header={'Mã bảng lương'}></Column>
          <Column colSpan={1} rowSpan={2} header={'Tên bảng lương'}></Column>
          <Column colSpan={1} rowSpan={2} header={'Thời gian'}></Column>
          <Column colSpan={1} rowSpan={2} header={'Từ ngày'}></Column>
          <Column colSpan={1} rowSpan={2} header={'Đến ngày'}></Column>
          <Column colSpan={1} rowSpan={2} header={'Thực lĩnh'}></Column>
          <Column colSpan={1} rowSpan={2} header={'Thuế TNCN'}></Column>
          <Column header={'BHXH'} colSpan={2} rowSpan={1} headerStyle={{textAlign: 'center', fontSize: '14px'}} style={{textAlign:'center'}}/>
          <Column header={'BHYT'} colSpan={2} rowSpan={1} headerStyle={{textAlign: 'center', fontSize: '14px'}}/>
          <Column header={'BHTN'} colSpan={2} rowSpan={1} headerStyle={{textAlign: 'center', fontSize: '14px'}}/>
          <Column colSpan={1} rowSpan={2} header={''} style={{minWidth: '90px'}}></Column>
        </Row>
        <Row style={{textAlign: 'center', fontSize: '14px'}}>
          <Column colSpan={1} header={'NV Đóng'} headerStyle={{textAlign: 'center', fontSize: '14px'}}></Column>
          <Column colSpan={1} header={'CTY Đóng'} headerStyle={{textAlign: 'center', fontSize: '14px'}}></Column>
          <Column colSpan={1} header={'NV Đóng'} headerStyle={{textAlign: 'center', fontSize: '14px'}}></Column>
          <Column colSpan={1} header={'CTY Đóng'} headerStyle={{textAlign: 'center', fontSize: '14px'}}></Column>
          <Column colSpan={1} header={'NV Đóng'} headerStyle={{textAlign: 'center', fontSize: '14px'}}></Column>
          <Column colSpan={1} header={'CTY Đóng'} headerStyle={{textAlign: 'center', fontSize: '14px'}}></Column>
        </Row>
      </ColumnGroup>
    },
    stateKey: 'mainTable',
    dataKey,
    columns: [
      {
        field: 'displayId', header: 'Mã bảng lương', minWidth: 140, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <a onClick={() => doRowView(rowData)}>
            {rowData.displayId}
          </a>
        }
      },
      {field: 'paySalaryName', header: 'Tên bảng lương', width: 150, matchMode: 'contains'},
      {
        field: 'year', header: 'Thời gian', width: 125, dataType: 'custom', customCell(rowData: any): any {
          return (
            `${rowData.periodNum}/${rowData.yearInt}`
          )
        }
      },
      {field: 'fromDate', header: 'Từ ngày', width: 125, matchMode: 'contains', dataType: 'date'},
      {field: 'thruDate', header: 'Đến ngày', width: 125, matchMode: 'contains', dataType: 'date'},
      {field: 'netPayAmount', header: 'Thực lĩnh', width: 150, matchMode: 'contains', dataType: 'number'},
      {field: 'taxableIncome', header: 'Thuế TNCN', width: 150, matchMode: 'contains', dataType: 'number'},
      {field: 'employeeSocialInsurance', header: 'BHXH NV đóng', width: 150, matchMode: 'contains', dataType: 'number'},
      {field: 'employerSocialInsurance', header: 'BHXH CTY đóng', width: 150, matchMode: 'contains', dataType: 'number'},
      {field: 'employeeMedicalInsurance', header: 'BHYT NV đóng', width: 150, matchMode: 'contains', dataType: 'number'},
      {field: 'employerMedicalInsurance', header: 'BHYT CTY đóng', width: 150, matchMode: 'contains', dataType: 'number'},
      {field: 'employeeUnemplInsurance', header: 'BHTN NV đóng', width: 150, matchMode: 'contains', dataType: 'number'},
      {field: 'employerUnemplInsurance', header: 'BHTN CTY đóng', width: 150, matchMode: 'contains', dataType: 'number'},
    ],
    indexColumnWidth: 100,
    actionColumnWidth: 100,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-eye', title: 'Xem chi tiết', className: 'p-button-info', command: () => doRowView(item)},
            {icon: 'pi pi-pencil', title: 'Cập nhật', className: 'p-button-warning', command: () => doRowUpdate(item)},
          ]
        })
      }
    }
  })
  const {renderCrup, doUpdate} = useCrupEmployeePayment({reloadLazyData})
  const doRowUpdate = (input) => {
    input.doUpdate = true
    setDisplayCreate(input)
  }
  const doRowView = (input) => {
    doUpdate(input, true)
  }
  const doCreate = () => {
    setDisplayCreate(Date.now())
  }
  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <span>{header}</span>
        <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"
                size="small" onClick={doCreate}/>
      </div>
    </div>
  );
  return (
    <div className="grid">
      <div className="col-12">
        <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12.5rem)'}}>
          {renderDataTable()}
        </div>
      </div>
      <NewEmployeePayment display={displayCreate} setDisplay={setDisplayCreate} reloadLazyData={reloadLazyData}/>
      {renderCrup()}
    </div>
  );
}