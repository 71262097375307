import {Route, Routes} from 'react-router-dom';

import DoanhThu from './doanh-thu';
import DoanhThuTheoKhachHang from './doanh-thu-theo-khach-hang';
import DoanhThuTheoSanPham from './doanh-thu-theo-san-pham';

export default function BaoCao() {

  return (
    <Routes>
      <Route path="doanh-thu" element={<DoanhThu/>}/>
      <Route path="doanh-thu-theo-khach-hang" element={<DoanhThuTheoKhachHang/>}/>
      <Route path="doanh-thu-theo-san-pham" element={<DoanhThuTheoSanPham/>}/>
    </Routes>
  );
}
