import {EntityService} from 'src/service/EntityService';

export class ProductService extends EntityService {

  static entity = '/iam-hkd/products';

  static getList(lazyLoadEvent, disabled): Promise<any> {
    return this.axios.get(`${this.entity}/list?lazyLoadEvent=${lazyLoadEvent}&disabled=${disabled}`).then(res => res.data);
  }
  static getProductType(): Promise<any> {
    return this.axios.get(`iam-common/product-types`).then(res => res.data);
  }
  static getUomList(): Promise<any> {
    return this.axios.get(`iam-common/uoms`).then(res => res.data);
  }
  static createUom(data:any): Promise<any> {
    return this.axios.post(`iam-common/uoms`,data).then(res => res.data);
  }
  static genProductPseudoId(): Promise<any> {
    return this.axios.get(`${this.entity}/gen-pseudo-id`).then(res => res.data);
  }
  static find(term: string): Promise<any> {
    return this.axios.get(`${this.entity}/find`, {params: {term}}).then(res => res.data);
  }

  static uploadExcel(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/import-excel`, data).then(res => res.data);
  }


}
