import {EntityService} from 'src/service/EntityService';

export class AgentsService extends EntityService {

  static entity = 'iam-manager/agents';

  static getTreeList(includeAll?: string): Promise<any> {
    return this.axios.get(`${this.entity}/tree-list`, {params: {includeAll}}).then(res => res.data);
  }

}
