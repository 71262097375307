import {Fragment, useEffect, useState} from 'react';
import {useDataTableBasic} from '@iamsoftware/react-hooks';
import {CommonService} from '../../../service/CommonService';

export const useCrupItemsByGdt = ({display}) =>{
  const dataKey = '_id';

  const [items, setItems] = useState([]);
  const [disabledTax, setDisabledTax] = useState(false);
  const [total, setTotal] = useState('');
  const [itemTaxTypeEnumIds, setItemTaxTypeEnumIds] = useState(null)
  const [dirty, setDirty] = useState(false)


  useEffect(() => {
    if (display) {
      setSelectedItems(null);
      CommonService.getTaxRate().then(({listData})=>{
        setItemTaxTypeEnumIds(listData.map(item =>{
          return {...item,value: item.enumId, label: item.description}
        }))
      })
    }
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps

  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>{total}</span>
        </Fragment>
      </div>
    </div>
  );

  const onEditorChange = async (rowData, column, newValue) => {
    //form da bi sua
    if (dirty === false){
      setDirty(true)
    }
    setItems(prevOrderItems => prevOrderItems.map(prevOrderItem => {
      if (prevOrderItem._id === rowData._id){
        if (column.field === 'itemTaxTypeEnumId'){
          var taxNum = 0
          itemTaxTypeEnumIds.map(item =>{
            if (newValue === item.value && Number(item.enumCode)){
              taxNum = Number(item.enumCode)
            }
          })
          prevOrderItem.tthue = rowData.thtien * taxNum /100
        }
        return {
          ...prevOrderItem,
          [column.field]: newValue
        }
      }
      return prevOrderItem;
    }))
  }

  const {render, selectedItems, setSelectedItems} = useDataTableBasic({
    tableHeader: tabHeader,
    dataKey,
    indexColumnWidth: 40,
    columns: [
      {field: 'mhhdvu', header: 'Mã hàng hóa', width: 100, dataType:'editor', editorConfig:{
        onEditorChange
        }},
      {field: 'thhdvu', header: 'Tên hàng hóa', width: 150},
      {field: 'dvtinh', header: 'Đơn vị', width: 75},
      {field: 'sluong', header: 'Số lượng', width: 75, dataType: 'editor',editorConfig:{
          onEditorChange, field: {type: 'InputNumber', InputNumberProps:{min: 0}}
        }},
      {field: 'dgia', header: 'Đơn giá', width: 100, dataType: 'editor', editorConfig:{
          onEditorChange, field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }},
      {field: 'tlckhau', header: '% Chiết khấu', width: 75, dataType: 'number', hidden: display?.discountTypeEnumId === 'InvDiscNotApplicable'},
      {field: 'stckhau', header: 'Số tiền CK', width: 100, dataType: 'number', hidden: display?.discountTypeEnumId === 'InvDiscNotApplicable'},
      {field: 'thtien', header: 'Thành tiền', width: 100, dataType: 'number'},
      {field: 'itemTaxTypeEnumId', header: '% Thuế', width: 100,hidden: disabledTax, className:'editor-dropdown', dataType: 'editor', editorConfig:{
        onEditorChange, disabled: disabledTax,
          field:{type:'Dropdown', DropdownProps:{options: itemTaxTypeEnumIds}}
        }},
      {field: 'tthue', header: 'Tiền thuế', width: 100,hidden: disabledTax, dataType: 'editor', editorConfig:{
        onEditorChange, disabled: disabledTax,
          field: {type: 'InputNumber', InputNumberProps:{min:0}}
        }},
    ],
    items,
  });


  const refreshData = (data) => {
    if (data?.length > 0) {
      setTotal(`${data.length} Sản phẩm`)
    }
  }
  useEffect(() => {
    refreshData(items)
  }, [items, items?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderProducts = () => {
    return (
      <div className="grid">
        <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 20.5rem)'}}>
          {render()}
        </div>
      </div>
    );
  }
  return {renderProductsGdt: renderProducts, itemsGdt: items, setItemsGdt: setItems, setDisabledTax, dirtyGdt: dirty};
}