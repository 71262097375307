import {Fragment, useState} from 'react';

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {ToastService, useDataTable, useToolbar} from '@iamsoftware/react-hooks';

import {EmailMessagesService as Service} from './EmailMessagesService';

export const EmailMessages = () => {

  const dataKey = 'emailMessageId';

  const [display, setDisplay] = useState(false);

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    dataKey,
    columns: [
      {field: dataKey, header: 'Mã', width: 110, matchMode: 'contains'},
      {field: 'emailTemplateName', header: 'Loại email', minWidth: 150, matchMode: 'contains'},
      {field: 'toUserName', header: 'Mã số thuế', width: 140, matchMode: 'contains'},
      {field: 'toAddresses', header: 'Đến địa chỉ', minWidth: 170, matchMode: 'contains'},
      {field: 'sentDate', header: 'Ngày gửi', width: 165, dataType: 'date-time'},
      {
        field: 'fromUsername', header: 'Người gửi', minWidth: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return `${rowData.fromUsername} - ${rowData.fromUserFullName}`;
        }
      },
      {field: 'status', header: 'Trạng thái', width: 150, matchMode: 'contains'}
    ],
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    }
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doView = () => {
    if (selectedItem && selectedItem[dataKey]) {
      setDisplay(true);
    }
  }

  const doSend = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.send(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }

  const {renderToolbar} = useToolbar({
    hasSelectedItem: selectedItem,
    doView,
    leftButtons: <Fragment>
      <Button label="Gửi lại" icon="pi pi-send" className="p-button-warning" onClick={doSend} disabled={!selectedItem}/>
    </Fragment>
  });

  const footer = (
    <div>
      <Button label="Gửi lại" icon="pi pi-send" className="p-button-warning p-button-sm mr-2" type="button" onClick={doSend}/>
      <Button label="Đóng" icon="pi pi-times" className="p-button-outlined p-button-secondary p-button-sm" type="button" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <div className="grid">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12">
        {renderDataTable()}
      </div>

      <Dialog header="Nội dung" footer={footer} visible={display} style={{width: '890px'}} position="top" onHide={() => setDisplay(false)}>
        {selectedItem && selectedItem.body && <div dangerouslySetInnerHTML={{__html: selectedItem.body}}></div>}
      </Dialog>
    </div>
  );

}