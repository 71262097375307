import {Fragment, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {Button} from 'primereact/button';
import {confirmPopup} from 'primereact/confirmpopup';

import {FormatDisplay, ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {RequestsService as Service} from './RequestsService';
import {ProductsService} from '../../category/products/ProductsService';
import {CustomersService} from '../list/CustomersService';
import {CommonService} from '../../../../service/CommonService';

interface Props {
  me: any,
  beCreate?: boolean
}

export const Requests = (props: Props) => {

  const header = 'Yêu cầu';
  const dataKey = 'requestId';

  const {search} = useLocation();

  const [provinces, setProvinces] = useState([]);
  const [counties, setCounties] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    CommonService.getProvinces().then(data => {
      setProvinces(data.listData.map(item => {
        return {value: item.toGeoId, label: item.geoName}
      }));
    });
    ProductsService.getList('').then(data => {
      setProducts(data.listData.map(item => {

        const label = [];
        if (item.pseudoId) {
          label.push(item.pseudoId);
        }
        if (item.amountValue) {
          label.push(item.amountValue);
        }
        if (item.periodNum && item.periodUom) {
          label.push(`${item.periodNum} ${item.periodUom}`);
        }
        label.push(`${FormatDisplay.number(item.price)}  ₫`);

        return {value: item.productId, label: label.join(' | ')}
      }));
    });
  }, []);

  useEffect(() => {
    if (props.beCreate && doCreate) {
      doCreate();
    }
  }, [props.beCreate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search);
      if (params.get('renew')) {
        const event = {
          filters: {
            partyId: {value: params.get('renew'), matchMode: 'equals'}
          }
        };
        CustomersService.getList(JSON.stringify(event)).then(data => {
          if (data?.listData && data.listData?.length === 1) {
            const _value = {
              customerPartyId: data.listData[0].partyId,
              partyTaxId: data.listData[0].pseudoId,
              partyName: data.listData[0].partyName
            }
            createRenew(_value);
          }
        });
      }
    }
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    dataKey,
    columns: [
      {field: 'requestId', header: 'Mã', width: 110, matchMode: 'contains'},
      {field: 'requestTypeEnum', header: 'Loại yêu cầu', width: 150, matchMode: 'contains'},
      {field: 'customerName', header: 'Tên khách hàng', minWidth: 150, matchMode: 'contains'},
      {field: 'partyTaxId', header: 'Mã số thuế', width: 140, matchMode: 'contains'},
      {field: 'customerContactName', header: 'Tên người liên hệ', width: 170, matchMode: 'contains'},
      {field: 'requestDate', header: 'Ngày tạo', width: 165, dataType: 'date-time'},
      {field: 'agentPseudoId', header: 'Thuộc đại lý', width: 150, matchMode: 'contains'},
      {field: 'status', header: 'Trạng thái', width: 150, matchMode: 'contains'},
      {field: 'description', header: 'Ghi chú', width: 150, matchMode: 'contains'}
    ],
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    }
  });

  const getByTaxCode = () => {
    const _value = form.getRawValue();
    if (_value.partyTaxId) {
      CommonService.getByTaxCode(_value.partyTaxId, 'N').then(({data}) => {
        if (data?.partyName) {
          _value.partyName = data.partyName;
          _value.address1 = data.address1;
          _value.stateProvinceGeoId = data.stateProvinceGeoId;
          _value.countyGeoId = data.countyGeoId;
          form.setValue(_value);
        }
      });
    }
  }

  const {render: renderDialogCrup, create, update, form} = useDialogCrup({
    header,
    dataKey,
    width: '60rem',
    fields: [
      {field: 'representativePartyId', type: 'hidden'},
      {field: 'itemProducts', type: 'hidden'},
      {field: 'orderItemSeqId', type: 'hidden'},
      {field: 'partyTaxId', header: 'Mã số thuế', InputTextProps: {maxLength: 15, keyfilter: 'int'}, className: 'md:col-4'},
      {field: 'getByTaxCode', header: '', type: 'custom', body: <Button icon="pi pi-search" className="p-button-rounded p-button-success" onClick={getByTaxCode}/>, className: 'md:col-1 pt-4 pl-0'},
      {field: 'partyName', header: 'Tên khách hàng', required: true, InputTextProps: {maxLength: 500}, className: 'md:col-7'},
      {field: 'address1', header: 'Địa chỉ', required: true, InputTextProps: {maxLength: 500}, className: 'md:col-12'},
      {field: 'stateProvinceGeoId', header: 'Tỉnh/Thành phố', required: true, type: 'Dropdown', DropdownProps: {options: provinces, filter: true}, className: 'md:col-6'},
      {field: 'countyGeoId', header: 'Quận/Huyện', required: true, type: 'Dropdown', DropdownProps: {options: counties, filter: true}, className: 'md:col-6'},
      {field: 'phoneNumber', header: 'Số điện thoại', InputTextProps: {maxLength: 17, keyfilter: 'int'}, className: 'md:col-6'},
      {field: 'emailAddress', header: 'Email', required: true, InputTextProps: {maxLength: 50}, className: 'md:col-6'},
      {field: 'representativeName', header: 'Người đại diện', required: true, InputTextProps: {maxLength: 500}, className: 'md:col-6'},
      {field: 'representativeJobTitle', header: 'Chức vụ', required: true, InputTextProps: {maxLength: 500}, className: 'md:col-6'},
      {field: 'productId', header: 'Gói dịch vụ', required: true, type: 'Dropdown', DropdownProps: {options: products}, className: 'md:col-6'},
      {field: 'effectiveDate', header: 'Ngày bắt đầu sử dụng', type: 'Calendar', className: 'md:col-6'},
      {field: 'description', header: 'Ghi chú', className: 'md:col-12'}
    ],
    createItem: item => {
      item.contactParties = [{
        partyName: item.representativeName,
        jobTitle: item.representativeJobTitle,
        emailAddress: item.emailAddress,
        phoneNumber: item.phoneNumber
      }];
      item.itemProducts = [{
        productId: item.productId
      }];
      delete item.productId;
      return Service.new(item);
    },
    updateItem: (id, item) => {
      item.contactParties = [{
        partyId: item.representativePartyId,
        partyName: item.representativeName,
        jobTitle: item.representativeJobTitle,
        emailAddress: item.emailAddress,
        phoneNumber: item.phoneNumber
      }];

      const itemProductDelIds = [];
      item.itemProducts.forEach(product => {
        if (product.productId !== item.productId) {
          itemProductDelIds.push(product.orderItemSeqId);
        }
      });
      item.itemProducts = [{
        productId: item.productId,
        orderItemSeqId: item.orderItemSeqId
      }];
      item.itemProductDelIds = itemProductDelIds;
      return Service.update(id, item);
    },
    reloadLazyData
  });

  const {stateProvinceGeoId} = form.getRawValue();
  useEffect(() => {
    if (stateProvinceGeoId) {
      CommonService.getCounties(stateProvinceGeoId).then(data => {
        setCounties(data.listData.map(item => {
          return {value: item.toGeoId, label: item.geoName}
        }));
      });
    }
  }, [stateProvinceGeoId]);

  const searchCustomer = () => {
    const _value = formRenew.getValue();
    if (_value.partyTaxId) {
      const event = {
        filters: {
          pseudoId: {value: _value.partyTaxId, matchMode: 'equals'}
        }
      };
      CustomersService.getList(JSON.stringify(event)).then(data => {
        if (data?.listData && data.listData?.length === 1) {
          _value.partyName = data.listData[0].partyName;
          _value.customerPartyId = data.listData[0].partyId;
          formRenew.setValue(_value);
        }
      });
    }
  }

  const {render: renderDialogRenew, create: createRenew, update: updateRenew, form: formRenew} = useDialogCrup({
    header: 'Gia hạn',
    dataKey,
    width: '60rem',
    fields: [
      {field: 'customerPartyId', type: 'hidden'},
      {field: 'itemProducts', type: 'hidden'},
      {field: 'orderItemSeqId', type: 'hidden'},
      {field: 'partyTaxId', header: 'Mã số thuế', required: true, InputTextProps: {maxLength: 15, keyfilter: 'int'}, className: 'md:col-4'},
      {field: 'getByTaxCode', header: '', type: 'custom', body: <Button icon="pi pi-search" className="p-button-rounded p-button-success" onClick={searchCustomer}/>, className: 'md:col-1 pt-4 pl-0'},
      {field: 'partyName', header: 'Tên khách hàng', required: true, disabled: true, className: 'md:col-7'},
      {field: 'productId', header: 'Gói dịch vụ', required: true, type: 'Dropdown', DropdownProps: {options: products}, className: 'md:col-12'},
      {field: 'description', header: 'Ghi chú', className: 'md:col-12'},
      {field: 'isCreateAgreement', header: 'Tạo hợp đồng', type: 'Checkbox', className: 'md:col-6'}
    ],
    createItem: item => {
      item.itemProducts = [{
        productId: item.productId
      }];
      delete item.productId;
      return Service.renew(item);
    },
    updateItem: (id, item) => {
      const itemProductDelIds = [];
      item.itemProducts.forEach(product => {
        if (product.productId !== item.productId) {
          itemProductDelIds.push(product.orderItemSeqId);
        }
      });
      item.itemProducts = [{
        productId: item.productId,
        orderItemSeqId: item.orderItemSeqId
      }];
      item.itemProductDelIds = itemProductDelIds;
      return Service.update(id, item);
    },
    reloadLazyData
  });

  let doCreate;
  if (props.me.roleTypeId === 'Agent') {
    doCreate = () => create({effectiveDate: new Date()})
  }

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doUpdate = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.get(selectedItem[dataKey]).then(data => {

        if (data.effectiveDate) {
          data.effectiveDate = new Date(data.effectiveDate);
        }
        data.productId = data.itemProducts[0].productId;
        data.orderItemSeqId = data.itemProducts[0].orderItemSeqId;

        switch (data.requestTypeEnumId) {
          case 'RqtExtensionAgreement':
            updateRenew(data);
            break;
          case 'RqtNewAgreement':
            update(data);
            break;
        }
      });
    }
  }

  const doApprove = event => {
    if (selectedItem && selectedItem[dataKey]) {
      confirmPopup({
        target: event.currentTarget,
        message: 'Xác nhận Duyệt khách hàng này?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          Service.approve(selectedItem[dataKey]).then(() => {
            ToastService.success();
            reloadLazyData();
          })
        }
      });
    }
  }
  const doReject = event => {
    if (selectedItem && selectedItem[dataKey]) {
      confirmPopup({
        target: event.currentTarget,
        message: 'Xác nhận Từ chối khách hàng này?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          Service.reject(selectedItem[dataKey]).then(() => {
            ToastService.success();
            reloadLazyData();
          })
        }
      });
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate,
    hasSelectedItem: selectedItem,
    doUpdate,
    leftButtons: <Fragment>
      <Button label="Gia hạn" icon="pi pi-calendar-plus" className="p-button-info mr-2" onClick={createRenew}/>
      <Button label="Duyệt" icon="pi pi-check" className="p-button-success mr-2" onClick={doApprove} disabled={!selectedItem}/>
      <Button label="Từ chối" icon="pi pi-times" className="p-button-secondary mr-2" onClick={doReject} disabled={!selectedItem}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12">
        {renderDataTable()}
      </div>

      {renderDialogCrup()}
      {renderDialogRenew()}
    </div>
  );
}