import {EntityService} from 'src/service/EntityService';

export class ExpenseService extends EntityService {

  static entity = '/iam-hkd/expense-items';

  static List(lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static createExpense(data: any): Promise<any> {
    return this.axios.post(`${this.entity}`, data).then(res => res.data);
  }

  static updateExpense(id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}`, data).then(res => res.data);
  }

  static deleteExpense(id: string, params?: any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/delete`, params).then(res => res.data);
  }

}