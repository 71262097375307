import {Fragment, useEffect, useState} from 'react';
import {useDataTableBasic} from '@iamsoftware/react-hooks';
import {BussinessJobService} from '../../danh-muc/business-jobs/BussinessJobService';

export const useCrupItemsByGdt = ({display}) => {
  const dataKey = '_id';

  const [items, setItems] = useState([]);
  const [total, setTotal] = useState('');
  const [dirty, setDirty] = useState(false);
  const [nodes, setNodes] = useState(null);

  useEffect(() => {
    if (display) {
      setSelectedItems(null);
      BussinessJobService.getTreeList().then(data => {
        setLabel(data.data)
        setNodes(data.data);
      });
    }
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps

  const setLabel = (data) => {
    data.map(item => {
      items.forEach((data) => {
        if (data.productCategoryId === item.key) {
          data.productCategory = item.data.categoryName;

        }
      })
      item.label = item.data.categoryName
      item.children.map(children => {
        items.forEach((data) => {
          if (data.productCategoryId === children.key) {
            data.productCategory = children.data.categoryName;

          }
        })
        children.label = children.data.categoryName
        return children
      })
      return item
    })
  }

  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>{total}</span>
        </Fragment>
      </div>
    </div>
  );

  const onEditorChange = async (rowData, column, newValue) => {
    //form da bi sua
    if (dirty === false) {
      setDirty(true)
    }
    setItems(prevOrderItems => prevOrderItems.map(prevOrderItem => {
      if (prevOrderItem._id === rowData._id) {
        return {
          ...prevOrderItem,
          [column.field]: newValue
        }
      }
      return prevOrderItem;
    }))
  }

  const {render, setSelectedItems} = useDataTableBasic({
    tableHeader: tabHeader,
    dataKey,
    indexColumnWidth: 40,
    columns: [
      {
        field: 'mhhdvu', header: 'Mã hàng hóa', width: 100, dataType: 'editor', editorConfig: {
          onEditorChange
        }
      },
      {
        field: 'productCategoryId', header: 'Ngành nghề KD', width: 300, className: 'editor-config', dataType: 'editor', editorConfig: {
          onEditorChange, field: {type: 'TreeSelect', TreeSelectProps: {options: nodes}}
        }
      },
      {field: 'thhdvu', header: 'Tên hàng hóa', width: 150},
      {field: 'dvtinh', header: 'Đơn vị', width: 75},
      {field: 'sluong', header: 'Số lượng', width: 75, dataType: 'editor',editorConfig:{
        onEditorChange, field: {type: 'InputNumber', InputNumberProps:{min: 0}}
        }},
      {field: 'dgia', header: 'Đơn giá', width: 100, dataType: 'editor', editorConfig:{
        onEditorChange, field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }},
      {field: 'tlckhau', header: '% Chiết khấu', width: 75, dataType: 'number', hidden: display?.discountTypeEnumId === 'InvDiscNotApplicable'},
      {field: 'stckhau', header: 'Số tiền CK', width: 100, dataType: 'number', hidden: display?.discountTypeEnumId === 'InvDiscNotApplicable'},
      {field: 'thtien', header: 'Thành tiền', width: 100, dataType: 'number'},
    ],
    items,
  });

  const refreshData = (data) => {
    if (data?.length > 0) {
      setTotal(`${data.length} Sản phẩm`)
    }
  }
  useEffect(() => {
    refreshData(items)
  }, [items, items?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderProducts = () => {
    return (
      <div className="grid">
        <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 20.5rem)'}}>
          {render()}
        </div>
      </div>
    );
  }
  return {renderProductsGdt: renderProducts, itemsGdt: items, setItemsGdt: setItems, dirtyGdt: dirty};
}