import {EntityService} from 'src/service/EntityService';

export class UomsService extends EntityService {

  static entity = '/iam-common/uoms';

  static getList(lazyLoadEvent: string): Promise<any> {
    return this.axios.get(`${this.entity}/list?lazyLoadEvent=${lazyLoadEvent}`).then(res => res.data);
  }
  static find(term: string): Promise<any> {
    return this.axios.get(`${this.entity}/find`, {params: {term}}).then(res => res.data);
  }
  static uploadExcel(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/import-excel`, data).then(res => res.data);
  }

}
