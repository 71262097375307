import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {useDataTableBasic} from '@iamsoftware/react-hooks';

import {CashBookService} from './CashBookService';

export const Histories = ({display, setDisplay}) => {

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (display) {
      CashBookService.getHistories(display.paymentMethodId).then(({listData}) => {
        setItems(listData);
      });
    }
  }, [display]);

  const  histories = useDataTableBasic({
    tableHeader:'Lịch sử giao dịch',
    indexColumnWidth:40,
    dataKey:'paymentId',
    columns:[
      {field:'paymentRefNum',header:'Mã giao dịch', minWidth:150, matchMode:'contains'},
      {field:'transTypeDesc',header:'Loại giao dịch',minWidth:250 ,matchMode:'contains'},
      {field:'transMemo',header:'Diễn giải', minWidth:250, matchMode:'contains'},
      {field:'transAmount',header:'Số tiền',dataType:'number', minWidth:150, matchMode:'contains'},
      {field:'postedDate',header:'Ngày giao dịch', minWidth: 150,dataType:'date'}
    ],
    items
  })

  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <Dialog header="Lịch sử giao dịch" footer={footer} visible={!!display} style={{width: '80rem'}} position="top" onHide={() => setDisplay(false)}>
      {histories.render()}
    </Dialog>
  );
}