import {EntityService} from '../../../service/EntityService';

export class PayrollAdjustmentService extends EntityService {
  static entity = 'iam-payroll';

  static getList(lazyLoadEvent: any, disabled: any): Promise<any> {
    return this.axios.get(`${this.entity}/adjustment-types?lazyLoadEvent=${lazyLoadEvent}&disabled=${disabled}`,).then(res => res.data);
  }

  static create(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/adjustment-types`, data).then(res => res.data);
  }

  static disable(payrollAdjustmentId: string): Promise<any> {
    return this.axios.put(`${this.entity}/adjustment-types/${payrollAdjustmentId}/disable`).then(res => res.data);
  }

  static enable(payrollAdjustmentId: string): Promise<any> {
    return this.axios.put(`${this.entity}/adjustment-types/${payrollAdjustmentId}/enable`).then(res => res.data);
  }

  static update(payrollAdjustmentId: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/adjustment-types/${payrollAdjustmentId}/update`, data).then(res => res.data);
  }

  static updateSequenceNum(data: any): Promise<any> {
    return this.axios.put(`${this.entity}/adjustment-types/display-sequence-num`, data).then(res => res.data);
  }


}