import {Route, Routes} from 'react-router-dom';
import LazadaConnection from './lazada';
import ShopeeConnection from './shopee';

export default function Connection({query}) {
  return (
    <Routes>
      <Route path="lazada" element={<LazadaConnection code={query.get('code')}/>}/>
      <Route path="shopee" element={<ShopeeConnection code={query.get('code')} shopId={query.get('shop_id')}/>}/>
    </Routes>
  )
}