import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {ConfirmDialogService, useDataTableBasic, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';
import {UomsService} from '../../danh-muc/uom/UomsService';
import {ProductService} from '../../ban-hang/san-pham/productService';
import {ExpenseService} from '../../danh-muc/khoan-muc-chi-phi/ExpenseService';
import {CommonService} from '../../../service/CommonService';

export const useCrupItems = ({displayDia, disabled}) => {
  const dataKey = 'dataKey';
  const updateTooltip = 'Cập nhật';
  const resendTooltip = 'Xóa';
  const [items, setItems] = useState([]);
  const [products, setProducts] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [deleteInvoiceItemSeqIds, setDeleteInvoiceItemSeqIds] = useState([])
  const [result ,setResult] = useState(null);

  useEffect(() => {
    if (displayDia) {
      setSelectedItems(null);
      //tao id cho items
      items.map(item =>{
        item.dataKey = `${Date.now()}_${item.invoiceItemSeqId}_${Math.random()}`;
        if (disabled === false){
          item.actions = [
            {icon: 'pi pi-trash', className: 'p-button-danger', title: 'Xoá', command: () => doDelete(item)}
          ]
        }
        return item
      })
    }
  }, [displayDia]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (displayDia && items?.length) {
  //     items.forEach((item, index) => {
  //       if (!item[dataKey]) {
  //         item[dataKey] = `${Date.now()}_${Math.random()}_${index}`;
  //       }
  //       if (!item.expenseNameWithCode) {
  //         item.expenseNameWithCode = `[${item.accountCode}] - ${item.accountName}`;
  //       }
  //       item.actions = [
  //         {icon: 'pi pi-pencil', title: updateTooltip, className: 'p-button-warning', command: () => doUpdate(item)},
  //         {icon: 'pi pi-trash', title: resendTooltip, className: 'p-button-danger', command: () => doDelete(item)},
  //       ]
  //     });
  //   }
  // }, [displayDia, items?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    CommonService.glAccountTypes().then(data => {
      setProductTypes(data.listData.map(item => {
        return {value: item.glAccountId, label: `[${item.accountCode}] - ${item.accountName}`}
      }))
    })
    ExpenseService.List('').then(data => {
      setProducts(data.listData.map(item => {
        return {...item, value: item.glAccountCode, label: `[${item.glAccountCode}] - ${item.glAccountName}`};
      }));
    });
  }, []);

  const searchProducts = event => {
    const _event: any = {
      filters: {
        glAccountClassEnumId:{value: result ,matchMode:'equals'}
      }
    };
    ExpenseService.List(JSON.stringify(_event)).then(data => {
      setProducts(data.listData.map(item => {
        return {...item, value: item.glAccountId, label: item.accountCode};
      }));
    });
  }

  const onEditorChange = async (rowData, column, newValue) => {
    setItems(prevOrderItems => prevOrderItems.map(prevOrderItem => {
      if (prevOrderItem.dataKey === rowData.dataKey) {
        if (column.field === 'glAccountCode'){
          var it = products.find((item) => item.glAccountCode === newValue)
          prevOrderItem.description = `Chi trả cho ${it.glAccountName}`;
          prevOrderItem.glAccountName = it.glAccountName;
          prevOrderItem.glAccountId = it.glAccountId;
        }
        return {
          ...prevOrderItem,
          [column.field]: newValue
        }
      }
      return prevOrderItem;
    }))
  }

  //ham tao dong`
  const addBlankItem = () => {
    setItems(prevOrderItems => {
      const dataKey = `${Date.now()}_${Math.random()}`;
      prevOrderItems.push({
        dataKey,
        description: 'Chi trả cho ',
        amount: 0,
        actions: [
          {icon: 'pi pi-trash', className: 'p-button-danger', title: 'Xoá mã hàng', command: () => doDelete(dataKey)}
        ]
      });
      return [...prevOrderItems];
    });
  }

  //Them 1 dong moi
  useEffect(() => {
    if (!items?.length) {
      // if (!items.find(it => !it.itemTypeEnumId) && disabled === false) {
      //   addBlankItem();
      // }
      addBlankItem();
    }
  }, [items]);

  const refreshData = (data) => {
    if (data?.length > 0) {
      let total = 0;
      data.forEach(item => {
        if (item.amount){
          total += item.amount;
        }
        item.actions = []
        if (disabled === false) {
          item.actions.push({icon: 'pi pi-trash', title: 'Xóa', className: 'p-button-danger', command: () => doDelete(item)})
        }
      })
    }
  }
  //Set lai data cho item
  useEffect(() => {
    refreshData(items)
  }, [items, items?.length]); // eslint-disable-line react-hooks/exhaustive-deps


  const {render: renderDialogCrup, create, update, form,display} = useDialogCrup({
    header: '!Chi phí',
    dataKey,
    width: '50rem',
    fields: [
      {
        field: 'productType', header: 'Loại chi phí', required: true, type: 'Dropdown'
        , DropdownProps: {options: productTypes},
        disabled: 'View', className: 'md:col-12'
      },
      {
        field: 'product', header: 'Tên chi phí', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: products, completeMethod: searchProducts, field: 'label', dropdown: true, forceSelection: true},
        disabled: 'View', className: 'md:col-12'
      },
      {field: 'amount', header: 'Số tiền', required: true, type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-4'},
      {field: 'description', header: 'Ghi chú', className: 'md:col-8'},
    ],
    createItem: item => {
      setItems(prevItems => {
        prevItems.push({
          dataKey: `${Date.now()}_${Math.random()}`,
          itemTypeEnumId: item.productType,
          glAccountId: item.product.value,
          accountCode: item.product.accountCode,
          accountName: item.product.accountName,
          amount: item.amount,
          description: item.description
        });
        return prevItems;
      });
      return Promise.resolve(item);
    },
    updateItem: (id, item) => {
      setItems(prevItems => {
        for (const prevItem of prevItems) {
          if (prevItem[dataKey] === id) {
            prevItem.glAccountId = item.product.value;
            prevItem.expenseNameWithCode = item.product.label;
            prevItem.description = item.description;
            prevItem.amount = item.amount;
            break;
          }
        }
        return prevItems;
      });
      return Promise.resolve(item);
    },
    hideToastMessage: true
  });

  const tabHeader = (
      <div>
        <div className="flex align-items-center justify-content-between ">
          <span>{items?.length} chi phí</span>
          {!disabled &&<Button icon="pi pi-plus" outlined severity="info" rounded title="Thêm dòng" className="mr-2 mb-1"
                               size="small" onClick={() => addBlankItem()}/>}
        </div>
      </div>
  );

  const {render, selectedItems, setSelectedItems} = useDataTableBasic({
    tableHeader: tabHeader,
    dataKey,
    indexColumnWidth: 45,
    columns: [
      {field: 'description', header: 'Diễn giải', minWidth: 300, dataType:'editor', className:'editor-config', editorConfig:{
          onEditorChange, readOnly: disabled,
        }},
      {field: 'amount', header: 'Số tiền', width: 200, dataType: 'editor', className:'editor-config', editorConfig:{
          onEditorChange, readOnly: disabled,
          field: {type: 'InputNumber', InputNumberProps:{min: 0}}
        }},
      {
        field: 'glAccountId', header: 'Loại chi phí', hidden: true
      },
      {field: 'glAccountCode',header: 'Khoản mục chi phí', width: 350, dataType:'editor', className:'editor-config',editorConfig:{
        onEditorChange, readOnly: disabled,
          field: {type: 'Dropdown', DropdownProps: {options: products, filter: true}}
        }},
      {
        field: 'glAccountName', header: 'Tên chi phí', width: 300
      },
    ],
    actionColumnWidth:50,
    items
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;


  const doCreate = () => {
    create();
  }


  const doDelete = (data) => {
    if (data && data[dataKey]) {
      ConfirmDialogService.confirm('Xóa sản phẩm', `Xác nhận xóa chi phí này ra khỏi danh sách?`, () => {
        setItems(prevItems => {
          if (data.invoiceItemSeqId){
            deleteInvoiceItemSeqIds.push(data.invoiceItemSeqId)
          }
          return prevItems.filter(prevItem => prevItem[dataKey] !== data[dataKey]);
        });
      });
    }
    setSelectedItems([]);
  }

  const {renderToolbar} = useToolbar({
    doCreate,
    hasSelectedItem: selectedItem,
    buttonLabel: {
      create: 'Thêm',
    }
  });

  const renderProducts = () => {
    return (
      <div className="grid">
        {/*{!disabled && <div className="col-12 pb-1">*/}
        {/*  {renderToolbar()}*/}
		{/*		</div>}*/}
        <div className="col-12 py-0" style={{minHeight: '0', maxHeight: 'calc(100vh - 35.4rem)'}}>
          {render()}
        </div>

        {renderDialogCrup()}
      </div>
    );
  }

  return {renderProducts, items, setItems, deleteInvoiceItemSeqIds, setDeleteInvoiceItemSeqIds};
}

