import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDataTableBasic, useDialogCrup, useForm, useToolbar} from '@iamsoftware/react-hooks';
import {XuatkhoService as Service} from './XuatkhoService';
import {EmployeesService} from '../../quan-ly/nhan-vien/EmployeesService';
import {FacilitiesService} from '../kho-hang/FacilitiesService';
import {CustomersService} from '../../ban-hang/khach-hang/CustomersService';

import {Fragment, useEffect, useState} from 'react';
import {Fieldset} from 'primereact/fieldset';
import {Button} from 'primereact/button';
import {useCrupItems} from '../xuat-kho/CrupItems';
import {SalesOrdersService} from '../../ban-hang/don-hang/ordersService';
import {useCrupSO} from '../../ban-hang/don-hang/CrupSO';
import {baseURL} from '../../../config';
import {Dialog} from 'primereact/dialog';

export default function XuatKho() {

  const header = 'Xuất Kho';
  const dataKey = 'shipmentId';
  const updateTooltip = 'Cập nhật';
  const resendTooltip = 'Xóa';
  const [customers, setCustomers] = useState([]);
  const [readOnly, setReadOnly] = useState(false);
  const [displayDia, setDisplayDia] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [enteredParties, setEnteredParties] = useState([]);
  const {renderProducts, items, setItems, deleteShipmentItemSourceIds, setDeleteShipmentItemSourceIds} = useCrupItems({displayDia, disabled});
  const [headerResult, setHeaderResult] = useState(null);
  //lay shipmentId cho dialog pdf
  const [displayMSVT, setDisplayMSVT] = useState(null);

  useEffect(() => {
    setHeaderResult(tabHeader)
  }, []);

  const searchFacilities = event => {
    const _event: any = {
      filters: {
        statusId: {value: 'FacActive', matchMode: 'equals'},
      }
    };
    FacilitiesService.getList(JSON.stringify(_event)).then(data => {
      setFacilities(data.listData.map(item => {
        delete item.disabled;
        let label = `[${item.pseudoId}] - ${item.facilityName}`
        return {...item, value: item.facilityId, label: label};
      }));
    });
  }


  const searchCustomer = event => {
    CustomersService.find('Buyer',event.query).then(data => {
      setCustomers(data.listData.map(item => {
        delete item.disabled;
        let label = `[${item.pseudoId}] - ${item.partyName}`;
        return {...item, value: item.partyId, label: label};
      }));
    });
  }

  const searchEmployees = event => {
    EmployeesService.find(event.query).then(data => {
      setEnteredParties(data.listData.map(item => {
        delete item.disabled;
        let label = `[${item.pseudoId}] - ${item.partyName}`;
        return {...item, value: item.partyId, label: label};
      }));
    });
  }

  const {render: renderDataTable, selectedItems, setSelectedItems, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    tableHeader: headerResult,
    dataKey,
    columns: [
      {
        field: 'originId', header: 'Mã chứng từ', minWidth: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <a onClick={() => {
            Service.get(rowData.shipmentId).then(data => {
              if(data.items[0]?.orderId !== undefined){
                doSwitch(data.items[0].orderId, true)
              }else {
                setDisabled(true)
                if (data.toPartyId) {
                  data.toParty = {value: data.toPartyId, label: `[${data.toPseudoId}] - ${data.toPartyName}`}
                }
                if (data.facilityId) {
                  data.facility = {value: data.facilityId, label: `[${data.facilityPseudoId}] - ${data.facilityName}`}
                }
                if (data.estimatedShipDate) {
                  data.estimatedShipDate = FormatDisplay.date(new Date(data.estimatedShipDate), 'YYYY-MM-DD');
                }
                if (data.enteredByPartyId) {
                  EmployeesService.get(data.enteredByPartyId).then(item => {
                    data.enteredByParty = {value: data.enteredByPartyId, label: `[${item.pseudoId}] - ${item.partyName}`}
                    commonInfoForm.setValue(data);
                  })
                } else {
                  commonInfoForm.setValue(data);
                }
                certInfoForm.setValue(data);
                //them dau vao product cho autocomplete
                data.items = data.items.map(item =>{
                  item.product = {value: item.productId, label: `[${item.pseudoId}] - ${item.productName}`}
                  return item
                })
                setItems(data.items);
                setReadOnly(true);
                view(data);
              }
            });
          }}>
            {rowData.originId}
          </a>
        }
      },
      {field: 'estimatedShipDate', header: 'Ngày tạo', minWidth: 150, dataType: 'date', matchMode: 'contains'},
      {field: 'actualStartDate', header: 'Ngày ghi sổ', minWidth: 150, dataType: 'date', matchMode: 'contains'},
      {field: 'shipmentTypeEnum', header: 'Loại chứng từ', minWidth: 200, matchMode: 'contains'},
      {field: 'toPartyName', header: 'Khách hàng', minWidth: 300, matchMode: 'contains'},
      {field: 'estimatedShipCost', header: 'Tổng giá trị', minWidth: 120, matchMode: 'contains', dataType: 'number'},
      {field: 'status', header: 'Trạng thái', minWidth: 150, matchMode: 'contains'},
    ],
    indexColumnWidth: 45,
    actionColumnWidth:150,
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-print', title: 'PDF phiếu xuất', className: 'p-button-secondary', command: () => setDisplayMSVT(item.shipmentId)}
          ];
          if (item.statusId !== 'ShipDelivered') {
            item.actions.push({icon: 'pi pi-pencil', title: 'Cập nhật', className: 'p-button-warning', command: () => doUpdate(item.shipmentId)},)
            item.actions.push({icon: 'pi pi-check', title: 'Ghi sổ', className: 'p-button-success', command: () => doComplete(item.shipmentId)},)
            item.actions.push({icon: 'pi pi-trash', title: 'Xóa', className: 'p-button-danger', command: () => doDelete(item.shipmentId)},)

          }
          if (item.statusId === 'ShipDelivered') {
            item.actions.push({icon: 'pi pi-times', title: 'Bỏ ghi sổ', className: 'p-button-danger', command: () => doUncomplete(item.shipmentId)},)
          }
        })}
    },
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    }
  });

  const {renderCrupSO, doCreateSO, doUpdateSO} = useCrupSO({reloadLazyData},);

  const commonInfoForm = useForm({
    fields: [
      {display: false},//Fix lỗi không gán được dữ liệu của phần tử đầu tiên trong form bằng update
      {
        field: 'toParty', header: 'Khách hàng', required: true, className: 'md:col-8', type: 'AutoComplete',
        AutoCompleteProps: {suggestions: customers, completeMethod: searchCustomer, field: 'label', dropdown: true, forceSelection: true},
        disabled: 'View'
      },
      {
        field: 'facility', header: 'Kho hàng', required: true, className: 'md:col-4', type: 'AutoComplete',
        AutoCompleteProps: {suggestions: facilities, completeMethod: searchFacilities, field: 'label', dropdown: true, forceSelection: true},
        disabled: 'View'
      },
      {
        field: 'enteredByParty', header: 'Nhân viên thực hiện', className: 'md:col-4', type: 'AutoComplete',
        AutoCompleteProps: {suggestions: enteredParties, completeMethod: searchEmployees, field: 'label', dropdown: true, forceSelection: true},
        disabled: 'View'
      },
      {field: 'deliveryName', header: 'Người nhận', className: 'md:col-4'},
      {field: 'handlingInstructions', header: 'Kèm theo', className: 'md:col-4'},
      {field: 'shipmentComment', header: 'Lý do', className: 'md:col-12'},
      {field: 'address1', header: 'Địa chỉ', className: 'md:col-12'},
    ],
    readOnly
  })

  const certInfoForm = useForm({
    fields: [
      {field: 'originId', header: 'Số chứng từ', required: true, className: 'md:col-12'},
      {field: 'estimatedShipDate', header: 'Ngày chứng từ', InputTextProps: {type: 'date'}, className: 'md:col-12'},
    ]
    ,readOnly
  })

  const {render: renderDialogView, update, create, view, display} = useDialogCrup({
    header: '!Chi tiết xuất kho',
    dataKey,
    width: '120rem',
    fields: [
      {
        type: 'custom', className: 'md:col-8', body: <Fieldset legend="Thông tin chung">
          {commonInfoForm.render()}
        </Fieldset>
      },
      {
        type: 'custom', className: 'md:col-4', body: <Fieldset legend="Chứng từ">
          {certInfoForm.render()}
        </Fieldset>
      },
      {
        type: 'custom', className: 'md:col-12', body: <Fieldset legend="Hàng tiền">
          {renderProducts()}
        </Fieldset>
      },
    ],
    createItem: async item => {
      if (!inject().toPartyId || !inject().facilityId || items.filter(it => it.productId).length <= 0){
        if (!inject().toPartyId){
          ToastService.error('Khách hàng không được rỗng');
        }
        if (!inject().facilityId){
          ToastService.error('Kho hàng không được rỗng');
        }if (items.filter(it => it.productId).length <= 0){
          ToastService.error('Chưa có sản phẩm hoặc sản phẩm không tồn tại trên hệ thống');
        }
        return Promise.reject()
      }else {
        return Service.create(await inject());
      }
    },
    updateItem: async (id, item) => {
      if (!inject().toPartyId || !inject().facilityId || items.filter(it => it.productId).length <= 0){
        if (!inject().toPartyId){
          ToastService.error('Khách hàng không được rỗng');
        }
        if (!inject().facilityId){
          ToastService.error('Kho hàng không được rỗng');
        }if (items.filter(it => it.productId).length <= 0){
          ToastService.error('Chưa có sản phẩm hoặc sản phẩm không tồn tại trên hệ thống');
        }
        return Promise.reject()
      }else {
        return Service.update(id, await inject());
      }
    },
    reloadLazyData
  });

  const {toParty} = commonInfoForm.getValue();

  useEffect(() => {
    if (toParty?.value && commonInfoForm.dirty['toParty']) {
      const _value = commonInfoForm.getRawValue();
      _value.address1 = toParty.address1;
      _value.shipmentComment = `Xuất kho từ ${toParty.partyName}`
      commonInfoForm.setValue(_value);

    }
  }, [toParty?.value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDisplayDia(display)
  }, [display]);

  const inject = () => {
    const item = commonInfoForm.getValue();
    item.toPartyId = item.toParty?.value;
    if(item.toPartyId){
      CustomersService.get(item.toPartyId).then(data =>{
        item.postalContactMechId = data.postalContactMechId;
      })
    }
    item.enteredByPartyId = item.enteredByParty?.value;
    item.facilityId = item.facility?.value;
    if (deleteShipmentItemSourceIds?.length) {
      item.deleteShipmentItemSourceIds = deleteShipmentItemSourceIds;
    }
    item.originId = certInfoForm.getValue().originId;
    item.estimatedShipDate = certInfoForm.getValue().estimatedShipDate;
    item.items = items.filter(it => it.productId)

    return item;
  }

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doUpdate = (data) => {
    if (data) {
      Service.get(data).then(data => {
        if (data.items[0]?.orderId !== undefined) {
          doSwitch(data.items[0]?.orderId, false)
        } else {
          setDisabled(false)
          if (data.toPartyId) {
            data.toParty = {value: data.toPartyId, label: `[${data.toPseudoId}] - ${data.toPartyName}`}
          }
          if (data.facilityId) {
            data.facility = {value: data.facilityId, label: `[${data.facilityPseudoId}] - ${data.facilityName}`}
          }
          if (data.estimatedShipDate) {
            data.estimatedShipDate = FormatDisplay.date(new Date(data.estimatedShipDate), 'YYYY-MM-DD');
          }
          if (data.enteredByPartyId) {
            EmployeesService.get(data.enteredByPartyId).then(item => {
              data.enteredByParty = {value: data.enteredByPartyId, label: `[${item.pseudoId}] - ${item.partyName}`}
              commonInfoForm.setValue(data);
            })
          } else {
            commonInfoForm.setValue(data);
          }
          certInfoForm.setValue(data);
          //them dau vao product cho autocomplete
          data.items = data.items.map(item =>{
            item.product = {value: item.productId, label: `[${item.pseudoId}] - ${item.productName}`}
            return item
          })
          setItems(data.items);
          setReadOnly(false);
          update(data);
        }

      })
    }
    ;
    setDeleteShipmentItemSourceIds([])
  }

  const doSwitch = (orderId, doView: boolean) => {
    SalesOrdersService.get(orderId).then(data => {
      doUpdateSO(orderId, data, doView,'Shipment');
    })
  }

  const doCreate = () => {
    setDeleteShipmentItemSourceIds([])
    commonInfoForm.reset();
    certInfoForm.reset();
    Service.getCode().then(data => {
      certInfoForm.setValue({originId: data.code, estimatedShipDate: FormatDisplay.date(new Date(),'YYYY-MM-DD')})
    });
    setItems([]);
    setSelectedItems([]);
    setReadOnly(false);
    setDisabled(false);
    create()
  }

  const doDelete = (input) => {
    ConfirmDialogService.confirm('Xác Nhận', 'Bạn chắc chắn muốn xóa phiếu xuất này ?', () => {
      Service.cancel(input).then(() => {
        ToastService.success();
        reloadLazyData();
      })
    })
  }
  const doComplete = (input) => {
    Service.complete(input, {'completedDate': FormatDisplay.date(new Date(), 'YYYY-MM-DD')}).then(data => {
        ToastService.success();
        reloadLazyData();
      }
    )

  }

  const doUncomplete = (input) => {
    Service.uncomplete(input).then(() => {
        ToastService.success();
        reloadLazyData();
      }
    )
  }
  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>Xuất kho</span>
          <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"
                  size="small" onClick={doCreate}/>
        </Fragment>
      </div>
    </div>
  );

  return (
    <div className="grid">
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12.5rem)'}}>
        {renderDataTable()}
      </div>
      <Dialog header="Phiếu xuất kho MS04 - VT" visible={!!displayMSVT} style={{width: '100vw', maxWidth: '1200px', height: '100%', maxHeight:'1100px'}} position="top" onHide={() => setDisplayMSVT(false)}>
        <div className="grid" style={{height: '100%'}}>
          <iframe src={`${baseURL}/iam-logistics/outgoing-shipment/${displayMSVT}/report-MS04`} width="100%" height="100%"/>
        </div>
      </Dialog>
      {renderDialogView()}
      {renderCrupSO()}
    </div>
  );
}