import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Dropdown} from 'primereact/dropdown';
import {InputText} from "primereact/inputtext";

import {ConfirmDialogService, FormatDisplay, ToastService, useDataTableBasic, useForm} from '@iamsoftware/react-hooks';
import {TimePeriodService as Service} from './TimePeriodService';
import {FacilitiesService} from '../../kho-van/kho-hang/FacilitiesService';
import {LiabilityServices} from '../../ban-hang/cong-no/LiabilityServices';


export const useCrupTimePeriod = ({reloadLazyData, reloadLazyData2, reloadLazyData3, reloadLazyData4, periodYear, activeIndex, paymentMethodTypeEnumId, selectedFacility, facilities, reloadLiabilitiesSupplier, reloadLiabilitiesCustomer}) => {
  const [display, setDisplay] = useState(null);
  const [items, setItems] = useState([]);
  const [employeeExpenseses, setEmployeeExpenseses] = useState([]);
  const [organizationTaxes, setOrganizationTaxes] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [liabilitiesSupplier, setLiabilitiesSupplier] = useState([]);
  const [liabilitiesCustomer, setLiabilitiesCustomer] = useState([]);


  const onEditorChange = (rowData, column, newValue) => {
    if (activeIndex === 0) {
      setItems(prevItems => prevItems.map(prevItem => {
        if (prevItem.productName === rowData.productName && prevItem.facilityId === rowData.facilityId) {
          if (column.field === 'beginningQuantityTotal') {
            prevItem.beginningAmountTotal = newValue * prevItem.beginningUnitAmount
          }
          if (column.field === 'beginningUnitAmount') {
            prevItem.beginningAmountTotal = newValue * prevItem.beginningQuantityTotal
          }
          return {
            ...prevItem,
            [column.field]: newValue
          }
        }
        return prevItem;
      }));
    } else if (activeIndex === 1) {
      setEmployeeExpenseses(prevItems => prevItems.map(prevItem => {
        if (prevItem.description === rowData.description) {
          return {
            ...prevItem,
            [column.field]: newValue
          }
        }
        return prevItem
      }))
    } else if (activeIndex === 2) {
      setOrganizationTaxes(prevItems => prevItems.map(prevItem => {
        if (prevItem.description === rowData.description) {
          return {
            ...prevItem,
            [column.field]: newValue
          }
        }
        return prevItem
      }))
    } else if (activeIndex === 3) {
      setPaymentMethods(prevItems => prevItems.map(prevItem => {
        if (prevItem.paymentMethodId === rowData.paymentMethodId) {
          return {
            ...prevItem,
            [column.field]: newValue
          }
        }
        return prevItem
      }))
    } else if (activeIndex === 4){
      setLiabilitiesSupplier(prevItems => prevItems.map(prevItem =>{
        if (prevItem._id === rowData._id){
          return {
            ...prevItem,
            [column.field]: newValue
          }
        }
        return prevItem
      }))
    } else if (activeIndex === 5){
      setLiabilitiesCustomer(prevItems => prevItems.map(prevItem =>{
        if (prevItem._id === rowData._id){
          return {
            ...prevItem,
            [column.field]: newValue
          }
        }
        return prevItem
      }))
    }
  }
  const {render: renderDataTable} = useDataTableBasic({
    tableHeader: `${items?.length} hàng hóa`,
    indexColumnWidth: 40,
    columns: [
      {field: 'pseudoId', header: 'Mã hàng hóa', minWidth: 150, matchMode: 'contains'},
      {field: 'productName', header: 'Tên hàng hóa', minWidth: 220, matchMode: 'contains'},
      {
        field: 'beginningQuantityTotal', header: 'Số lượng', minWidth: 150, matchMode: 'contains',
        dataType: 'editor', editorConfig: {
          onEditorChange,
          field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }
      },
      {
        field: 'beginningUnitAmount', header: 'Đơn giá', minWidth: 150, matchMode: 'contains',
        dataType: 'editor', editorConfig: {
          onEditorChange,
          field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }
      },
      {field: 'beginningAmountTotal', header: 'Thành tiền', dataType: 'number', minWidth: 150, matchMode: 'contains'},
    ],
    items,
  });
  const {render: renderSalaryTable} = useDataTableBasic({
    tableHeader: `${employeeExpenseses?.length} khoản`,
    indexColumnWidth: 40,
    columns: [
      {field: 'description', header: 'Các khoản nộp', minWidth: 150, matchMode: 'contains'},
      {
        field: 'beginningBalance', header: 'Số dư đầu kì', minWidth: 150, matchMode: 'contains',
        dataType: 'editor', editorConfig: {
          onEditorChange,
          field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }
      },
    ],
    items: employeeExpenseses,
  });
  const {render: renderOrganizationTaxesTable} = useDataTableBasic({
    tableHeader: `${organizationTaxes?.length} khoản thuế nộp NSNN`,
    indexColumnWidth: 40,
    columns: [
      {field: 'description', header: 'Các khoản nộp', minWidth: 150, matchMode: 'contains'},
      {
        field: 'beginningBalance', header: 'Số dư đầu kì', minWidth: 150, matchMode: 'contains',
        dataType: 'editor', editorConfig: {
          onEditorChange,
          field: {type: 'InputNumber'}
        }
      },
    ],
    items: organizationTaxes,
  });
  const {render: renderPaymentMethodsTable} = useDataTableBasic({
    tableHeader: `${paymentMethods?.length} khoản`,
    indexColumnWidth: 40,
    columns: [
      {field: 'bankName', header: 'Tên ngân hàng', minWidth: 150, matchMode: 'contains', hidden: paymentMethodTypeEnumId === 'PmtCash'},
      {field: 'accountNumber', header: 'Số tài khoản', minWidth: 150, matchMode: 'contains', hidden: paymentMethodTypeEnumId === 'PmtCash'},
      {field: 'titleOnAccount', header: 'Chủ tài khoản', minWidth: 150, matchMode: 'contains', hidden: paymentMethodTypeEnumId === 'PmtCash'},
      {
        field: 'paymentMethodTypeEnumId', header: 'Loại', minWidth: 150, hidden: paymentMethodTypeEnumId === 'PmtBankAccount', dataType: 'custom', customCell(rowData: any): any {
          return <span> Quỹ tiền mặt </span>
        }
      },
      {
        field: 'beginningBalance', header: 'Số dư đầu kì', minWidth: 150, matchMode: 'contains',
        dataType: 'editor', editorConfig: {
          onEditorChange,
          field: {type: 'InputNumber'}
        }
      },
    ],
    items: paymentMethods,
  });

  const {render: renderLiabilitiesSupplier} = useDataTableBasic({
    tableHeader:`${liabilitiesSupplier?.length} công nợ`,
    indexColumnWidth: 40,
    dataKey: '_id',
    columns:[
      {field:'toPseudoId', header:'Mã nhà cung cấp', width: 250, matchMode: 'contains'},
      {field:'toPartyName', header:'Tên nhà cung cấp', minWidth: 250, matchMode: 'contains'},
      {field:'beginningBalance', header:'Nợ đầu kỳ', width: 250, dataType:'editor', matchMode: 'equals', editorConfig:{
        onEditorChange,
          field:{type: 'InputNumber'}
        }},
    ],
    items: liabilitiesSupplier
  })

  const {render: renderLiabilitiesCustomer} = useDataTableBasic({
    tableHeader:`${liabilitiesCustomer?.length} công nợ`,
    indexColumnWidth: 40,
    dataKey: '_id',
    columns:[
      {field:'toPseudoId', header:'Mã khách hàng', width: 250, matchMode: 'contains'},
      {field:'toPartyName', header:'Tên khách hàng', minWidth: 250, matchMode: 'contains'},
      {field:'beginningBalance', header:'Nợ đầu kỳ', width: 250, dataType:'editor', matchMode: 'equals', editorConfig:{
          onEditorChange,
          field:{type: 'InputNumber'}
        }},
    ],
    items: liabilitiesCustomer
  })

  const doUpdate = async (selectedPeriod: string, activeIndex) => {
    if (activeIndex === 0) {
      Service.getTimePeriodList(selectedPeriod, "", selectedFacility).then(data => {
          setItems(data.listData)
        }
      )
    } else if (activeIndex === 1) {
      Service.getEmployeeExpensesList('', '').then(data => {
        setEmployeeExpenseses(data.listData)
      })
    } else if (activeIndex === 2) {
      Service.organizationTaxes('').then(data => {
        setOrganizationTaxes(data.listData)
      })
    } else if (activeIndex === 3) {
      Service.paymentMethod(paymentMethodTypeEnumId, '', '').then(data => {
        setPaymentMethods(data.listData)
      })
    }else if (activeIndex === 4){
      LiabilityServices.getListPurchase('',{timePeriodId: selectedPeriod}).then(data =>{
        data.listData.map(item =>{
          item._id = `${item.organizationPartyId}_${item.toPartyId}_${item.timePeriodId}`;
          return item
        })
        setLiabilitiesSupplier(data.listData)
      })
    }else if (activeIndex === 5){
      LiabilityServices.getListSale('',{timePeriodId: selectedPeriod}).then(data =>{
        data.listData.map(item =>{
          item._id = `${item.organizationPartyId}_${item.toPartyId}_${item.timePeriodId}`;
          return item
        })
        setLiabilitiesCustomer(data.listData)
      })
    }

    setDisplay(Date.now());
  }
  const onSubmit = () => {
    if (activeIndex === 0) {
      if (selectedFacility) {
        Service.updateProductInventory({facilityId: selectedFacility, items: items}).then(() => {
            ToastService.success();
            reloadLazyData();
            setDisplay(null)
          }
        )
      } else {
        ToastService.error('Chưa chọn kho hàng')
      }
    } else if (activeIndex === 1) {
      employeeExpenseses.map(item => {
        delete item?.accountName;
        delete item?.description;
        delete item?.enumTypeId;
        delete item?.glAccountClassEnumId;
        delete item?.organizationPartyId;
        delete item?.sequenceNum;
        delete item?.timePeriodId;
      })
      Service.updateEmployeeExpenses({items: employeeExpenseses}).then(() => {
        ToastService.success();
        reloadLazyData2();
        setDisplay(null);
      })
    } else if (activeIndex === 2) {
      organizationTaxes.map(item => {
        delete item?.accountName;
        delete item?.description;
        delete item?.enumTypeId;
        delete item?.glAccountClassEnumId;
        delete item?.organizationPartyId;
        delete item?.sequenceNum;
        delete item?.timePeriodId;
      })
      Service.updateOrganizationTaxes({items: organizationTaxes}).then(() => {
        ToastService.success();
        reloadLazyData3();
        setDisplay(null);
      })
    } else if (activeIndex === 3) {
      Service.updatePaymentMethod({items: paymentMethods}).then(() => {
        ToastService.success();
        reloadLazyData4();
        setDisplay(null);
      })
    }else if (activeIndex === 4){
      LiabilityServices.update({items: liabilitiesSupplier}).then(()=>{
        ToastService.success();
        reloadLiabilitiesSupplier();
        setDisplay(null)
      })
    }else if (activeIndex === 5){
      LiabilityServices.update({items: liabilitiesCustomer}).then(()=>{
        ToastService.success();
        reloadLiabilitiesCustomer();
        setDisplay(null)
      })
    }
  }

  const footer = (
    <div className="flex flex-row-reverse flex-wrap">
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
      <Button label="Lưu" icon="pi pi-check" type="button" outlined severity="info" size="small" onClick={() => onSubmit()}/>
    </div>
  );

  const header = () => {
    return (
      <div className="lex justify-content-center font-medium">
        {activeIndex === 0 && <div style={{textAlign: 'center'}}><b style={{textAlign: 'center', fontSize: '20px'}}> Số dư đầu kỳ Tồn kho VTHH</b> <br/></div>}
        {activeIndex === 1 && <div style={{textAlign: 'center'}}><b style={{textAlign: 'center', fontSize: '20px'}}> Tiền lương và các khoản nộp theo lương</b> <br/></div>}
        {activeIndex === 2 && <div style={{textAlign: 'center'}}><b style={{textAlign: 'center', fontSize: '20px'}}> Số dư đầu kỳ của tiền thuế nộp NSNN</b> <br/></div>}
        {activeIndex === 3 && <div style={{textAlign: 'center'}}><b style={{textAlign: 'center', fontSize: '20px'}}> Số dư đầu kỳ của tiền mặt, tiền gửi NH</b> <br/></div>}
        {activeIndex === 4 && <div style={{textAlign: 'center'}}><b style={{textAlign: 'center', fontSize: '20px'}}> Số dư đầu kỳ của công nợ nhà cung cấp</b> <br/></div>}
        {activeIndex === 5 && <div style={{textAlign: 'center'}}><b style={{textAlign: 'center', fontSize: '20px'}}> Số dư đầu kỳ của công nợ khách hàng</b> <br/></div>}
        <div className="mr-8 flex">
          <b>Năm: <InputText className="mb-3" style={{width: '100px'}} value={periodYear?.label} disabled={true}/></b>
          {activeIndex === 0 && <b className="ml-2 mt-2">Kho hàng :</b>} {activeIndex === 0 && <Dropdown className="mb-3 mr-2   ml-2 w-full md:w-14rem" value={selectedFacility} options={facilities}/>}
        </div>
      </div>
    )
  }

  const render = () => {
    return (
      <Fragment>
        <Dialog header={header} footer={footer} visible={!!display} maximized={true} draggable={false} onHide={() => setDisplay(null)}>
          <div className="grid">
            <div className="col-12" style={{maxHeight: 'calc(100vh - 13rem)'}}>
              {activeIndex === 0 && renderDataTable()}
              {activeIndex === 1 && renderSalaryTable()}
              {activeIndex === 2 && renderOrganizationTaxesTable()}
              {activeIndex === 3 && renderPaymentMethodsTable()}
              {activeIndex === 4 && renderLiabilitiesSupplier()}
              {activeIndex === 5 && renderLiabilitiesCustomer()}
            </div>
          </div>
        </Dialog>
      </Fragment>
    );
  }

  return {renderCrupTimePeriod: render, doUpdateTimePeriod: doUpdate};
}