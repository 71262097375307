import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {TreeSelect} from 'primereact/treeselect';
import {Dropdown} from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';

import {ConfirmDialogService, InputTemplateProps, useDataTableBasic, useDialogCrup} from '@iamsoftware/react-hooks';
import {ProductService} from '../san-pham/productService';
import {BussinessJobService} from '../../danh-muc/business-jobs/BussinessJobService';
import {UomsService} from '../../danh-muc/uom/UomsService';
import {UploadExcelItems} from './UploadExcelItems';
import {CommonService} from '../../../service/CommonService';
import {SanPham} from '../san-pham';


export const useCrupSOItems = ({display, disabled}) => {
  const dataKey = 'dataKey';

  const [items, setItems] = useState([]);

  const [products, setProducts] = useState([]);
  const [quantityUoms, setQuantityUoms] = useState([]);
  const [deleteOrderItemSeqIds] = useState([])
  const [nodes, setNodes] = useState(null);
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [actionColumnWith, setActionColumnWith] = useState(0);
  const [displayExcel, setDisplayExcel] = useState(false);
  const [itemsDirty, setItemsDirty] = useState(false);
  const [discountType, setDiscountType] = useState('')
  const [discountTypes, setDiscountTypes] = useState([])

  const {createProduct,renderCrupProduct, renderCrupUom, quickCreateData, setQuickCreateData} = SanPham()

  useEffect(() => {
    CommonService.getDiscountType().then(data => {
      setDiscountTypes(data.listData.map(item => {
        return {value: item.enumId, label: item.description}
      }));
    })
    setDiscountType('InvDiscNotApplicable')
  }, [])
  useEffect(() => {
    if (discountTypes) {
      if (disabled === false) {
        setActionColumnWith(45)
      } else setActionColumnWith(0)
    }
  }, [discountTypes]);
  const onEditorChange = async (rowData, column, newValue) => {
    let prodData: any;
    setItems(prevOrderItems => prevOrderItems.map(prevOrderItem => {
      if (prevOrderItem._id === rowData._id) {
        if (column.field === 'product') {
          prodData = products.find(it => it.value === newValue?.value)
          if (prodData) {
            prevOrderItem.productId = prodData?.productId;
            prevOrderItem.productName = prodData?.productName;
            prevOrderItem.productCategoryId = prodData?.productCategoryId;
            prevOrderItem.quantityUomId = prodData?.amountUomId;
            prevOrderItem.quantityUom = quantityUoms.find(uom=> uom.value === prodData?.amountUomId)?.label
            prevOrderItem.unitListPrice = prodData?.salePrice || 0;
            prevOrderItem.quantity = 1;
            prevOrderItem.itemTotal = prevOrderItem.quantity * prevOrderItem.unitListPrice;
            prevOrderItem.sourcePercentage = 0;
            prevOrderItem.exemptAmount = 0;
          }
        }
        if (column.field === 'quantity') {
          prevOrderItem.itemTotal = newValue * rowData.unitListPrice;
          prevOrderItem.exemptAmount = newValue * rowData.unitListPrice * rowData.sourcePercentage / 100;
        }
        if (column.field === 'unitListPrice') {
          prevOrderItem.itemTotal = newValue * rowData.quantity;
          prevOrderItem.exemptAmount = newValue * rowData.quantity * rowData.sourcePercentage / 100;
        }
        if (column.field === 'sourcePercentage') {
          prevOrderItem.exemptAmount = newValue * rowData.itemTotal / 100;
        }
        if (['quantity', 'unitListPrice', 'sourcePercentage', 'exemptAmount', 'itemTotal'].includes(column.field)) {
          setItemsDirty(true);
        }
        return {
          ...prevOrderItem,
          [column.field]: newValue
        }
      }
      return prevOrderItem;
    }))
  }

  const addBlankItem = () => {
    setItems(prevOrderItems => {
      const _id = `${Date.now()}_${Math.random()}`;
      prevOrderItems.push({
        _id,
        actions: [
          {icon: 'pi pi-trash', className: 'p-button-danger', title: 'Xoá mã hàng', command: () => doRowDelete(_id)}
        ]
      });
      return [...prevOrderItems];
    });
  }
  //reload lai danh sach sp, dvt khi tao sp moi qua import
  const reloadDataByImportExcel = (prod: any, uom: any) => {
    if (prod === 'Y') {
      ProductService.getList('', 'N').then(data => {
        setProducts(data.listData.map(item => {
          let label = `[${item.pseudoId}] - ${item.productName}`;
          items.forEach((data) => {
            if (data.productId === item.productId) {
              data.productName = label;
            }
          })
          return {...item, value: item.productId, label: label};
        }));
      });
    }
    if (uom === 'Y') {
      UomsService.getList('').then(data => {
        setQuantityUoms(data.listData.map(item => {
          return {value: item.uomId, label: item.abbreviation}
        }));
      });
    }
  }

  useEffect(() => {
    if (items?.length) {
      if (!items.find(it => !it.product)) {
        addBlankItem();
      }
    } else {
      addBlankItem();
    }
  }, [items]);

  const setLabel = (data) => {
    data.map(item => {
      items.forEach((data) => {
        if (data.productCategoryId === item.key) {
          data.productCategory = item.data.categoryName;

        }
        if (selectedNodeKey === item.key) {
          setCategoryName(item.data.categoryName)
          setCategoryId(item.data.productCategoryId)
        }
      })
      item.label = item.data.categoryName
      item.children.map(children => {
        items.forEach((data) => {
          if (data.productCategoryId === children.key) {
            data.productCategory = children.data.categoryName;

          }
        })
        if (selectedNodeKey === children.key) {
          setCategoryName(children.data.categoryName)
          setCategoryId(children.data.productCategoryId)
        }
        children.label = children.data.categoryName
        return children
      })
      return item
    })
  }

  //Tao id cho items
  useEffect(() => {
    if (display) {
      setSelectedItems(null);
      items.map(item => {
        item._id = `${Date.now()}_${item.orderItemSeqId}_${Math.random()}`;
        item.itemTotal = item.quantity * item.unitListPrice || '';
        item.actions = [
          {icon: 'pi pi-trash', className: 'p-button-danger', title: 'Xoá', command: () => doRowDelete(item)}
        ]
        return item
      })
    }
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (display) {
      ProductService.getList('', 'N').then(data => {
        setProducts(data.listData.map(item => {
          let label = `[${item.pseudoId}] - ${item.productName}`;
          items.forEach((data) => {
            if (data.productId === item.productId) {
              data.productName = label;
            }
          })
          return {...item, value: item.productId, label: label};
        }));
      });
    }
  }, [display])


  useEffect(() => {
    UomsService.getList('').then(data => {
      setQuantityUoms(data.listData.map(item => {
        return {value: item.uomId, label: item.abbreviation}
      }));
    });
  }, [display])

  const searchQuantityUoms = event => {
    UomsService.find(event.query).then(data => {
      setQuantityUoms(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.uomId, label: item.abbreviation};
      }));
    });
  }

  const searchProducts = event => {
    ProductService.find(event.query).then(data => {
      setProducts(data.listData.map(item => {
        delete item.disabled;
        let label = `[${item.pseudoId}] - ${item.productName}`;
        return {...item, value: item.productId, label: label};
      }));
    });
  }

  useEffect(() => {
    if (display) {
      BussinessJobService.getTreeList().then(data => {
        setLabel(data.data)
        setNodes(data.data);
      });
    }
  }, [display])

  const setNodeKey = (data) => {
    setSelectedNodeKey(data)
  }
  const refreshData = (data) => {
    if (data?.length > 0) {
      data.forEach(item => {
        item.actions = []
        if (disabled === false) {
          item.actions.push({icon: 'pi pi-trash', title: 'Xóa', className: 'p-button-danger', command: () => doRowDelete(item)})
        }
      })
    }
  }
  useEffect(() => {
    refreshData(items)
  }, [items, items?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const doUploadExcel = () => {
    setDisplayExcel(true)
  }
  const doDeleteAll = () => {
    ConfirmDialogService.confirm('Xóa tất cả', `Xác nhận xóa tất cả sản phẩm ra khỏi danh sách?`, () => {
      setItems(prevItems => {
        return prevItems.filter(prevItem => !prevItem.productId);
      });
    });
  }

  const {render: renderDialogCrup, create, update, form, formMethod} = useDialogCrup({
    header: '!Sản phẩm',
    dataKey,
    width: '50rem',
    fields: [
      {
        field: 'product', header: 'Tên sản phẩm', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: products, completeMethod: searchProducts, field: 'label', dropdown: true, forceSelection: true},
        disabled: 'Update', className: 'md:col-12'
      },
      {
        field: 'productCategory', header: 'Ngành nghề KD', className: 'md:col-12', type: 'custom',
        body: <div>
          <div>Ngành nghề KD</div>
          <TreeSelect value={selectedNodeKey} onChange={(e) => setNodeKey(e.value)} options={nodes}
                      className="mt-2"></TreeSelect>
        </div>
      },
      {
        field: 'qtyUom', header: 'Đơn vị tính', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: quantityUoms, completeMethod: searchQuantityUoms, field: 'label', dropdown: true, forceSelection: true},
        className: 'md:col-4'
      },
      {field: 'quantity', header: 'Số lượng', required: true, type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-4'},
      {field: 'unitListPrice', header: 'Đơn giá', required: true, type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-4'},
      {field: 'itemTotal', header: 'Thành tiền', type: 'InputNumber', disabled: true, InputNumberProps: {min: 0}, className: 'md:col-4'},
      {field: 'sourcePercentage', header: 'Tỷ lệ CK', type: 'InputNumber', InputNumberProps: {min: 0, max: 100, minFractionDigits: 0, maxFractionDigits: 2}, className: 'md:col-4'},
      {field: 'exemptAmount', header: 'Số tiền CK', type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-4'},

    ],
    createItem: item => {
      setItems(prevItems => {
        prevItems.push({
          dataKey: `${Date.now()}_${Math.random()}`,
          productId: item.product.value,
          productName: item.product.label,
          productCategoryId: categoryId,
          productCategory: categoryName,
          unitListPrice: item.unitListPrice,
          quantity: item.quantity,
          quantityUomId: item.qtyUom?.value,
          quantityUom: item.qtyUom?.label,
          sourcePercentage: item.sourcePercentage,
          exemptAmount: item.exemptAmount,
          itemTotal: item.unitListPrice > 0 && item.quantity > 0 ? item.unitListPrice * item.quantity : 0,
        });
        return prevItems;
      });
      form.reset()
      return Promise.resolve(item);
    },
    updateItem: (id, item) => {
      setItems(prevItems => {
        for (const prevItem of prevItems) {
          if (prevItem[dataKey] === id) {
            prevItem.unitListPrice = item.unitListPrice;
            prevItem.quantity = item.quantity;
            prevItem.quantityUomId = item.qtyUom?.value;
            prevItem.quantityUom = item.qtyUom?.label;
            prevItem.sourcePercentage = item.sourcePercentage;
            prevItem.exemptAmount = item.exemptAmount;
            prevItem.productCategoryId = categoryId;
            prevItem.productCategory = categoryName;
            prevItem.itemTotal = item.unitListPrice > 0 && item.quantity > 0 ? item.unitListPrice * item.quantity : 0;
            break;
          }
        }
        return prevItems;
      });
      return Promise.resolve(item);
    },
    hideToastMessage: true
  });

  const {render, selectedItems, setSelectedItems} = useDataTableBasic({
    tableHeader: (
      <div>
        <div className="flex align-items-center justify-content-between ">
          <Fragment>
            <span>Danh sách sản phẩm</span>
            {disabled === false && <div>
							<Dropdown style={{width: '200px'}} onChange={(e) => setDiscountType(e.value)} value={discountType} options={discountTypes} className="mr-2 mb-1  p-inputtext-sm" placeholder={'Chiết Khấu'}></Dropdown>
							<Button label="Nhập từ excel" icon="pi pi-file-excel" outlined severity="success" className="mr-2 mb-1 "
											size="small" onClick={doUploadExcel}/>
							<Button label="Xóa tất cả" icon="pi pi-trash" outlined severity="danger" className="mr-2 mb-1 "
											size="small" onClick={doDeleteAll}/>
						</div>
            }
          </Fragment>
        </div>
      </div>
    ),
    dataKey: '_id',
    indexColumnWidth: 40,
    columns: [
      {
        field: 'product', header: 'Sản phẩm', width: 200, dataType: 'editor', className: 'editor-config', editorConfig: {
          onEditorChange, readOnly: disabled,
          field: {type: 'InputCustom', inputTemplate(inputTemplateProps: InputTemplateProps): any {
            const {props, fieldProps} = inputTemplateProps;
            return (
              <div className="p-inputgroup flex-1">
                <AutoComplete {...fieldProps} suggestions={products} field="label" completeMethod={searchProducts} onChange={e => props.onInputChange(props.field, e.value)} dropdown></AutoComplete>
                <Button icon="pi pi-plus" title="Thêm sản phẩm" className="p-button-success" onClick={()=> createProduct(props.item._id)}></Button>
              </div>
            )
            }}
        }
      },
      {
        field: 'productCategoryId', header: 'Ngành nghề KD', width: 200, dataType: 'editor', className: 'editor-config', editorConfig: {
          onEditorChange, readOnly: disabled,
          field: {type: 'TreeSelect', TreeSelectProps: {options: nodes}}
        }
      },
      {field: 'quantityUomId', hidden: true},
      {
        field: 'quantityUom', header: 'ĐVT', width: 80, dataType: 'editor', className: 'editor-config', editorConfig: {
          onEditorChange, readOnly: true,
        }
      },
      {
        field: 'quantity', header: 'SL', width: 75, dataType: 'editor', className: 'editor-config', editorConfig: {
          onEditorChange, readOnly: disabled,
          field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }
      },
      {
        field: 'unitListPrice', header: 'Đơn giá', width: 75, dataType: 'editor', className: 'editor-config', editorConfig: {
          onEditorChange, readOnly: disabled,
          field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }
      },
      {
        field: 'itemTotal', header: 'Thành tiền', width: 100, dataType: 'editor', className: 'editor-config', editorConfig: {
          onEditorChange, readOnly: disabled,
          field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }
      },
      {
        field: 'sourcePercentage', header: 'Tỷ lệ CK', width: 75, dataType: 'editor', className: 'editor-config', editorConfig: {
          onEditorChange, readOnly: disabled,
          field: {type: 'InputNumber', InputNumberProps: {min: 0, max: 100}}
        }, hidden: discountType === 'InvDiscNotApplicable'
      },
      {
        field: 'exemptAmount', header: 'Tiền CK', width: 75, dataType: 'editor', className: 'editor-config', editorConfig: {
          onEditorChange, readOnly: disabled,
          field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }, hidden: discountType === 'InvDiscNotApplicable'
      },
    ],
    items,
    actionColumnWidth: actionColumnWith
  });

  //Fill sản phẩm vừa tạo nhanh vào đơn hàng
  useEffect(() => {
    if (quickCreateData?._id && quickCreateData?.productId){
      let objIndex = items.findIndex(obj => obj._id === quickCreateData._id);
      quickCreateData.product = {value: quickCreateData.productId, label: `[${quickCreateData.pseudoId}] - ${quickCreateData.productName}`};
      quickCreateData.quantityUomId = quickCreateData?.amountUomId;
      quickCreateData.quantity = 1;
      quickCreateData.unitListPrice = quickCreateData?.salePrice || 0;
      quickCreateData.itemTotal = quickCreateData.unitListPrice;
      items[objIndex] = {...quickCreateData,...items[objIndex]};
      addBlankItem();
    }
  }, [quickCreateData]);


  const doRowDelete = (data) => {
    ConfirmDialogService.confirm('Xóa sản phẩm', `Xác nhận xóa sản phẩm ${data.productName} ra khỏi danh sách?`, () => {
      setItems(prevItems => {
        if (data.orderItemSeqId) {
          deleteOrderItemSeqIds.push(data.orderItemSeqId)
        }
        return prevItems.filter(prevItem => prevItem._id !== data._id);
      });
    });
  }

  const renderProducts = () => {
    return (
      <div className="grid">
        <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 15.5rem)'}}>
          {render()}
        </div>
        {renderDialogCrup()}
        {renderCrupProduct()}
        {renderCrupUom()}
        <UploadExcelItems setItems={setItems} display={displayExcel} setDisplay={setDisplayExcel} doRowDelete={doRowDelete} reloadDataByImportExcel={reloadDataByImportExcel}/>
      </div>
    );
  }
  return {renderProducts, items, setItems, deleteOrderItemSeqIds, itemsDirty, setItemsDirty};
}
