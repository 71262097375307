
import {AccountInfoService as Service} from './AccountInfoService';
import {useEffect, useState} from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import {FormatDisplay, useDataTableBasic, useDialogCrup} from '@iamsoftware/react-hooks';
import {CommonService} from '../../../service/CommonService';

export const AccountInfo = () =>{
  const header = "Quản lý tài khoản";
  const [accInfo,setAccInfo] = useState(null)
  const [assetList,setAssetList] = useState(null)
  const [stateGeoId,setStateGeoId] = useState([])
  const [reload, setReload] = useState(true)

  useEffect(() => {
    if (reload === true){
      Service.getInfo().then(data =>{
        data.assetList.map(item =>{
          item.usedQuantity = item?.originalQuantity - item?.quantityOnHandTotal;
          item.receivedDate = FormatDisplay.date(item.receivedDate, 'YYYY-MM-DD');
          item.activationValidThru = FormatDisplay.date(item.activationValidThru, 'YYYY-MM-DD');
          return item
        })
        setAccInfo(data)
        setAssetList(data.assetList)
      })
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    CommonService.getProvinces().then(({listData}) =>{
      setStateGeoId(listData.map(item =>{
        return {value: item.toGeoId, label: item.geoName}
      }))
    })
  }, []);

  const {render: renderDataTableBasic} = useDataTableBasic({
    tableHeader:'',
    dataKey:'assetId',
    columns:[
      {field:'assetName', header:'Gói dịch vụ', minWidth:200},
      {field:'acquireCost', header:'Giá niêm yết', minWidth:100 ,dataType:'number'},
      {field:'receivedDate', header:'Ngày hiệu lưc', minWidth:150, dataType:'date'},
      {field:'activationValidThru', header:'Ngày hết hạn', minWidth:150,dataType:'date'},
    ],
    items: assetList
  })

  const {render: renderDialogCrup, update} = useDialogCrup({
    header:'thông tin',
    width:'80rem',
    fields:[
      {field:'partyName',header:'Tên công ty',required:true,  className:'md:col-12'},
      {field:'address1',header:'Địa chỉ', className:'md:col-12'},
      {field:'emailAddress',header:'Email', className:'md:col-6'},
      {field:'phoneNumber',header:'Số điện thoại', className:'md:col-6'},
      {field:'faxNumber',header:'Số fax', className:'md:col-6'},
      {field:'stateProvinceGeoId',header:'Tỉnh/Thành phố', className:'md:col-6', type:'Dropdown', DropdownProps:{options: stateGeoId}},
      {field:'bankName',header:'Tên ngân hàng', className:'md:col-6'},
      {field:'accountNumber',header:'Số tài khoản', className:'md:col-6'},
      {field:'representativeName',header:'Tên người đại diên', className:'md:col-6'},
      {field:'representativeJobTitle',header:'Chức vụ người đại diện', className:'md:col-6'},
    ],
    updateItem(id: string, item: any): Promise<any> {
      return Service.updateInfo(item).then()
    },
    reloadLazyData(data?: any) {
      setReload(true);
    }
  })


  const topHeader =(
    <div className="flex align-items-center justify-content-between" style={{padding:'0rem 0 2rem 0'}}>
      <strong style={{fontSize:'1.2rem'}}>Danh sách chữ ký số: {assetList?.length || 0}</strong>
      <div>
        <Button label="Thêm chữ ký số"></Button>
      </div>
    </div>
  )

  return (
    <div className="card">
      <TabView>
        <TabPanel header="Thông tin tài khoản">
          <div >
            <div style={{padding: '1rem 2rem 0 2rem'}}>
              <div className="flex align-items-center justify-content-between">
                <h5 style={{alignContent:'center',margin:'1rem 0'}}>Thông tin chung</h5>
                <Button icon="pi pi-pencil" size="small" rounded severity="warning" onClick={() => update(accInfo)}></Button>
              </div>
              <div>
                <table style={{fontSize: '1rem'}}>
                  <tr>
                    <th className="flex align-item-start" style={{width: '10vw', padding: '8px', textAlign: 'left', fontWeight: 'normal'}}>Mã số thuế:</th>
                    <th colSpan= {3} style={{textAlign: 'left',}}>{accInfo?.pseudoId}</th>
                  </tr>
                  <tr>
                    <th className="flex align-item-start" style={{width: '10vw', padding: '8px', textAlign: 'left', fontWeight: 'normal'}}>Tên đơn vị:</th>
                    <th colSpan= {3} style={{textAlign: 'left'}}>{accInfo?.partyName}</th>
                  </tr>
                  <tr>
                    <th className="flex align-item-start" style={{width: '10vw', padding: '8px', textAlign: 'left', fontWeight: 'normal'}}>Địa chỉ:</th>
                    <th colSpan= {3} style={{textAlign: 'left'}}>
                      {accInfo?.address1}</th>
                  </tr>
                  <tr>
                    <th className="flex align-item-start" style={{width: '10vw', padding: '8px', textAlign: 'left', fontWeight: 'normal'}}>Người đại diện:</th>
                    <th style={{textAlign: 'left', width:'20vw'}}>
                      {accInfo?.representativeName}</th>
                    <th className="flex align-item-start" style={{width: '8vw', padding: '8px', textAlign: 'left', fontWeight: 'normal'}}>Chức vụ:</th>
                    <th style={{textAlign: 'left', width:'20vw'}}>
                      {accInfo?.representativeJobTitle}</th>
                  </tr>
                  <tr>

                  </tr>
                  <tr>
                    <th className="flex align-item-start" style={{width: '10vw', padding: '8px', textAlign: 'left', fontWeight: 'normal'}}>Ngân hàng:</th>
                    <th style={{textAlign: 'left', width:'20vw'}}>
                      {accInfo?.bankName}</th>
                    <th className="flex align-item-start" style={{width: '8vw', padding: '8px', textAlign: 'left', fontWeight: 'normal'}}>Số tài khoản:</th>
                    <th style={{textAlign: 'left', width:'20vw'}}>
                      {accInfo?.accountNumber}</th>
                  </tr>
                  <tr>

                  </tr>
                </table>
                <hr style={{width: '74vw'}}/>
              </div>
            </div>
            <div style={{padding: '0 2rem 0rem 2rem'}}>
              <h5>Thông tin nhận thông báo</h5>
              <div>
                <table style={{fontSize: '1rem'}}>
                  <tr>
                    <th className="flex align-item-start" style={{width: '10vw', padding: '8px', textAlign: 'left', fontWeight: 'normal'}}>Email:</th>
                    <th style={{textAlign: 'left',}}>
                      {accInfo?.emailAddress}
                    </th>
                  </tr>
                  <tr>
                    <th className="flex align-item-start" style={{width: '10vw', padding: '8px', textAlign: 'left', fontWeight: 'normal'}}>Điện thoại:</th>
                    <th style={{textAlign: 'left'}}>
                      {accInfo?.phoneNumber}
                    </th>
                  </tr>
                </table>
                <hr style={{width: '74vw'}}/>
              </div>
            </div>
            <div style={{padding: '0 2rem 2rem 2rem'}}>
              <h5>Dịch vụ đã mua</h5>
              <div>
                {renderDataTableBasic()}
              </div>
            </div>
          </div>
        </TabPanel>
        //thông tin chứ ký số
        {/*<TabPanel header="Thông tin Chữ ký số">*/}
        {/*  <div className="card">*/}
        {/*    {topHeader}*/}
        {/*    {*/}
        {/*      assetList.map((item,index) =>{*/}
        {/*        const cardHeader = (*/}
        {/*          <div className="flex align-items-center justify-content-between" style={{fontSize:'1rem',padding:'0.5rem 2rem 0.5rem 2rem', background:'aliceblue'}}>*/}
        {/*            <strong>Chữ ký số {index + 1}</strong>*/}
        {/*            <div>*/}
        {/*              <Button className="mr-2" icon="pi pi-history" rounded outlined></Button>*/}
        {/*              <Button icon="pi pi-times" rounded outlined></Button>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        )*/}
        {/*        return (*/}
        {/*          <Card header={cardHeader}>*/}
        {/*            <table style={{fontSize: '1rem'}}>*/}
        {/*              <tr>*/}
        {/*                <th className="flex align-item-start" style={{width: '15vw', padding: '6px', textAlign: 'left', fontWeight: 'normal'}}>Tên chủ thể chứng thư số:</th>*/}
        {/*                <th style={{textAlign: 'left'}}>*/}
        {/*                  CÔNG TY TNHH UTACHI*/}
        {/*                </th>*/}
        {/*              </tr>*/}
        {/*            </table>*/}
        {/*          </Card>*/}
        {/*        )*/}
        {/*      })*/}
        {/*    }*/}
        {/*  </div>*/}
        {/*</TabPanel>*/}
      </TabView>
      {renderDialogCrup()}
    </div>
  )
}