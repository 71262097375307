import {EntityService} from 'src/service/EntityService';

export class XuatkhoService extends EntityService {
  static entity = 'iam-logistics/outgoing-shipment';

  static issue(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/issue`).then(res => res.data);
  }

  static getCode(): Promise<any> {
    return this.axios.get(`${this.entity}/gen-pseudo-id`).then(res => res.data);
  }
  static complete(id: string, data:any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/complete`, data).then(res => res.data);
  }
  static uncomplete(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/uncomplete`).then(res => res.data);
  }
  static cancel(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/cancel`).then(res => res.data);
  }

}