import {useContext, useEffect, useState} from 'react';
import {Link, useLocation, useSearchParams} from 'react-router-dom';
import {CSSTransition} from 'react-transition-group';

import {classNames} from 'primereact/utils';
import {Ripple} from 'primereact/ripple';

import {AppContext} from '../layout/context/appcontext';

import {AppMenuItemProps} from 'src/types';
import {MenuContext, MenuProvider} from 'src/layout/context/menucontext';

export const AppMenu = () => {

  const {me} = useContext(AppContext);

  const [appMenus, setAppMenu] = useState([])

  useEffect(() => {
    if (['Customer', 'CusManager', 'Administrator', 'Agent', 'OrgInternal'].includes(me.roleTypeId)) {
      setAppMenu([
        {
          label: '',
          items: [{label: 'Tổng quan', icon: 'pi pi-desktop', to: '/dashboard'}]
        },
        {
          label: 'Bán hàng',
          items: [
            {label: 'Đơn hàng bán', icon: 'pi pi-fw pi-shopping-cart', to: '/ban-hang/don-hang'},
            {label: 'Hóa đơn', icon: 'pi pi-fw icons8-invoice', to: '/ban-hang/hoa-don'},
            {label: 'Công nợ', icon: 'pi pi-fw icons8-debt', to: '/ban-hang/cong-no'},
            {label: 'Thanh toán', icon: 'pi pi-fw pi-dollar', to: '/ban-hang/thanh-toan'},
          ]
        },
        {
          label: 'Mua hàng',
          items: [
            {label: 'Đơn hàng mua', icon: 'pi pi-fw pi-cart-plus', to: '/mua-hang/don-hang'},
            {label: 'Hóa đơn', icon: 'pi pi-fw icons8-invoice', to: '/mua-hang/hoa-don'},
            {label: 'Công nợ', icon: 'pi pi-fw icons8-debt', to: '/mua-hang/cong-no'},
            {label: 'Thanh toán', icon: 'pi pi-fw pi-dollar', to: '/mua-hang/thanh-toan'},
          ]
        },
        {
          label: 'Kho',
          items: [
            {label: 'Kho hàng', icon: 'pi pi-fw pi-server', to: '/kho-van/kho-hang'},
            {label: 'Nhập kho', icon: 'pi pi-fw pi-download', to: '/kho-van/nhap-kho'},
            {label: 'Xuất kho', icon: 'pi pi-fw pi-upload', to: '/kho-van/xuat-kho'},
            {label: 'Tồn kho', icon: 'pi pi-fw pi-file-edit', to: '/kho-van/kiem-kho'},
          ]
        },
        {
          label: 'Kế toán',
          items: [
            {label: 'Số dư đầu kì', icon: 'pi pi-fw pi-chart-bar', to: '/ke-toan/so-du-dau-ki'},
            {label: 'Phiếu thu', icon: 'pi pi-fw pi-arrow-up-right', to: '/ke-toan/phieu-thu'},
            {label: 'Phiếu chi', icon: 'pi pi-fw pi-arrow-down-left', to: '/ke-toan/phieu-chi'},
            {
              label: 'Sổ quỹ', icon: 'pi pi-fw pi-money-bill', items: [
                {label: 'Tiền mặt, tiền gửi', icon: 'pi pi-fw pi-credit-card', to: '/danh-muc/tai-khoan-ngan-hang'},
                {label: 'Nghiệp vụ khác', icon: 'pi pi-fw pi-dollar', to: '/ke-toan/quy-tien-mat'}
              ]
            },
            // {label: 'Chi phí', icon: 'pi pi-fw pi-wallet ', to: '/ke-toan/chi-phi'}

          ]
        },
        {
          label: 'Lương',
          items: [
            {label: 'Tham số', icon: 'pi pi-fw pi-list', to: '/luong/tham-so'},
            {label: 'Bảng lương', icon: 'pi pi-fw pi-dollar', to: '/luong/bang-luong'},
            {label: 'Phiếu chi', icon: 'pi pi-fw pi-arrow-down-left', to: '/luong/phieu-chi'},
          ]
        },
        {
          label: 'Thuế',
          items: [
            {label: 'HKD, CNKD', icon: 'pi pi-fw pi-users', to: '/thue/hkd'},
            {label: 'Người lao động', icon: 'pi pi-fw pi-user', to: '/thue/nguoi-lao-dong'},
            {label: 'Phiếu chi', icon: 'pi pi-fw pi-arrow-down-left', to: '/thue/phieu-chi'},
            {label: '01/CNKD Tờ khai CNKD', icon: 'pi pi-fw pi-file', to: '/thue/to-khai'},

          ]
        },
        {
          label: 'Hóa đơn TCT',
          items: [
            {label: 'Yêu cầu đồng bộ', icon: 'pi pi-fw pi-sync', to: '/cqt/yeu-cau-dong-bo'},
            {label: 'Hóa đơn mua vào', icon: 'pi pi-fw pi-arrow-down-left  ', to: '/cqt/hoa-don-mua-vao'},
            {label: 'Hóa đơn bán ra', icon: 'pi pi-fw pi-arrow-up-right ', to: '/cqt/hoa-don-ban-ra'},
            {label: 'Kết nối TCGP hóa đơn', icon: 'pi pi-fw icons8-disconnected ', to: '/tcgp'},
          ]
        },
        {
          label: 'Báo cáo TT88', icon: 'pi pi-fw pi-file-edit',
          items: [
            {label: 'S1-HKD', icon: 'pi pi-fw pi-clipboard', to: 'bao-cao/s1'},
            {label: 'S2-HKD', icon: 'pi pi-fw pi-clipboard', to: 'bao-cao/s2'},
            {label: 'S3-HKD', icon: 'pi pi-fw pi-clipboard', to: 'bao-cao/s3'},
            {label: 'S4-HKD', icon: 'pi pi-fw pi-clipboard', to: 'bao-cao/s4'},
            {label: 'S5-HKD', icon: 'pi pi-fw pi-clipboard', to: 'bao-cao/s5'},
            {label: 'S6-HKD', icon: 'pi pi-fw pi-clipboard', to: 'bao-cao/s6'},
            {label: 'S7-HKD', icon: 'pi pi-fw pi-clipboard', to: 'bao-cao/s7'},
          ]
        },
        {
          label: 'Báo cáo quản trị', icon: 'pi pi-fw pi-file-edit',
          items: [
            {label: 'Tổng hợp tồn kho', icon: 'pi pi-fw pi-folder', to: 'bao-cao-quan-tri/tong-hop-ton-kho'},
          ]
        },
        {
          label: 'Kết nối sàn',
          items: [
            {label: 'Lazada', icon: 'pi pi-fw icons8-lazada', to: '/ket-noi-san/lazada'},
            {label: 'Shopee', icon: 'pi pi-fw icons8-shopee', to: '/ket-noi-san/shopee'}
          ]
        },
        {
          label: 'Danh mục',
          items: [
            {label: 'Sản phẩm', icon: 'pi pi-fw pi-box', to: '/ban-hang/san-pham'},
            {label: 'Đơn vị sản phẩm', icon: 'pi pi-fw pi-ellipsis-h', to: '/danh-muc/uom'},
            {
              label: 'Đối tượng', icon: 'pi pi-fw pi-users',
              items: [
                {label: 'Nhà cung cấp', icon: 'pi pi-fw pi-id-card', to: '/mua-hang/nha-cung-cap'},
                {label: 'Khách hàng', icon: 'pi pi-fw pi-users', to: '/ban-hang/khach-hang'},
                {label: 'Nhân viên', icon: 'pi pi-fw pi-users', to: '/quan-ly/nhan-vien'},
              ]
            },
            {label: 'Khoản mục chi phí', icon: 'pi pi-fw pi-dollar', to: '/danh-muc/khoan-muc-chi-phi'},
            {label: 'Ngành nghề kinh doanh', icon: 'pi pi-fw pi-briefcase', to: '/danh-muc/business-jobs'},
          ]
        },
      ].map(it => pluck(it)));
    }
  }, [me.roleTypeId]); // eslint-disable-line react-hooks/exhaustive-deps

  const pluck = menu => {
    if (menu.items?.length) {
      if (typeof menu.visible !== 'boolean') {
        menu.items.forEach(item => pluck(item));
        menu.visible = menu.items.filter(item => item.visible !== false).length > 0;
      }
    }
    return menu;
  }

  return (
    <MenuProvider>
      <ul className="layout-menu">
        {appMenus.map((item, i) => {
          return !item?.seperator ? <AppMenuitem item={item} root={true} index={i} key={i}/> : <li className="menu-separator"></li>;
        })}
      </ul>
    </MenuProvider>
  );
}

const AppMenuitem = (props: AppMenuItemProps) => {

  const pathname = useLocation().pathname;
  const [searchParams] = useSearchParams();

  const {activeMenu, setActiveMenu} = useContext(MenuContext);
  const item = props.item;
  const key = props.parentKey ? props.parentKey + '-' + props.index : String(props.index);
  const isActiveRoute = item!.to && pathname === item!.to;
  const active = activeMenu === key || activeMenu.startsWith(key + '-');
  const onRouteChange = (url: string) => {
    if (item!.to && item!.to === url) {
      setActiveMenu(key);
    }
  };

  useEffect(() => {
    onRouteChange(pathname);
  }, [pathname, searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const itemClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    //avoid processing disabled items
    if (item!.disabled) {
      event.preventDefault();
      return;
    }

    //execute command
    if (item!.command) {
      item!.command({originalEvent: event, item: item});
    }

    // toggle active state
    if (item!.items) setActiveMenu(active ? (props.parentKey as string) : key);
    else setActiveMenu(key);
  };

  const subMenu = item!.items && item!.visible !== false && (
    <CSSTransition timeout={{enter: 1000, exit: 450}} classNames="layout-submenu" in={props.root ? true : active} key={item!.label}>
      <ul>
        {item!.items.map((child, i) => {
          return <AppMenuitem item={child} index={i} className={child.badgeClass} parentKey={key} key={child.label}/>;
        })}
      </ul>
    </CSSTransition>
  );

  return (
    <li className={classNames({'layout-root-menuitem': props.root, 'active-menuitem': active})}>
      {props.root && item!.visible !== false && <div className="layout-menuitem-root-text">{item!.label}</div>}
      {(!item!.to || item!.items) && item!.visible !== false ? (
        <a href={item!.url} onClick={(e) => itemClick(e)} className={classNames(item!.class, 'p-ripple')} target={item!.target} tabIndex={0}>
          <i className={classNames('layout-menuitem-icon', item!.icon)}></i>
          <span className="layout-menuitem-text">{item!.label}</span>
          {item!.items && <i className="pi pi-fw pi-angle-down layout-submenu-toggler"></i>}
          <Ripple/>
        </a>
      ) : null}

      {item!.to && !item!.items && item!.visible !== false ? (
        <Link to={item!.to} replace={item!.replaceUrl} target={item!.target} onClick={(e) => itemClick(e)} className={classNames(item!.class, 'p-ripple', {'active-route': isActiveRoute})} tabIndex={0}>
          <i className={classNames('layout-menuitem-icon', item!.icon)}></i>
          <span className="layout-menuitem-text">{item!.label}</span>
          {item!.items && <i className="pi pi-fw pi-angle-down layout-submenu-toggler"></i>}
          <Ripple/>
        </Link>
      ) : null}

      {subMenu}
    </li>
  );
}