import {EntityService} from 'src/service/EntityService';

export class EmployeePaymentService extends EntityService {

  static entity = 'iam-payroll/pay-histories';

  static mappingColumns(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/mapping-columns`, data).then(res => res.data);
  }
  static adjustmentTypes(): Promise<any> {
    return this.axios.get(`iam-payroll/adjustment-types?includeAll=Y`).then(res => res.data);
  }
  static update(organizationPayId:string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${organizationPayId}/update`, data).then(res => res.data);
  }

}
