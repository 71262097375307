import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDialogCrup, useForm, useToolbar, validateTaxCode} from '@iamsoftware/react-hooks';
import {Fragment, useEffect, useState} from 'react';
import {Button} from 'primereact/button';

import {CommonService} from '../../../service/CommonService';
import {TaxPaymentService as Service} from './TaxPaymentService'
import {useCrupPayment} from './CrupPayment';


export default function PhieuChi() {

  const header = 'Phiếu chi';
  const dataKey = 'paymentId';
  const [disabled, setDisabled] = useState('N');
  const [rateBasisEnumIds, setRateBasisEnumIds] = useState([]);
  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <span>{header}</span>
        <div>
          <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"
                  size="small" onClick={() => doCreatePayment()}/>
        </div>
      </div>
    </div>
  );
  useEffect(() => {
    CommonService.getPayrollRateType().then(data => {
      setRateBasisEnumIds(data.listData.map(item => {
          return {value: item.enumId, label: item.description}
        }
      ))
    })
  }, [])

  const {render: renderDataTable, reloadLazyData, refreshLazyData} = useDataTable({
    stateKey: 'mainTable',
    tableHeader: tabHeader,
    dataKey,
    columns: [
      {field: 'paymentRefNum', header: 'Mã chứng từ', width: 150, matchMode: 'contains', dataType:'custom', customCell(rowData: any): any {
        return <span style={{color:'blue'}} onDoubleClick={()=>doUpdatePayment(rowData, true)}>{rowData.paymentRefNum}</span>
        }},
      {field: 'effectiveDate', header: 'Ngày chứng từ', width: 150, dataType: 'date'},
      {field: 'paymentTypeEnum', header: 'Loại chứng từ', width: 250, matchMode: 'contains'},
      {field: 'amount', header: 'Số tiền', width: 120, matchMode: 'numeric', dataType: 'number'},
      {field: 'paymentInstrumentEnum', header: 'HTTT', width: 120, matchMode: 'contains'},
      {field: 'toPartyName', header: 'Nơi nhận', width: 120, matchMode: 'contains'},
      {field: 'memo', header: 'Diễn giải', width: 150, matchMode: 'contains'},
      {field: 'status', header: 'Trạng thái', width: 150, matchMode: 'contains'},
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 120,
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = []
          item.actions.push({icon: 'pi pi-pencil', title: 'Cập nhật', className: 'p-button-warning', command: () => doUpdatePayment(item, false)},)
          if (item.statusId === 'PmntDelivered') {
            item.actions.push({icon: 'pi pi-times', title: 'Bỏ ghi sổ', className: 'p-button-danger', command: () => doUnDelivery(item)},)
          } else {
            item.actions.push({icon: 'pi pi-check', title: 'Ghi sổ', className: 'p-button-success', command: () => doDelivery(item)},)
          }
          item.actions.push({icon: 'pi pi-trash', title: 'Xóa', className: 'p-button-danger', command: () => doDelete(item)},)
        })
      }
    },
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent).then(data => {
          return data
        }
      );
    },

  });
  const {renderCrupPayment, doUpdatePayment, doCreatePayment} = useCrupPayment({reloadLazyData});


  const doDelivery = (input) => {
    Service.delivery(input.paymentId).then(() => {
        ToastService.success();
        reloadLazyData();
      }
    )
  }
  const doUnDelivery = (input) => {
    Service.unDelivery(input.paymentId).then(() => {
        ToastService.success();
        reloadLazyData();
      }
    )
  }
  const doDelete = (input) => {
    ConfirmDialogService.confirm('Xóa phiếu chi', 'Xác nhận xóa phiếu chi này?', () => {
      Service.deletePayment(input.paymentId).then(() => {
          ToastService.success();
          reloadLazyData();
        }
      )
    })
  }
  return (
    <div className="grid">
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12.5rem)'}}>
        {renderDataTable()}
        {renderCrupPayment()}
      </div>
    </div>
  );
}
