import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';

import {FormatDisplay, ToastService, useDataTableBasic, useForm} from '@iamsoftware/react-hooks';

import {PayrollAdjustmentService as Service} from './PayrollAdjustmentService'

export const useCrupSequenceNum = ({reloadLazyData, disabled}) => {
  const [display, setDisplay] = useState(null);
  const [items, setItems] = useState([]);

  const onEditorChange = (rowData, column, newValue) => {
    setItems(prevItems => prevItems.map(prevItem => {
      if (prevItem.description === rowData.description) {
        return {
          ...prevItem,
          [column.field]: newValue
        }
      }
      return prevItem;
    }));
  }
  const {render: renderDataTable} = useDataTableBasic({
    indexColumnWidth: 40,
    columns: [
      {field: 'description', header: 'Tên tham số', minWidth: 150, matchMode: 'contains'},
      {field: 'rateBasisEnum', header: 'Tính chất', minWidth: 150, matchMode: 'contains'},
      {field: 'classTypeEnum', header: 'Loại tham số', minWidth: 150, matchMode: 'contains'},
      {
        field: 'sequenceNum', header: 'Số thứ tự', minWidth: 150, matchMode: 'contains',
        dataType: 'editor', editorConfig: {
          onEditorChange,
          field: {type: 'InputNumber', InputNumberProps: {min: 0,showButtons:true}}
        }
      },
    ],
    items,
  });


  const doUpdate = () => {
    Service.getList("", disabled).then(data => {
      setItems(data.listData)
    })
    setDisplay(Date.now());
  }
  const onSubmit = () => {
    Service.updateSequenceNum({items: items}).then(() => {
      ToastService.success();
      reloadLazyData();
      setDisplay(null)
    })

  }

  const footer = (
    <div className="flex flex-row-reverse flex-wrap">
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
      {<Button label="Lưu" icon="pi pi-check" size="small" severity="success" onClick={() => onSubmit()}/>}
    </div>
  );

  const header = () => {
    return (
      <div className="flex justify-content-between flex-wrap">
        <div className="mr-8">
          <h4>Thứ tự tham số</h4>
        </div>
      </div>
    )

  }
  const render = () => {
    return (
      <Fragment>
        <Dialog header={header} footer={footer} visible={!!display} onHide={() => setDisplay(null)} style={{width: "800px"}}>
          <div className="grid">
            <div className="col-12">
              {renderDataTable()}
            </div>
          </div>
        </Dialog>
      </Fragment>
    );
  }

  return {renderSequenceData: render, doUpdateSequence: doUpdate};
}
