import {EntityService} from '../../../service/EntityService';

export class InvoiceServices extends EntityService{
  static entity = '/iam-sale/invoices';

  static orderAddbled(): Promise<any> {
    return this.axios.get(`${this.entity}/order-addable`).then(res => res.data);
  }

  static saveAndCreateTCGP(invoiceId: any,data: any): Promise<any>{
    return this.axios.post(`${this.entity}/${invoiceId}/tcgp-create-assoc`,data).then(res => res.data)
  }

  static cancelTCGP(invoiceId: any): Promise<any>{
    return this.axios.put(`${this.entity}/${invoiceId}/tcgp-cancel-assoc`).then(res => res.data)
  }

  static syncTCGP(invoiceId: any): Promise<any>{
    return this.axios.put(`${this.entity}/${invoiceId}/tcgp-sync`).then(res => res.data)
  }

  static issueInfoTCGP(invoiceId: any): Promise<any> {
    return this.axios.get(`${this.entity}/${invoiceId}/tcgp-issue-info`).then(res => res.data);
  }

  static signByTCGP(data: any): Promise<any>{
    return this.axios.post(`http://localhost:13369/signing`,data).then(res => res.data)
  }

  static issuedTCGP(invoiceId: any): Promise<any>{
    return this.axios.put(`${this.entity}/${invoiceId}/tcgp-issued`).then(res => res.data)
  }
}