import {useDataTable} from '@iamsoftware/react-hooks';

import {ReportService as Service} from '../ReportService';

export const Agents = () => {

  const {render: renderDataTable} = useDataTable({
    stateKey: 'mainTable',
    indexColumnWidth: 45,
    headerColumnGroups: [
      {header: 'Đại lý', colSpan: 9},
      {header: 'Đại lý quản lý', colSpan: 3}
    ],
    columns: [
      {field: 'pseudoId', header: 'Mã đại lý', width: 140, matchMode: 'contains'},
      {field: 'partyTypeEnum', header: 'Loại đại lý', width: 120, matchMode: 'contains'},
      {field: 'partyName', header: 'Tên đại lý', minWidth: 150, matchMode: 'contains'},
      {field: 'partyTaxId', header: 'Mã số thuế', width: 145, matchMode: 'contains'},
      {field: 'representativeName', header: 'Tên người đại diện', width: 170, matchMode: 'contains'},
      {field: 'address1', header: 'Địa chỉ', width: 170, matchMode: 'contains'},
      {field: 'emailAddress', header: 'Email', width: 150, matchMode: 'contains'},
      {field: 'phoneNumber', header: 'Số điện thoại', width: 150, matchMode: 'contains'},
      {field: 'parentPseudoId', header: 'Mã', width: 145, matchMode: 'contains'},
      {field: 'parentPartyName', header: 'Tên', width: 150, matchMode: 'contains'},
      {field: 'parentPartyTaxId', header: 'Mã số thuế', width: 145, matchMode: 'contains'}
    ],
    getList: lazyLoadEvent => {
      return Service.getAgents(lazyLoadEvent);
    }
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderDataTable()}
      </div>
    </div>
  );

}