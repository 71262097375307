import {Fragment, useEffect, useState} from 'react';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {FormatDisplay} from '@iamsoftware/react-hooks';

import {S4ReportService as Service} from "./S4ReportService";
import {useCrupOutGoingPayment} from '../../ke-toan/phieu-chi/CrupOutGoingPayment';
import {useCrupEmployeePayment} from '../../luong/bang-luong/CrupEmployeePayment';
import {DeclareDetail} from '../../thue/to-khai/DeclareDetail';

export const S4Detail = ({display, setDisplay, update, reCalculate, reload}) => {
  const [data, setData] = useState(null)
  useEffect(() => {
    load()
  }, [display])
  useEffect(() => {
    if (reload === true) {
      load()
    }
  }, [reload])
  const load = () => {
    if (display?.reportId) {
      Service.get(display?.reportId).then(data => {
        setData(data)
      })
    }
  }
  const header = (
    <div style={{textAlign: 'center'}}>
      <span>SỔ THEO DÕI TÌNH HÌNH THỰC HIỆN NGHĨA VỤ THUẾ ĐỐI VỚI NGÂN SÁCH NHÀ NƯỚC</span>
    </div>
  )
  const footer = (
    <div className="flex flex-row-reverse flex-wrap">
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
      <Button label="Tính toán lại dữ liệu" icon="pi pi-refresh" type="button" outlined severity="danger" size="small" onClick={() => reCalculate(display, true)}/>
      <Button label="Cập nhật" icon="pi pi-pencil" type="button" outlined severity="warning" size="small" onClick={() => update(display, true)}/>
    </div>
  );
  return (
    <Dialog className="dialog-report" header={header} footer={footer} visible={!!display} maximized={true} draggable={false} onHide={() => setDisplay(null)}>
      <Screen rowData={data} display={display}/>
    </Dialog>
  );
}
const Screen = ({rowData, display}) => {
  const [displayDetail, setDisplayDetail] = useState(null);
  const [updating, setUpdating] = useState(false);

  const {renderPayments, doUpdateOutgoingPayment} = useCrupOutGoingPayment({reloadLazyData: null})
  const {renderCrup, doUpdate} = useCrupEmployeePayment({reloadLazyData: null})

  const doDisplayData = (input) => {
    if (input.paymentId) {
      input.paymentTypeEnumId = 'PtOtherIssue'
      doUpdateOutgoingPayment(input, true)
    } else if (input.organizationPayId) {
      doUpdate(input, true)
    } else if (input.declarationId) {
      setUpdating(false);
      setDisplayDetail(input)
    }
  }
  return (
    <div className="grid">
      <div className="col-12" style={{textAlign: 'center'}}>
        <span style={{textAlign: 'center'}} className="mb-2"> <i style={{textAlign: 'center'}}> Từ ngày {FormatDisplay.date(new Date(rowData?.fromDate), 'DD-MM-YYYY')} đến ngày {FormatDisplay.date(new Date(rowData?.thruDate), 'DD-MM-YYYY')}</i></span>
      </div>
      <div className="p-datatable p-component p-datatable-striped p-datatable-gridlines p-datatable-sm">
        <div className="p-datatable-wrapper" style={{height: 'calc(100vh - 15.5rem)'}}>
          <table className="p-datatable-table">
            <thead className="p-datatable-thead" style={{position: 'sticky', top: 0, zIndex: 1}}>
            <tr>
              <th colSpan={2} style={{width: 300, textAlign: 'center'}}>Chứng từ</th>
              <th rowSpan={2} style={{minWidth: 300}}>Diễn giải</th>
              <th rowSpan={2} style={{width: 200}}>Số thuế phải nộp</th>
              <th rowSpan={2} style={{width: 200}}>Số thuế đã nộp</th>
              <th rowSpan={2} style={{width: 200}}>Ghi chú</th>
            </tr>
            <tr>
              <th style={{width: 150}}>Số hiệu</th>
              <th style={{width: 150}}>Ngày tháng</th>
            </tr>
            </thead>
            <tbody className="p-datatable-tbody">
            <tr style={{background: "#F8F9FA"}}>
              <td style={{textAlign: 'center'}}><b>A</b></td>
              <td style={{textAlign: 'center'}}><b>B</b></td>
              <td style={{textAlign: 'center'}}><b>C</b></td>
              <td style={{textAlign: 'center'}}><b>1</b></td>
              <td style={{textAlign: 'center'}}><b>2</b></td>
              <td style={{textAlign: 'center'}}><b>3</b></td>
            </tr>
            {rowData?.data.map((item, index) => {
              if (item?.isBold === 'Y') {
                return (
                  <Fragment>
                    {(item.description === 'Số dư đầu kỳ') && <tr>
											<td colSpan={6} style={{background: '#D6EEEE'}}><b>Loại thuế
												: {item.accountName}</b></td>
										</tr>}
                    <tr key={index} style={{fontWeight: "bold"}}>
                      <td>{item.displayId}</td>
                      {(item.effectiveDate) &&
												<td>{FormatDisplay.date(new Date(item.effectiveDate), 'DD/MM/YYYY')}</td>}
                      {(!item.effectiveDate) && <td></td>}
                      <td>{item.description}</td>
                      <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.debitAmount)}</td>
                      <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.creditAmount)}</td>
                      <td>{}</td>
                    </tr>
                  </Fragment>
                )
              } else {
                return (
                  <Fragment>
                    <tr key={index}>
                      <td><a className={' cursor-pointer'} onClick={() => doDisplayData(item)}>{item.displayId} </a></td>
                      {(item.effectiveDate) &&
												<td>{FormatDisplay.date(new Date(item.effectiveDate), 'DD/MM/YYYY')}</td>}
                      {(!item.effectiveDate) && <td></td>}
                      <td>{item.description}</td>
                      <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.debitAmount)}</td>
                      <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.creditAmount)}</td>
                      <td>{}</td>
                    </tr>
                  </Fragment>
                )
              }
            })
            }
            </tbody>
          </table>
        </div>
      </div>
      {renderPayments()}
      {renderCrup()}
      <DeclareDetail display={displayDetail} setDisplay={setDisplayDetail} doUpdate={updating} reloadLazyData={null}/>
    </div>
  )
    ;

}