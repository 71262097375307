import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {EmployeePaymentService as Service} from './EmployeePaymentService';
import {FormatDisplay} from '@iamsoftware/react-hooks';

export const useCrupEmployeePayment = ({reloadLazyData}) => {
  const [display, setDisplay] = useState(null);
  const [tableData, setTableData] = useState(null)
  const [dynamicData, setDynamicData] = useState(null)

  const doUpdate = async (rowData: any, doView: boolean) => {
    if (doView === true) {
      Service.get(rowData?.organizationPayId).then(data => {
        setTableData(data)
        setDynamicData(data.dynamicColumns)
      })
      setDisplay(Date.now());
    }
  }

  const footer = (
    <div className="flex flex-row-reverse flex-wrap">
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
    </div>
  );

  const header = () => {
    return (
      <div style={{textAlign: 'center', fontSize: '20px'}}>
        <span>{tableData?.paySalaryName}</span>
        <br/>
        <span style={{textAlign: 'center', fontSize:'15px', fontWeight:'normal'}} className="mb-2"> <i style={{textAlign: 'center'}}> Từ ngày {FormatDisplay.date(new Date(tableData?.fromDate), 'DD-MM-YYYY')} đến ngày {FormatDisplay.date(new Date(tableData?.thruDate), 'DD-MM-YYYY')}</i></span>
      </div>
    )

  }

  const render = () => {
    return (
      <Fragment>
        <Dialog header={header} footer={footer} visible={!!display} maximized={true} draggable={false} onHide={() => setDisplay(null)}>
          <div className='grid mt-3'>
            <div className='col-12 md:col-6 xl:col-3'>
              <div className='p-2 border-1 surface-border flex align-items-center justify-content-between hover:surface-100 cursor-pointer border-round'>
                <div className='flex align-items-center'>
                  <span><b>Lương thực lĩnh:</b></span>
                </div>
                <span>{FormatDisplay.number(tableData?.netPayAmount)}</span>
              </div>
            </div>
            <div className='col-12 md:col-6 xl:col-3'>
              <div className='p-2 border-1 surface-border flex align-items-center justify-content-between hover:surface-100 cursor-pointer border-round'>
                <div className='flex align-items-center'>
                  <span><b>BHXH CTY đóng:</b></span>
                </div>
                <span>{FormatDisplay.number(tableData?.employerSocialInsurance)}</span>
              </div>
            </div>
            <div className='col-12 md:col-6 xl:col-3'>
              <div className='p-2 border-1 surface-border flex align-items-center justify-content-between hover:surface-100 cursor-pointer border-round'>
                <div className='flex align-items-center'>
                  <span><b>BHYT CTY đóng:</b></span>
                </div>
                <span>{FormatDisplay.number(tableData?.employerMedicalInsurance)}</span>
              </div>
            </div>
            <div className='col-12 md:col-6 xl:col-3'>
              <div className='p-2 border-1 surface-border flex align-items-center justify-content-between hover:surface-100 cursor-pointer border-round'>
                <div className='flex align-items-center'>
                  <span><b>BHTN CTY đóng:</b></span>
                </div>
                <span>{FormatDisplay.number(tableData?.employerUnemplInsurance)}</span>
              </div>
            </div>
            <div className='col-12 md:col-6 xl:col-3'>
              <div className='p-2 border-1 surface-border flex align-items-center justify-content-between hover:surface-100 cursor-pointer border-round'>
                <div className='flex align-items-center'>
                  <span><b>Thuế TNCN:</b></span>
                </div>
                <span>{FormatDisplay.number(tableData?.taxableIncome)}</span>
              </div>
            </div>
            <div className='col-12 md:col-6 xl:col-3'>
              <div className='p-2 border-1 surface-border flex align-items-center justify-content-between hover:surface-100 cursor-pointer border-round'>
                <div className='flex align-items-center'>
                  <span><b>BHXH NV đóng:</b></span>
                </div>
                <span>{FormatDisplay.number(tableData?.employeeSocialInsurance)}</span>
              </div>
            </div>
            <div className='col-12 md:col-6 xl:col-3'>
              <div className='p-2 border-1 surface-border flex align-items-center justify-content-between hover:surface-100 cursor-pointer border-round'>
                <div className='flex align-items-center'>
                  <span><b>BHYT NV đóng: </b></span>
                </div>
                <span>{FormatDisplay.number(tableData?.employeeMedicalInsurance)}</span>
              </div>
            </div>
            <div className='col-12 md:col-6 xl:col-3'>
              <div className='p-2 border-1 surface-border flex align-items-center justify-content-between hover:surface-100 cursor-pointer border-round'>
                <div className='flex align-items-center'>
                  <span><b>BHTN NV đóng:</b></span>
                </div>
                <span>{FormatDisplay.number(tableData?.employeeUnemplInsurance)}</span>
              </div>
            </div>
          </div>
          <div className="p-datatable p-component p-datatable-striped p-datatable-gridlines p-datatable-sm">
            <div className="p-datatable-header">
              <div className="table-header">{tableData?.items.length} Nhân viên</div>
            </div>
            <div className="p-datatable-wrapper">
              <table className="p-datatable-table">
                <thead className="p-datatable-thead">
                <tr>
                  <th style={{width: 100, textAlign: 'center'}}>Mã NV</th>
                  <th style={{minWidth: 200, textAlign: 'center'}}>Tên NV</th>
                  {dynamicData?.map((item) => {
                    return (
                      <Fragment>
                        <th style={{textAlign: 'center', width: (tableData?.dynamicColumns.length) * 120}}>{item.description}</th>
                      </Fragment>
                    )
                  })
                  }
                </tr>
                </thead>
                <tbody className="p-datatable-tbody">
                {tableData?.items.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.pseudoId}</td>
                      <td>{item.partyName}</td>
                      {dynamicData?.map(dynamic => {
                        if (item[dynamic.payrollAdjustmentId]) {
                          return (<td style={{textAlign: 'right'}}>{(item[dynamic.payrollAdjustmentId])}</td>)
                        } else {
                          return (<td style={{textAlign: 'right'}}>{FormatDisplay.number(0)}</td>)
                        }
                      })}
                    </tr>)
                })}
                </tbody>
              </table>
            </div>
          </div>

        </Dialog>
      </Fragment>
    );
  }

  return {renderCrup: render, doUpdate};
}
