import {Route, Routes} from 'react-router-dom';

import {Agents} from './agents';
import {CustomerDetail} from './customer-detail';
import {CustomerSummary} from './customer-summary';

export default function Report() {

  return (
    <Routes>
      <Route path="agents" element={<Agents/>}/>
      <Route path="customer-detail" element={<CustomerDetail/>}/>
      <Route path="customer-summary" element={<CustomerSummary/>}/>
    </Routes>
  );
}
