import {EntityService} from 'src/service/EntityService';

export class TimePeriodService extends EntityService {
  static entity = "iam-accounting/beginning-balance"

  static getTimePeriod(): Promise<any> {
    return this.axios.get(`${this.entity}/time-period`).then(res => res.data);
  }

  static getBeginTimePeriod(): Promise<any> {
    return this.axios.get(`iam-party/beginning-config/timePeriod`).then(res => res.data);
  }

  static updateBeginTimePeriod(data: any): Promise<any> {
    return this.axios.put(`iam-party/beginning-config/timePeriod`, data).then(res => res.data);
  }

  static getTimePeriodList(timePeriodId: string, lazyLoadEvent: string, facilityId?:string): Promise<any> {
    return this.axios.get(`${this.entity}/product-inventories?timePeriodId=${timePeriodId}&facilityId=${facilityId}&lazyLoadEvent=${lazyLoadEvent}`).then(res => res.data);
  }

  static getEmployeeExpensesList(timePeriodId: string, lazyLoadEvent: string): Promise<any> {
    return this.axios.get(`${this.entity}/employee-expenses?timePeriodId=${timePeriodId}&lazyLoadEvent=${lazyLoadEvent}`).then(res => res.data);
  }

  static organizationTaxes(lazyLoadEvent: string): Promise<any> {
    return this.axios.get(`${this.entity}/organization-taxes?lazyLoadEvent=${lazyLoadEvent}`).then(res => res.data);
  }

  static paymentMethod(paymentMethodTypeEnumId: string, lazyLoadEvent: string, timePeriodId?: string,): Promise<any> {
    return this.axios.get(`${this.entity}/payment-method?timePeriodId=${timePeriodId}&paymentMethodTypeEnumId=${paymentMethodTypeEnumId}&lazyLoadEvent=${lazyLoadEvent}`).then(res => res.data);
  }

  static updateProductInventory(data: any): Promise<any> {
    return this.axios.put(`${this.entity}/product-inventories`, data).then(res => res.data);
  }

  static updateEmployeeExpenses(data: any): Promise<any> {
    return this.axios.put(`${this.entity}/employee-expenses`, data).then(res => res.data);
  }

  static updateOrganizationTaxes(data: any): Promise<any> {
    return this.axios.put(`${this.entity}/organization-taxes`, data).then(res => res.data);
  }

  static updatePaymentMethod(data: any): Promise<any> {
    return this.axios.put(`${this.entity}/payment-method`, data).then(res => res.data);
  }
  static reCalculate(timePeriodId: string): Promise<any> {
    return this.axios.put(`${this.entity}/recalculate?timePeriodId=${timePeriodId}`).then(res => res.data);
  }
}