import {Fragment, useEffect, useState} from 'react';
import {Fieldset} from 'primereact/fieldset';
import {Calendar} from 'primereact/calendar';

import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDialogCrup, useForm, useToolbar} from '@iamsoftware/react-hooks';
import {EmployeeTaxService as Service} from './EmployeeTaxService';
import {CommonService} from '../../../service/CommonService';
import moment from 'moment/moment';

const statuses = [
  {value: 'N', label: 'Đang hoạt động'},
  {value: 'Y', label: 'Ngừng hoạt động'}
];


export const EmployeeTax = () => {
  const header = 'Thuế TNCN của NLĐ';
  const dataKey = 'partyId';
  const [data, setData] = useState([])
  const [fromDate, setFromDate] = useState(null)
  const [thruDate, setThruDate] = useState(null)
  let total = 0
  useEffect(()=>{
    Service.getTaxList('','').then(data => {
      setData(data.listData)
    })
  },[])
  const getData = () => {
    setData([])
    if (fromDate && thruDate) {
      Service.getTaxList(
        (new Date(new Date((fromDate)).toLocaleDateString()).setHours(0, 0, 0, 0)),
        (new Date(new Date((thruDate)).toLocaleDateString()).setHours(23, 59, 59, 999))).then(data => {
        setData(data.listData)
      })
    }else {
      if (fromDate && !thruDate) {
        Service.getTaxList((new Date(new Date((fromDate)).toLocaleDateString()).setHours(0, 0, 0, 0)), '').then(data => {
          setData(data.listData)
        })
      } else if (!fromDate && thruDate) {
        Service.getTaxList('', (new Date(new Date((thruDate)).toLocaleDateString()).setHours(23, 59, 59, 999))).then(data => {
          setData(data.listData)
        })
      } else {
        Service.getTaxList('', '').then(data => {
          setData(data.listData)
        })
      }
    }
  }
  const doClear = () => {
    setFromDate(null)
    setThruDate(null)
  }
  return (
    <div className="card">
      <div className="flex flex-row flex-wrap">
        {/*{searchForm.render()}*/}
        <div className="col-6 flex flex-wrap gap-3 p-fluid">
          <div className="flex-auto">
            <label htmlFor="calendar-1" className="font-bold block mb-2">
              Từ ngày
            </label>
            <Calendar id="calendar-1" value={fromDate} onChange={(e) => setFromDate(e.value)} dateFormat="dd/mm/yy" />
          </div>
          <div className="flex-auto">
            <label htmlFor="calendar-2" className="font-bold block mb-2">
              Đến ngày
            </label>
            <Calendar id="calendar-2" value={thruDate} onChange={(e) => setThruDate(e.value)} dateFormat="dd/mm/yy" />
          </div>
        </div>
        <Button style={{marginTop: '35px'}} icon="pi pi-search" rounded outlined aria-label="Search" tooltip={'Tìm kiếm'} onClick={()=>getData()}/>
        <Button icon="pi pi-times" size="small" rounded outlined severity="secondary" style={{marginLeft: '10px', marginTop: '35px'}} onClick={doClear}/>

        {/*<Button label="Reset" icon="pi pi-times" size="small" outlined severity="secondary" style={{marginLeft: '10px', height: '25px', marginTop: '37px'}} onClick={doClear}/>*/}
      </div>
      <div className="col-12 pb-0">
        <div className="p-datatable p-component p-datatable-striped p-datatable-gridlines p-datatable-sm">
          <div className="p-datatable-header">
            <div className="table-header"> Thuế thu nhập cá nhân (TNCN)</div>
          </div>
          <div className="p-datatable-wrapper">
            <table className="p-datatable-table">
              <thead className="p-datatable-thead">
              <tr>
                <th style={{width: 50, textAlign: 'center'}}>STT</th>
                <th style={{width: 100, textAlign: 'center'}}>Ngày chứng từ</th>
                <th style={{width: 100, textAlign: 'center'}}>Mã nhân viên</th>
                <th style={{width: 100, textAlign: 'center'}}>Tên nhân viên</th>
                <th style={{width: 300, textAlign: 'center'}}>Kì tính lương</th>
                <th style={{width: 10, textAlign: 'center'}}>Thuế TNCN</th>
              </tr>
              </thead>
              <tbody className="p-datatable-tbody">
              {data?.map((dataItem, index) => {
                total += dataItem.invoiceTotal
                return (
                  <tr>
                    <td style={{textAlign: 'center'}}>{index + 1}</td>
                    <td style={{textAlign: 'left'}}>{FormatDisplay.date(dataItem.invoiceDate)}</td>
                    <td style={{textAlign: 'left'}}>{dataItem.toPseudoId}</td>
                    <td style={{textAlign: 'left'}}>{dataItem.toPartyName}</td>
                    <td style={{textAlign: 'left'}}>{dataItem.paySalaryName} [{dataItem.paySalaryDisplayId}]</td>
                    <td style={{textAlign: 'right'}}>{FormatDisplay.number(dataItem.invoiceTotal)}</td>
                  </tr>
                )
              })}
              {data?.length === 0  && <tr>
								<td colSpan={6}>Không có dữ liệu</td>
							</tr>}
              <tr style={{background: "#F8F9FA"}}>
                <td colSpan={5} style={{textAlign: 'right'}}><b>Tổng cộng</b></td>
                <td colSpan={1} style={{textAlign: 'right'}}><b>{FormatDisplay.number(total)}</b></td>
              </tr>

              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  );
}