import {EntityService} from 'src/service/EntityService';

export class OutgoingInvoiceService extends EntityService {

  static entity = 'iam-integrate/gdt';

  static getList(lazyLoadEvent: string): Promise<any> {
    return this.axios.get(`${this.entity}/invoices?type=sale&lazyLoadEvent=${lazyLoadEvent}`).then(res => res.data);
  }
}
