import {FormatDisplay, ToastService, useDialogCrup, useForm, validateTaxCode} from '@iamsoftware/react-hooks';
import {Fieldset} from 'primereact/fieldset';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';

import {CustomersService as Service} from './CustomersService';
import {Fragment, useEffect, useState} from 'react';
import {CommonService} from '../../../service/CommonService';

const partyIdTypes = [
  {value: 'PtidCccd', label: 'Căn cước công dân'},
  {value: 'PtidCmnd', label: 'Chứng minh nhân dân'}
];
export const useCrupCustomer = ({display, setDisplay, reloadLazyData}) => {
  const header = 'Khách hàng';
  const dataKey = 'partyId';
  const [partyTaxId, setPartyTaxId] = useState(null);
  const [crupMethod, setCrupMethod] = useState(null);
  const [partyClasses, setPartyClasses] = useState([]);
  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [partyClassificationId, setPartyClassificationId] = useState(null)
  const [readOnly, setReadOnly] = useState(false)

  const getByTaxCode = () => {
    const _value = commonInfoForm.getValue();
    if (partyTaxId && validateTaxCode(partyTaxId)) {
      CommonService.getByTaxCode(partyTaxId, 'N').then(({data}) => {
        if (data?.partyName) {
          _value.pseudoId = partyTaxId;
          _value.partyName = data.partyName;
          _value.address1 = data.address1;
          commonInfoForm.setValue(_value);
        }
      });
    } else {
      ToastService.error('Mã số thuế không hợp lệ');
    }
  }
  useEffect(() => {
    CommonService.getPartyClass().then(data => {
      setPartyClasses(data.listData.map(item => {
        return {value: item.partyClassificationId, label: item.description}
      }));
    });
  }, [display])


  const commonInfoForm = useForm({
    fields: [
      {field: 'pseudoId', header: 'Mã khách hàng', required: true, className: 'md:col-4'},
      {field: 'partyName', header: 'Tên khách hàng', required: true, className: 'md:col-4'},
      {
        field: 'partyClassificationId', header: 'Loại tổ chức', required: true, type: 'Dropdown', className: 'md:col-4',
        DropdownProps: {
          options: partyClasses,
        },
      },
      {
        field: 'partyTaxId', header: 'Mã số thuế', className: 'md:col-4', type: 'custom',
        body: <Fragment>
          <label>Mã số thuế</label>
          <div className="p-inputgroup flex-1">
            <InputText value={partyTaxId} onChange={(e) => setPartyTaxId(e.target.value)}/>
            <Button icon="pi pi-search" style={{width:'3.5rem'}} severity="info" onClick={getByTaxCode} disabled={crupMethod !== 'Create'}/>
          </div>
        </Fragment>
      },
      {field: 'contactNumber', header: 'Số điện thoại', className: 'md:col-4'},
      {field: 'emailAddress', header: 'Email', className: 'md:col-4'},
      {field: 'address1', header: 'Địa chỉ', className: 'md:col-12'},
    ],
    readOnly
  });
  const relatedInfoForm = useForm({
    fields: [
      {field: 'relatedPartyName', header: 'Tên người đại diện', className: 'md:col-4'},
      {field: 'relatedPhoneNumber', header: 'Số điện thoại', className: 'md:col-4'},
      {field: 'jobTitle', header: 'Chức vụ', className: 'md:col-4'},
      {
        field: 'relatedIdTypeEnumId', header: 'Loại giấy tờ', type: 'Dropdown', className: 'md:col-4',
        DropdownProps: {
          options: partyIdTypes,
        },
      },
      {field: 'relatedIdValue', header: 'Số CMND/CCCD', className: 'md:col-4'},
      {field: 'relatedIdPlace', header: 'Nơi cấp', className: 'md:col-4'},
      {field: 'relatedIdDate', header: 'Ngày cấp', className: 'md:col-4', InputTextProps: {type: 'date'}},
      {field: 'relatedAddress1', header: 'Địa chỉ', className: 'md:col-8'},
    ],
    readOnly
  })
  const bankInfoForm = useForm({
    fields: [
      {field: 'bankName', header: 'Tên ngân hàng', className: 'md:col-4'},
      {field: 'accountNumber', header: 'Số tài khoản', className: 'md:col-4'},
      {field: 'ownerAccountName', header: 'Chủ tài khoản', className: 'md:col-4'},
    ],
    readOnly
  })
  const commonInfo = commonInfoForm.getValue()
  useEffect(() => {
    setPartyClassificationId(commonInfo.partyClassificationId)
  }, [commonInfo.partyClassificationId]);

  const inject = item => {
    const commonInfo = commonInfoForm.getValue();
    const relatedInfo = relatedInfoForm.getValue();
    const bankInfo = bankInfoForm.getValue();
    commonInfo.partyTaxId = partyTaxId;
    return Object.assign(item, commonInfo, relatedInfo, bankInfo);
  }


  const {render: renderDialogCrup, create, update, view, formMethod} = useDialogCrup({
    header,
    dataKey,
    width: '80rem',
    fields: [
      {
        type: 'custom', className: 'md:col-12 mb-3',
        body: <Fieldset legend="Thông tin chung">
          {commonInfoForm.render()}
        </Fieldset>
      },
      {
        type: 'custom', className: 'md:col-12 mb-3',
        body: <Fieldset legend="Thông tin người đại diện">
          {relatedInfoForm.render()}
        </Fieldset>, displayDependency(): boolean {
          return partyClassificationId === 'PcltHkdEnterprise'
        }
      },
      {
        type: 'custom', className: 'md:col-12 mb-3',
        body: <Fieldset legend="Tài khoản ngân hàng">
          {bankInfoForm.render()}
        </Fieldset>
      },
    ],
    createItem: async item => {
      if (!inject(item)?.partyClassificationId || !inject(item)?.partyName || !inject(item)?.pseudoId) {
        if (!inject(item)?.partyClassificationId){
          ToastService.error('Bạn chưa chọn loại tổ chức')
        }
        if (!inject(item)?.partyName){
          ToastService.error('Trường tên khách hàng không được rỗng')
        }
        if (!inject(item)?.pseudoId){
          ToastService.error('Trường mã khách hàng không được rỗng')
        }
        return Promise.reject();
      }else {
        return Service.create(await inject(item));
      }
    },
    updateItem: async (id, item) => {
      if (!inject(item)?.partyClassificationId || !inject(item)?.partyName || !inject(item)?.pseudoId) {
        if (!inject(item)?.partyClassificationId){
          ToastService.error('Bạn chưa chọn loại tổ chức')
        }
        if (!inject(item)?.partyName){
          ToastService.error('Trường tên khách hàng không được rỗng')
        }
        if (!inject(item)?.pseudoId){
          ToastService.error('Trường mã khách hàng không được rỗng')
        }
        return Promise.reject();
      }else {
        item.paymentMethodId = paymentMethodId
        return Service.update(id, await inject(item));
      }
    },
    reloadLazyData
  });
  useEffect(() => {
    setCrupMethod(formMethod);
  }, [formMethod]);
  const doCreate = () => {
    setPartyTaxId(null);
    relatedInfoForm.reset();
    commonInfoForm.reset();
    bankInfoForm.reset();
    create();
  }

  const doUpdate = (rowData, doView: boolean) => {
    Service.get(rowData.partyId).then(data => {
      data.relatedIdDate = FormatDisplay.date(data.relatedIdDate, 'YYYY-MM-DD');
      data.issuedDate = FormatDisplay.date(data.issuedDate, 'YYYY-MM-DD');
      setPartyClassificationId(data.partyClassificationId)
      setPaymentMethodId(data?.paymentMethodId)
      commonInfoForm.setValue(data);
      relatedInfoForm.setValue(data);
      bankInfoForm.setValue(data);
      setPartyTaxId(data.partyTaxId);
      if (doView === true) {
        setReadOnly(true);
        view(data)
      } else {
        setReadOnly(false);
        update(data);
      }
    });
  }
  const render = () => {
    return (
      renderDialogCrup()
    )
  }

  return {renderCustomer: render, doCreateCustomer: doCreate, doUpdateCustomer: doUpdate};

}