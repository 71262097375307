import {Fragment, useEffect, useState} from 'react';

import {useDataTableBasic} from '@iamsoftware/react-hooks';

export const useCrupReadOnlySOItems = ({display, disabled}) => {
  const dataKey = "_id";

  const [items, setItems] = useState([]);

  const [total, setTotal] = useState('');


  useEffect(() => {
    if (display) {
      setSelectedItems(null);
      items.map(it =>{
        it._id = `${Date.now()}_${it.orderItemSeqId}_${Math.random()}`
        return it
      })
    }
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps



  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>{items?.length || 0} Sản phẩm</span>
        </Fragment>
      </div>
    </div>
  );

  const {render, selectedItems, setSelectedItems} = useDataTableBasic({
    tableHeader: tabHeader,
    dataKey,
    indexColumnWidth: 40,
    columns: [
      {field: 'productName', header: 'Sản phẩm', width: 150, matchMode: 'contains'},
      {field: 'productCategory', header: 'Ngành nghề KD', width: 300, matchMode: 'contains'},
      {field: 'quantityUom', header: 'ĐVT', width: 50, matchMode: 'contains'},
      {field: 'quantity', header: 'SL', width: 75, matchMode: 'equals', dataType: 'number'},
      {field: 'unitListPrice', header: 'Đơn giá', width: 100, matchMode: 'equals', dataType: 'number'},
      {field: 'itemTotal', header: 'Thành tiền', width: 100, matchMode: 'equals', dataType: 'number'},
      {field: 'sourcePercentage', header: 'Tỷ lệ CK', width: 75, matchMode: 'equals', dataType: 'number'},
      {field: 'exemptAmount', header: 'Số tiền CK', width: 100, matchMode: 'equals', dataType: 'number'},
    ],
    items,
  });


  const renderProducts = () => {
    return (
      <div className="grid">
        <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 15.5rem)'}}>
          {render()}
        </div>
      </div>
    );
  }
  return {renderProducts2: renderProducts, items2: items, setItems2: setItems};
}