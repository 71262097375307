import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {TabView, TabPanel} from 'primereact/tabview';
import {Checkbox} from "primereact/checkbox";
import {Dropdown} from 'primereact/dropdown';
import {InputNumber} from 'primereact/inputnumber';
import {AutoComplete} from "primereact/autocomplete";
import {RadioButton} from "primereact/radiobutton";
import {InputText} from 'primereact/inputtext';

import {FormatDisplay, ToastService, useForm} from '@iamsoftware/react-hooks';
import {Fieldset} from 'primereact/fieldset';
import {useCrupPOItems} from './CrupPOItems';
import {CommonService} from '../../../service/CommonService';
import {PurchaseOrderService as Service} from './OrdersService';
import {FacilitiesService} from '../../kho-van/kho-hang/FacilitiesService';
import {EmployeesService} from '../../quan-ly/nhan-vien/EmployeesService';
import {SuppliersService} from '../nha-cung-cap/SuppliersService';
import {useCrupReadOnlyPOItems} from './CrupReadOnlyPOItems';
import {useCrupSupplier} from '../nha-cung-cap/CrupSupplier';
import {useCrupEmployee} from '../../quan-ly/nhan-vien/CrupEmployee';
import {BankAccountService} from '../../danh-muc/tai-khoan-ngan-hang/BankAccountService';
import {useCrupItemsByGdt} from './CrupItemsByGdt';


export const useCrupPO = ({reloadLazyData}, doClosePopUp?) => {
  const [isUpdate, setIsUpdate] = useState(false)
  const [orderId, setOrderId] = useState(null);
  const [display, setDisplay] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [invoiceFormTypes, setInvoiceFormTypes] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [shipmentCheck, setShipmentCheck] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalExemptAmount, setTotalExemptAmount] = useState(0);
  const [totalTaxAmount, setTotalTaxAmount] = useState(0);
  const [supplierPartyName, setSupplierPartyName] = useState(null);
  const [employee, setEmployee] = useState(null)
  const [displayCrupEmployee, setDisplayCrupEmployee] = useState(null)
  const [editableForm, setEditableForm] = useState(false)
  const [onCredit, setOnCredit] = useState('');
  const [outgoingPaymentInstrument, setOutgoingPaymentInstrument] = useState('')
  const [outgoingPaymentInstruments, setOutgoingPaymentInstruments] = useState([])
  const [invoicePaymentMethods, setInvoicePaymentMethods] = useState([]);
  const [purchaseInvoiceStatus, setPurchaseInvoiceStatus] = useState([])
  const [invoiceStatusId, setInvoiceStatusId] = useState('')
  const [fromPaymentMethods, setFromPaymentMethods] = useState([]);
  const [toPaymentMethods, setToPaymentMethods] = useState(null);
  const [displayTax, setDisplayTax] = useState(false)
  const [displayId, setDisplayId] = useState('')
  const [paymentRefNum, setPaymentRefNum] = useState('')
  const [shipmentOriginId, setShipmentOriginId] = useState('')

  //xem chi tiet phieu chi tu mua hang qua man hinh phieu chi
  const [lookByScreen, setLookByScreen] = useState(null)
  //Tạo dơn hàng từ tct
  const [createByGdt, setCreateByGdt] = useState(false);

  const itemTemplate = (item) => {
    return (
      <div className="flex align-items-center justify-content-between">
        <div style={{width: '30%'}}>{item.label}</div>
        <div style={{width: '70%', float: 'left'}}>{item.partyName}</div>
      </div>
    );
  };
  useEffect(() => {
    if (display) {
      SuppliersService.getList('{"filters":{"disabled":{"value":"N","matchMode":"equals"}}}').then(data => {
        setVendors(data.listData.map(item => {
          return {value: item.partyId, label: `[${item.pseudoId}] - ${item.partyName}`}
        }));
      });
      FacilitiesService.getList('').then(data => {
        setFacilities(data.listData.map(item => {
          return {value: item.facilityId, label: item.facilityName}
        }));
      });
      CommonService.getInvoicePaymentMethod().then(data => {
        setInvoicePaymentMethods(data.listData.map(item => {
          return {value: item.enumId, label: item.description}
        }));
      });
      BankAccountService.getList('').then(data => {
        setFromPaymentMethods(data.listData.map(item => {
          return {value: item.paymentMethodId, label: `${item.bankName} - ${item.bankAccount}`}
        }));
      });
      CommonService.getOutgoingPaymentInstruments().then(data => {
        setOutgoingPaymentInstruments(data.listData.map(item => {
          return {value: item.enumId, label: item.description}
        }));
      });
      CommonService.getInvoiceFormTypes().then(data => {
        setInvoiceFormTypes(data.listData.map(item => {
          return {value: item.enumId, label: `${item.enumCode} - ${item.description}`}
        }));
      });
      CommonService.getPurchaseInvoiceStatus().then(data => {
        setPurchaseInvoiceStatus(data.listData.map(item => {
          return {value: item.statusId, label: item.description}
        }))
      })
    }
  }, [display])
  const searchVendors = event => {
    SuppliersService.find(event.query).then(data => {
      setVendors(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.partyId, label: item.pseudoId};
      }));
    });
  }
  useEffect(() => {
    if (supplierPartyName?.value) {
      SuppliersService.get(supplierPartyName.value).then(data => {
        setToPaymentMethods([{value: data.paymentMethodId, label: data.bankName}])
      })
    }
  }, [supplierPartyName])
  const searchEmployees = event => {
    EmployeesService.find(event.query).then(data => {
      setEmployees(data.listData.map(item => {
        delete item.disabled;
        let label = `[${item.pseudoId}] - ${item.partyName}`;
        return {...item, value: item.partyId, label: label};
      }));
    });
  }
  const getDisplayId = () => {
    Service.genPseudoId().then(data => {
      setDisplayId(data.code)
    })
  }
  const getShipmentPseudoId = () => {
    Service.genShipmentPseudoId().then(data => {
      setShipmentOriginId(data.code)
    })
  }

  //set edit enable from
  const changeCustomer = (data) => {
    setSupplierPartyName(data);
    setEditableForm(true);
  }
  const getPaymentRefNum = () => {
    Service.genPaymentPseudoId(outgoingPaymentInstrument).then(data => {
      setPaymentRefNum(data.code)
    })
  }

  const {renderProducts, items, setItems, deleteOrderItemSeqIds} = useCrupPOItems({display, disabled, displayTax});
  const {renderProducts2, items2, setItems2,} = useCrupReadOnlyPOItems({display, disabled, displayTax});
  const {setItemsGdt, itemsGdt, renderProductsGdt, setDisabledTax, dirtyGdt} = useCrupItemsByGdt({display});
  const {renderSupplier, doCreateSupplier} = useCrupSupplier({reloadLazyData, setSupplierPartyName: setSupplierPartyName})
  const {doCreateEmployee, renderEmployee} = useCrupEmployee({display: displayCrupEmployee, setDisplay: setDisplayCrupEmployee, reloadLazyData})
  const commonInfoForm = useForm({
    fields: [
      {
        field: 'customerParty', type: 'custom', className: 'md:col-4', body: <div>
          <div>
            <div>
              Mã nhà cung cấp<span style={{color: 'red'}}>*</span>
            </div>
            <div className='p-inputgroup mt-2'>
              <AutoComplete field='label' disabled={disabled} value={supplierPartyName} suggestions={vendors} completeMethod={searchVendors} itemTemplate={itemTemplate} onChange={(e) => {
                changeCustomer(e.value);
              }} dropdown/>
              {!disabled && <span className="p-inputgroup-addon" style={{background: '#22C55E', color: 'white', cursor: 'pointer'}} onClick={() => doCreateSupplier()}>
            <i className="pi pi-plus"/>
          </span>}
            </div>
          </div>
        </div>, display: createByGdt === false
      },
      {
        field: 'vendorName', header: 'Tên nhà cung cấp', className: 'md:col-8', display: !createByGdt, disabled: true
      },
      {
        field: 'vendorPartyTaxId', header: 'Mã số thuế', className: 'md:col-4', required: true, display: createByGdt === true, disabled: createByGdt
      },
      {
        field: 'vendorPartyName', header: 'Tên nhà cung cấp', className: 'md:col-8', required: true, display: createByGdt === true, disabled: createByGdt
      },
      {field: 'address1', header: 'Địa chỉ', className: 'md:col-8', display: createByGdt === false},
      {field: 'address1', header: 'Địa chỉ', className: 'md:col-12', display: createByGdt === true},
      {
        field: 'paymentComments', header: 'Kèm theo', className: 'md:col-4', displayDependency(): boolean {
          return activeIndex === 2 || activeIndex === 1
        }
      },
      {
        field: 'paymentMemo', header: 'Diễn giải', className: 'md:col-6', displayDependency(): boolean {
          return activeIndex === 2;
        }
      },
      {
        field: 'fromPartyTaxId', header: 'Mã số thuế', className: 'md:col-4', displayDependency(): boolean {
          return activeIndex === 0 && createByGdt === false;
        }
      },
      {
        field: 'enteredByParty', type: 'custom', className: 'md:col-4', body: <div>
          <div>
            <div>
              Nhân viên thực hiện
            </div>
            <div className='p-inputgroup mt-2'>
              <AutoComplete field='label' disabled={disabled} value={employee} suggestions={employees} completeMethod={searchEmployees} itemTemplate={itemTemplate} onChange={(e) => {
                setEmployee(e.value);
              }} dropdown/>
              {!disabled && <span className="p-inputgroup-addon" style={{background: '#22C55E', color: 'white', cursor: 'pointer'}} onClick={() => doCreateEmployee()}>
            <i className="pi pi-plus"/>
          </span>}
            </div>
          </div>
        </div>, displayDependency(item: any): boolean {
          return activeIndex === 0 || activeIndex === 1
        }
      },
      {
        field: 'facilityId', header: 'Kho hàng', className: 'md:col-4', type: 'Dropdown', required: true, DropdownProps: {
          options: facilities
        }, displayDependency(): boolean {
          return activeIndex === 1
        }
      },
      {
        field: 'shipmentDeliveryName', header: 'Người giao', className: 'md:col-4', displayDependency(): boolean {
          return activeIndex === 0 || activeIndex === 1
        }
      },
      {
        field: 'invoiceDescription', header: 'Ghi chú', className: 'md:col-12', displayDependency(): boolean {
          return activeIndex === 1
        }
      },
      {
        field: 'shipmentHandlingInstructions', header: 'Kèm theo', className: 'md:col-4', displayDependency(): boolean {
          return activeIndex === 0
        }
      },
      {
        field: 'orderComments', header: 'Diễn giải', className: 'md:col-12', displayDependency(): boolean {
          return activeIndex === 0 || activeIndex === 1
        }
      },
      {
        field: 'paymentFromName', header: 'Người nhận', className: 'md:col-6', displayDependency(): boolean {
          return activeIndex === 2 && outgoingPaymentInstrument === "PiCash"
        }
      },
      {
        field: 'fromPaymentMethodId', header: 'Tài khoản chi', type: 'Dropdown', DropdownProps: {options: fromPaymentMethods}, className: 'md:col-3', displayDependency(): boolean {
          return activeIndex === 2 && outgoingPaymentInstrument === "PiOutPaymentOrder"
        }
      },
      {
        field: 'toPaymentMethodId', header: 'Tài khoản nhận', type: 'Dropdown', DropdownProps: {options: toPaymentMethods}, className: 'md:col-3', displayDependency(): boolean {
          return activeIndex === 2 && outgoingPaymentInstrument === "PiOutPaymentOrder"
        }
      },
    ],
    disabled
  });

  const infoForm = useForm({
    fields: [
      {
        field: 'displayId', type: 'custom', className: 'md:col-12', body: <div>
          <div>
            <div>
              Số chứng từ<span style={{color: 'red'}}>*</span>
            </div>
            <div className='p-inputgroup mt-2'>
              <InputText value={displayId} onChange={e => setDisplayId(e.target.value)}/>
              <span className="p-inputgroup-addon" style={{background: '#3B82F6', color: 'white', cursor: 'pointer'}} onClick={getDisplayId}>
            <i className="pi pi-sync"/>
          </span>
            </div>
          </div>
        </div>, displayDependency(): boolean {
          return activeIndex === 0
        },
      },
      {
        field: 'placedDate', header: 'Ngày chứng từ', InputTextProps: {type: 'date'}, className: 'md:col-12', displayDependency(): boolean {
          return activeIndex === 0
        }
      },
      {
        field: 'invoiceFormTypeEnumId', header: 'Mẫu hóa đơn', className: 'md:col-12', type: 'Dropdown', DropdownProps: {
          options: invoiceFormTypes
        }, displayDependency(): boolean {
          return activeIndex === 0 && invoiceStatusId === 'InvoiceReceived'
        }, required: true, disabled: createByGdt
      },
      {
        field: 'invoiceSerial', header: 'Ký hiệu', className: 'md:col-6', displayDependency(): boolean {
          return activeIndex === 0 && invoiceStatusId === 'InvoiceReceived'
        }, required: true, disabled: createByGdt
      },
      {
        field: 'invoiceNo', header: 'Số hóa đơn', className: 'md:col-6', displayDependency(): boolean {
          return activeIndex === 0 && invoiceStatusId === 'InvoiceReceived'
        }, disabled: createByGdt
      },
      {
        field: 'invoiceDate', header: 'Ngày hóa đơn', className: 'md:col-6', InputTextProps: {type: 'date'}, displayDependency(): boolean {
          return activeIndex === 0 && invoiceStatusId === 'InvoiceReceived' && !createByGdt
        }, disabled: createByGdt
      },
      {
        field: 'invoiceDate', header: 'Ngày hóa đơn', className: 'md:col-12', InputTextProps: {type: 'date'}, displayDependency(): boolean {
          return activeIndex === 0 && invoiceStatusId === 'InvoiceReceived' && createByGdt
        }, disabled: createByGdt
      },
      {
        field: 'taxAuthCode', header: 'Mã CQT', className: 'md:col-12', display: createByGdt, disabled: createByGdt
      },
      {
        field: 'invoicePaymentMethodEnumId', header: 'HTTT', className: 'md:col-6', type: 'Dropdown', DropdownProps: {
          options: invoicePaymentMethods
        }, displayDependency(): boolean {
          return activeIndex === 0 && invoiceStatusId === 'InvoiceReceived' && !createByGdt
        }
      },
      {
        field: 'externalId', header: 'Mã CQT', className: 'md:col-12', displayDependency(): boolean {
          return activeIndex === 0 && invoiceStatusId === 'InvoiceReceived' && !createByGdt
        }
      },
      {
        field: 'shipmentOriginId', type: 'custom', className: 'md:col-12', body: <div>
          <div>
            <div>
              Mã chứng từ<span style={{color: 'red'}}>*</span>
            </div>
            <div className='p-inputgroup mt-2'>
              <InputText value={shipmentOriginId} onChange={e => setShipmentOriginId(e.target.value)}/>
              <span className="p-inputgroup-addon" style={{background: '#3B82F6', color: 'white', cursor: 'pointer'}} onClick={getShipmentPseudoId}>
            <i className="pi pi-sync"/>
          </span>
            </div>
          </div>
        </div>, displayDependency(): boolean {
          return activeIndex === 1
        },
      },
      {
        field: 'estimatedShipDate', header: 'Ngày nhập', className: 'md:col-12', InputTextProps: {type: 'date'}, displayDependency(): boolean {
          return activeIndex === 1
        }
      },
      {
        field: 'paymentMethodEnumId', header: 'Hình thức thanh toán', className: 'md:col-12', type: 'Dropdown', DropdownProps: {
          options: invoicePaymentMethods
        }, displayDependency(): boolean {
          return activeIndex === 1
        }
      },
    ],
    disabled
  });
  const paymentInfoForm = useForm({
    fields: [
      {
        field: 'paymentRefNum', type: 'custom', className: 'md:col-12', body: <div>
          <div>
            <div>
              Số chứng từ<span style={{color: 'red'}}>*</span>
            </div>
            <div className='p-inputgroup mt-2'>
              <InputText value={paymentRefNum} onChange={e => setPaymentRefNum(e.target.value)}/>
              <span className="p-inputgroup-addon" style={{background: '#3B82F6', color: 'white', cursor: 'pointer'}} onClick={getPaymentRefNum}>
            <i className="pi pi-sync"/>
          </span>
            </div>
          </div>
        </div>, displayDependency(): boolean {
          return activeIndex === 2
        },
      },
      {
        field: 'effectiveDate', header: 'Ngày  chứng từ', InputTextProps: {type: 'date'}, className: 'md:col-12', displayDependency(): boolean {
          return activeIndex === 2
        }, required: true
      },
      {
        field: 'grandTotal', header: 'Số tiền thanh toán', type: 'InputNumber', className: 'md:col-12', displayDependency(): boolean {
          return activeIndex === 2
        }, disabled: true
      },
    ],
    disabled
  });

  const form1 = useForm({
    fields: [
      {
        type: 'custom', className: 'md:col-9',
        body: <Fieldset legend="Thông tin chung">
          {commonInfoForm.render()}
        </Fieldset>
      },
      {
        type: 'custom', className: 'md:col-3',
        body: <Fieldset legend="Chứng từ">
          {infoForm.render()}
        </Fieldset>
      },
    ],
    disabled
  });
  const form2 = useForm({
    fields: [
      {
        type: 'custom', className: 'md:col-9',
        body: <Fieldset legend="Thông tin chung">
          {commonInfoForm.render()}
        </Fieldset>
      },
      {
        type: 'custom', className: 'md:col-3',
        body: <Fieldset legend="Chứng từ">
          {paymentInfoForm.render()}
        </Fieldset>
      },
    ],
    disabled
  });

  const commonData = commonInfoForm.getRawValue();
  const infoData = infoForm.getRawValue();
  const paymentData = paymentInfoForm.getRawValue();
  useEffect(() => {
    if (createByGdt === false) {
      setTotalAmount(0);
      setTotalExemptAmount(0);
      setGrandTotal(0)
      let totalCounter = 0;
      let exemptCounter = 0;
      let grandTotalCounter = 0;
      let taxTotalCounter = 0;
      items.forEach(item => {
        if (item.productId) {
          totalCounter += item.itemTotal
          if (item.exemptAmount) {
            exemptCounter += item.exemptAmount
          }
          if (item.itemTaxAmount) {
            taxTotalCounter += item.itemTaxAmount
          }
        }
      });
      grandTotalCounter = totalCounter - exemptCounter + taxTotalCounter
      setTotalAmount(totalCounter);
      setTotalExemptAmount(exemptCounter);
      setTotalTaxAmount(taxTotalCounter);
      setGrandTotal(grandTotalCounter);
    }
  }, [JSON.stringify(items)]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (onCredit === 'N') {
      setOutgoingPaymentInstrument('PiOutPaymentOrder')
    }
  }, [onCredit])
  useEffect(() => {
    if (infoData?.invoiceFormTypeEnumId === 'IftTax' && invoiceStatusId === 'InvoiceReceived') {
      setDisplayTax(true)
    } else {
      setDisplayTax(false)
    }
  }, [invoiceStatusId, infoData?.invoiceFormTypeEnumId])
  useEffect(() => {
      if (onCredit === 'N') {
        const _value = paymentInfoForm.getRawValue();
        const _valueOrder = infoForm.getRawValue();
        Service.genPaymentPseudoId('').then(data => {
          _value.paymentRefNum = data.code;
          if (!_value.effectiveDate){
            _value.effectiveDate = _valueOrder.placedDate;
          }
          _value.grandTotal = grandTotal;
          paymentInfoForm.setValue(_value);
        })
        setActiveIndex(2)
      } else if (onCredit === 'Y') {
        setActiveIndex(0)
      }
    }
    ,
    [onCredit, outgoingPaymentInstrument]
  );

  useEffect(() => {
    if (shipmentCheck === true && !shipmentOriginId && editableForm === true) {
      Service.genShipmentPseudoId().then(data => {
        setShipmentOriginId(data.code)
      })
    }
  }, [shipmentCheck]);

  useEffect(() => {
    if (supplierPartyName?.value && editableForm === true) {
      SuppliersService.get(supplierPartyName.value).then(data => {
        commonData.address1 = data.address1;
        commonData.partyContactName = data.relatedPartyName;
        commonData.fromPartyTaxId = data.partyTaxId;
        commonData.sourceAccountNumber = data.accountNumber;
        commonData.sourceBankName = data.bankName;
        commonData.orderComments = `Mua hàng từ ${data.partyName}`;
        commonData.vendorName = data.partyName;
        commonInfoForm.setValue(commonData);
      })
    }
  }, [supplierPartyName]); // eslint-disable-line react-hooks/exhaustive-deps

  const reset = () => {
    commonInfoForm.reset();
    infoForm.reset();
    paymentInfoForm.reset();
    setEditableForm(false)
    setSupplierPartyName(null)
  }
  const inject = () => {
    const data = Object.assign(
      commonData,
      infoData,
      paymentData);
    if (createByGdt === false) {
      data.items = items.filter(it => it.productId)
      data.vendorPartyId = supplierPartyName?.value
    } else {
      data.invoiceSyncId = itemsGdt[0]?.invoiceId
      data.items = itemsGdt;
    }
    if (onCredit) {
      data.onCredit = onCredit
    }
    if (shipmentCheck === true) {
      data.createShipment = "Y";
    } else {
      data.createShipment = "N";
    }
    if (paymentStatus) {
      data.paymentStatusId = paymentStatus
    }
    if (deleteOrderItemSeqIds?.length) {
      data.deleteOrderItemSeqIds = deleteOrderItemSeqIds;
    }
    if (employee) {
      data.enteredByPartyId = employee?.value;
    }
    if (data.invoiceDate) {
      data.invoiceDate = Date.parse(data.invoiceDate)
    }
    if (data.estimatedShipDate) {
      data.estimatedShipDate = Date.parse(data.estimatedShipDate)
    }
    if (data.placedDate) {
      data.placedDate = Date.parse(data.placedDate)
    }
    if (data.effectiveDate) {
      data.effectiveDate = Date.parse(data.effectiveDate)
    }
    if (outgoingPaymentInstrument) {
      data.paymentInstrumentEnumId = outgoingPaymentInstrument
    }
    if (invoiceStatusId) {
      data.invoiceStatusId = invoiceStatusId
    }
    if (displayId) {
      data.displayId = displayId
    }
    if (shipmentOriginId) {
      data.shipmentOriginId = shipmentOriginId
    }
    if (paymentRefNum) {
      data.paymentRefNum = paymentRefNum
    }
    return data
  }

  const doCreate = (defaultValue?: any) => {
    setCreateByGdt(false)
    reset();
    setOrderId(null);
    setShipmentCheck(false);
    setPaymentStatus('PmntPromised')
    setActiveIndex(0);
    setGrandTotal(0);
    setEmployee(null)
    setOnCredit('Y')
    setInvoiceStatusId('InvoiceReceived')
    doSetShipment(true)
    Service.genPseudoId().then(data => {
      setDisplayId(data.code)
      infoForm.setValue(defaultValue?.formvalue || {
        displayId: data.code,
        placedDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD'),
        invoiceDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD'),
        cashEffectiveDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD'),
        transferEffectiveDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD'),
        estimatedShipDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD'),
        transferInstrument: true,
      })
    })
    setItems([]);
    setItems2([]);
    setDisabled(false);
    setDisplay(Date.now());
    setEditableForm(false)
  }
  const doUpdate = async (orderId: string, data: any, doView: boolean, lookBy?: string) => {
    setCreateByGdt(false)
    setLookByScreen(lookBy || null)
    reset()
    setOrderId(orderId);
    setDisplayId(data.displayId)
    setShipmentOriginId(data.shipmentOriginId)
    setOnCredit(data.onCredit)
    setTotalAmount(data.subTotal);
    setTotalExemptAmount(data.discountTotal);
    setGrandTotal(data.grandTotal);
    setTotalTaxAmount(data.taxTotal);
    setOutgoingPaymentInstrument(data.paymentInstrumentEnumId)
    setInvoiceStatusId(data.invoiceStatusId)
    setPaymentRefNum(data.paymentRefNum)

    if (data.placedDate) {
      data.placedDate = FormatDisplay.date(new Date(data.placedDate), 'YYYY-MM-DD');
    }
    if (data.estimatedShipDate) {
      data.estimatedShipDate = FormatDisplay.date(new Date(data.estimatedShipDate), 'YYYY-MM-DD');
    }
    if (data.invoiceDate) {
      data.invoiceDate = FormatDisplay.date(new Date(data.invoiceDate), 'YYYY-MM-DD');
    }

    if (data.effectiveDate) {
      data.effectiveDate = FormatDisplay.date(new Date(data.effectiveDate), 'YYYY-MM-DD');
    }
    if (data.createShipment === "Y") {
      data.createShipment = true;
      setShipmentCheck(true)
    } else {
      data.createShipment = false;
      setShipmentCheck(false)
    }
    if (data.cashInstrument === "Y") {
      data.cashInstrument = true;
    } else {
      data.cashInstrument = false;
    }
    if (data.transferInstrument === "Y") {
      data.transferInstrument = true;
    } else {
      data.transferInstrument = false;
    }
    if (data.paymentStatusId) {
      setPaymentStatus(data.paymentStatusId)
    }
    if (data.vendorPartyId) {
      await SuppliersService.get(data.vendorPartyId).then(output => {
        data.address1 = output.address1;
        setSupplierPartyName({value: data.vendorPartyId, label: output.pseudoId});
        data.vendorName = output.partyName;
      })
    }
    if (data.enteredByPartyId) {
      await EmployeesService.get(data.enteredByPartyId).then(item => {
        if (item?.pseudoId) {
          setEmployee({value: data.enteredByPartyId, label: `[${item.pseudoId}] - ${item.partyName}`})
        }
      });
    }
    infoForm.setValue(data);
    paymentInfoForm.setValue(data);
    commonInfoForm.setValue(data);

    //them dau vao product cho autocomplete
    data.items = data.items.map(item =>{
      item.product = {value: item.productId, label: `[${item.pseudoId}] - ${item.productName}`}
      return item
    })

    setItems(data.items);
    setItems2(data.items.filter(it => it.productId));
    if (lookBy === 'Payment') {
      //Chuyen den man hinh phieu chi khi xem chi tiet tu ketoan-phieuchi
      setActiveIndex(2)
    } else if (lookBy === 'Shipment') {
      //Chuyen den man hinh phieu nhap khi xem chi tiet tu kho-phieunhap
      setActiveIndex(1)
    } else {
      setActiveIndex(0);
    }
    setIsUpdate(true);
    setEditableForm(false)
    setTimeout(() => {
      if (doView === true) {
        setDisabled(true);
        setDisplay(Date.now());
      } else {
        setDisabled(false);
        setDisplay(Date.now());
      }
    }, 75)
  }
  const onSubmit = event => {
    if (!submitting && infoForm.valid() && commonInfoForm.valid()) {
      if (items.filter(it => it.productId)?.length > 0) {
        setSubmitting(true);
        let promise;
        if ((!supplierPartyName && !createByGdt) || (!shipmentOriginId && shipmentCheck === true) || !displayId){
          if (!supplierPartyName){
            ToastService.error('Trường NCC không được rỗng');
          }
          if (!shipmentOriginId && shipmentCheck === true){
            ToastService.error('Trường mã chứng từ không được rỗng');
          }
          if(!displayId){
            ToastService.error('Trường số chứng từ không được rỗng');
          }
          setSubmitting(false);
        }else {
          if (orderId) {
            promise = Service.update(orderId, inject());
          } else {
            if (createByGdt) {
              promise = Service.createGdt(inject());
            } else {
              promise = Service.create(inject());
            }
          }
          promise.then(response => {
            if (response.errors) {
              ToastService.error(response.errors)
            } else {
              doClosePopUp();
              setDisplay(null);
              ToastService.success();
              reloadLazyData();
            }
          }).finally(() => {
            setSubmitting(false);
          });
        }
      } else {
        ToastService.error('Chưa có sản phẩm trong đơn hàng hoặc sản phẩm không tồn tại trên hệ thống')
      }
    }
    event.preventDefault();
  }

  const doSetShipment = (data) => {
    setShipmentCheck(data);
    setActiveIndex(0);
    if (data === true) {
      getShipmentPseudoId()
      setActiveIndex(1)
    } else {
      setActiveIndex(0);
    }
  }
  useEffect(() => {
    setTimeout(() => {
      if (!lookByScreen) {
        if (paymentStatus === 'PmntDelivered') {
          if (isUpdate === true) {
            setActiveIndex(0);
          } else if (isUpdate === false) {
            setActiveIndex(2);
          }
        } else if (paymentStatus === 'PmntPromised') {
          setActiveIndex(0)
          setIsUpdate(false)
        }
      }
    }, 50)
  }, [isUpdate, paymentStatus]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (onCredit === 'N') {
      if (!paymentRefNum) {
        getPaymentRefNum()
      }
      setActiveIndex(2)
    }
  }, [onCredit])
  //Change checkbox by click label
  const doClickLabel = (type) => {
    if (type === 'shipment') {
      switch (shipmentCheck) {
        case true:
          doSetShipment(false);
          break;
        case false:
          doSetShipment(true);
          break;
      }
    }
  }

  //set disabledTax screen create invoice by GDT
  const {invoiceFormTypeEnumId} = infoForm.getRawValue()
  useEffect(() => {
    if (invoiceFormTypeEnumId && invoiceFormTypeEnumId === 'IftTax') {
      setDisabledTax(false)
    } else {
      setDisabledTax(true)
    }
  }, [invoiceFormTypeEnumId]);

  //Tinh lai tien thue create by GDT
  useEffect(() => {
    if (itemsGdt?.length > 0 && dirtyGdt) {
      let taxTotal = 0
      itemsGdt.forEach(item => {
        if (item.tthue) {
          taxTotal += item.tthue;
        }
      })
      setTotalTaxAmount(taxTotal)
    }
  }, [itemsGdt]);

  //Create invoice by gdt
  const doCreateGdt = (data) => {
    setActiveIndex(0);
    setCreateByGdt(true)
    reset();
    setOrderId(null);
    setShipmentCheck(false);
    setPaymentStatus('PmntPromised')
    setGrandTotal(data.invoiceTotal);
    setTotalExemptAmount(data.discountTotal);
    setTotalAmount(data.subTotal);
    setTotalTaxAmount(data.taxTotal);
    setEmployee(null);
    setOnCredit('Y');
    setInvoiceStatusId('InvoiceReceived');
    doSetShipment(false);
    data.vendorPartyTaxId = data.fromPartyTaxId;
    data.vendorPartyName = data.fromPartyName;
    data.address1 = data.fromAddress;
    data.invoiceDate = FormatDisplay.date(new Date(data.invoiceDate), 'YYYY-MM-DD');
    Service.genPseudoId().then(it => {
      setDisplayId(it.code)
      data.placedDate = FormatDisplay.date(new Date(), 'YYYY-MM-DD');
      data.estimatedShipDate = FormatDisplay.date(new Date(), 'YYYY-MM-DD');
      data.cashEffectiveDate = FormatDisplay.date(new Date(), 'YYYY-MM-DD');
      data.transferEffectiveDate = FormatDisplay.date(new Date(), 'YYYY-MM-DD');
      data.transferInstrument = true;
    })
    data.items.map(it => {
      if (Number(it.itemTaxTypeEnumCode)) {
        it.tthue = it.thtien * it.itemTaxTypeEnumCode / 100
      }
      return it
    })
    setTimeout(() => {
      infoForm.setValue(data)
      commonInfoForm.setValue(data)
      paymentInfoForm.setValue(data)
      setItemsGdt(data.items.map(it => {
        it._id = `${Date.now()}_${it.invoiceItemSeqId}_${Math.random()}`
        return it
      }))
      setItems(data.items)
    }, 250)
    setDisabled(false);
    setDisplay(data);
    setEditableForm(true)
  }

  const header = () => {
    return (
      <div className=" flex jjustify-content-between flex-wrap">
        <div className="mr-6">
          <h4> Đơn hàng mua</h4>
        </div>
        <div className="ml-8">
          <div className="ml-8">
            {headerItem()}
          </div>
        </div>
      </div>
    )
  }

  const headerItem = () => {
    return (
      <div className="ml-8 flex justify-content-center font-medium">
        <div className="flex flex-wrap mt-2">
          <div className="flex font-medium">
            <RadioButton className={'ml-1'} inputId="credit1" name="pizza" value="Y" onChange={(e) => setOnCredit(e.value)} checked={onCredit === 'Y'}/>
            <label htmlFor="credit1" style={{fontSize: '1rem', marginTop: '4px'}} className="ml-2">Ghi nợ </label>
          </div>
          <div className="flex justify-content-center font-medium">
            <RadioButton className={'ml-1'} inputId="credit2" name="pizza" value="N" onChange={(e) => setOnCredit(e.value)} checked={onCredit === 'N'}/>
            <label htmlFor="credit2" style={{fontSize: '1rem', marginTop: '4px'}} className="ml-2">Thanh toán ngay</label>
          </div>
        </div>
        <div className="ml-2">
          <Dropdown disabled={disabled || onCredit === 'Y'} placeholder="Hình thức thanh toán" className="ml-2 w-full md:w-14rem p-inputtext-sm" value={outgoingPaymentInstrument} onChange={(e) => setOutgoingPaymentInstrument(e.value)} options={outgoingPaymentInstruments} optionLabel="label"></Dropdown>
        </div>
        <div className="ml-2">
          <Dropdown disabled={disabled || createByGdt} placeholder="Trạng thái hóa đơn" className="ml-2 w-full md:w-14rem p-inputtext-sm" value={invoiceStatusId} onChange={(e) => setInvoiceStatusId(e.value)} options={purchaseInvoiceStatus} optionLabel="label"></Dropdown>
        </div>
        <div className="ml-2">
          <Checkbox disabled={disabled} className="ml-2 mt-2 mr-1" onChange={e => doSetShipment(e.checked)} checked={shipmentCheck}></Checkbox>
          <label onClick={() => {
            doClickLabel('shipment')
          }} style={{fontSize: '1rem'}}> Tạo phiếu nhập</label>
        </div>
      </div>
    )
  }

  const footer = (
    <div className="flex" style={{justifyContent: 'end'}}>
      {!disabled && <Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit} loading={submitting}/>}
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
    </div>
  );

  const renderItems = (
    <div className="col-12">
      {disabled === false && createByGdt === false && renderProducts()}
      {disabled === true && createByGdt === false && renderProducts2()}
      {disabled === false && createByGdt === true && renderProductsGdt()}
      <div className="flex flex-row-reverse flex-wrap mt-3">
        <div className="ml-4">
          <label htmlFor="total" className="font-bold block mb-2">Tổng tiền thanh toán</label>
          <InputNumber inputId="total" value={grandTotal} disabled/>
        </div>
        <div className="ml-4">
          <label htmlFor="total-exempt" className="font-bold block mb-2">Tổng tiền thuế</label>
          <InputNumber inputId="total-exempt" value={totalTaxAmount} disabled/>
        </div>
        <div className="ml-4">
          <label htmlFor="total-exempt" className="font-bold block mb-2">Tổng tiền chiết khấu</label>
          <InputNumber inputId="total-exempt" value={totalExemptAmount} disabled/>
        </div>
        <div>
          <label htmlFor="total-amount" className="font-bold block mb-2">Tổng tiền hàng</label>
          <InputNumber inputId="total-amount" value={totalAmount} disabled/>
        </div>
      </div>

    </div>);

  const render = () => {
    return (
      <Fragment>
        <Dialog header={header} footer={footer} visible={!!display} maximized={true} draggable={false} onHide={() => setDisplay(null)}>

          <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            <TabPanel header='Đơn hàng mua'>
              <div className="grid">
                <div className="col-12">
                  {form1.render()}
                </div>
                {renderItems}
              </div>
            </TabPanel>
            {shipmentCheck === true &&
							<TabPanel header='Phiếu nhập kho'>
								<div className="grid">
									<div className="col-12">
                    {form1.render()}
									</div>
                  {renderItems}
								</div>
							</TabPanel>}
            {onCredit === 'N' &&
							<TabPanel header='Phiếu chi'>
								<div className="grid">
									<div className="col-12">`
                    {form2.render()}
									</div>
                  {renderItems}
								</div>
							</TabPanel>}
          </TabView>
        </Dialog>
        {renderSupplier()}
        {renderEmployee()}
      </Fragment>
    );
  }

  return {renderCrupPO: render, doCreatePO: doCreate, doUpdatePO: doUpdate, doCreateGdt};
}