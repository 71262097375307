import {EntityService} from 'src/service/EntityService';

export class PayrollPaymentService extends EntityService {

  static entity = 'iam-payroll/payments';

  static amountInfo(organizationPayId: string, paymentTypeEnumId: string): Promise<any> {
    return this.axios.get(`${this.entity}/pay-adjustment-amount-info?organizationPayId=${organizationPayId}&paymentTypeEnumId=${paymentTypeEnumId}`).then(res => res.data);
  }
  static delivery(paymentId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${paymentId}/delivery`).then(res => res.data);
  }
  static unDelivery(paymentId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${paymentId}/un-delivery`).then(res => res.data);
  }
  static deletePayment(paymentId: string): Promise<any> {
    return this.axios.delete(`${this.entity}/${paymentId}/cancel`).then(res => res.data);
  }

}
