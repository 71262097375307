import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {FormatDisplay} from '@iamsoftware/react-hooks';
import {S1ReportService as Service} from './S1ReportService'
import {SalesOrdersService} from '../../ban-hang/don-hang/ordersService';
import {useCrupSO} from '../../ban-hang/don-hang/CrupSO';

export const S1Detail = ({display, setDisplay, update, reCalculate, reload}) => {
  const [headerDate, setHeaderDate] = useState('')
  const [data, setData] = useState(null)

  useEffect(() => {
    load()
  }, [display])

  useEffect(() => {
    if (reload === true) {
      load()
    }
  }, [reload])

  const load = () => {
    if (display?.reportId) {
      Service.get(display.reportId).then(data => {
        if (data.timePeriodTypeId === "ReportYear") {
          setHeaderDate(`${data?.timePeriodType} ${data?.yearInt}`)
        } else {
          setHeaderDate(`${data?.timePeriodType} ${data?.periodNum} năm ${data?.yearInt}`)
        }
        setData(data)
      })
    }
  }


  const header = (
    <div style={{textAlign: 'center'}}>
      <span>Sổ chi tiết doanh thu bán hàng hóa, dịch vụ<br/> {headerDate}</span>
    </div>
  )
  const footer = (
    <div className="flex flex-row-reverse flex-wrap">
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
      <Button label="Tính toán lại dữ liệu" icon="pi pi-refresh" type="button" outlined severity="danger" size="small" onClick={() => reCalculate(display, true)}/>
      <Button label="Cập nhật" icon="pi pi-pencil" type="button" outlined severity="warning" size="small" onClick={() => update(display, true)}/>
    </div>
  );
  return (
    <Dialog className="dialog-report" header={header} footer={footer} visible={!!display} maximized={true} draggable={false} onHide={() => setDisplay(null)}>
      <Screen rowData={data}/>
    </Dialog>
  );

}
const Screen = ({rowData}) => {
  const [footerData, setFootData] = useState([])
  const [reportCategoryTaxIds, setReportCategoryTaxIds] = useState([])
  const {renderCrupSO, doUpdateSO} = useCrupSO({reloadLazyData: null});

  useEffect(() => {
    if (rowData?.summaryData) {
      setFootData(Object.values(rowData.summaryData))
      setReportCategoryTaxIds(Object.keys(rowData.summaryData))
    }
  }, [rowData])
  const displayOrder = (input) => {
    if (input.orderId) {
      SalesOrdersService.get(input.orderId).then(data => {
        doUpdateSO(input.orderId, data, true)
      })
    }
  }
  return (
    <div className="grid">
      <div className="col-12" style={{textAlign: 'center'}}>
        <span style={{textAlign: 'center'}} className="mb-2"> <i style={{textAlign: 'center'}}> Từ ngày {FormatDisplay.date(new Date(rowData?.fromDate), 'DD-MM-YYYY')} đến ngày {FormatDisplay.date(new Date(rowData?.thruDate), 'DD-MM-YYYY')}</i></span>
      </div>
      <div className="p-datatable p-component p-datatable-striped p-datatable-gridlines p-datatable-sm">
        <div className="p-datatable-wrapper" style={{height: 'calc(100vh - 15.5rem)'}}>
          <table className="p-datatable-table">
            <thead className="p-datatable-thead" style={{position: 'sticky', top: 0, zIndex: 1}}>
            <tr>
              <th rowSpan={2} style={{width: 100, textAlign: 'center'}}>Ngày tháng ghi sổ</th>
              <th colSpan={2} style={{width: 150, textAlign: 'center'}}>Chứng từ</th>
              <th rowSpan={2} style={{width: 300}}>Diễn giải</th>
              {rowData?.dynamicColumns.map((item, index) => {
                return (
                  <Fragment>
                    <th colSpan={item.childItems.length} style={{textAlign: 'center', width: (item.childItems.length) * 120}}>{item.categoryName}</th>
                  </Fragment>
                )
              })
              }
            </tr>
            <tr>
              <th style={{width: 100}}>Số hiệu</th>
              <th style={{width: 130}}>Ngày tháng</th>
              {rowData?.dynamicColumns.map((item) => {
                return (
                  item.childItems.map(childItem => {
                    return (<Fragment>
                      <th style={{textAlign: 'center', width: 100}}>VAT {childItem.vatRate}% <br/>TNCN {childItem.personalIncomeTaxRate}%</th>
                    </Fragment>)
                  })
                )
              })
              }
            </tr>
            </thead>
            <tbody className="p-datatable-tbody">
            {rowData?.data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{FormatDisplay.date(new Date(item.completedDate), 'DD/MM/YYYY')}</td>
                  <td><a className={' cursor-pointer'} onClick={() => displayOrder(item)}>{item.displayId}</a></td>
                  <td>{FormatDisplay.date(new Date(item.effectiveDate), 'DD/MM/YYYY')}</td>
                  <td>{item.description}</td>
                  {
                    reportCategoryTaxIds.map(reportCategoryTaxId => {
                      if (item.reportCategoryTaxId !== reportCategoryTaxId) {
                        return (<td style={{textAlign: "right"}}>{FormatDisplay.number(0)}</td>)
                      } else return (<td style={{textAlign: 'right'}}>{FormatDisplay.number(item.amount)}</td>)
                    })
                  }
                </tr>
              )
            })
            }
            <tr style={{background: "#F8F9FA"}}>
              <td colSpan={4} style={{textAlign: 'center'}}><b>Tổng cộng</b></td>
              {footerData.map((footer => {
                return (<td style={{textAlign: 'right'}}><b>{FormatDisplay.number(footer)}</b></td>)
              }))}
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      {renderCrupSO()}
    </div>
  );

}
