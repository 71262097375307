import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDataTableBasic, useDialogCrup, useForm, useToolbar} from '@iamsoftware/react-hooks';
import {Fragment, useEffect, useState} from 'react';
import {Button} from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import {InputText} from 'primereact/inputtext';
import {LiabilityServices as Service, LiabilityServices} from '../../ban-hang/cong-no/LiabilityServices';
import {TimePeriodService} from '../../ke-toan/so-du-dau-ki/TimePeriodService';
import { Fieldset } from 'primereact/fieldset';
import {PurchaseOrderService} from '../don-hang/OrdersService';
import {useCrupPO} from '../don-hang/CrupPO';

interface periodOptions {
  label: string;
  value: string;
}

export const SupplierLiability = () =>{
  const header = 'Công nợ nhà cung cấp';
  const dataKey = '_id';

  const [timePeriodId, setTimePeriodId] = useState<periodOptions>(null);
  const [timeSync, setTimeSync] = useState(null);
  const [items, setItems] = useState(null);
  const [itemPayments, setItemPayments] = useState(null);
  const [periodOptions, setPeriodOptions] = useState([]);

  useEffect(() => {
    TimePeriodService.getTimePeriod().then(({listData})=>{
      let options = listData.map(item =>{
        return {value: item.timePeriodId, label: item.periodNum};
      })
      setTimePeriodId(options[0]?.value);
      setPeriodOptions(options);
    })
    getTimeSync();
  }, []);

  const getTimeSync = () =>{
    Service.timeSync().then(data =>{
      setTimeSync(data.lastRuntime)
    });
  }

  const periodTemplate = (option: periodOptions) => {
    return option.label;
  }

  const tableHeader = (
    <div className="flex align-items-center justify-content-between">
      <span>{header}</span>
      <div className="flex align-items-center">
        <Button title="Đồng bộ" className="mr-2" icon="pi pi-sync" rounded outlined severity="secondary" onClick={() => doSync()}></Button>
        <InputText style={{padding: '0.4rem 0.4rem'}} disabled={true} value={FormatDisplay.date(new Date(timeSync), "DD/MM/YYYY HH:mm:ss")}></InputText>
      </div>
    </div>
  )

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    tableHeader: tableHeader,
    dataKey,
    stateKey: 'mainTable',
    indexColumnWidth: 45,
    columns: [
      {
        field: 'toPartyName', header: 'Tên nhà cung cấp', minWidth: 250, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <span>[{rowData.toPseudoId}] - {rowData.toPartyName}</span>
        }},
      // {field:'periodName', header: 'Năm', minWidth: 100, matchMode:'contains'},
      {field:'beginningBalance', header: 'Nợ đầu kỳ', minWidth:100,matchMode:'equals', className:'td-config-number'},
      {field:'debitsAmount', header:'Phát sinh trong kỳ', width:175, matchMode:'equals', dataType:'number'},
      {field: 'paymentAmount', header:'Thanh toán', minWidth:100, matchMode:'equals', dataType:'number'},
      {field: 'endingBalance', header: 'Nợ cuối kỳ',minWidth:100, matchMode:'equals', className:'td-config-number'}
    ],
    getList(lazyLoadEvent: any): Promise<any> {
      let params = {timePeriodId: timePeriodId}
      if (timePeriodId){
        return Service.getListPurchase(lazyLoadEvent,params).then((data) =>{
          data.listData.forEach(item =>{
            //thêm id ,format số -a -> (a)
            item._id = `${item.organizationPartyId}_${item.toPartyId}_${item.timePeriodId}`;
            if (item.endingBalance < 0){
              item.endingBalance = `(${FormatDisplay.number(item.endingBalance*(-1))})`
            }else {
              item.endingBalance = `${FormatDisplay.number(item.endingBalance)}`
            }
            if (item.beginningBalance < 0){
              item.beginningBalance = `(${FormatDisplay.number(item.beginningBalance*(-1))})`
            }else {
              item.beginningBalance = `${FormatDisplay.number(item.beginningBalance)}`
            }
          })
          return data
        })
      }else {
        return new Promise<any>(resolve => resolve({}));
      }
    },
    actionColumnWidth:45,
    initActions(items: Array<any>) {
      if (items){
        items.forEach(item =>{
          item.actions =[
            {icon: 'pi pi-eye', title: 'Chi tiết', className: 'p-button-info', command: () => doUpdate(item)},
          ]
        })
      }
    }
  })

  const {renderCrupPO, doCreatePO, doUpdatePO} = useCrupPO({reloadLazyData},);

  const commonInfoForm = useForm({
    fields:[
      {field:'toPseudoId', header:'Mã đại lý', className:'md:col-6'},
      {field:'beginningBalance', header:'Nợ đầu kỳ', className:'md:col-6'},
      {field:'toPartyName', header:'Tên đại lý', className:'md:col-6'},
      {field:'debitsAmount', header:'Phát sinh trong kỳ', className:'md:col-6', type:'InputNumber'},
      {field:'address1', header:'Địa chỉ', className:'md:col-6'},
      {field:'paymentAmount', header:'Đã thanh toán', className:'md:col-6', type:'InputNumber'},
      {field:'partyTypeEnum', header:'Loại', className:'md:col-3'},
      {field:'lastRuntime', header:'Thời điểm đồng bộ', className:'md:col-3'},
      {field:'endingBalance', header:'Nợ cuối kỳ', className:'md:col-6'},
    ],
    readOnly: true
  })

  const {render: renderDataTableBasic} = useDataTableBasic({
    tableHeader:`${items?.length} đơn hàng`,
    dataKey:'orderId',
    columns:[
      {field:'completedDate', header:'Ngày ghi sổ', width: 250},
      {
        field:'displayId', header:'Mã đơn hàng', minWidth: 150, dataType:'custom',customCell(rowData: any): any {
          return <span style={{color:'blue'}} onDoubleClick={()=> doSwitch(rowData.orderId, true)}>{rowData.displayId}</span>
        }
      },
      {field:'grandTotal', header:'Số tiền',width:200, dataType:'number'}
    ],
    items,
    originalProps: {
      paginator: true,
      rows: 5,
      rowsPerPageOptions: [5, 10,20, 50,100]
    }
  })

  const {render: renderDataTablePayment} = useDataTableBasic({
    tableHeader:`${itemPayments?.length} thanh toán`,
    dataKey:'paymentId',
    columns:[
      {field:'effectiveDate', header:'Ngày ghi sổ', width: 250},
      {
        field:'paymentRefNum', header:'Mã thanh toán', minWidth: 150, dataType:'custom',customCell(rowData: any): any {
          return <span style={{color:'blue'}}>{rowData.paymentRefNum}</span>
        }
      },
      {field:'memo', header:'Diễn giải', minWidth: 150},
      {field:'amount', header:'Số tiền',width:200, dataType:'number'}
    ],
    items: itemPayments,
    originalProps: {
      paginator: true,
      rows: 5,
      rowsPerPageOptions: [5, 10,20, 50,100]
    }
  })

  const {render: renderDialogCrup, view} = useDialogCrup({
    header:'!Chi tiết công nợ',
    width: '100rem',
    fields:[
      {
        type:'custom', className:'md:col-12', body: <Fieldset legend="Thông tin chung" className="content-padding">
          {commonInfoForm.render()}
        </Fieldset>
      },
      {
        type: 'custom', className: 'md:col-12', body: <div style={{maxHeight: 'calc(100vh - 20.5rem)'}}>
          {renderDataTableBasic()}
        </div>
      },
      {
        type: 'custom', className: 'md:col-12', body: <div style={{maxHeight: 'calc(100vh - 20.5rem)'}}>
          {renderDataTablePayment()}
        </div>
      }
    ]
  })

  const doSync = () =>{
    ConfirmDialogService.confirm('Xác nhận đồng bộ','Bạn có muốn đồng bộ lại công nợ?',()=>{
      Service.recalculate({timePeriodId: timePeriodId}).then(()=>{
        ToastService.success('Đồng bộ thành công');
        reloadLazyData();
        getTimeSync();
      }).catch(()=>{
        ToastService.error('Đồng bộ thất bại')
      })
    })
  }

  const doSwitch = (orderId, doView: boolean) => {
    PurchaseOrderService.get(orderId).then(data => {
      doUpdatePO(orderId, data, doView);
    })
  }

  const doUpdate =(item)=>{
    setItems(null)
    if (item.toPartyId && item.organizationPartyId && item.timePeriodId){
      let params = {toPartyId: item.toPartyId, organizationPartyId: item.organizationPartyId, timePeriodId: item.timePeriodId};
      Service.getDetail(params).then(data =>{
        setItems(data.items.map(item =>{
          item.completedDate = FormatDisplay.date(new Date(item.completedDate), 'DD/MM/YY HH:mm')
          return item
        }));
        //xy ly -a -> (a)
        if (data.beginningBalance < 0){
          data.beginningBalance = `(${FormatDisplay.number(data.beginningBalance*(-1))})`
        }else {
          data.beginningBalance = `${FormatDisplay.number(data.beginningBalance)}`
        }
        if (data.endingBalance < 0){
          data.endingBalance = `(${FormatDisplay.number(data.endingBalance*(-1))})`
        }else {
          data.endingBalance = `${FormatDisplay.number(data.endingBalance)}`
        }
        if (data.lastRuntime){
          data.lastRuntime = `${FormatDisplay.date(new Date(data.lastRuntime), 'DD/MM/YYYY-HH:mm')}`
        }
        setItemPayments(data.itemPayments.map(item =>{
          item.effectiveDate = FormatDisplay.date(new Date(item.effectiveDate), 'DD/MM/YY HH:mm')
          return item
        }))
        commonInfoForm.setValue(data);
        view(data)
      })
    }
  }

  //Reload lai danh sach cong no khi chon nam
  useEffect(() => {
    reloadLazyData()
  }, [timePeriodId]);

  return (
    <div className="grid">
      <SelectButton className="ml-2" value={timePeriodId} onChange={(e) => setTimePeriodId(e.value)} itemTemplate={periodTemplate} optionLabel="label" options={periodOptions} />
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12.5rem)'}}>
        {renderDataTable()}
      </div>
      {renderDialogCrup()}
      {renderCrupPO()}
    </div>
  )
}