import {EntityService} from '../../../../service/EntityService';

export class CustomersService extends EntityService {

  static entity = 'iam-manager/customers';

  static approve(partyId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${partyId}/approve`).then(res => res.data);
  }

  static reject(partyId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${partyId}/reject`).then(res => res.data);
  }

  static changeAgent(partyId: string, agentPartyId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${partyId}/change-agent`, {agentPartyId}).then(res => res.data);
  }

}
