import {Route, Routes} from 'react-router-dom';

import {Employees} from './nhan-vien';
import {AccountInfo} from './thong-tin-tai-khoan';

export default function QuanLy() {

  return (
    <Routes>
      <Route path="nhan-vien" element={<Employees/>}/>
      <Route path="thong-tin-tai-khoan" element={<AccountInfo/>}></Route>
    </Routes>
  );
}