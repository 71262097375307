import {Fragment, useEffect, useState} from 'react';
import {Column} from 'primereact/column';
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';
import {ConfirmDialogService, FormatDisplay, useDataTableBasic, useDialogCrup} from '@iamsoftware/react-hooks';

export const useCrupPaymentItems = ({display, disabled, items}) => {
  const dataKey = 'dataKey';
  const [headerResult, setHeaderResult] = useState(null);
  const [grandTotal, setGrandTotal] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [amountApplied, setAmountApplied] = useState(0);
  useEffect(() => {
    if (items.length) {
      let sum1 = 0;
      let sum2 = 0;
      let sum3 = 0;
      items.map(item => {
        sum1 += item?.grandTotal;
        sum2 += item?.remainingAmount;
        sum3 += item?.amountApplied;
      })
      setGrandTotal(sum1)
      setRemainingAmount(sum2)
      setAmountApplied(sum3)
    }
  }, [items]);
  useEffect(() => {
    setHeaderResult(tabHeader)
  }, []);

  //Tao id cho items
  useEffect(() => {
    if (display) {
      items.map(item => {
        item._id = `${Date.now()}_${item.orderItemSeqId}_${Math.random()}`;
        return item
      })
    }
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps


  const {render} = useDataTableBasic({
    tableHeader: headerResult,
    dataKey: '_id',
    columns: [
      {field: 'placedDate', header: 'Ngày chứng từ', dataType: 'date', width: 150},
      {field: 'displayId', header: 'Số chứng từ', width: 150},
      {field: 'orderComments', header: 'Diễn giải', width: 400},
      {field: 'grandTotal', header: 'Số phải thu', dataType: 'number', width: 200},
      {field: 'remainingAmount', header: 'Số chưa thu', dataType: 'number', width: 200},
      {field: 'amountApplied', header: 'Số thu', dataType: 'number', width: 200},
    ],
    items,
    originalProps: {
      footerColumnGroup: <ColumnGroup>
        <Row>
          <Column colSpan={3} footerStyle={{textAlign: 'center'}} footer='Tổng cộng:'></Column>
          <Column footer={FormatDisplay.number(grandTotal)} footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
          <Column footer={FormatDisplay.number(remainingAmount)} footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
          <Column footer={FormatDisplay.number(amountApplied)} footerStyle={{textAlign: 'right', fontSize: '14px'}}/>
        </Row>
      </ColumnGroup>
    }
  });

  const renderPaymentItems = () => {
    return (
      <div className="grid">
        <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 15.5rem)'}}>
          {render()}
        </div>
      </div>
    );
  }
  const tabHeader = (
    <div className="flex align-items-center justify-content-between ">
      <Fragment>
        <span>Chứng từ</span>
      </Fragment>
    </div>
  );
  return {renderPaymentItems};
}
