import {Fragment, useEffect, useState} from 'react';

import {SelectButton} from 'primereact/selectbutton';
import {Button} from 'primereact/button';
import {ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {HouseholdsService as Service} from './HouseholdsService';
import {CommonService} from 'src/service/CommonService';

const statuses = [
  {value: 'N', label: 'Đang hoạt động'},
  {value: 'Y', label: 'Đã khóa'}
];

export const DialogHouseholds = ({setSelectedItem}) => {

  const header = 'Hộ kinh doanh';
  const dataKey = 'partyId';

  const [disabled, setDisabled] = useState('N');

  const [requiredParams, setRequiredParams] = useState(null);

  const [provinces, setProvinces] = useState([]);
  const [counties, setCounties] = useState([]);

  useEffect(() => {
    CommonService.getProvinces().then(data => {
      setProvinces(data.listData.map(item => {
        return {value: item.toGeoId, label: item.geoName}
      }));
    });
  }, []);

  useEffect(() => {
    if (disabled) {
      setRequiredParams({disabled: {value: disabled, matchMode: 'equals'}});
    } else {
      setRequiredParams({});
    }
  }, [disabled]);

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    stateKey: 'Households',
    dataKey,
    columns: [
      {field: 'pseudoId', header: 'Mã', width: 110, matchMode: 'contains'},
      {field: 'partyName', header: 'Tên hộ', minWidth: 150, matchMode: 'contains'},
      {field: 'address1', header: 'Địa chỉ', minWidth: 170, matchMode: 'contains'},
      {field: 'comments', header: 'Ghi chú', width: 150, matchMode: 'contains'}
    ],
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    requiredParams
  });

  const getByTaxCode = () => {
    const _value = form.getRawValue();
    if (_value.partyTaxId) {
      CommonService.getByTaxCode(_value.partyTaxId, 'N').then(({data}) => {
        if (data?.partyName) {
          _value.partyName = data.partyName;
          _value.address1 = data.address1;
          _value.stateProvinceGeoId = data.stateProvinceGeoId;
          _value.countyGeoId = data.countyGeoId;
          form.setValue(_value);
        }
      });
    }
  }

  const {render: renderDialogCrup, create, update, form} = useDialogCrup({
    header,
    dataKey,
    width: '60rem',
    fields: [
      {field: 'partyTaxId', header: 'Mã số thuế', InputTextProps: {maxLength: 15, keyfilter: 'int'}, className: 'md:col-4'},
      {field: 'getByTaxCode', header: '', type: 'custom', body: <Button icon="pi pi-search" className="p-button-rounded p-button-success" onClick={getByTaxCode}/>, className: 'md:col-1 pt-4 pl-0'},
      {field: 'partyName', header: 'Tên hộ', required: true, InputTextProps: {maxLength: 500}, className: 'md:col-7'},
      {field: 'address1', header: 'Địa chỉ', required: true, InputTextProps: {maxLength: 500}, className: 'md:col-12'},
      {field: 'stateProvinceGeoId', header: 'Tỉnh/Thành phố', required: true, type: 'Dropdown', DropdownProps: {options: provinces, filter: true}, className: 'md:col-6'},
      {field: 'countyGeoId', header: 'Quận/Huyện', required: true, type: 'Dropdown', DropdownProps: {options: counties, filter: true}, className: 'md:col-6'},
      {field: 'phoneNumber', header: 'Số điện thoại', InputTextProps: {maxLength: 17, keyfilter: 'int'}, className: 'md:col-6'},
      {field: 'emailAddress', header: 'Email', InputTextProps: {maxLength: 50}, className: 'md:col-6'},
      {field: 'comments', header: 'Ghi chú', className: 'md:col-12'}
    ],
    createItem: item => {
      return Service.create(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });

  const {stateProvinceGeoId} = form.getRawValue();
  useEffect(() => {
    if (stateProvinceGeoId) {
      CommonService.getCounties(stateProvinceGeoId).then(data => {
        setCounties(data.listData.map(item => {
          return {value: item.toGeoId, label: item.geoName}
        }));
      });
    }
  }, [stateProvinceGeoId]);

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  useEffect(() => {
    setSelectedItem(selectedItem);
  }, [selectedItem]); // eslint-disable-line react-hooks/exhaustive-deps

  const doUpdate = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.get(selectedItem[dataKey]).then(data => {
        update(data);
      });
    }
  }

  const doDisable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.disable(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }
  const doEnable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.enable(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate: create,
    hasSelectedItem: selectedItem,
    doUpdate,
    leftButtons: <Fragment>
      {disabled === 'N' && <Button label="Khoá" icon="pi pi-lock" className="p-button-danger" onClick={doDisable} disabled={!selectedItem}/>}
      {disabled === 'Y' && <Button label="Mở khoá" icon="pi pi-lock" className="p-button-success" onClick={doEnable} disabled={!selectedItem}/>}
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        <SelectButton value={disabled} options={statuses} onChange={e => setDisabled(e.value)}/>
      </div>
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 29rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogCrup()}
    </div>
  );
}