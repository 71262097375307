import {Route, Routes} from 'react-router-dom';

import {EmailMessages} from './email-messages';
import {UserAccounts} from './user-accounts';

export default function System() {

  return (
    <Routes>
      <Route path="email-messages" element={<EmailMessages/>}/>
      <Route path="user-accounts" element={<UserAccounts/>}/>
    </Routes>
  );
}
