import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';


import {FormatDisplay, ToastService, useForm} from '@iamsoftware/react-hooks';
import {Fieldset} from 'primereact/fieldset';
import {CommonService} from '../../../service/CommonService';
import {TaxPaymentService as Service} from './TaxPaymentService'
import {BankAccountService} from '../../danh-muc/tai-khoan-ngan-hang/BankAccountService';
import {PurchaseOrderService} from '../../mua-hang/don-hang/OrdersService';

export const useCrupPayment = ({reloadLazyData}) => {
  const [display, setDisplay] = useState(null);
  const [paymentInstruments, setPaymentInstruments] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [taxPaymentTypes, setTaxPaymentTypes] = useState([]);
  const [doView, setDoView] = useState(false);
  const [fromBank, setFromBank] = useState([]);
  const [updating, setUpdating] = useState(false)
  const [paymentId, setPaymentId] = useState('')
  useEffect(() => {
    CommonService.getPaymentInstruments().then(data => {
      setPaymentInstruments(data.listData.map(item => {
        return {value: item.enumId, label: item.description}
      }));
    });
    CommonService.getPaymentType().then(data => {
      setPaymentTypes(data.listData.map(item => {
        return {value: item.enumId, label: item.description}
      }));
    });
    CommonService.getTaxPaymentType().then(data => {
      setTaxPaymentTypes(data.listData.map(item => {
        return {value: item.enumId, label: item.description}
      }));
    });

  }, [])
  useEffect(() => {
    const _event: any = {
      filters: {}
    };
    BankAccountService.getList(JSON.stringify(_event)).then(data => {
      setFromBank(data.listData.map(item => {
        return {value: item.paymentMethodId, label: `[${item.bankAccount}] - ${item.bankName}`};
      }))
    })
  }, []);
  const commonInfoForm = useForm({
    fields: [
      {
        field: 'paymentTypeEnumId', header: 'Loại chứng từ', className: 'md:col-6', required: true, type: 'Dropdown',
        DropdownProps: {options: taxPaymentTypes},
      },
      {
        field: 'paymentInstrumentEnumId', header: 'Hình thức thanh toán', required:true, type: 'Dropdown', className: 'md:col-6', DropdownProps: {
          options: paymentInstruments
        }
      },
      {field: 'amount', header: 'Số tiền', required:true, className: 'md:col-6', type: 'InputNumber'},
      {field: 'paymentMethodId', header: 'Tài khoản chuyển', className: 'md:col-6', type: 'Dropdown', DropdownProps: {options: fromBank}},
      {field: 'comments', header: 'Chứng từ kèm theo', className: 'md:col-12',},
      {field: 'memo', header: 'Diễn giải', className: 'md:col-12',},
    ],
    readOnly: doView
  });

  const infoForm = useForm({
    fields: [
      {
        field: 'paymentRefNum', header: 'Số chứng từ', required:true, className: 'md:col-12'
      },
      {
        field: 'effectiveDate', header: 'Ngày chứng từ ', required:true, className: 'md:col-12', InputTextProps: {type: 'date'}
      },
    ],
    readOnly: doView
  });


  const form = useForm({
    fields: [
      {
        type: 'custom', className: 'md:col-8',
        body: <Fieldset legend="Thông tin chung">
          {commonInfoForm.render()}
        </Fieldset>
      },
      {
        type: 'custom', className: 'md:col-4',
        body: <Fieldset legend="Chứng từ">
          {infoForm.render()}
        </Fieldset>
      },
    ],
  });


  const commonData = commonInfoForm.getValue();
  const infoData = infoForm.getValue();
  const {paymentInstrumentEnumId} = commonInfoForm.getRawValue()
  useEffect(() => {
    if (paymentInstrumentEnumId && doView === false && commonInfoForm.dirty['paymentInstrumentEnumId'] || updating === false) {
      const _value = infoForm.getRawValue()
      PurchaseOrderService.genPaymentPseudoId(paymentInstrumentEnumId).then(data => {
        _value.paymentRefNum = data.code
        if (_value.effectiveDate === '') {
          _value.effectiveDate = FormatDisplay.date(new Date(), 'YYYY-MM-DD')
        }
        infoForm.setValue(_value)
      })
    }
  }, [paymentInstrumentEnumId])
  const reset = () => {
    commonInfoForm.reset();
    infoForm.reset();
  }


  const doCreate = () => {
    setDoView(false)
    setUpdating(false)
    reset()
    setDisplay(Date.now());
    PurchaseOrderService.genPaymentPseudoId('PiCash').then(data => {
      infoForm.setValue({
        paymentRefNum: data.code,
        effectiveDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD')
      })
    })

  }
  const doUpdate = async (input: any,doView: boolean) => {
    setDoView(doView)
    setUpdating(true)
    reset()
    setDisplay(Date.now());
    setPaymentId(input.paymentId)
    input.effectiveDate = FormatDisplay.date(new Date(input.effectiveDate), 'YYYY-MM-DD')
    commonInfoForm.setValue(input)
    infoForm.setValue(input)
  }
  const inject = () => {
    const data = Object.assign(
      commonData,
      infoData);
    return data
  }

  const onSubmit = () => {
    if (infoForm.valid() && commonInfoForm.valid()) {
      if (updating === false) {
        Service.create(inject()).then(() => {
          setDisplay(null);
          ToastService.success();
          reloadLazyData();
        });
      } else if (updating === true) {
        Service.update(paymentId, inject()).then(() => {
          setDisplay(null)
          ToastService.success();
          reloadLazyData();
        })
      }

    }
  }
  const footer = (
    <div className="flex flex-row-reverse flex-wrap">
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
      {!doView && <Button label="Lưu" icon="pi pi-check" size="small" severity="success" onClick={() => onSubmit()}/>}
    </div>
  );

  const header = () => {
    return (
      <div className=" flex jjustify-content-between flex-wrap">
        <div className="mr-8">
          <h4>Phiếu chi</h4>
        </div>
      </div>
    )

  }

  const render = () => {
    return (
      <Fragment>
        <Dialog header={header} footer={footer} visible={!!display} maximized={true} draggable={false} onHide={() => setDisplay(null)}>
          <div className="grid">
            <div className="col-12">
              {form.render()}
            </div>
          </div>
        </Dialog>
      </Fragment>
    );
  }

  return {renderCrupPayment: render, doUpdatePayment: doUpdate, doCreatePayment: doCreate};
}
