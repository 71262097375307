import {EntityService} from 'src/service/EntityService';

export class CustomersService extends EntityService {
  static entity = 'iam-party/customers';

  static getGeoId(): Promise<any> {
    return this.axios.get(`iam-common/geos/provinces`).then(res => res.data);
  }

  static find(data ,term: string): Promise<any> {
    return this.axios.get(`${this.entity}/find?roleTypeId=${data}`, {params: {term}}).then(res => res.data);
  }

  static uploadExcel(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/import-excel`, data).then(res => res.data);
  }

}
