import {ToastService, useDataTable, useDataTableBasic, useDialogCrup, useForm, useToolbar} from '@iamsoftware/react-hooks';
import {Button} from 'primereact/button';
import {InputText} from "primereact/inputtext";
import {Dialog} from 'primereact/dialog';

import {RequestService as Service} from './RequestService';
import {useEffect, useState} from 'react';
import {AppStorage, UserStorage} from '../../../service/UserStorage';

export const Request = () => {
  const [headerResult, setHeaderResult] = useState(null);
  const [messageTypes, setMessageTypes] = useState([]);
  const [tokenContent, setTokenContent] = useState(null);
  const [cKey, setCKey] = useState('')
  const [cValue, setCValue] = useState('')
  const [items, setItems] = useState([])
  const [display, setDisplay] = useState(null);
  const [displayRequest, setDisplayRequest] = useState(null);

  const dataKey = 'requestSyncId';

  useEffect(() => {
    setHeaderResult(tabHeader)
    Service.getMessageTypes().then(data => {
      setMessageTypes(data.listData.map(item => {
        return {value: item.systemMessageTypeId, label: item.description}
      }));
    })
  }, []);
  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    tableHeader: headerResult,
    dataKey,
    columns: [
      {width: 150, field: 'startTime', header: 'Ngày yêu cầu', matchMode: 'contains', dataType: 'date'},
      {width: 150, field: 'invoiceMessageType', header: 'Loại hóa đơn', matchMode: 'contains'},
      {width: 150, field: 'syncFromDate', header: 'Đồng bộ từ ngày', dataType: 'date', matchMode: 'contains'},
      {width: 125, field: 'syncThruDate', header: 'Đến ngày', dataType: 'date', matchMode: 'contains'},
      {width: 150, field: 'status', header: 'Trạng thái', matchMode: 'contains'},
      {width: 150, field: 'availableRecord', header: 'Cần đồng bộ'},
      {width: 150, field: 'syncedRecord', header: 'Đã đồng bộ',},
      {width: 400, field: 'errors', header: 'Lỗi', matchMode: 'contains'}
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 40,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-eye', title: 'Chi tiết yêu cầu đồng bộ', className: 'p-button-info', command: () => doRowView(item)},
          ]
        })
      }
    }
  });

  const form = useForm({
    fields: [
      {field: 'taxCode', header: 'Mã số thuế', required: true, disabled: true, className: 'md:col-12'},
      {field: 'password', header: 'Mật khẩu (hoadondientu.gdt.gov.vn)', required: true, className: 'md:col-12'},
      {field: 'systemMessageTypeId', header: 'Loại hóa đơn', type: 'Dropdown', DropdownProps: {options: messageTypes}, required: true, className: 'md:col-12'},
      {field: 'fromDate', header: 'Từ ngày', InputTextProps: {type: 'date'}, required: true, className: 'md:col-6'},
      {field: 'thruDate', header: 'Đến ngày', InputTextProps: {type: 'date'}, required: true, className: 'md:col-6'},
      {
        type: 'custom', body: <div className={'flex justify-content-between'}>
          <img className={'md:col-5'} style={{height: '80px'}} src={`data:image/svg+xml;utf8,${encodeURIComponent(tokenContent)}`}/>
          <Button style={{marginTop: '20px', width: '40px', height: '40px'}} onClick={() => getCaptcha()} outlined rounded icon={'pi pi-refresh'}></Button>
          <InputText style={{marginTop: '20px', width: '390px', height: '40px'}} placeholder={'Nhập mã captcha'} value={cValue} onChange={e => setCValue(e.target.value)}></InputText>
        </div>, className: 'md:col-12'
      }
    ],
  });

  const {render: renderDetail} = useDataTableBasic({
    tableHeader: 'Chi tiết đồng bộ',
    indexColumnWidth: 40,
    columns: [
      {width: 100, field: 'initDate', header: 'Bắt đầu ', dataType: 'date'},
      {width: 125, field: 'processedDate', header: ' Hoàn thành', dataType: 'date'},
      {width: 125, field: 'failCount', header: 'Lần thất bại', dataType: 'number'},
      {width: 125, field: 'status', header: 'Trạng thái',},
      {width: 300, field: 'resultText', header: 'Kết quả',},
      {width: 75, field: 'errorText', header: 'Lỗi',},
    ],
    items,
  });
  useEffect(() => {
    if (!displayRequest){
      setCValue(null)
    }
    },
    [displayRequest])

  const doRowView = (rowData) => {
    Service.syncDetail(rowData.requestSyncId).then(data => {
      setItems(data.items)
      setDisplay(Date.now())
    })
  }
  const getCaptcha = () => {
    Service.getCaptcha().then(data => {
      setTokenContent(data.content)
      setCKey(data.key)
    })
  }
  const doCreate = () => {
    getCaptcha()
    form.setValue({taxCode: AppStorage.get('partyTaxId')})
    setDisplayRequest(Date.now())
  }
  const onSubmit = () => {
    if (form.valid()) {
      const _value = form.getValue()
      _value.ckey = cKey
      _value.cvalue = cValue
      Service.createRequest(_value).then(data => {
          if (data.success === 'N') {
            ToastService.error(data.errors)
            getCaptcha()
          } else {
            ToastService.success()
            setDisplayRequest(null)
          }

        }
      )
    }

  }
  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <span>Yêu cầu đồng bộ</span>
        <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"
                size="small" onClick={() => doCreate()}/>
      </div>
    </div>
  );
  const footer = (
    <div className="flex flex-row justify-content-end flex-wrap">
      {displayRequest && <Button label={'Lưu'} icon={'pi pi-check'} onClick={() => onSubmit()}></Button>}
      {displayRequest && <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplayRequest(null)}/>}
      {display && <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>}
    </div>
  );
  return (
    <div className="grid">
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12.5rem)'}}>
        {renderDataTable()}
        <Dialog header={'Tạo mới yêu cầu đồng bộ'} footer={footer} visible={!!displayRequest} style={{width: '80rem',}} position="top" onHide={() => setDisplayRequest(null)}>
          {form.render()}
        </Dialog>
        <Dialog header={'Kết quả đồng bộ hoá đơn'} footer={footer} visible={!!display} style={{width: '80rem',}} position="top" onHide={() => setDisplay(null)}>
          {renderDetail()}
        </Dialog>
      </div>
    </div>
  );
}