import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDialogCrup, useForm, useToolbar} from '@iamsoftware/react-hooks';
import {Fragment, useEffect, useState} from 'react';
import {Button} from 'primereact/button';
import {Fieldset} from 'primereact/fieldset';

import {ExpenseInvoicesService as Service} from './ExpenseInvoicesService';
import {CommonService} from '../../../service/CommonService';
import {ExpenseService} from '../../danh-muc/khoan-muc-chi-phi/ExpenseService';
import {EmployeesService} from '../../quan-ly/nhan-vien/EmployeesService';
import {SuppliersService} from '../../mua-hang/nha-cung-cap/SuppliersService';
import {BankAccountService} from '../../danh-muc/tai-khoan-ngan-hang/BankAccountService';
import {CustomersService} from '../../ban-hang/khach-hang/CustomersService';
import {ObjectType} from '../phieu-chi/objectType';
import {PurchaseOrderService} from '../../mua-hang/don-hang/OrdersService';

export const ExpenseInvoice = () => {
  const header = 'Chi phí';
  const dataKey = 'invoiceId';
  const updateTooltip = 'Cập nhật';
  const resendTooltip = 'Xóa';
  const [typeEnums, setTypeEnums] = useState([])
  const [accountNames, setAccountNames] = useState([])
  const [paymentTypes, setPaymentTypes] = useState([])
  const [employees, setEmployees] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [fromBank, setFromBank] = useState([]);
  const [toBank, setToBank] = useState([]);
  const [roleTypes, setRoleTypes] = useState([]);
  const [objectTypes, setObjectTypes] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [invoiceItemSeqId, setInvoiceItemSeqId] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [readOnly, setReadOnly] = useState(null);
  const [objBySearch, setObjBySearch] = useState([]);
  const [displayObj, setDisplayObj] = useState(null);

  useEffect(() => {
    EmployeesService.getList('').then(data => {
      setEmployees(data.listData.map(item => {
        return {value: item.partyId, label: item.partyName}
      }));
    });
  }, [])
  useEffect(() => {
    CommonService.getPartyRoles().then(data => {
      setRoleTypes(data.listData.map(item => {
        return {value: item.roleTypeId, label: item.description}
      }))
    })
  }, []);

  useEffect(() => {
    const _event: any = {
      filters: {}
    };
    BankAccountService.getList(JSON.stringify(_event)).then(data => {
      setFromBank(data.listData.map(item => {
        return {value: item.paymentMethodId, label: `[${item.bankAccount}] - ${item.bankName}`};
      }))
    })
  }, []);

  useEffect(() => {
    CommonService.glAccountTypes().then(data => {
      setTypeEnums(data.listData.map(item => {
        return {value: item.enumId, label: item.description};
      }))
    })
  }, []);

  useEffect(() => {
    CommonService.getPaymentStatuses().then(data => {
      setPaymentStatus(data.listData.map(item => {
        return {value: item.statusId, label: item.description};
      }))
    })
  }, []);

  useEffect(() => {
    CommonService.getPaymentInstruments().then(data => {
      setPaymentTypes(data.listData.map(item => {
        return {value: item.enumId, label: item.description};
      }))
    });
  }, []);

  useEffect(() => {
    ExpenseService.List('').then(data => {
      setAccountNames(data.listData.map(item => {
        return {value: item.glAccountId, label: `[${item.accountCode}] - ${item.accountName}`};
      }))
    })
  }, []);

  const searchEmployees = event => {
    EmployeesService.find(event.query).then(data => {
      setEmployees(data.listData.map(item => {
        delete item.disabled;
        let label = `[${item.pseudoId}] - ${item.partyName}`;
        return {...item, value: item.partyId, label: label};
      }));
    });
  }

  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <span>Chi phí</span>
        <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"
                size="small" onClick={() => doCreate()}/>
      </div>
    </div>
  );

  const {render: renderDataTable, selectedItems, setSelectedItems, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    tableHeader: tabHeader,
    columns: [
      {
        field: 'paymentRefNum', header: 'Số chứng từ', matchMode: 'contains', minWidth: 150, dataType: 'custom', customCell(rowData: any): any {
          return <a onClick={() => {
            doUpdate(rowData, true)
          }}>{rowData.paymentRefNum}</a>
        }
      },
      {field: 'invoiceDate', header: 'Ngày tháng', matchMode: 'contains', width: 120, dataType: 'date'},
      {
        field: 'fromParty', header: 'Đối tượng', matchMode: 'contains', width: 300, dataType: 'custom', customCell(rowData: any): any {
          return <div>
            {rowData.fromPartyName}</div>
        }
      },
      {field: 'itemTypeEnum', header: 'Loại chi phí', matchMode: 'contains', minWidth: 150},
      {
        field: 'accountCode', header: 'Khoản mục chi phí', matchMode: 'contains', minWidth: 175, dataType: 'custom', customCell(rowData: any): any {
          return <div>[{rowData.accountCode}] {rowData.accountName}</div>
        }
      },
      {field: 'amount', header: 'Số tiền', matchMode: 'contains', width: 100, dataType: 'number'},
      {field: 'description', header: 'Diễn giải', matchMode: 'contains', width: 150},
      {field: 'paymentStatus', header: 'Thanh toán', matchMode: 'contains', minWidth: 150},
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 120,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-pencil', title: updateTooltip, className: 'p-button-warning', command: () => doUpdate(item, false)},
          ]
          if (item.paymentStatusId === 'PmntAuthorized') {
            item.actions.push({icon: 'pi pi-check', title: 'Ghi sổ', className: 'p-button-success', command: () => doComplete(item)})
          } else {
            item.actions.push({icon: 'pi pi-times', title: 'Bỏ ghi sổ', className: 'p-button-danger', command: () => doUnComplete(item)})
          }
          item.actions.push({icon: 'pi pi-trash', title: resendTooltip, className: 'p-button-secondary', command: () => doDelete(item)})
        })
      }
    }
  });

  const certInfoForm = useForm({
    fields: [
      {field: 'paymentRefNum', header: 'Số chứng từ', required: true, className: 'md:col-12'},
      {
        field: 'invoiceDate', header: 'Ngày chứng từ', required: true, className: 'md:col-12', InputTextProps: {type: 'date'},
      },
    ],
    readOnly
  })

  const commonInfoForm = useForm({
    fields: [
      {
        field: 'partyId', header: 'Đối tượng', required: true, className: 'md:col-8', type: 'Dropdown',
        DropdownProps: {options: suppliers},
        disabled: 'View'
      },
      {
        className: 'md:col-1', type: 'custom', body: <Button className="mt-5" icon="pi pi-search" rounded severity="success" aria-label="Tìm kiếm"
                                                             onClick={() => setDisplayObj(Date.now())}/>
      },
      {field: 'toName', header: 'Người nhận', className: 'md:col-4'},
      {field: 'address1', header: 'Địa chỉ', className: 'md:col-4'},
      {field: 'itemTypeEnumId', header: 'Loại chi phí', type: 'Dropdown', DropdownProps: {options: typeEnums}, className: 'md:col-4'},
      {field: 'glAccountId', header: 'Tên chi phí', required: true, className: 'md:col-4', type: 'Dropdown', DropdownProps: {options: accountNames}},
      {field: 'amount', header: 'Số tiền', className: 'md:col-4', type: 'InputNumber', InputNumberProps: {min: 0}, required: true},
      {
        field: 'paymentInstrumentEnumId', header: 'Hình thức thanh toán', className: 'md:col-4', type: 'Dropdown', DropdownProps: {options: paymentTypes}, required: true
      },
      {
        field: 'paymentMethodId', header: 'Tài khoản chuyển', required: true, className: 'md:col-6', type: 'Dropdown', DropdownProps: {options: fromBank},
        displayDependency(item: any): boolean {
          return ['PiCashOrWireTransfer', 'PiWireTransfer'].includes(item?.paymentInstrumentEnumId)
        }
      },
      {
        field: 'toPaymentMethodId', header: 'Tài khoản nhận', required: true, className: 'md:col-6', type: 'Dropdown', DropdownProps: {options: toBank},
        displayDependency(item: any): boolean {
          return ['PiCashOrWireTransfer', 'PiWireTransfer'].includes(item?.paymentInstrumentEnumId)
        }
      },
      {field: 'description', header: 'Diễn giải', className: 'md:col-12'},

      {field: 'memo', header: 'Ghi chú kèm theo', className: 'md:col-8'},
      {
        field: 'enteredByParty', header: 'Nhân viên phụ trách', className: 'md:col-4', type: 'AutoComplete',
        AutoCompleteProps: {suggestions: employees, completeMethod: searchEmployees, field: 'label', dropdown: true, forceSelection: true},

      },
    ],
    readOnly
  })

  const {render: renderDialogCrup, create, update, view, form, formMethod, display} = useDialogCrup({
    header,
    dataKey,
    width: '120rem',
    fields: [
      {
        type: 'custom', className: 'md:col-8', body: <Fieldset legend="Thông tin chung">
          {commonInfoForm.render()}
        </Fieldset>
      },
      {
        type: 'custom', className: 'md:col-4', body: <Fieldset legend="Chứng từ">
          {certInfoForm.render()}
        </Fieldset>
      },
    ],
    createItem: async item => {
      return Service.create(await inject());
    },
    updateItem: async (id, item) => {
      return Service.updateItem(id, invoiceItemSeqId, await inject());
    },
    reloadLazyData
  });

  const _value = commonInfoForm.getRawValue();
  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  //Loc ten chi phi thoe loại
  useEffect(() => {
    if (_value.itemTypeEnumId){
      ExpenseService.List('',{glAccountClassEnumId: _value.itemTypeEnumId}).then(data => {
        setAccountNames(data.listData.map(item => {
          return {value: item.glAccountId, label: `[${item.accountCode}] - ${item.accountName}`};
        }))
      })
    }
  }, [_value.itemTypeEnumId]);

  useEffect(() => {
    const formData = commonInfoForm.getRawValue();
    suppliers.map(supplier => {
      if (_value.partyId === supplier.value) {
        if (supplier.roleTypeId === 'Buyer') {
          CustomersService.get(_value.partyId).then(data => {
            formData.address1 = data.address1
            formData.memo = `Chi trả cho ${data.partyName}`;
            formData.postalContactMechId = data.postalContactMechId
            commonInfoForm.setValue(formData);
            if (data?.paymentMethodId) {
              setToBank([{value: data.paymentMethodId, label: `[${data.accountNumber}] - ${data.bankName}`}])
            } else {
              setToBank([]);
            }
          })
        }
        if (supplier.roleTypeId === 'Employee') {
          EmployeesService.get(_value.partyId).then(data => {
            formData.memo = `Chi trả cho ${data.partyName}`;
            formData.address1 = data.address1
            formData.postalContactMechId = data.postalContactMechId
            commonInfoForm.setValue(formData);

            if (data?.paymentMethodId) {
              setToBank([{value: data.paymentMethodId, label: `[${data.accountNumber}] - ${data.bankName}`}])
            } else {
              setToBank([]);
            }
          })
        }
        if (supplier.roleTypeId === 'Supplier') {
          SuppliersService.get(_value.partyId).then(data => {
            formData.memo = `Chi trả cho ${data.partyName}`;

            formData.address1 = data.address1
            formData.postalContactMechId = data.postalContactMechId
            commonInfoForm.setValue(formData);

            if (data?.paymentMethodId) {
              setToBank([{value: data.paymentMethodId, label: `[${data.accountNumber}] - ${data.bankName}`}])
            } else {
              setToBank([]);
            }
          })
        }

      }
    })

  }, [_value.partyId]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    CommonService.getParties().then(data => {
      setSuppliers(data.listData.map(item => {
        return {value: item.partyId, label: `[${item.pseudoId}] - ${item.partyName}`, roleTypeId: item.roleTypeId}
      }))
    })
  }, [display]);

  useEffect(() => {
    const _value = commonInfoForm.getRawValue()
    if (objBySearch?.length) {
      _value.partyId = objBySearch[0].value;
      commonInfoForm.setValue(_value);
    }
  }, [displayObj, objBySearch]);
  const doCreate = () => {
    setSuppliers([]);
    setObjBySearch([]);
    commonInfoForm.reset();
    certInfoForm.reset();
    Service.genPseudoId().then(data => {
      certInfoForm.setValue({paymentRefNum: data.code, invoiceDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD')})
    })
    setReadOnly(false);
    setSelectedItems([])
    create()
  }

  const doUpdate = (data, doView: boolean) => {
    Service.getDetail(data.invoiceId, data.invoiceItemSeqId).then(async data => {
      setInvoiceItemSeqId(data.invoiceItemSeqId);
      setPaymentId(data.paymentId);
      data.partyId = data.fromPartyId;
      if (data.enteredByPartyId) {
        await EmployeesService.get(data.enteredByPartyId).then(item => {
          data.enteredByParty = {value: data.enteredByPartyId, label: `[${item.pseudoId}] - ${item.partyName}`};
        })
      }
      if (data?.toPaymentMethodId) {
        setToBank([{value: data.toPaymentMethodId, label: `[${data.toAccountNumber}] - ${data.toBankName}`}])
      } else {
        setToBank([]);
      }
      if (data.invoiceDate) {
        data.invoiceDate = FormatDisplay.date(new Date(data.invoiceDate), 'YYYY-MM-DD');
      }
      if (doView === true) {
        commonInfoForm.setValue(data);
        certInfoForm.setValue(data);
        setReadOnly(true);
        view(data)
      } else {
        commonInfoForm.setValue(data);
        certInfoForm.setValue(data);
        setReadOnly(false);
        update(data);
      }
    });
  }
  const inject = () => {
    const item = commonInfoForm.getValue();
    const certItem = certInfoForm.getValue();
    item.paymentRefNum = certItem.paymentRefNum;
    item.invoiceDate = certItem.invoiceDate;
    item.fromPartyId = item.partyId;
    item.paymentId = paymentId;
    item.enteredByPartyId = item.enteredByParty?.value;
    return item;
  }

  const doDelete = (data) => {
    if (data && data[dataKey]) {
      ConfirmDialogService.confirm('Xác Nhận', 'Bạn chắc chắn muốn xóa khoản chi phí mục này', () => {
        Service.delete(data[dataKey]).then(() => {
          ToastService.success();
          reloadLazyData();
        })
      })
    }
  }

  const doComplete = (data) => {
    if (data && data[dataKey]) {
      ConfirmDialogService.confirm('Xác Nhận', 'Bạn chắc chắn muốn ghi sổ khoản mục chi phí này', () => {
        Service.complete(data[dataKey]).then(() => {
          ToastService.success();
          reloadLazyData();
        })
      })
    }
  }

  const doUnComplete = (data) => {
    if (data && data[dataKey]) {
      ConfirmDialogService.confirm('Xác Nhận', 'Bạn chắc chắn muốn bỏ ghi sổ khoản mục chi phí này', () => {
        Service.unComplete(data[dataKey]).then(() => {
          ToastService.success();
          reloadLazyData();
        })
      })
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate: doCreate,
    hasSelectedItem: selectedItem,
  });

  return (
    <div className="grid">
      {/*<div className="col-12">*/}
      {/*  {renderToolbar()}*/}
      {/*</div>*/}
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12.5rem)'}}>
        {renderDataTable()}
      </div>
      {renderDialogCrup()}
      <ObjectType method={formMethod} setObjectType={setObjBySearch} display={displayObj} setDisplay={setDisplayObj}/>
    </div>
  )
}