import {ToastService, useDataTable, useDialogCrup, useToolbar, FormatDisplay, useForm, validateTaxCode, ConfirmDialogService} from '@iamsoftware/react-hooks';

import {CustomersService as Service} from './CustomersService';

import {Fragment, useState, useEffect} from 'react';
import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {CommonService} from '../../../service/CommonService';
import {UploadExcel} from './UploadExcel';
import {useCrupCustomer} from './CrupCustomer';


const statuses = [
  {value: 'N', label: 'Đang hoạt động'},
  {value: 'Y', label: 'Đã khóa'}
];

const lockTooltip = 'Khóa';
const unlockTooltip = 'Mở khóa';

export default function KhachHang() {
  const [requiredParams, setRequiredParams] = useState(null);
  const header = 'Khách hàng';
  const dataKey = 'partyId';
  const [disabled, setDisabled] = useState('N');
  const [displayUploadExcel, setDisplayUploadExcel] = useState(null);
  const [displayCreateCustomer, setDisplayDocreateCustomer] = useState(null);


  useEffect(() => {
    if (disabled) {
      setRequiredParams({disabled: {value: disabled, matchMode: 'equals'}});
    } else {
      setRequiredParams({});
    }
  }, [disabled]);

  const doDownloadExcel = () => {
    window.open(`${Service.baseURL}/${Service.entity}/excel`)
  }

  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <span>{header}</span>
        <div>
          <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-1 mb-1 w-7rem h-2rem"
                  size="small" onClick={() => doCreateCustomer()}/>
          <Button label="Upload Excel" icon="pi pi-upload" severity="secondary" className="mr-1 mb-1 w-10rem h-2rem"
                  size="small" onClick={() => setDisplayUploadExcel(Date.now())}/>
          <Button label="Download Excel" icon="pi pi-download" outlined severity="secondary" className="mr-1 mb-1 h-2rem"
                  size="small" onClick={doDownloadExcel}/>
        </div>
      </div>
    </div>
  );

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    tableHeader: tabHeader,
    dataKey,
    columns: [
      {
        field: 'pseudoId', header: 'Mã khách hàng', width: 150,  matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <a onClick={() => Service.get(rowData.partyId).then(data => {
            doUpdateCustomer(data, true);
          })
          }>{rowData.pseudoId}</a>
        }
      },
      {field: 'partyName', header: 'Tên khách hàng', width: 250, matchMode: 'contains'},
      {field: 'partyTaxId', header: 'Mã số thuế', width: 150, matchMode: 'contains'},
      {field: 'partyTypeEnum', header: 'Loại', width: 150, matchMode: 'contains'},
      {field: 'telNumber', header: 'SĐT', width: 150, matchMode: 'contains'},
      {field: 'address1', header: 'Địa chỉ', width: 250, matchMode: 'contains'},
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 90,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-pencil', title: 'Cập nhật khác hàng', className: 'p-button-warning', command: () => doUpdateCustomer(item, false)},
          ]
          if (item.disabled === 'N') {
            item.actions.push({icon: 'pi pi-lock', title: lockTooltip, className: 'p-button-danger', command: () => doDisable(item)},)
          } else {
            item.actions.push({icon: 'pi pi-unlock', title: unlockTooltip, className: 'p-button-success', command: () => doEnable(item)},)
          }
        })
      }
    },
    requiredParams
  });

  const {renderCustomer, doUpdateCustomer, doCreateCustomer} = useCrupCustomer({display: displayCreateCustomer, setDisplay: setDisplayDocreateCustomer, reloadLazyData})

  const clearAndSwitch = (data) => {
    setDisabled(data);
  };

  const doDisable = (data) => {
    ConfirmDialogService.confirm('Khóa KH', `Xác nhận khóa ${data.partyName} ?`, () => {
      Service.disable(data.partyId).then(() => {
        ToastService.success();
        reloadLazyData();
      });

    })
  }
  const doEnable = (data) => {
    ConfirmDialogService.confirm('Mở khóa KH', `Xác nhận mở khóa ${data.partyName} ?`, () => {
      Service.enable(data.partyId).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    })
  }

  return (
    <div className="grid">
      <div className="col-12">
        <SelectButton value={disabled} options={statuses} onChange={e => clearAndSwitch(e.value)}/>
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12.5rem)'}}>
        {renderDataTable()}
      </div>
      {renderCustomer()}
      <UploadExcel display={displayUploadExcel} setDisplay={setDisplayUploadExcel} reloadLazyData={reloadLazyData}/>
    </div>
  );
}
