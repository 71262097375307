import {EntityService} from 'src/service/EntityService';

export class BussinessJobService extends EntityService {

  static entity = 'iam-hkd/categories';

  static getTreeList(): Promise<any> {
    return this.axios.get(`${this.entity}/tree`).then(res => res.data);
  }
}
