import {EntityService} from '../../../service/EntityService';

export class LazadaConnectionServices extends EntityService{
  static entity = 'iam-integrate/lazada';

  static getList(lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/product-stores?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static getLink(): Promise<any> {
    return this.axios.get(`${this.entity}/access-token-link`).then(res => res.data);
  }

  static getHistories(productStoreId: any): Promise<any> {
    return this.axios.get(`${this.entity}/sync-histories?productStoreId=${productStoreId}`).then(res => res.data);
  }

  static disconnect(id: any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/disconnect`).then(res => res.data);
  }

  static getDetailHis(id: any): Promise<any> {
    return this.axios.get(`${this.entity}/sync-histories/${id}`).then(res => res.data);
  }

  static createCon(data: any): Promise<any> {
    return this.axios.post(`iam-integrate/generate-access-token`, data).then(res => res.data);
  }
}