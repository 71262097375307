import {Fragment, useEffect, useState} from 'react';

import {SelectButton} from 'primereact/selectbutton';
import {Button} from 'primereact/button';
import {ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {UserAccountsService as Service} from './UserAccountsService';

const roleTypes = [
  {label: 'TK đại lý', value: 'Agent'},
  {label: 'TK khách hàng', value: 'Customer'}
];
const statuses = [
  {value: 'N', label: 'Đang hoạt động'},
  {value: 'Y', label: 'Đã khóa'}
];

export const UserAccounts = () => {

  const dataKey = 'userId';

  const [roleTypeId, setRoleTypeId] = useState(null);
  const [disabled, setDisabled] = useState(null);

  useEffect(() => {
    setRoleTypeId(roleTypes[0].value);
    setDisabled(statuses[0].value);
  }, []);

  useEffect(() => {
    if (roleTypeId && disabled) {
      reloadLazyData();
    }
  }, [roleTypeId, disabled]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    dataKey,
    columns: [
      {field: 'username', header: 'Tên đăng nhập', width: 145, matchMode: 'contains'},
      {field: 'userFullName', header: 'Họ và tên', minWidth: 140, matchMode: 'contains'},
      {field: 'roleType', header: 'Vai trò', width: 170, matchMode: 'contains'},
      {field: 'emailAddress', header: 'Email', minWidth: 150, matchMode: 'contains'},
      {field: 'ownerPseudoId', header: 'Mã tổ chức', width: 145, matchMode: 'contains'},
      {field: 'ownerPartyName', header: 'Tổ chức', minWidth: 170, matchMode: 'contains'},
      {field: 'ownerPartyTaxId', header: 'MST tổ chức', width: 145, matchMode: 'contains'}
    ],
    getList: lazyLoadEvent => {
      return new Promise<any>((resolve, reject) => {
        if (roleTypeId && disabled) {
          resolve({roleTypeId, disabled});
        } else {
          reject();
        }
      }).then(params => Service.getList(lazyLoadEvent, params));
    }
  });

  const {render: renderDialogResetPassword, update: resetPassword} = useDialogCrup({
    header: '!Reset mật khẩu',
    dataKey,
    width: '40rem',
    fields: [
      {field: 'password', header: 'Mật khẩu mới', required: true, type: 'Password', className: 'md:col-12'}
    ],
    updateItem: (id, item) => {
      return Service.resetPassword(id, item.password);
    },
    reloadLazyData
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doLock = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.lock(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }
  const doUnlock = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.unlock(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }

  const {renderToolbar} = useToolbar({
    leftButtons: <Fragment>
      <Button label="Reset mật khẩu" icon="pi pi-history" className="p-button-warning mr-2" onClick={() => resetPassword(selectedItem)} disabled={!selectedItem}/>
      {disabled === 'N' && <Button label="Khoá" icon="pi pi-lock" className="p-button-danger" onClick={doLock} disabled={!selectedItem}/>}
      {disabled === 'Y' && <Button label="Mở khoá" icon="pi pi-lock" className="p-button-success" onClick={doUnlock} disabled={!selectedItem}/>}
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-4">
        <SelectButton value={roleTypeId} options={roleTypes} onChange={e => setRoleTypeId(e.value)}/>
      </div>
      <div className="col-4">
        <SelectButton value={disabled} options={statuses} onChange={e => setDisabled(e.value)}/>
      </div>
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12">
        {renderDataTable()}
      </div>

      {renderDialogResetPassword()}
    </div>
  );

}