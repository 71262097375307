import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {useDataTable, useForm} from '@iamsoftware/react-hooks';

import {ReportService as Service} from '../ReportService';
import {AgentsService} from '../../agents/AgentsService';

export const CustomerDetail = () => {

  const [currentLazyLoadEvent, setCurrentLazyLoadEvent] = useState(null);

  const [agents, setAgents] = useState([]);

  useEffect(() => {
    AgentsService.getList('').then(data => {
      setAgents(data.listData.map(item => {
        return {value: item.partyId, label: `${item.pseudoId} - ${item.partyName}`}
      }));
    });
  }, []);

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    indexColumnWidth: 45,
    columns: [
      {field: 'agreementDate', header: 'Ngày đăng ký', width: 135, dataType: 'date'},
      {field: 'approvedDate', header: 'Ngày duyệt', width: 120, dataType: 'date'},
      {field: 'partyTaxId', header: 'Mã số thuế', width: 145, matchMode: 'contains'},
      {field: 'partyName', header: 'Khách hàng', width: 170, matchMode: 'contains'},
      {field: 'address1', header: 'Địa chỉ', minWidth: 150, matchMode: 'contains'},
      {field: 'representativeName', header: 'Người liên hệ', width: 150, matchMode: 'contains'},
      {field: 'purchasedQuantity', header: 'SL hoá đơn đã mua', width: 170, matchMode: 'contains'},
      {field: 'usedQuantity', header: 'SL đã phát hành', width: 170, matchMode: 'contains'},
      {field: 'remainQuantity', header: 'SL còn lại', width: 140, matchMode: 'contains'},
      {field: 'itemDescription', header: 'Gói dịch vụ', width: 150, matchMode: 'contains'},
      {field: 'listPrice', header: 'Giá niêm yết', width: 130, matchMode: 'contains'},
      {field: 'price', header: 'Giá bán', width: 130, matchMode: 'contains'},
      {field: 'status', header: 'Trạng thái', width: 150, matchMode: 'contains'},
      {field: 'agentPseudoId', header: 'Đại lý', width: 150, matchMode: 'contains'}
    ],
    getList: lazyLoadEvent => {
      setCurrentLazyLoadEvent(lazyLoadEvent);
      return new Promise<any>(resolve => {
        resolve(getFilters());
      }).then(params => Service.getCustomerDetail(lazyLoadEvent, params));
    },
    selectionMode: 'null' as any
  });

  const getFilters = () => {
    const params: any = {};
    const {agreementDate, approveDate, childAgentPartyId} = getValue();
    if (agreementDate?.length === 2) {
      params.fromDate = new Date(agreementDate[0]).getTime();
      params.thruDate = new Date(agreementDate[1]).getTime();
    }
    if (approveDate?.length === 2) {
      params.fromApproveDate = new Date(approveDate[0]).getTime();
      params.thruApproveDate = new Date(approveDate[1]).getTime();
    }
    if (childAgentPartyId) {
      params.childAgentPartyId = childAgentPartyId;
    }
    return params;
  }

  const exportExcel = () => {
    const params = getFilters();
    params.lazyLoadEvent = currentLazyLoadEvent;

    window.open(`${Service.baseURL}/${Service.entity}/customer-summary/excel?${new URLSearchParams(params).toString()}`);
  }

  const {render: renderForm, getValue} = useForm({
    fields: [
      {field: 'agreementDate', header: 'Ngày HĐ', type: 'Period', className: 'md:col-3'},
      {field: 'approveDate', header: 'Ngày duyệt', type: 'Period', className: 'md:col-3'},
      {field: 'childAgentPartyId', header: 'Đại lý', required: true, type: 'Dropdown', DropdownProps: {options: agents, filter: true}, className: 'md:col-3'},
      {
        field: 'search', type: 'custom',
        body: <Fragment>
          <Button icon="pi pi-search" label="Tìm kiếm" className="p-button-info p-button-sm mr-2" onClick={reloadLazyData}/>
          <Button icon="pi pi-file-excel" label="Xuất Excel" className="p-button-success p-button-sm" onClick={exportExcel}/>
        </Fragment>,
        className: 'md:col-3 un-fluid pt-5'
      }
    ]
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderForm()}
      </div>
      <div className="col-12">
        {renderDataTable()}
      </div>
    </div>
  );

}