import {Route, Routes} from 'react-router-dom';

import {Agents} from './agents';
import Category from './category';
import Customer from './customer';
import Report from './report';
import System from './system';

export default function Manager({me}) {

  return (
    <Routes>
      <Route path="agents" element={<Agents/>}/>
      <Route path="agents/create" element={<Agents beCreate={true}/>}/>
      <Route path="category/*" element={<Category/>}/>
      <Route path="customer/*" element={<Customer me={me}/>}/>
      <Route path="report/*" element={<Report/>}/>
      <Route path="system/*" element={<System/>}/>
    </Routes>
  );
}
