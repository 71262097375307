import {Fragment, useEffect, useState} from 'react';
import { DataTable } from 'primereact/datatable';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {FormatDisplay} from '@iamsoftware/react-hooks';

import {S7ReportService as Service} from './S7ReportService';

export const S7Detail = ({display, setDisplay, update, reCalculate, reload})=>{
  const [headerDate, setHeaderDate] = useState('')
  const [data, setData] = useState(null)
  useEffect(() => {
    load()
  }, [display])
  useEffect(() => {
    if (reload === true) {
      load()
    }
  }, [reload])
  const load = () => {
    if (display?.reportId) {
      Service.get(display?.reportId).then(data => {
        if (data?.timePeriodTypeId === "ReportYear") {
          setHeaderDate(`${data?.timePeriodType} ${data?.yearInt}`)
        } else {
          setHeaderDate(`${data?.timePeriodType} ${data?.periodNum} năm ${data?.yearInt}`)
        }
        setData(data)
      })
    }
  }
  const header = (
    <div style={{textAlign: 'center'}}>
      <span>SỔ TIỀN GỬI NGÂN HÀNG</span>
    </div>
  )
  const footer = (
    <div className="flex flex-row-reverse flex-wrap">
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
      <Button label="Tính toán lại dữ liệu" icon="pi pi-refresh" type="button" outlined severity="danger" size="small" onClick={() => reCalculate(display, true)}/>
      <Button label="Cập nhật" icon="pi pi-pencil" type="button" outlined severity="warning" size="small" onClick={() => update(display, true)}/>
    </div>
  );
  return (
    <Dialog className="dialog-report" header={header} footer={footer} visible={!!display} maximized={true} draggable={false} onHide={() => setDisplay(null)}>
      <Screen rowData={data} display={display}/>
    </Dialog>
  );
}
const Screen = ({rowData, display}) => {
  const [footerData, setFootData] = useState([])
  return (
    <div className="grid">
      <div className="col-12" style={{textAlign: 'center'}}>
        <span style={{textAlign: 'center'}} className="mb-2"> <i style={{textAlign: 'center'}}> Từ ngày {FormatDisplay.date(new Date(rowData?.fromDate), 'DD-MM-YYYY')} đến ngày {FormatDisplay.date(new Date(rowData?.thruDate), 'DD-MM-YYYY')}</i></span>
      </div>
      <div className="p-datatable p-component p-datatable-striped p-datatable-gridlines p-datatable-sm">
        <div className="p-datatable-wrapper" style={{height:'calc(100vh - 15.5rem)'}}>
          <table className="p-datatable-table">
            <thead className="p-datatable-thead" style={{position: 'sticky', top: 0,zIndex:1}}>
            <tr>
              <th rowSpan={2} style={{width: 100, textAlign: 'center'}}>Ngày tháng ghi sổ</th>
              <th rowSpan={2} style={{width: 100, textAlign: 'center'}}>Ngày, tháng chứng từ</th>
              <th colSpan={2} style={{width: 300, textAlign: 'center'}}>Số hiệu chứng từ</th>
              <th rowSpan={2} style={{minWidth: 300}}>Diễn giải</th>
              <th colSpan={3} style={{width: 400}}>Số tiền</th>
              <th rowSpan={2} style={{width: 200}}>Ghi chú</th>
            </tr>
            <tr>
              <th style={{width: 150}}>Thu</th>
              <th style={{width: 150}}>Chi</th>
              <th style={{width: 125}}>Thu</th>
              <th style={{width: 125}}>Chi</th>
              <th style={{width: 150}}>Tồn</th>
            </tr>
            </thead>
            <tbody className="p-datatable-tbody">
            <tr style={{background: "#F8F9FA"}}>
              <td style={{textAlign: 'center'}}><b>A</b></td>
              <td style={{textAlign: 'center'}}><b>B</b></td>
              <td style={{textAlign: 'center'}}><b>C</b></td>
              <td style={{textAlign: 'center'}}><b>D</b></td>
              <td style={{textAlign: 'center'}}><b>E</b></td>
              <td style={{textAlign: 'center'}}><b>1</b></td>
              <td style={{textAlign: 'center'}}><b>2</b></td>
              <td style={{textAlign: 'center'}}><b>3</b></td>
              <td style={{textAlign: 'center'}}><b>4</b></td>
            </tr>
            {rowData?.data.map((item, index) => {
              if (item?.isBold === 'Y'){
                return (
                  <Fragment>
                    {(item.description === 'Số dư đầu kỳ')&&<tr>
											<td colSpan={9} style={{background: '#D6EEEE'}}><b>Tài khoản ngân hàng
												: {item?.accountNumber} {item?.bankName}</b></td>
										</tr>}
                    <tr key={index} style={{fontWeight: "bold"}}>
                      {(item.completedDate ) &&
												<td>{FormatDisplay.date(new Date(item.completedDate ), 'DD/MM/YYYY')}</td>}
                      {(!item.completedDate ) && <td></td>}
                      {(item.effectiveDate) &&
												<td>{FormatDisplay.date(new Date(item.effectiveDate), 'DD/MM/YYYY')}</td>}
                      {(!item.effectiveDate) && <td></td>}
                      <td>{item?.receiptDisplayId }</td>
                      <td>{item?.issuanceDisplayId  }</td>
                      <td>{item?.description}</td>
                      <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.creditAmount )}</td>
                      <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.debitAmount )}</td>
                      <td style={{textAlign: 'right', color: 'red'}}>{FormatDisplay.number(item?.endingAmount)}</td>
                      <td>{}</td>
                    </tr>
                  </Fragment>
                )
              } else {
                return (
                  <Fragment>
                    <tr key={index}>
                      {(item.completedDate ) &&
												<td>{FormatDisplay.date(new Date(item.completedDate ), 'DD/MM/YYYY')}</td>}
                      {(!item.completedDate ) && <td></td>}
                      {(item.effectiveDate) &&
												<td>{FormatDisplay.date(new Date(item.effectiveDate), 'DD/MM/YYYY')}</td>}
                      {(!item.effectiveDate) && <td></td>}
                      <td>{item?.receiptDisplayId }</td>
                      <td>{item?.issuanceDisplayId  }</td>
                      <td>{item?.description}</td>
                      <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.creditAmount )}</td>
                      <td style={{textAlign: 'right'}}>{FormatDisplay.number(item?.debitAmount )}</td>
                      <td style={{textAlign: 'right', color: 'red'}}>{FormatDisplay.number(item?.endingAmount)}</td>
                      <td>{}</td>
                    </tr>
                  </Fragment>
                )
              }
            })
            }
            </tbody>
          </table>
        </div>
      </div>

    </div>
  )
    ;

}