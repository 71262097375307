import {Fragment, useEffect, useState} from 'react';

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {ToastService} from '@iamsoftware/react-hooks';

import {HouseholdsService as Service} from './HouseholdsService';
import {UserStorage} from 'src/service/UserStorage';

import {DialogHouseholds} from './DialogHouseholds';

export const Households = ({household, setHousehold}) => {

  const [display, setDisplay] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (UserStorage.get('household')) {
      Service.get(UserStorage.get('household')).then(data => {
        if (data?.partyId) {
          setHousehold(data);
        } else {
          setDisplay(true);
        }
      });
    } else {
      setDisplay(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const doSelect = () => {
    if (selectedItem) {
      UserStorage.set('household', selectedItem.partyId);
      window.location.reload();
    }
  }

  const doHide = () => {
    if (household) {
      setDisplay(false);
    } else {
      ToastService.error('Bạn chưa chọn hộ kinh doanh');
    }
  }

  const footer = (
    <div>
      <Button label="Chọn hộ kinh doanh" icon="pi pi-check" className="p-button-sm mr-2" type="button" onClick={doSelect} disabled={!selectedItem}/>
      <Button label="Đóng" icon="pi pi-times" className="p-button-outlined p-button-secondary p-button-sm" type="button" onClick={doHide}/>
    </div>
  );

  return (
    <Fragment>
      <div className="house-holds" onClick={() => setDisplay(true)}>
        <p className="mb-0"><b>{household?.partyName}</b></p>
        <p className="text-right">{household?.pseudoId}</p>
      </div>

      <Dialog header="Hộ kinh doanh" footer={footer} visible={display} style={{width: '70rem'}} position="top" onHide={doHide}>
        <DialogHouseholds setSelectedItem={setSelectedItem}/>
      </Dialog>
    </Fragment>
  );
}