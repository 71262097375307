import {ConfirmDialogService, ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';
import {Fragment, useEffect, useState} from 'react';
import {Button} from 'primereact/button';

import {ExpenseService as Service} from './ExpenseService';
import {CommonService} from '../../../service/CommonService';

export const ExpenseItems = () => {
  const header = 'Khoản mục chi phí';
  const dataKey = 'glAccountCode';
  const updateTooltip = 'Cập nhật';
  const resendTooltip = 'Xóa';
  const [typeEnums, setTypeEnums] = useState([])

  useEffect(() => {
    CommonService.glAccountTypes().then(data => {
      setTypeEnums(data.listData.map(item => {
        return {value: item.glAccountId, label: `[${item.accountCode}] - ${item.accountName}`};
      }))
    })
  }, []);

  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <span>{header}</span>
        <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"
                size="small" onClick={() => doCreate()}/>
      </div>
    </div>
  );

  const {render: renderDataTable, selectedItems, setSelectedItems, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    tableHeader: tabHeader,
    dataKey,
    columns: [
      {
        field: 'accountCode', header: 'Mã khoản mục chi phí', matchMode: 'contains', width: 200, dataType: 'custom', customCell(rowData: any): any {
          return <a onDoubleClick={() => {
            setSelectedItems([rowData])
            view(rowData)
          }}>{rowData.glAccountCode}</a>
        }
      },
      {field: 'glAccountName', header: 'Tên khoản mục chi phí', matchMode: 'contains', minWidth: 350},
      {field: 'accountName', header: 'Loại', matchMode: 'contains', minWidth: 350},
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 90,
    getList: lazyLoadEvent => {
      return Service.List(lazyLoadEvent);
    },
    initActions(items: Array<any>) {
      if (items){
        items.forEach(item =>{
          item.actions = [
            {icon: 'pi pi-pencil', title: updateTooltip, className: 'p-button-warning', command: () => doUpdate(item)},
            {icon: 'pi pi-trash', title: resendTooltip, className: 'p-button-danger', command: () => doDelete(item)},
          ]
        })
      }
    }
  });

  const {render: renderDialogCrup, create, update, view, form} = useDialogCrup({
    header,
    dataKey,
    width: '40rem',
    fields: [
      {field: 'glAccountCode', header: 'Mã mục chi phí', required: true, className: 'md:col-12'},
      {field: 'glAccountName', header: 'Tên khoản mục chi phí', required: true, className: 'md:col-12'},
      {field: 'glAccountId', header: 'Loại chi phí', type: 'Dropdown', DropdownProps: {options: typeEnums, required: true}, className: 'md:col-12', required: true},
    ],
    createItem: item => {
      return Service.createExpense(item);
    },
    updateItem: (id, item) => {
      id = item.glAccountId
      return Service.updateExpense(id, inject(item));
    },
    reloadLazyData
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const inject = (data) =>{
    data.fromDate = selectedItem.fromDate
    return data
  }

  const doCreate = () => {
    setSelectedItems([])
    create()
  }

  const doUpdate = (data) => {
    if (data && data[dataKey]) {
      setSelectedItems(data)
      update(data);
    }
  }

  const doDelete = (data) => {
    if (data && data[dataKey]) {
      ConfirmDialogService.confirm('Xác Nhận', 'Bạn chắc chắn muốn xóa khoản mục này', () => {
        Service.deleteExpense(data['glAccountId'], data).then(()=>{
          ToastService.success();
          reloadLazyData();
        })
      })
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate: doCreate,
    hasSelectedItem: selectedItem,
  });

  return (
    <div className="grid">
      {/*<div className="col-12">*/}
      {/*  {renderToolbar()}*/}
      {/*</div>*/}
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12.5rem)'}}>
        {renderDataTable()}
      </div>
      {renderDialogCrup()}
    </div>
  )
}