import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDataTableBasic, useDialogCrup, useForm} from '@iamsoftware/react-hooks';
import {Button} from 'primereact/button';
import {Fieldset} from 'primereact/fieldset';

import {S2ReportService as Service} from './S2ReportService';
import {Fragment, useEffect, useState} from 'react';
import {CommonService} from '../../../service/CommonService';
import {FacilitiesService} from '../../kho-van/kho-hang/FacilitiesService';
import {ProductService} from '../../ban-hang/san-pham/productService';
import {S2Detail} from './S2Detail';
import {Dialog} from 'primereact/dialog';
import {Dropdown} from 'primereact/dropdown';

export const S2HKD = ({me}) => {

  const dataKey = 'reportId';
  const [displayDetail, setDisplayDetail] = useState(null)
  const [headerResult, setHeaderResult] = useState(null);
  const [timePeriodTypes, setTimePeriodTypes] = useState([])
  const [facilities, setFacilities] = useState([]);
  const [products, setProducts] = useState([]);
  const [assetIssuePriceMethods, setAssetIssuePriceMethods] = useState([])
  const [productDatas, setProductDatas] = useState([]);
  const [reload, setReload] = useState(false);
  const [display, setDisplay] = useState(false);
  const [displayPdf, setDisplayPdf] = useState(null);
  const [selectedFacilityId, setSelectedFacilityId] = useState(null);
  useEffect(() => {
    setHeaderResult(tabHeader)
    CommonService.timePeriodType().then(data => {
      setTimePeriodTypes(data.listData.map(item => {
        return {value: item.timePeriodTypeId, label: item.description};
      }))
    });
    CommonService.getAssetIssuePriceMethod().then(data => {
      setAssetIssuePriceMethods(data.listData.map(item => {
        return {value: item.enumId, label: item.description};
      }))
    });
    FacilitiesService.getList('').then(data => {
      setSelectedFacilityId(data.listData[0]?.facilityId)
      setFacilities(data.listData.map(item => {
        return {value: item.facilityId, label: item.facilityName}
      }));
    });
    ProductService.find("").then(data => {
      setProducts(data.listData.map(item => {
        delete item.disabled;
        let label = `[${item.pseudoId}] - ${item.productName}`;
        return {...item, value: item.productId, label: label};
      }));
    });
  }, []);

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    tableHeader: headerResult,
    dataKey: dataKey,
    columns: [
      {
        field: 'reportId', header: 'Mã báo cáo', width: 125, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <a onClick={() => setDisplayDetail(rowData)}>
            {rowData.reportId}
          </a>
        }
      },
      {field: 'reportName', header: 'Tên báo cáo', width: 400, matchMode: 'contains'},
      {
        field: 'timePeriodType', header: 'Kỳ báo cáo', width: 175, dataType: 'custom', customCell(rowData: any): any {
          if (!rowData.periodNum) {
            return `${rowData.timePeriodType} ${rowData.yearInt}`
          } else {
            return `${rowData.timePeriodType} ${rowData.periodNum} năm ${rowData.yearInt}`
          }
        }
      },
      {field: 'fromDate', header: 'Từ ngày', width: 100, matchMode: 'contains', dataType: 'date'},
      {field: 'thruDate', header: 'Đến ngày', width: 100, matchMode: 'contains', dataType: 'date'},
      {field: 'priceMethodEnum', header: 'PP tính giá', width: 150, matchMode: 'contains'},
      {field: 'facilityDesc', header: 'Kho', width: 100, matchMode: 'contains'},
      {field: 'productDesc', header: 'Sản phẩm', width: 300, matchMode: 'contains'},
      {field: 'createdDate', header: 'Ngày tạo', width: 100, matchMode: 'contains', dataType: 'date'},
      {field: 'lastUpdatedStamp', header: 'Ngày cập nhật', width: 200, matchMode: 'contains', dataType: 'date-time'},
    ],
    indexColumnWidth: 40,
    actionColumnWidth: 150,
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-eye', title: 'Xem chi tiết báo cáo', className: 'p-button-info', command: () => setDisplayDetail(item)},
            {icon: 'pi pi-pencil', title: 'Cập nhật tham số báo cáo', className: 'p-button-warning', command: () => doUpdate(item, false)},
            {icon: 'pi pi-refresh', title: 'Tính toán lại dữ liệu báo cáo', className: 'p-button-danger', command: () => reCalculate(item, false)},
          ]
          item.actionMenus = [
            {icon: 'pi pi-file-excel', label: 'Tải excel', className: 'p-button-secondary', command: () => doDownloadExcel(item.reportId)},
            {icon: 'pi pi-file-pdf', label: 'Xem pdf', className: 'p-button-help', command: () => setDisplayPdf(item.reportId)}
          ]
        })
      }
    },
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    }
  });

  const form = useForm({
    fields: [
      {field: 'partyName', header: 'Hộ KD/ cá nhân KD', required: true, className: 'md:col-12', disabled: true},
      {field: 'address1', header: 'Địa chỉ', required: true, className: 'md:col-12'},
      {
        field: 'timePeriodTypeId', header: 'Kì báo cáo', type: 'Dropdown', DropdownProps: {
          options: timePeriodTypes
        }, required: true, className: 'md:col-4'
      },
      {
        field: 'monthPeriodNum', header: 'Tháng', required: true, className: 'md:col-4', displayDependency(item: any): boolean {
          return ['ReportMonth'].includes(item?.timePeriodTypeId)
        }, type: 'Dropdown', DropdownProps: {options: Months}
      },
      {
        field: 'quarterPeriodNum', header: 'Quý', required: true, className: 'md:col-4', displayDependency(item: any): boolean {
          return ['ReportQuarter'].includes(item?.timePeriodTypeId)
        }, type: 'Dropdown', DropdownProps: {options: Quarters}
      },
      {field: 'yearInt', header: 'Năm', required: true, className: 'md:col-4', type: 'Dropdown', DropdownProps: {options: Years}},
      {field: 'fromDate', header: 'Từ ngày', disabled: true, InputTextProps: {type: 'date'}, className: 'md:col-6'},
      {field: 'thruDate', header: 'Đến ngày', disabled: true, InputTextProps: {type: 'date'}, className: 'md:col-6'},
      {field: 'priceMethodEnumId', header: 'PP tính giá xuất kho', required: true, type: "Dropdown", DropdownProps: {options: assetIssuePriceMethods}, className: 'md:col-6'},
      {field: 'facilityIds', header: 'Kho', required: true, type: 'MultiSelect', MultiSelectProps: {options: facilities}, className: 'md:col-6'},
    ],
  });

  const {render, selectedItems, setSelectedItems} = useDataTableBasic({
    tableHeader: `${products?.length} sản phẩm`,
    dataKey: 'productId',
    columns: [
      {field: 'pseudoId', header: 'Mã sản phẩm', width: 150, matchMode: 'contains'},
      {field: 'productName', header: 'Tên sản phẩm', width: 300, matchMode: 'contains'},
    ],
    items: products,
    selectionMode: 'checkbox'
  });

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header: '!',
    dataKey,
    width: '80rem',
    fields: [
      {
        type: 'custom', className: 'md-col-12 mb-3',
        body: <Fieldset legend="S2-HKD: Sổ chi tiết vật liệu, dụng cụ (sản phẩm, hàng hóa)">
          {form.render()}
          {render()}
        </Fieldset>
      },
    ],
    createItem(): Promise<any> {
      return validate().then(async () => Service.create(await inject()));
    },
    updateItem(id: string, item: any): Promise<any> {
      return validate().then(async () => Service.update(id, await inject()).then(() => {
        if (display === true) {
          setReload(true)
        }
      }))
    },
    reloadLazyData
  })

  const _value = form.getValue()

  const validate = () => {
    return new Promise<void>((resolve, reject) => {
      if (!form.valid()) {
        reject();
        ToastService.error('Vui lòng nhập đầy đủ thông tin.');
      } else if (form.valid()) {
        resolve();
      }
    });

  }

  const inject = () => {
    if (form.valid()) {
      if (_value.timePeriodTypeId === 'ReportMonth') {
        _value.periodNum = Number(_value.monthPeriodNum)
      } else if (_value.timePeriodTypeId === 'ReportQuarter') {
        _value.periodNum = Number(_value.quarterPeriodNum)
      }
      delete _value.quarterPeriodNum
      delete _value.monthPeriodNum
      delete _value.partyName
      _value.productIds = []
      selectedItems.map(selectedItem => {
        _value.productIds.push(selectedItem.productId)
      })
      return _value;
    }
  }

  useEffect(() => {
    if (_value.timePeriodTypeId === 'ReportMonth') {
      if (_value.yearInt && _value.monthPeriodNum) {
        _value.fromDate = FormatDisplay.date(new Date(_value.yearInt, _value.monthPeriodNum - 1, 1), 'YYYY-MM-DD');
        _value.thruDate = FormatDisplay.date(new Date(_value.yearInt, _value.monthPeriodNum, 0), 'YYYY-MM-DD');
      }
      form.setValue(_value)
    } else if (_value.timePeriodTypeId === 'ReportQuarter') {
      switch (_value.quarterPeriodNum) {
        case '1':
          _value.fromDate = FormatDisplay.date(new Date(_value.yearInt, 0, 1), 'YYYY-MM-DD');
          _value.thruDate = FormatDisplay.date(new Date(_value.yearInt, 2, 31), 'YYYY-MM-DD');
          break;
        case '2':
          _value.fromDate = FormatDisplay.date(new Date(_value.yearInt, 3, 1), 'YYYY-MM-DD');
          _value.thruDate = FormatDisplay.date(new Date(_value.yearInt, 5, 30), 'YYYY-MM-DD');
          break;
        case '3':
          _value.fromDate = FormatDisplay.date(new Date(_value.yearInt, 6, 1), 'YYYY-MM-DD');
          _value.thruDate = FormatDisplay.date(new Date(_value.yearInt, 8, 30), 'YYYY-MM-DD');
          break;
        case '4':
          _value.fromDate = FormatDisplay.date(new Date(_value.yearInt, 9, 1), 'YYYY-MM-DD');
          _value.thruDate = FormatDisplay.date(new Date(_value.yearInt, 11, 31), 'YYYY-MM-DD');
          break;
      }
      form.setValue(_value)
    } else if (_value.timePeriodTypeId === 'ReportYear') {
      if (_value.yearInt) {
        _value.fromDate = FormatDisplay.date(new Date(_value.yearInt, 0, 1), 'YYYY-MM-DD');
        _value.thruDate = FormatDisplay.date(new Date(_value.yearInt, 11, 31), 'YYYY-MM-DD');
      }
      form.setValue(_value)
    }
  }, [_value?.timePeriodTypeId, _value?.yearInt, _value?.monthPeriodNum, _value?.quarterPeriodNum])

  useEffect(() => {
    if (productDatas) {
      productDatas.map((productData) => {
        products.map(product => {
          if (productData.productId === product.productId && productData.reportItemId === "beginningAmount") {
            selectedItems.push(product)
          }
        })

      })
    }
  }, [productDatas])
  const doCreate = () => {
    setProductDatas([])
    form.reset();
    setSelectedItems([])
    let _value = form.getValue();
    _value.address1 = me?.address1
    _value.partyName = me?.partyName
    form.setValue(_value)
    create()
  }
  const doUpdate = (rowData, displayDetail: boolean) => {
    setReload(false)
    form.reset();
    setProductDatas([])
    setSelectedItems([])
    Service.get(rowData.reportId).then(data => {
      data.partyName = me?.partyName
      if (data.timePeriodTypeId === 'ReportMonth') {
        data.monthPeriodNum = data.periodNum.toString()
      } else if (data.timePeriodTypeId === 'ReportQuarter') {
        data.quarterPeriodNum = data.periodNum.toString()
      }
      data.fromDate = FormatDisplay.date(data.fromDate, 'YYYY-MM-DD');
      data.thruDate = FormatDisplay.date(data.thruDate, 'YYYY-MM-DD');
      data.facilityIds = []
      data.items.map(item => {
        data.facilityIds.push(item.facilityId)
      })
      form.setValue(data)
      setProductDatas(data.items[0].productData)
      if (displayDetail === true) {
        setDisplay(true)
      } else setDisplay(false)
      update(data)
    })
  }
  const reCalculate = (rowData, displayDetail: boolean) => {
    ConfirmDialogService.confirm('Tính toán lại', 'Xác nhận tính toán lại dữ liệu báo cáo?', () => {
      Service.reCalculate(rowData.reportId).then(() => {
        ToastService.success();
        reloadLazyData();
        if (displayDetail === true) {
          setDisplayDetail(rowData)
          setReload(true)
        }
      })
    })
  }
  const doDownloadExcel = (reportId) => {
    window.open(`${Service.baseURL}/${Service.entity}/${reportId}/excel`)
  }

  const doDownloadPdf = () =>{
      if (selectedFacilityId && displayPdf){
        window.open(`${Service.baseURL}/${Service.entity}/${displayPdf}/pdf?facilityId=${selectedFacilityId}`)
        setDisplayPdf(null)
      }
  }


  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>S2-HKD: Sổ chi tiết vật liệu, dụng cụ (sản phẩm, hàng hóa)</span>
          <div>
            <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-1 mb-1 w-7rem h-2rem"
                    size="small" onClick={doCreate}/>
          </div>
        </Fragment>
      </div>
    </div>
  );

  const footer = (

    <div className="flex align-items-center justify-content-end">
      <Fragment>
        <div>
          <Button label="Xác nhận" severity="info" className="mr-1 mb-1 h-2rem"
                  size="small" onClick={doDownloadPdf}/>
        </div>
      </Fragment>
    </div>
  )

  return (
    <div className="grid">
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 12.5rem)'}}>
        {renderDataTable()}
        {renderDialogCrup()}
        <S2Detail display={displayDetail} setDisplay={setDisplayDetail} update={doUpdate} reCalculate={reCalculate} reload={reload}/>
        <Dialog footer={footer} visible={!!displayPdf} style={{width:'400px'}} position="center" draggable={false} onHide={() => setDisplayPdf(null)}>
          <strong>Chọn kho muốn xuất pdf</strong>
          <Dropdown required={true} value={selectedFacilityId} onChange={(e) => setSelectedFacilityId(e.value)} options={facilities} optionLabel="label"
                    placeholder="" className="w-full mt-3" />
        </Dialog>
      </div>
    </div>
  );
}
const year = new Date().getFullYear();
const Years = [
  {value: year, label: year},
  {value: year - 1, label: year - 1},
  {value: year - 2, label: year - 2},
  {value: year - 3, label: year - 3},
];
const Quarters = [
  {value: '1', label: '1'},
  {value: '2', label: '2'},
  {value: '3', label: '3'},
  {value: '4', label: '4'}
];
const Months = [
  {value: '1', label: '1'},
  {value: '2', label: '2'},
  {value: '3', label: '3'},
  {value: '4', label: '4'},
  {value: '5', label: '5'},
  {value: '6', label: '6'},
  {value: '7', label: '7'},
  {value: '8', label: '8'},
  {value: '9', label: '9'},
  {value: '10', label: '10'},
  {value: '11', label: '11'},
  {value: '12', label: '12'}
];