import {Fragment, useContext, useEffect, useRef} from 'react';
import {Route, Routes, useLocation, useNavigate, useSearchParams} from 'react-router-dom';

import {classNames} from 'primereact/utils';
import {useEventListener, useUnmountEffect} from 'primereact/hooks';

import {AppTopbarRef, LayoutState} from 'src/types';
import {LayoutContext} from 'src/layout/context/layoutcontext';
import {AppContext} from 'src/layout/context/appcontext';

import {AppTopbar} from './AppTopbar';
import {AppMenu} from './AppMenu';

import Manager from './manager';
import {BanHang} from './ban-hang';
import KeToan from './ke-toan';
import KhoVan from './kho-van';
import MuaHang from './mua-hang';
import DanhMuc from './danh-muc';
import QuanLy from './quan-ly';
import Salary from './luong';
import BaoCao from './bao-cao';
import Taxes from './thue';
import Dashboard from './dashboard';
import Connection from './ket-noi-san';
import CQT from './cqt';
import BaoCaoQuanTri from './bao-cao-quan-tri';
import {InvoiceConnected} from './tcgp';

export default function App() {

  const {layoutConfig, layoutState, setLayoutState} = useContext(LayoutContext);
  const {setAuthContext, me} = useContext(AppContext);

  const pathname = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const query = new URLSearchParams(window.location.search);

  const topbarRef = useRef<AppTopbarRef>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [bindMenuOutsideClickListener, unbindMenuOutsideClickListener] = useEventListener({
    type: 'click',
    listener: (event) => {
      const isOutsideClicked = !(
        sidebarRef.current?.isSameNode(event.target as Node) ||
        sidebarRef.current?.contains(event.target as Node) ||
        topbarRef.current?.menubutton?.isSameNode(event.target as Node) ||
        topbarRef.current?.menubutton?.contains(event.target as Node)
      );

      if (isOutsideClicked) {
        hideMenu();
      }
    }
  });

  useEffect(() => {
    setAuthContext(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    hideMenu();
    hideProfileMenu();
  }, [pathname, searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const [bindProfileMenuOutsideClickListener, unbindProfileMenuOutsideClickListener] = useEventListener({
    type: 'click',
    listener: (event) => {
      const isOutsideClicked = !(
        topbarRef.current?.topbarmenu?.isSameNode(event.target as Node) ||
        topbarRef.current?.topbarmenu?.contains(event.target as Node) ||
        topbarRef.current?.topbarmenubutton?.isSameNode(event.target as Node) ||
        topbarRef.current?.topbarmenubutton?.contains(event.target as Node)
      );

      if (isOutsideClicked) {
        hideProfileMenu();
      }
    }
  });

  const hideMenu = () => {
    setLayoutState((prevLayoutState: LayoutState) => ({
      ...prevLayoutState,
      overlayMenuActive: false,
      staticMenuMobileActive: false,
      menuHoverActive: false
    }));
    unbindMenuOutsideClickListener();
    unblockBodyScroll();
  };

  const hideProfileMenu = () => {
    setLayoutState((prevLayoutState: LayoutState) => ({
      ...prevLayoutState,
      profileSidebarVisible: false
    }));
    unbindProfileMenuOutsideClickListener();
  };

  const blockBodyScroll = (): void => {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  };

  const unblockBodyScroll = (): void => {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  };

  useEffect(() => {
    if (layoutState.overlayMenuActive || layoutState.staticMenuMobileActive) {
      bindMenuOutsideClickListener();
    }

    layoutState.staticMenuMobileActive && blockBodyScroll();
  }, [layoutState.overlayMenuActive, layoutState.staticMenuMobileActive]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (layoutState.profileSidebarVisible) {
      bindProfileMenuOutsideClickListener();
    }
  }, [layoutState.profileSidebarVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  useUnmountEffect(() => {
    unbindMenuOutsideClickListener();
    unbindProfileMenuOutsideClickListener();
  });

  const containerClass = classNames('layout-wrapper', {
    'layout-overlay': layoutConfig.menuMode === 'overlay',
    'layout-static': layoutConfig.menuMode === 'static',
    'layout-static-inactive': layoutState.staticMenuDesktopInactive && layoutConfig.menuMode === 'static',
    'layout-overlay-active': layoutState.overlayMenuActive,
    'layout-mobile-active': layoutState.staticMenuMobileActive,
    'p-input-filled': layoutConfig.inputStyle === 'filled',
    'p-ripple-disabled': !layoutConfig.ripple
  });

  function ForwardDashBoard() {
    const navigate = useNavigate()

    useEffect(() => {
      if (!query) {
        setTimeout(() => {
          navigate('/dashboard', {replace: true});
        }, 200)
      } else {
        if (query.get('code') && !query.get('shop_id')){
          setTimeout(() => {
            navigate(`/ket-noi-san/lazada`, {replace: true});
          }, 200)
        }else {
          setTimeout(() => {
            navigate(`/ket-noi-san/shopee`, {replace: true});
          }, 200)
        }
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
  }

  return (
    <div className={containerClass}>
      {me &&
				<Fragment>
					<AppTopbar ref={topbarRef}/>

					<div ref={sidebarRef} className="layout-sidebar">
						<AppMenu/>
					</div>

					<div className="layout-main-container">
						<div className="layout-main card pb-0">
              {['Administrator', 'Agent'].includes(me.roleTypeId) &&
								<Routes>
									<Route path="manager/*" element={<Manager me={me}/>}/>
								</Routes>
              }
							<Routes>
								<Route path="/" element={<ForwardDashBoard/>}/>
								<Route path="ban-hang/*" element={<BanHang/>}/>
								<Route path="ke-toan/*" element={<KeToan/>}/>
								<Route path="kho-van/*" element={<KhoVan/>}/>
								<Route path="mua-hang/*" element={<MuaHang/>}/>
								<Route path="danh-muc/*" element={<DanhMuc/>}/>
								<Route path="quan-ly/*" element={<QuanLy/>}/>
								<Route path="luong/*" element={<Salary/>}/>
								<Route path="thue/*" element={<Taxes/>}/>
								<Route path="dashboard" element={<Dashboard/>}/>
								<Route path="bao-cao/*" element={<BaoCao me={me}/>}/>
								<Route path="ket-noi-san/*" element={<Connection query={query}/>}/>
								<Route path="cqt/*" element={<CQT/>}/>
								<Route path="bao-cao-quan-tri/*" element={<BaoCaoQuanTri/>}/>
                <Route path="tcgp" element={<InvoiceConnected/>}/>
							</Routes>
						</div>
					</div>
				</Fragment>
      }
    </div>
  );
}
