import {Route, Routes} from 'react-router-dom';

import BaoCao from './bao-cao';
import DonHang from './don-hang';
import NhaCungCap from './nha-cung-cap';
import {PurchaseInvoices} from './hoa-don';
import {SupplierLiability} from './cong-no';
import {PurchasePayment} from './thanh-toan';

export default function MuaHang() {

  return (
    <Routes>
      <Route path="bao-cao/*" element={<BaoCao/>}/>
      <Route path="don-hang" element={<DonHang/>}/>
      <Route path="nha-cung-cap" element={<NhaCungCap/>}/>
      <Route path="hoa-don" element={<PurchaseInvoices/>}/>
      <Route path="cong-no" element={<SupplierLiability/>}/>
      <Route path="thanh-toan" element={<PurchasePayment/>}/>
    </Routes>
  );
}
