import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {useDataTableBasic} from '@iamsoftware/react-hooks';
import {InventoriesService as Service} from './InventoriesService';

export const InvenHistory = ({display, setDisplay, historyType})=> {

  const [items, setItems] = useState([]);
  const [header, setHeader] = useState(null);
  const [receiptHide, setReceiptHide] = useState(null);
  const [issuanceHide, setIssuanceHide] = useState(null);


  useEffect(() => {
    if (display && historyType){
      if (historyType === 'Receipt'){
        setHeader('Lịch sử nhập kho: '+ display.productName);
        Service.receiptHistory(display.productId).then(data =>{
          setItems(data.listData);
          setReceiptHide(false);
          setIssuanceHide(true);
        })
      }else {
        setHeader('Lịch sử xuất kho: '+ display.productName);
        Service.issuanceHistory(display.productId).then(data =>{
          setItems(data.listData)
          setReceiptHide(true);
          setIssuanceHide(false);
        })
      }
    }
  }, [display, historyType]);

  const  histories = useDataTableBasic({
    tableHeader:header,
    indexColumnWidth:40,
    dataKey:'assetId',
    columns:[
      {field:'displayId',header:'Mã chứng từ', minWidth:150, matchMode:'contains'},
      {field:'orderType',header:'Loại chứng từ', minWidth:200, matchMode:'contains'},
      {field:'pseudoId',header:'Mã kho', minWidth:150, matchMode:'contains'},
      {field:'facilityName',header:'Tên kho',minWidth:250 ,matchMode:'contains'},
      {field:'receivedDate',header:'Ngày nhập', minWidth:125, matchMode:'contains', dataType:'date',hidden: receiptHide},
      {field:'issuedDate',header:'Ngày xuất', minWidth:125, matchMode:'contains', dataType:'date', hidden: issuanceHide},
      {field:'quantityAccepted',header:'Số lượng nhập',dataType:'number', minWidth:150, matchMode:'contains', hidden: receiptHide},
      // {field:'quantityRejected',header:'Số lượng hủy', minWidth: 150,dataType:'number', matchMode:'contains'},
      {field:'quantity',header:'Số lượng xuất', minWidth: 150,dataType:'number', matchMode:'contains', hidden: issuanceHide},
    ],
    items
  })

  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <Dialog header={header} footer={footer} visible={!!display} style={{width: '80rem'}} position="top" onHide={() => setDisplay(false)}>
      {histories.render()}
    </Dialog>
  );
}