import {EntityService} from 'src/service/EntityService';

export class CashBookService extends EntityService {

  static entity = 'iam-accounting/cashbook';
  static createBank(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/create-bank-account`, data).then(res => res.data);
  }

  static getHistories(id: any): Promise<any> {
    return this.axios.get(`${this.entity}/${id}/histories`).then(res => res.data);
  }

  static bankAccount(lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/bank-accounts?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static cashAccount(lazyLoadEvent?: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/cash?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

}
