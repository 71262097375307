import {useEffect, useRef, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Checkbox} from 'primereact/checkbox';

import {ConfirmDialogService, ToastService, useDataTableBasic} from '@iamsoftware/react-hooks';
import {SalesOrdersService as Service} from './ordersService';

export const UploadExcelItems = ({display, setDisplay, setItems, doRowDelete, reloadDataByImportExcel}) =>{
  const inputSignedFile = useRef(null);
  const [createProd, setCreateProd] = useState(true);
  const [file, setFile] = useState(null);

  const onUploadSignedFile = event => {
    if (event.target?.files?.length === 1) {
      setFile(event.target.files[0]);
    } else {
      ToastService.error('Chưa chọn file Excel')
    }
  }

  const downloadExcel = () => {
    window.open(`${Service.baseURL}/iam-hkd/products/import-order-items-template`);
  }

  const onSubmit = () => {
    if (file) {
      if (createProd === true){
        Service.uploadExcel(Service.toFormData({contentFile: file,createIfAbsence: 'Y'})).then(data => {
          if (data.hasNewProduct === 'Y'){
            reloadDataByImportExcel('Y','N')
          }
          if (data.hasNewUom === 'Y'){
            reloadDataByImportExcel('N','Y')
          }
          data.items.map(item =>{
            item._id = `${Date.now()}_${item.orderItemSeqId}_${Math.random()}`;
            item.actions = [
              {icon: 'pi pi-trash', className: 'p-button-danger', title: 'Xoá', command: () => doRowDelete(item)}
            ]
          })
          setItems(data.items)
        });
      }else {
        Service.uploadExcel(Service.toFormData({contentFile: file,createIfAbsence: 'N'})).then(data => {
          data.items.map(item =>{
            item._id = `${Date.now()}_${item.orderItemSeqId}_${Math.random()}`;
            item.actions = [
              {icon: 'pi pi-trash', className: 'p-button-danger', title: 'Xoá', command: () => doRowDelete(item)}
            ]
          })
          setItems(data.items)
        });
      }
      inputSignedFile.current.value = '';
      setDisplay(false)
    }
  }


  const footer = (
    <div className="flex justify-content-between">
      <div>
      </div>
      <div>
        <Button label="Tải file excel mẫu" icon="pi pi-download" className="p-button-outlined p-button-help p-button-sm" type="button" onClick={downloadExcel}/>
        <Button label="Tải lên" icon="pi pi-upload" className="p-button-outlined p-button-success p-button-sm" type="button" onClick={onSubmit}/>
        <Button label="Đóng" icon="pi pi-times" className="p-button-outlined p-button-secondary p-button-sm" type="button" onClick={() => setDisplay(false)}/>
      </div>
    </div>
  );

  return (
    <Dialog header={'Upload file Excel'} draggable={false} footer={footer} visible={!!display} style={{width: '40rem', height: '20rem',}} position="top" onHide={() => setDisplay(false)}>
      <div className="flex col-12">
        <input className="mt-5 md:col-12" ref={inputSignedFile} type="file" accept='.xlsx' onChange={onUploadSignedFile}/>
      </div>
      <div className="flex align-items-center mt-3 ml-2">
        <Checkbox inputId="createIfAbsence" onChange={e => setCreateProd(e.checked)} checked={createProd}/>
        <label htmlFor="createIfAbsence" className="ml-2">Tạo sản phẩm nếu chưa tồn tại</label>
      </div>
    </Dialog>
  );
}