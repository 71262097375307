import {EntityService} from 'src/service/EntityService';

export class OutgoingPaymentsService extends EntityService {

  static entity = 'iam-accounting/outgoingPayments';

  static approve(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/delivery`).then(res => res.data);
  }
  static unApprove(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/un-delivery`).then(res => res.data);
  }
  static cancel(id: string): Promise<any> {
    return this.axios.delete(`${this.entity}/${id}/cancel`).then(res => res.data);
  }
  static genPaymentPseudoId(paymentInstrumentEnumId?:string): Promise<any> {
    return this.axios.get(`${this.entity}/gen-pseudo-id?paymentInstrumentEnumId=${paymentInstrumentEnumId}`).then(res => res.data);
  }

  static outgoingPaymentInstrumentType(): Promise<any> {
    return this.axios.get(`/iam-common/outgoing-payment-instrument-type`).then(res => res.data);
  }


}