import {Route, Routes} from 'react-router-dom';
import {HoKinhDoanh} from './ho-kinh-doanh';
import {EmployeeTax} from './nguoi-lao-dong';
import PhieuChi from './phieu-chi';
import {ToKhai} from './to-khai';
export default function Taxes() {
  return (
    <Routes>
      <Route path="hkd" element={<HoKinhDoanh/>}/>
      <Route path="nguoi-lao-dong" element={<EmployeeTax/>}/>
      <Route path="phieu-chi" element={<PhieuChi/>}/>
      <Route path="to-khai" element={<ToKhai/>}/>
     </Routes>
  );
}