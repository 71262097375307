import {Route, Routes} from 'react-router-dom';

import BaoCao from './bao-cao';
import DonHang from './don-hang';
import KhachHang from './khach-hang';
import {SanPham} from './san-pham';
import {SaleInvoices} from './hoa-don';
import {CustomerLiability} from './cong-no';
import {SalePayment} from './thanh-toan';
export const BanHang =()=> {

  return (
    <Routes>
      <Route path="bao-cao/*" element={<BaoCao/>}/>
      <Route path="don-hang" element={<DonHang />}/>
      <Route path="khach-hang" element={<KhachHang/>}/>
      <Route path="san-pham" element={SanPham().renderProduct}/>
      <Route path="hoa-don" element={SaleInvoices().renderSaleInvoice(false)}/>
      <Route path="cong-no" element={<CustomerLiability/>}/>
      <Route path="thanh-toan" element={<SalePayment/>}/>
    </Routes>
  );
}
