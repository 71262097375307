import { Dropdown } from 'primereact/dropdown';
import {useEffect, useState} from 'react';
import moment from 'moment';
import { Chart } from 'primereact/chart';
import {DashboardService as Service} from './DashboardSerivce';
import { InputNumber } from 'primereact/inputnumber';

export default function Dashboard () {
  const [times, setTimes] = useState('THISFISCALQUARTER')
  const [cashAmount, setCashAmount] = useState(0);
  const [bankAmount, setBankAmount] = useState(0);
  const [receiptAmount, setReceiptAmount] = useState(0);
  const [issueAmount, setIssueAmount] = useState(0);
  const [textValue, setTextValue] = useState(3)
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [textValue1, setTextValue1] = useState(3)
  const [chartData1, setChartData1] = useState({});
  const [chartOptions1, setChartOptions1] = useState({});
  const [textValue2, setTextValue2] = useState(3)
  const [chartData2, setChartData2] = useState({});
  const [chartOptions2, setChartOptions2] = useState({});

  useEffect(() => {
    Service.getDashboarhCash().then(data =>{
      setCashAmount(data.number)
    })
    Service.getDashboarhBank().then(data =>{
      setBankAmount(data.number)
    })
  }, []);

  useEffect(() => {
    if (times){
      const {fromDate, thruDate} = getPeriodValue(times)
      Service.getDashboarhAmount(new Date(fromDate).getTime(), new Date(thruDate).getTime()).then(data =>{
        setReceiptAmount(data.receiptTotal)
        setIssueAmount(data.issueTotal)
      })
    }
  }, [times]);

  useEffect(() => {
    if (textValue){
      const lineOptions = {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      };
      const lineData = {
        labels: new Array<any>(),
        datasets:[{
          label: 'Doanh thu',
          data: new Array<any>(),
          fill: false,
          borderColor:'#42A5F5',
          tension: .4
        }]
      }
      Service.getReceiptPeriod(textValue).then(({listData}) =>{
        if (listData){
          listData.forEach(item =>{
            lineData.labels.push(item.label);
            lineData.datasets[0].data.push(item.value);
          })
          setChartData(lineData)
          setChartOptions(lineOptions)
        }
      })
    }
  }, [textValue]);

  useEffect(() => {
    if (textValue1){
      const lineOptions = {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      };
      const lineData = {
        labels: new Array<any>(),
        datasets:[{
          label: 'Chi phí',
          data: new Array<any>(),
          fill: false,
          borderColor:'#42A5F5',
          tension: .4
        }]
      }
      Service.getIssuePeriod(textValue).then(({listData}) =>{
        if (listData){
          listData.forEach(item =>{
            lineData.labels.push(item.label);
            lineData.datasets[0].data.push(item.value);
          })
          setChartData1(lineData)
          setChartOptions1(lineOptions)
        }
      })
    }
  }, [textValue1]);

  useEffect(() => {
    if (textValue2){
      const lineOptions = {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      };
      const lineData = {
        labels: new Array<any>(),
        datasets:[{
          label: 'Lợi nhuận',
          data: new Array<any>(),
          fill: false,
          borderColor:'#42A5F5',
          tension: .4
        }]
      }
      Service.getProfitPeriod(textValue2).then(({listData}) =>{
        if (listData){
          listData.forEach(item =>{
            lineData.labels.push(item.label);
            lineData.datasets[0].data.push(item.value);
          })
          setChartData2(lineData)
          setChartOptions2(lineOptions)
        }
      })
    }
  }, [textValue2]);

  const getPeriodValue = (period?: string) =>{
    let fromDate = new Date();
    let thruDate = new Date();

    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const quarters = ['FISCALQUARTER1', 'FISCALQUARTER2', 'FISCALQUARTER3', 'FISCALQUARTER4'];

    if (period && months.indexOf(period) >= 0){
      fromDate = moment().month(months.indexOf(period)).startOf('month').toDate();
      thruDate = moment().month(months.indexOf(period)).endOf('month').toDate();
    }else if ((period && quarters.indexOf(period) >= 0)){
      fromDate = moment().quarter(quarters.indexOf(period) + 1).startOf('quarter').toDate();
      thruDate = moment().quarter(quarters.indexOf(period) + 1).endOf('quarter').toDate();
    }else {
      switch (period) {
        case 'TODAY':
          fromDate = moment().startOf('day').toDate();
          thruDate = moment().endOf('day').toDate();
          break;
        case 'THISWEEK':
          fromDate = moment().startOf('isoWeek').toDate();
          thruDate = moment().endOf('isoWeek').toDate();
          break;
        case 'THISWEEKTODATE':
          fromDate = moment().startOf('isoWeek').toDate();
          break;
        case 'THISMONTH':
          fromDate = moment().startOf('month').toDate();
          thruDate = moment().endOf('month').toDate();
          break;
        case 'THISMONTHTODATE':
          fromDate = moment().startOf('month').toDate();
          break;
        case 'THISFISCALQUARTER':
          fromDate = moment().startOf('quarter').toDate();
          thruDate = moment().endOf('quarter').toDate();
          break;
        case 'THISFISCALQUARTERTODATE':
          fromDate = moment().startOf('quarter').toDate();
          break;
        case 'THISFISCALYEAR':
          fromDate = moment().startOf('year').toDate();
          thruDate = moment().endOf('year').toDate();
          break;
        case 'THISFISCALYEARTODATE':
          fromDate = moment().startOf('year').toDate();
          break;
        case 'FIRSTSIXMONTH':
          fromDate = moment().startOf('year').toDate();
          thruDate = moment().month(5).endOf('month').toDate();
          break;
        case 'LASTSIXMONTH':
          fromDate = moment().month(6).startOf('month').toDate();
          thruDate = moment().endOf('year').toDate();
          break;
        case 'YESTERDAY':
          fromDate = moment().subtract(1, 'day').startOf('day').toDate();
          thruDate = moment().subtract(1, 'day').endOf('day').toDate();
          break;
        case 'LASTWEEK':
          fromDate = moment().subtract(1, 'week').startOf('isoWeek').toDate();
          thruDate = moment().subtract(1, 'week').endOf('isoWeek').toDate();
          break;
        case 'LASTMONTH':
          fromDate = moment().subtract(1, 'month').startOf('month').toDate();
          thruDate = moment().subtract(1, 'month').endOf('month').toDate();
          break;
        case 'LASTFISCALQUARTER':
          fromDate = moment().subtract(1, 'quarter').startOf('quarter').toDate();
          thruDate = moment().subtract(1, 'quarter').endOf('quarter').toDate();
          break;
        case 'LASTFISCALYEAR':
          fromDate = moment().subtract(1, 'year').startOf('year').toDate();
          thruDate = moment().subtract(1, 'year').endOf('year').toDate();
          break;
        case 'NEXTWEEK':
          fromDate = moment().add(1, 'week').startOf('isoWeek').toDate();
          thruDate = moment().add(1, 'week').endOf('isoWeek').toDate();
          break;
        case 'NEXTMONTH':
          fromDate = moment().add(1, 'month').startOf('month').toDate();
          thruDate = moment().add(1, 'month').endOf('month').toDate();
          break;
        case 'NEXTFISCALQUARTER':
          fromDate = moment().add(1, 'quarter').startOf('quarter').toDate();
          thruDate = moment().add(1, 'quarter').endOf('quarter').toDate();
          break;
        case 'NEXTFISCALYEAR':
          fromDate = moment().add(1, 'year').startOf('year').toDate();
          thruDate = moment().add(1, 'year').endOf('year').toDate();
          break;
      }
    }
    return {fromDate, thruDate};
  }

  return (
    <div className="grid">
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card">
          <div className="flex">
            <i className="pi pi-wallet" style={{fontSize: '3.5rem', color: 'orange'}}/>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              <span style={{flex: '100%', fontSize: '1.8rem', opacity: '0.5'}} className="ml-4 mb-1">Tiền mặt</span>
              <span style={{flex: '100%', fontSize: '1.2rem', fontWeight: 'bold'}} className="ml-4">{new Intl.NumberFormat('vi-VN').format(cashAmount)}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card">
          <div className="flex">
            <i className="pi pi-credit-card" style={{fontSize: '3.5rem', color: 'orange'}}/>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              <span style={{flex: '100%', fontSize: '1.8rem', opacity: '0.5'}} className="ml-4 mb-1">Tiền gửi</span>
              <span style={{flex: '100%', fontSize: '1.2rem', fontWeight: 'bold'}} className="ml-4">{new Intl.NumberFormat('vi-VN').format(bankAmount)}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 lg:col-12 xl:col-6">
        <div className="card">
          <div className="flex">
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              <span style={{flex: '100%', fontWeight: 'bold'}} className="mb-1">Dòng tiền</span>
              <Dropdown style={{flex: '100%'}} className="p-dropdown-dashboard" options={ReportPeriods} value={times} onChange={(e) => setTimes(e.value)} optionLabel="label"/>
            </div>
            <div className="flex ml-4">
              <i className="pi pi-arrow-down" style={{fontSize: '3.5rem', color: 'orange'}}/>
              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                <span style={{flex: '100%', fontSize: '1.8rem', opacity: '0.5'}} className="ml-4 mb-1">Thu</span>
                <span style={{flex: '100%', fontSize: '1.2rem', fontWeight: 'bold'}} className="ml-4">{new Intl.NumberFormat('vi-VN').format(receiptAmount)}</span>
              </div>
            </div>
            <div className="flex ml-2">
              <i className="pi pi-arrow-up" style={{fontSize: '3.5rem', color: 'orange'}}/>
              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                <span style={{flex: '100%', fontSize: '1.8rem', opacity: '0.5'}} className="ml-4 mb-1">Chi</span>
                <span style={{flex: '100%', fontSize: '1.2rem', fontWeight: 'bold'}} className="ml-4">{new Intl.NumberFormat('vi-VN').format(issueAmount)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 lg:col-12 xl:col-4">
        <div className="card">
          <div className="flex justify-content-between align-items-center">
            <h5>Doanh thu</h5>
            <div>
              <InputNumber allowEmpty={false} className="config-input mb-3" value={textValue} onChange={(e) => setTextValue(e.value)} locale="vi-VN" min={1} max={24}/>
              <span>Tháng gần đây</span>
            </div>
          </div>
          <div className="p-card-content">
            <Chart type="line" data={chartData} options={chartOptions}/>
          </div>
        </div>
      </div>
      <div className="col-12 lg:col-12 xl:col-4">
        <div className="card">
          <div className="flex justify-content-between align-items-center">
            <h5>Chi phí</h5>
            <div>
              <InputNumber allowEmpty={false} className="config-input mb-3" value={textValue1} onChange={(e) => setTextValue1(e.value)} locale="vi-VN" min={1} max={24}/>
              <span>Tháng gần đây</span>
            </div>
          </div>
          <div className="p-card-content">
            <Chart type="line" data={chartData1} options={chartOptions1}/>
          </div>
        </div>
      </div>

      <div className="col-12 lg:col-12 xl:col-4">
        <div className="card">
          <div className="flex justify-content-between align-items-center">
            <h5>Lợi nhuận</h5>
            <div>
              <InputNumber allowEmpty={false} className="config-input mb-3" value={textValue2} onChange={(e) => setTextValue2(e.value)} locale="vi-VN" min={1} max={24}/>
              <span>Tháng gần đây</span>
            </div>
          </div>
          <div className="p-card-content">
            <Chart type="line" data={chartData2} options={chartOptions2}/>
          </div>
        </div>
      </div>

    </div>
  )
}

const ReportPeriods = [
  {value: 'THISWEEK', label: 'Tuần này'},
  {value: 'THISMONTH', label: 'Tháng này'},
  {value: 'THISFISCALQUARTER', label: 'Quý này'},
  {value: 'THISFISCALYEAR', label: 'Năm nay'},
  {value: 'FIRSTSIXMONTH', label: '6 tháng đầu năm'},
  {value: 'LASTSIXMONTH', label: '6 tháng cuối năm'},
  {value: 'JAN', label: 'Tháng 1'},
  {value: 'FEB', label: 'Tháng 2'},
  {value: 'MAR', label: 'Tháng 3'},
  {value: 'APR', label: 'Tháng 4'},
  {value: 'MAY', label: 'Tháng 5'},
  {value: 'JUN', label: 'Tháng 6'},
  {value: 'JUL', label: 'Tháng 7'},
  {value: 'AUG', label: 'Tháng 8'},
  {value: 'SEP', label: 'Tháng 9'},
  {value: 'OCT', label: 'Tháng 10'},
  {value: 'NOV', label: 'Tháng 11'},
  {value: 'DEC', label: 'Tháng 12'},
  {value: 'FISCALQUARTER1', label: 'Quý I'},
  {value: 'FISCALQUARTER2', label: 'Quý II'},
  {value: 'FISCALQUARTER3', label: 'Quý III'},
  {value: 'FISCALQUARTER4', label: 'Quý IV'},
  {value: 'LASTWEEK', label: 'Tuần trước'},
  {value: 'LASTMONTH', label: 'Tháng trước'},
  {value: 'LASTFISCALQUARTER', label: 'Quý trước'},
  {value: 'LASTFISCALYEAR', label: 'Năm trước'}
];