import {FormatDisplay, ToastService, useDialogCrup, useForm, validateTaxCode} from '@iamsoftware/react-hooks';
import {Fieldset} from 'primereact/fieldset';
import {Button} from 'primereact/button';

import {EmployeesService as Service} from './EmployeesService';
import {useEffect, useState} from 'react';
import {CommonService} from '../../../service/CommonService';
const partyIdTypes = [
  {value: 'PtidCccd', label: 'Căn cước công dân'},
  {value: 'PtidCmnd', label: 'Chứng minh nhân dân'}
];
export const useCrupEmployee = ({display, setDisplay, reloadLazyData}) => {
  const header = 'Nhân viên';
  const dataKey = 'partyId';
  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [readOnly, setReadOnly] = useState(false)
  const [agreementTypes, setAgreementTypes] = useState([]);
  useEffect(() => {
    CommonService.getAgreementTypes().then(data => {
      setAgreementTypes(data.listData.map(item => {
        return {value: item.enumId, label: item.description}
      }))
    })
  }, []);
  const commonInfoForm = useForm({
    fields: [
      {field: 'pseudoId', header: 'Mã nhân viên', required: true, className: 'md:col-3'},
      {field: 'partyName', header: 'Tên nhân viên', required: true, className: 'md:col-3'},
      {field: 'birthDate', header: 'Ngày sinh', InputTextProps: {type: 'date'}, className: 'md:col-3'},
      {field: 'gender', header: 'Giới tính', type: 'Dropdown', DropdownProps: {options: CommonService.Genders}, className: 'md:col-3'},
      {field: 'partyTaxId', header: 'Mã số thuế', className: 'md:col-3'},
      {field: 'jobTitle', header: 'Chức vụ', className: 'md:col-3'},
      {field: 'agreementTypeEnumId', header: 'Loại hợp đồng', type: 'Dropdown', DropdownProps: {options: agreementTypes}, className: 'md:col-3'},
      {field: 'amount', header: 'Lương cơ bản', type: 'InputNumber', className: 'md:col-3'},
      {field: 'partyIdTypeEnumId', header: 'Loại giấy tờ', type: 'Dropdown', DropdownProps: {options: CommonService.partyTypeEnumIds}, className: 'md:col-3'},
      {field: 'idValue', header: 'Số CMND/CCCD', className: 'md:col-3'},
      {field: 'issuedDate', header: 'Ngày cấp', className: 'md:col-3', InputTextProps: {type: 'date'}},
      {field: 'issuedBy', header: 'Nơi cấp', className: 'md:col-3'},
    ],
    readOnly
  });

  const contactInfoForm = useForm({
    fields: [
      {field: 'address1', header: 'Địa chỉ', className: 'md:col-12'},
      {field: 'contactNumber', header: 'Điện thoại di động', className: 'md:col-4'},
      {field: 'emailAddress', header: 'Địa chỉ email', className: 'md:col-8'},
    ],
    readOnly
  });

  const bankInfoForm = useForm({
    fields: [
      {field: 'accountNumber', header: 'Số tài khoản',required:true ,className: 'md:col-4'},
      {field: 'bankName', header: 'Tên ngân hàng',required:true, className: 'md:col-4'},
      {field: 'ownerAccountName', header: 'Chủ tài khoản',required:true, className: 'md:col-4'},
    ],
    readOnly
  });
  const {render: renderDialogCrup, create, update, view} = useDialogCrup({
    header,
    dataKey,
    width: '80rem',
    fields: [
      {
        type: 'custom', className: 'md-col-12 mb-3',
        body: <Fieldset legend="Thông tin chung">
          {commonInfoForm.render()}
        </Fieldset>
      },
      {
        type: 'custom', className: 'md-col-12 mb-3',
        body: <Fieldset legend="Thông tin liên hệ">
          {contactInfoForm.render()}
        </Fieldset>
      },
      {
        type: 'custom', className: 'md-col-12 mb-3',
        body: <Fieldset legend="Thông tin ngân hàng">
          {bankInfoForm.render()}
        </Fieldset>
      },
    ],
    createItem: async item => {
      if (!inject(item).partyName || !inject(item).partyName || !bankInfoForm.valid()){
        if (!inject(item)?.partyName){
          ToastService.error('Trường tên nhân viên không được rỗng')
        }
        if (!inject(item)?.pseudoId){
          ToastService.error('Trường mã nhân viên không được rỗng')
        }
        return Promise.reject();
      }else {
        return Service.create(await inject(item));
      }
    },
    updateItem: async (id, item) => {
      if (!inject(item).partyName || !inject(item).partyName || !bankInfoForm.valid()){
        if (!inject(item)?.partyName){
          ToastService.error('Trường tên nhân viên không được rỗng')
        }
        if (!inject(item)?.pseudoId){
          ToastService.error('Trường mã nhân viên không được rỗng')
        }
        return Promise.reject();
      }else {
        item.paymentMethodId = paymentMethodId
        return Service.update(id, await inject(item));
      }
    },
    reloadLazyData
  });

  const inject = item => {
    const commonInfo = commonInfoForm.getRawValue();
    const relatedInfo = contactInfoForm.getRawValue()
    const bankInfo = bankInfoForm.getRawValue()
    return Object.assign(item, bankInfo, commonInfo, relatedInfo);
  }


  const doCreate = () => {
    contactInfoForm.reset();
    commonInfoForm.reset();
    bankInfoForm.reset();
    create();
  }
  const doUpdate = (rowData, doView: boolean) => {
    Service.get(rowData.partyId).then(data => {
      data.birthDate = FormatDisplay.date(data.birthDate, 'YYYY-MM-DD');
      data.issuedDate = FormatDisplay.date(data.issuedDate, 'YYYY-MM-DD');
      setPaymentMethodId(data?.paymentMethodId);
      commonInfoForm.setValue(data);
      contactInfoForm.setValue(data);
      bankInfoForm.setValue(data);
      if (doView === true){
        setReadOnly(true)
        view(data)
      }else {
        setReadOnly(false);
        update(data);
      }
    });
  }

  const render = () => {
    return (
      renderDialogCrup()
    )
  }

  return {renderEmployee: render, doCreateEmployee: doCreate, doUpdateEmployee: doUpdate};

}