import {Route, Routes} from 'react-router-dom';
import {UOM} from './uom';
import {BusinessJobs} from './business-jobs';
import {ExpenseItems} from './khoan-muc-chi-phi';
import {BankAccounts} from './tai-khoan-ngan-hang'

export default function DanhMuc() {

  return (
    <Routes>
      <Route path='uom' element={<UOM/>}/>
      <Route path='business-jobs' element={<BusinessJobs/>}/>
      <Route path='khoan-muc-chi-phi' element={<ExpenseItems/>}/>
      <Route path='tai-khoan-ngan-hang' element={<BankAccounts/>}/>
    </Routes>
  );
}
