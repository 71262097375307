import {Fragment, useEffect, useState} from 'react';
import {Fieldset} from 'primereact/fieldset';

import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {Checkbox} from "primereact/checkbox";

import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDialogCrup, useForm, useToolbar} from '@iamsoftware/react-hooks';
import {TaxDeclareService as Service} from './TaxDeclareService';
import {CommonService} from '../../../service/CommonService';
import moment from 'moment/moment';
import {CrupCreate} from './crupCreate';
import {DeclareDetail} from './DeclareDetail';

const statuses = [
  {value: 'N', label: 'Đang hoạt động'},
  {value: 'Y', label: 'Ngừng hoạt động'}
];


export const ToKhai = () => {
  const [headerResult, setHeaderResult] = useState(null);
  const [displayCreate, setDisplayCreate] = useState(null);
  const [displayDetail, setDisplayDetail] = useState(null);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    setHeaderResult(tabHeader)
  }, []);
  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>01/CNKD - Tờ khai thuế đối với hộ kinh doanh, cá nhân kinh doanh</span>
          <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"
                  size="small" onClick={() => {
            setDisplayCreate(Date.now())
          }}/>
        </Fragment>
      </div>
    </div>
  );
  const {render: renderDataTable, reloadLazyData} = useDataTable({
    stateKey: 'mainTable',
    tableHeader: tabHeader,
    dataKey: 'declarationId',
    columns: [
      {field: 'createdDate', header: 'Ngày lập', width: 100, matchMode: 'contains', dataType: 'date'},
      {
        field: 'declarationPseudoId', header: 'Mã tờ khai', width: 100, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <a onClick={() => {
            setUpdating(false);
            setDisplayDetail(rowData)
          }}>{rowData.declarationPseudoId}</a>
        }
      },
      {field: 'declarationName', header: 'Tên tờ khai', width: 300, matchMode: 'contains'},
      {
        header: 'Kỳ kê khai', width: 100, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          if (rowData.timePeriodTypeId === "TaxQuarter" || rowData.timePeriodTypeId === "TaxMonth") {
            return <span>{rowData.periodType} {rowData.periodNum} năm {rowData.yearName}</span>
          } else if (rowData.timePeriodTypeId === "TaxYear") {
            return <span>Từ tháng {rowData.periodNum}/{rowData.yearName} đến tháng {rowData.toPeriodNum}/{rowData.yearName}</span>
          } else if (rowData.timePeriodTypeId === "TaxDate") {
            return <span>{FormatDisplay.date(rowData.fromDate, "DD/MM/YYYY")}</span>
          }
        }
      },
      {
        field: 'firstDeclaration', header: 'Tờ khai lần đầu', width: 100, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          let checked = false;
          if (rowData.firstDeclaration === "Y") {
            checked = true
          } else {
            checked = false
          }
          return <span className={'flex justify-content-center flex-wrap'}>
                    <Checkbox checked={checked}/>
          </span>
        }
      },
      {field: 'additionalTime', header: 'Bổ sung lần thứ', width: 100, matchMode: 'contains'},

    ],
    indexColumnWidth: 45,
    actionColumnWidth: 80,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent)
    },
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {
              icon: 'pi pi-pencil', title: 'Cập nhật tờ khai', className: 'p-button-warning', command: () => {
                setUpdating(true);
                setDisplayDetail(item)
              }
            },
            {
              icon: 'pi pi-download', title: 'Tải XML', className: 'p-button-secondary', command: () => {
                downloadXml(item.declarationId);
              }
            }
          ]
        })
      }
    },

  });

  const downloadXml = (item) =>{
    if (item){
      window.open(`${Service.baseURL}/${Service.entity}/${item}/export-xml`)
    }
  }
  return (
    <div className="grid">
      {renderDataTable()}
      <CrupCreate display={displayCreate} setDisplay={setDisplayCreate} reloadLazyData={reloadLazyData}/>
      <DeclareDetail display={displayDetail} setDisplay={setDisplayDetail} doUpdate={updating} reloadLazyData={reloadLazyData}/>
    </div>
  );
}