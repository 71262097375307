import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {useDataTable, useDataTableBasic, useForm} from '@iamsoftware/react-hooks';
import {CommonService} from '../../../service/CommonService';
import {CustomersService} from '../../ban-hang/khach-hang/CustomersService';

export const ObjectType = ({method, setObjectType, display, setDisplay}) =>{
  const [roleTypes, setRoleTypes] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (display){
      CommonService.getPartyRoles().then(data => {
        setRoleTypes(data.listData.map(item => {
          return {value: item.roleTypeId, label: item.description}
        }))
      })
    }
  }, [display]);

  useEffect(() => {
    if (method){
      commonInfoForm.reset()
    }
  }, [display]);

  const commonInfoForm = useForm({
    fields:[
      {
        field: 'roleTypeId', header: 'Loại đối tượng', required: true, className: 'md:col-4', type: 'Dropdown', DropdownProps: {options: roleTypes}
      },
    ]
  })

  const {render: renderDataTable, selectedItems} = useDataTableBasic({
    tableHeader: 'Đối tượng',
    indexColumnWidth:45,
    dataKey:'partyId',
    columns:[
      {field:'pseudoId', header:'Mã đối tượng', minWidth:150,matchMode:'contains'},
      {field:'partyName', header:'Tên đối tượng', minWidth:250,matchMode:'contains'},
      {field:'partyTypeEnum', header:'Loại đối tượng', minWidth:150,matchMode:'contains'},
    ],
    items
  })

  const selectedItem: any = (selectedItems && selectedItems['partyId']) ? selectedItems : null;

  const _value = commonInfoForm.getRawValue()

  useEffect(() => {
    setItems([])
    doSearch(_value.roleTypeId)
  }, [_value.roleTypeId]);

  const doSearch = (roleTypeId) =>{
    if (roleTypeId){
      CustomersService.find(roleTypeId, '').then(data => {
        setItems(data.listData.map(item => {
          delete item.disabled;
          let label = `[${item.pseudoId}] - ${item.partyName}`;
          return {...item, value: item.partyId, label: label};
        }));
      });
    }
  }

  const doConfirm = ()=>{
    if (selectedItems && selectedItem){
      setObjectType([selectedItem]) ;
      setDisplay(null)
      setItems([])
      commonInfoForm.reset()
    }
  }

  const footer = (
    <div>
      <Button label="Đồng ý" icon="pi pi-check" type="button" outlined severity="success" size="small" onClick={() => doConfirm()}/>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
    </div>
  );

  return (
    <Dialog header="Tìm kiếm đối tượng" footer={footer} visible={!!display} style={{width: '80rem'}} position="top" onHide={() => setDisplay(null)}>
      <div className="grid">
        <div className="col-12">
          {commonInfoForm.render()}
        </div>
        <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 18rem)'}}>
          {renderDataTable()}
        </div>
      </div>
    </Dialog>
  );
}