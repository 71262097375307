import {EntityService} from 'src/service/EntityService';

export class RequestService extends EntityService {

  static entity = 'iam-integrate/gdt';

  static getList(lazyLoadEvent: string): Promise<any> {
    return this.axios.get(`${this.entity}/sync-requests?lazyLoadEvent=${lazyLoadEvent}`).then(res => res.data);
  }
  static getMessageTypes(): Promise<any> {
    return this.axios.get(`${this.entity}/message-types`).then(res => res.data);
  }
  static getCaptcha(): Promise<any> {
    return this.axios.get(`${this.entity}/captcha`).then(res => res.data);
  }
  static createRequest(data:any): Promise<any> {
    return this.axios.post(`${this.entity}/sync-requests`, data).then(res => res.data);
  }
  static syncDetail(requestSyncId:string): Promise<any> {
    return this.axios.get(`${this.entity}/sync-requests/${requestSyncId}/sent-messages`).then(res => res.data);
  }
}
