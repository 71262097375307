import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {RadioButton} from "primereact/radiobutton";
import { TabView, TabPanel } from 'primereact/tabview';

import {FormatDisplay, ToastService, useDataTableBasic, useForm} from '@iamsoftware/react-hooks';
import {TaxDeclareService as Service} from './TaxDeclareService'
import {CommonService} from '../../../service/CommonService';

export const CrupCreate = ({display, setDisplay, reloadLazyData}) => {
  const [headerDate, setHeaderDate] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [taxDeclarationTypes, setTaxDeclarationTypes] = useState([]);
  const [timePeriodTypes, setTimePeriodTypes] = useState([])
  const [departmentTaxAuthorityIds, setDepartmentTaxAuthorityIds] = useState([])
  const [districtDeptTaxAuthorityIds, setDistrictDeptTaxAuthorityIds] = useState([])
  const [firstDeclare, setFirstDeclare] = useState([])

  //phụ lục
  const addendumList = [
    {dataKey: '01-2/BK-HDKD', pseudoId: '01-2/BK-HĐKD' ,addendumName: 'Phụ lục 01-2/BK-HĐKD: Bảng kê hoạt động kinh doanh trong kỳ của hộ kinh doanh, cá nhân kinh doanh'},
    {dataKey: '110/2023/QH15', pseudoId: 'PL 110/2023/QH15' ,addendumName: 'Giảm thuế giá trị gia tăng theo Nghị quyêt số 110/2023/QH15'}
  ]

  useEffect(() => {
    CommonService.getTaxDeclarationType().then(data => {
      setTaxDeclarationTypes(data.listData.map(item => {
        return {value: item.enumId, label: item.description};
      }))
    });
    CommonService.getTaxTimePeriodType().then(data => {
      setTimePeriodTypes(data.listData.map(item => {
        return {value: item.timePeriodTypeId, label: item.description};
      }))
    });
    CommonService.getDepartmentTaxAuthority().then(data => {
      setDepartmentTaxAuthorityIds(data.listData.map(item => {
        return {value: item.taxAuthorityId, label: item.description};
      }))
    });
    value.declarationName = "TỜ KHAI THUẾ ĐỐI VỚI HỘ KINH DOANH, CÁ NHÂN KINH DOANH"
    value.declarationTypeEnumId = "TdtKeKhai"
    value.timePeriodTypeId = "TaxQuarter"
    value.periodNum = "1"
    value.yearName = new Date().getFullYear();
    setSelectedCategory("1")
    form.setValue(value)
    setSelectedItems([{dataKey: '01-2/BK-HDKD', pseudoId: '01-2/BK-HĐKD' ,addendumName: 'Phụ lục 01-2/BK-HĐKD: Bảng kê hoạt động kinh doanh trong kỳ của hộ kinh doanh, cá nhân kinh doanh'}])

  }, [display])


  const header = (
    <div style={{textAlign: 'center'}}>
      <span>Tạo mới tờ khai cá nhân kinh doanh</span>
    </div>
  )

  const {render: renderDataTableBasic, selectedItems,setSelectedItems} = useDataTableBasic({
    dataKey: 'dataKey',
    indexColumnWidth:45,
    selectionMode: 'checkbox',
    columns:[
      {field:'pseudoId', header:'Mã phụ lục', width: 250},
      {field:'addendumName', header:'Tên phụ lục', minWidth: 200},
    ],
    items: addendumList,
  })

  const form = useForm({
    fields: [
      {field: 'declarationName', header: 'Tên tờ khai', required: true, className: 'md:col-12'},
      {field: 'declarationTypeEnumId', header: 'Đối tượng', required: true, type: 'Dropdown', DropdownProps: {options: taxDeclarationTypes}, className: 'md:col-12'},
      {
        field: 'timePeriodTypeId', header: 'Kì kê khai', required: true, type: 'Dropdown', DropdownProps: {options: timePeriodTypes}, className: 'md:col-3', disabledDependency(item: any): boolean {
          return selectedCategory === "2"
        }
      },
      {
        field: 'dateEffective', header: 'Ngày', required: true, className: 'md:col-3', displayDependency(item: any): boolean {
          return value.timePeriodTypeId === 'TaxDate'
        }, type: 'Dropdown', DropdownProps: {options: Days},
        disabledDependency(item: any): boolean {
          return selectedCategory === "2"
        }
      },
      {
        field: 'periodNum', header: 'Quý', required: true, className: 'md:col-3', displayDependency(item: any): boolean {
          return value.timePeriodTypeId === 'TaxQuarter'
        }, type: 'Dropdown', DropdownProps: {options: Quarters}, disabledDependency(item: any): boolean {
          return selectedCategory === "2"
        }
      },
      {
        field: 'periodNum', header: 'Tháng', required: true, className: 'md:col-3', displayDependency(item: any): boolean {
          return value.timePeriodTypeId === 'TaxMonth' || value.timePeriodTypeId === 'TaxDate'
        }, type: 'Dropdown', DropdownProps: {options: Months}, disabledDependency(item: any): boolean {
          return selectedCategory === "2"
        }
      },
      {
        field: 'periodNum', header: 'Từ tháng', required: true, className: 'md:col-3', displayDependency(item: any): boolean {
          return value.timePeriodTypeId === 'TaxYear'
        }, type: 'Dropdown', DropdownProps: {options: Months}, disabledDependency(item: any): boolean {
          return selectedCategory === "2"
        }
      },
      {
        field: 'toPeriodNum', header: 'Đến tháng', required: true, className: 'md:col-3', displayDependency(item: any): boolean {
          return value.timePeriodTypeId === 'TaxYear'
        }, type: 'Dropdown', DropdownProps: {options: Months}, disabledDependency(item: any): boolean {
          return selectedCategory === "2"
        }
      },

      {
        field: 'yearName', header: 'Năm', required: true, type: 'Dropdown', DropdownProps: {options: Years}, className: 'md:col-3', disabledDependency(item: any): boolean {
          return selectedCategory === "2"
        }
      },
      {
        type: 'custom', body: <div></div>, className: 'md:col-3 ', displayDependency(item: any): boolean {
          return value.timePeriodTypeId === 'TaxQuarter' || value.timePeriodTypeId === 'TaxMonth'

        }
      },
      {
        type: 'custom', body: <div></div>, className: 'md:col-6', displayDependency(item: any): boolean {
          return value.timePeriodTypeId === ''

        }
      },
      {field: 'departmentTaxAuthorityId', header: 'CQT cấp Cục', required: true, className: 'md:col-6', type: 'Dropdown', DropdownProps: {options: departmentTaxAuthorityIds}},
      {field: 'districtDeptTaxAuthorityId', header: 'CQT nơi nộp', required: true, className: 'md:col-6', type: 'Dropdown', DropdownProps: {options: districtDeptTaxAuthorityIds}},
      {
        type: 'custom', body: <div className={'flex flex-column gap-3'} style={{marginTop: '11px'}}>
          <div className="flex align-items-center">
            <RadioButton inputId="input1" value="1" onChange={(e) => setSelectedCategory(e.value)} checked={selectedCategory === '1'}/>
            <label htmlFor="input1" className="ml-2">Tờ khai lần đầu</label>
          </div>
          <div className="flex align-items-center">
            <RadioButton inputId="input2" value="2" onChange={(e) => setSelectedCategory(e.value)} checked={selectedCategory === '2'}/>
            <label htmlFor="input2" className="ml-2">Tờ khai bổ sung</label>
          </div>
        </div>, className: 'md:col-3'
      },
      {
        field: 'additionalTime', header: 'Lần', InputTextProps: {type: 'number'}, required: true, className: 'md:col-3', displayDependency(item: any): boolean {
          return selectedCategory === '2'
        }
      },
      {
        field: 'firstDeclaration', header: 'Tờ khai lần đầu', required: true, className: 'md:col-6', displayDependency(item: any): boolean {
          return selectedCategory === '2'
        }, type: 'Dropdown', DropdownProps: {options: firstDeclare}
      },
      {
        type:'custom', body: <TabView>
          <TabPanel header="CHỌN PHỤ LỤC TỜ KHAI">
            {renderDataTableBasic()}
          </TabPanel>
        </TabView>
      }
    ],
  });
  const value = form.getValue()

  useEffect(() => {
    CommonService.getDistrictDeptTaxAuthority(value?.departmentTaxAuthorityId).then(data => {
      setDistrictDeptTaxAuthorityIds(data.listData.map(item => {
        return {value: item.toTaxAuthorityId, label: item.toDescription};
      }))
    });
  }, [value?.departmentTaxAuthorityId])
  useEffect(() => {
    if (selectedCategory === "2") {
      Service.getTaxList().then(data => {
        setFirstDeclare(data.listData.map(item => {
          return {
            value: item.declarationId, label: item.declarationPseudoId,
            timePeriodTypeId: item.timePeriodTypeId,
            periodNum: item.periodNum, yearName: item.yearName,
            departmentTaxAuthorityId: item.departmentTaxAuthorityId,
            districtDeptTaxAuthorityId: item.districtDeptTaxAuthorityId
          };
        }))
      })
    }
  }, [selectedCategory])
  useEffect(() => {
    if (value?.firstDeclaration) {
      firstDeclare.map(item => {
        if (item.value = value.firstDeclaration) {
          value.periodNum = item.periodNum
          value.departmentTaxAuthorityId = item.departmentTaxAuthorityId
          value.districtDeptTaxAuthorityId = item.districtDeptTaxAuthorityId
          value.timePeriodTypeId = item.timePeriodTypeId
          value.yearName = item.yearName
          form.setValue(value)
        }
      })
    }
  }, [value?.firstDeclaration])
  const onSubmit = () => {
    if (form.valid) {
      if (selectedCategory === '1') {
        value.firstDeclaration = 'Y'
        value.additionalTime = null
      } else {
        value.firstDeclaration = 'N'
      }
      if (selectedItems.find((item)=>item.dataKey ==='110/2023/QH15')){
        value.addendumTaxDeduction = 'Y';
      }else {
        value.addendumTaxDeduction = 'N';
      }
      if (!selectedItems.find((item)=>item.dataKey === '01-2/BK-HDKD')){
        ToastService.error('Phụ lục 01/2/BK-HĐKD là phụ lục bắt buộc')
      }
      Service.create(value).then(() => {
        ToastService.success();
        setDisplay(null);
        reloadLazyData();
        form.reset();
      })
    }
  }
  const footer = (
    <div className="flex flex-row-reverse flex-wrap">
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
      <Button label="Lưu" icon="pi pi-check" type="button" outlined severity="info" size="small" onClick={() => onSubmit()}/>
    </div>
  );
  return (
    <Dialog header={header} footer={footer} visible={!!display} style={{width: '1000px'}} onHide={() => setDisplay(null)}>
      {form.render()}
    </Dialog>
  );

}
const year = new Date().getFullYear();
const Years = [
  {value: year, label: year},
  {value: year - 1, label: year - 1},
  {value: year - 2, label: year - 2},
  {value: year - 3, label: year - 3},
  {value: year - 4, label: year - 4},
  {value: year - 5, label: year - 5},
];
const Quarters = [
  {value: 1, label: 1},
  {value: 2, label: 2},
  {value: 3, label: 3},
  {value: 4, label: 4}
];
const Months = [
  {value: 1, label: 1},
  {value: 2, label: 2},
  {value: 3, label: 3},
  {value: 4, label: 4},
  {value: 5, label: 5},
  {value: 6, label: 6},
  {value: 7, label: 7},
  {value: 8, label: 8},
  {value: 9, label: 9},
  {value: 10, label: 10},
  {value: 11, label: 11},
  {value: 12, label: 12}
];
const Days = [
  {value: 1, label: 1},
  {value: 2, label: 2},
  {value: 3, label: 3},
  {value: 4, label: 4},
  {value: 5, label: 5},
  {value: 6, label: 6},
  {value: 7, label: 7},
  {value: 8, label: 8},
  {value: 9, label: 9},
  {value: 10, label: 10},
  {value: 11, label: 11},
  {value: 12, label: 12},
  {value: 13, label: 13},
  {value: 14, label: 14},
  {value: 15, label: 15},
  {value: 16, label: 16},
  {value: 17, label: 17},
  {value: 18, label: 18},
  {value: 19, label: 19},
  {value: 20, label: 20},
  {value: 21, label: 21},
  {value: 22, label: 22},
  {value: 23, label: 23},
  {value: 24, label: 24},
  {value: 25, label: 25},
  {value: 26, label: 26},
  {value: 27, label: 27},
  {value: 28, label: 28},
  {value: 29, label: 29},
  {value: 30, label: 30},
  {value: 31, label: 31},
];