import {EntityService} from '../../../../service/EntityService';

export class UserAccountsService extends EntityService {

  static entity = 'iam-manager/user-accounts';

  static lock(userId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${userId}/lock`).then(res => res.data);
  }

  static unlock(userId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${userId}/unlock`).then(res => res.data);
  }

  static resetPassword(userId: string, password: string): Promise<any> {
    return this.axios.put(`${this.entity}/${userId}/reset-password`, {password}).then(res => res.data);
  }

}
